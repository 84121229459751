import clsx from 'clsx';
import type { JSX } from 'react';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { linkTo } from 'ts/commons/links/LinkTo';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import type { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Props for SubViewMenu. */
type SubViewMenuProps = {
	views: ViewDescriptor[];
};

/** Creates the menu for switching views within a perspective from an expanded sidebar. */
export function SubViewMenu({ views }: SubViewMenuProps): JSX.Element | null {
	const hash = useNavigationHash();
	const currentView = hash.getViewName();
	if (views.length === 0) {
		return null;
	}

	const activeSubview = PerspectiveViewDescriptorBase.findViewDescriptor(
		views,
		currentView ?? '',
		hash.getAction() ?? undefined
	);
	return (
		<div id="sub-menu-container" className="ui secondary left pointing vertical menu" style={{ width: '100%' }}>
			{views.map(view => (
				<div key={view.anchor} className="item-wrapper">
					<TeamscaleLink
						data-name={view.name}
						className={clsx('item', 'view-switcher-' + view.anchor, {
							active: view.anchor === activeSubview?.anchor
						})}
						to={getLinkToPerspective(hash.getPerspective(), view, hash)}
					>
						{view.name}
						{!!view.isBeta && <span className="beta-label">Beta</span>}
					</TeamscaleLink>
				</div>
			))}
		</div>
	);
}

/**
 * Returns the link to the view on the given perspective taking the #keepPathAndArgumentsOfCurrentViewForSubviews into
 * account.
 */
export function getLinkToPerspective(
	perspective: ETeamscalePerspective,
	viewDescriptor: ViewDescriptor,
	currentHash: NavigationHash
): string {
	return (
		linkTo(
			perspective,
			viewDescriptor,
			// Links to project-specific perspectives or to the Dashboard perspective should keep the currently selected project (if available)
			viewDescriptor.requiresProject ? currentHash.getProject() : undefined
		) +
		'/' +
		(viewDescriptor.keepPathAndArgumentsOfCurrentViewForSubviews
			? currentHash.getProjectAndPath().getPath() + currentHash.getArgumentsString()
			: '')
	);
}
