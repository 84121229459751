import { absoluteLocationToArtificialPath } from 'ts/base/routing/PerspectiveHashHistory';
import { HISTORY } from 'ts/base/routing/PerspectiveHashRouter';

/**
 * Navigates to the given full URL (incl. protocol, base URL etc.) using either React Router if appropriate or by
 * navigating to the page via window.location.
 */
export function navigateTo(href: string, replace?: boolean, state?: unknown, openInNewTab?: boolean): void {
	if (openInNewTab) {
		window.open(href, '_blank');
		return;
	}
	if (shouldBrowserHandle(href)) {
		window.location.href = href;
		return;
	}
	// If the URL hasn't changed, a regular <a> will do a replace instead of
	// a push, so do the same here.
	const shouldReplace = replace ?? window.location.href === href;

	const to = absoluteLocationToArtificialPath(new URL(href));
	if (shouldReplace) {
		HISTORY.replace(to, state);
	} else {
		HISTORY.push(to, state);
	}
}

/** Determines whether the given full URL can be navigated to using the React Router. */
export function shouldBrowserHandle(href: string): boolean {
	let url: URL;
	try {
		url = new URL(href);
	} catch (e) {
		return true;
	}
	return (
		// Let browser handle e.g. /logout
		(!url.pathname.endsWith('.html') && !url.pathname.endsWith('.html/')) ||
		// Let browser handle external links
		window.location.host !== url.host ||
		// Let the browser handle mailto links and similar
		!url.protocol.startsWith('http')
	);
}
