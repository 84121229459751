import * as MetricsUtilsTemplate from 'soy/commons/MetricsUtilsTemplate.soy.generated';
import type { SanitizedHtml } from 'ts-closure-library/lib/soy/data';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import {
	getColorRepresentationForSemanticColor,
	TRAFFIC_LIGHT_GREEN,
	TRAFFIC_LIGHT_ORANGE,
	TRAFFIC_LIGHT_RED,
	TRAFFIC_LIGHT_YELLOW_ALTERNATIVE
} from 'ts/perspectives/tests/pareto/data/ColorSemantics';
import { EMetricValueType } from 'typedefs/EMetricValueType';
import type { MetricAssessment } from 'typedefs/MetricAssessment';
import type { MetricDirectorySchemaEntry } from 'typedefs/MetricDirectorySchemaEntry';
import { MetricProperties } from './../MetricProperties';
import { UIUtils } from './../UIUtils';
import { MetricFormatterBase } from './MetricFormatterBase';

/** The options supported by this formatter. */
export type NumericFormatterOptions = Partial<{
	/** Whether non-ratio values should be abbreviated (e.g, 1000 -> 1k). Defaults to {@code true}. */
	[MetricFormatterBase.ABBREVIATE_VALUES_OPTION]: boolean;
	/**
	 * Whether the value should be interpreted as bytes and thus abbreviation must divide by 1024 instead of 1000.
	 * Defaults to {@code false}.
	 */
	[MetricFormatterBase.VALUE_IS_BYTES_OPTION]: boolean;
	/** The number of decimal places to use. Defaults to {@code 1}. */
	[MetricFormatterBase.DECIMAL_PLACES_OPTION]: number;
	/** Whether positive numbers should be rendered with a sign as well. Defaults to {@code false}. */
	[MetricFormatterBase.ALWAYS_SHOW_SIGN]: boolean;
	/** The tooltip to use by {@link NumericValueFormatter#formatValueAsHtml}. */
	[MetricFormatterBase.TOOLTIP_OVERRIDE_OPTION]: string;
	/** Optional: The rating for the numeric value (e.g. 'YELLOW'). */
	rating: string;
}>;

/** Describes how numbers should be treated during formatting (e.g. when rounding) */
export type NumberFormatInfo = {
	maxDecimals: number;
	minDecimals?: number;
};

/** A formatter for numeric values. */
export class NumericValueFormatter extends MetricFormatterBase<NumericFormatterOptions, number> {
	/** A pattern describing durations given as strings */
	public static readonly DURATION_PATTERN =
		'((?<days>\\d+)d)?' +
		'\\s*((?<hours>\\d+)h)?' +
		'\\s*((?<minutes>\\d+)m(\\s+|$)+)?' +
		'\\s*((?<seconds>\\d+)s)?' +
		'\\s*((?<millis>\\d+)ms)?$';

	public constructor(options: NumericFormatterOptions) {
		super(options);
	}

	/** Parses the given number string as float value. */
	public static parse(numberString: string): number {
		return parseFloat(numberString);
	}

	/** Formats the given number, using a max. decimal number of 1 per default. */
	public static formatNumberDefault(value: number, maxDecimals = 1): string {
		return NumericValueFormatter.roundWithMaxDecimals(value, maxDecimals).toLocaleString('en');
	}

	private static roundWithMaxDecimals(value: number, maxDecimals: number): number {
		return Number(Math.round(Number(value + `e+${maxDecimals}`)) + `e-${maxDecimals}`);
	}

	/**
	 * Formats the given value as percentage (e.g. 2 -> 200%). Will also append the '%' sign. If the value were rounded
	 * from a value not equal to 1 to a value of 1, the percentage value will be corrected to 99.9% (TS-32703)
	 */
	public static formatAsPercentage(
		value: number,
		roundingInfo: NumberFormatInfo = {
			minDecimals: 1,
			maxDecimals: 1
		}
	): string {
		let roundedValue = NumericValueFormatter.roundWithMaxDecimals(value * 100.0, roundingInfo.maxDecimals);
		if (roundedValue === 100 && value < 1) {
			roundedValue -= 0.1;
		}
		return (
			roundedValue.toLocaleString('en', {
				minimumFractionDigits: roundingInfo.minDecimals
			}) + '%'
		);
	}

	public override formatValueAsHtml(value: number, colorBlindModeEnabled?: boolean): SanitizedHtml {
		const formattedValue = this.formatValueAsText(value);
		const tooltipText = this.getStringOption(MetricFormatterBase.TOOLTIP_OVERRIDE_OPTION, String(value));
		const color = NumericValueFormatter.getMetricColor(this.options.rating, colorBlindModeEnabled);
		return UIUtils.sanitizedHtml(
			MetricsUtilsTemplate.stringValuedMetric({
				value: formattedValue,
				tooltip: tooltipText,
				sortValue: value as string | number,
				color
			})
		);
	}

	public override formatValueAsText(value: string | number): string {
		const roundNonRatioValues = this.getBooleanOption(MetricFormatterBase.ABBREVIATE_VALUES_OPTION, true);
		const valueIsBytes = this.getBooleanOption(MetricFormatterBase.VALUE_IS_BYTES_OPTION, false);
		const decimalPlaces = this.getNumberOption(MetricFormatterBase.DECIMAL_PLACES_OPTION, 1);
		const isRatioMetric = this.getBooleanOption(MetricFormatterBase.IS_RATIO_OPTION, false);
		const alwaysShowSign = this.getBooleanOption(MetricFormatterBase.ALWAYS_SHOW_SIGN, false);
		const isDurationMetric = this.getBooleanOption(MetricFormatterBase.IS_DURATION_OPTION, false);
		let formattedValue = NumericValueFormatter.formatNumericMetricAsText(
			value,
			{ valueType: EMetricValueType.NUMERIC.name } as MetricDirectorySchemaEntry,
			roundNonRatioValues,
			valueIsBytes,
			isRatioMetric,
			decimalPlaces,
			isDurationMetric
		);
		if (alwaysShowSign && Number(value) >= 0) {
			formattedValue = '+' + formattedValue;
		}
		return formattedValue;
	}

	/**
	 * Returns the matching color for a given metric, if a rating is available otherwise returns <code>null</code>.
	 *
	 * @returns Html color code that should be used for this metric
	 */
	public static getMetricColor(
		metricAssessmentColor: string | null | undefined,
		colorBlindModeEnabled?: boolean
	): string | undefined {
		if (metricAssessmentColor == null) {
			return undefined;
		}
		switch (metricAssessmentColor) {
			case 'GREEN':
				return getColorRepresentationForSemanticColor(TRAFFIC_LIGHT_GREEN, colorBlindModeEnabled);
			case 'YELLOW':
				return getColorRepresentationForSemanticColor(TRAFFIC_LIGHT_YELLOW_ALTERNATIVE, colorBlindModeEnabled);
			case 'RED':
				return getColorRepresentationForSemanticColor(TRAFFIC_LIGHT_RED, colorBlindModeEnabled);
			case 'ORANGE':
				return getColorRepresentationForSemanticColor(TRAFFIC_LIGHT_ORANGE, colorBlindModeEnabled);
			default:
				return undefined;
		}
	}

	/**
	 * Formats a numeric metric value as text.
	 *
	 * @param metricValue - The numeric metric value.
	 * @param schemaEntryOrMetricAssessment
	 * @param roundNonRatioValues - Whether non-ratio values should be rounded to k(ilo), G(iga), ...
	 * @param valueIsBytes - Whether the value is bytes and thus rounding is performed by 1024 steps
	 * @param isRatioMetric - Whether the metric is displayed as percentage (e.g. clone coverage)
	 * @param decimalPlaces - The maximum number of decimal places that should be shown
	 * @param isDurationMetric - Whether the metric's value represents a duration
	 */
	public static formatNumericMetricAsText(
		metricValue: number | string,
		schemaEntryOrMetricAssessment: MetricDirectorySchemaEntry | MetricAssessment,
		roundNonRatioValues = true,
		valueIsBytes = false,
		isRatioMetric = false,
		decimalPlaces = 1,
		isDurationMetric = false
	): string {
		metricValue = Number(metricValue);
		if (isDurationMetric) {
			return NumericValueFormatter.convertSecondsToFormattedTime(metricValue, roundNonRatioValues);
		} else if (isRatioMetric) {
			return NumericValueFormatter.formatAsPercentage(metricValue, {
				maxDecimals: decimalPlaces,
				minDecimals: decimalPlaces
			});
		} else if (roundNonRatioValues) {
			return NumericValueFormatter.formatDoubleMetricCompact(
				metricValue,
				schemaEntryOrMetricAssessment,
				valueIsBytes
			);
		}
		return NumericValueFormatter.formatNumberDefault(metricValue, decimalPlaces);
	}

	/**
	 * Formats the number of a double metric as a string and performs "smart" conversions to keep the number compact.
	 *
	 * @param value The metric value.
	 * @param valueIsBytes - Whether the value is bytes and thus rounding is performed by 1024 steps
	 */
	public static formatDoubleMetricCompact(
		value: number,
		schemaEntryOrMetricAssessment?: MetricDirectorySchemaEntry | MetricAssessment,
		valueIsBytes = false
	): string {
		let suffix = '';
		if (schemaEntryOrMetricAssessment && MetricProperties.isRatioMetric(schemaEntryOrMetricAssessment)) {
			value *= 100;
			suffix = '%';
		} else {
			let divider = 1000;
			if (valueIsBytes) {
				divider = 1024;
			}
			const suffixes = ['k', 'M', 'G', 'T', 'P'];
			while (Math.abs(value) > divider) {
				value /= divider;
				suffix = suffixes.shift()!;
			}
		}
		return NumericValueFormatter.formatNumberDefault(value) + suffix;
	}

	/**
	 * Converts a given value in seconds to hours, minutes and remaining seconds
	 *
	 * @param totalSeconds The total number of seconds
	 */
	public static convertSecondsToFormattedTime(totalSeconds: number, abbreviateValues = true): string {
		if (totalSeconds <= 0) {
			return '0s';
		}
		if (!abbreviateValues) {
			if (totalSeconds < 1) {
				return Number(totalSeconds).toFixed(3) + 's';
			}
			return Number(totalSeconds).toFixed(0) + 's';
		}

		const secondsPerDay = 3600 * 24;
		const result: Array<{ value: number; unit: string }> = [
			{ unit: 'd', value: Math.floor(totalSeconds / secondsPerDay) },
			{ unit: 'h', value: Math.floor((totalSeconds % secondsPerDay) / 3600) },
			{ unit: 'm', value: Math.floor((totalSeconds % 3600) / 60) },
			{ unit: 's', value: Math.floor(totalSeconds % 60) },
			{ unit: 'ms', value: Math.round((totalSeconds - Math.floor(totalSeconds)) * 1000) }
		];

		return ArrayUtils.trimArray(result, element => element.value === 0)
			.map(element => `${element.value}${element.unit}`)
			.join(' ');
	}

	/** Converts a given string in the format of `DURATION_PATTERN` to seconds (a number). */
	public static convertDurationTextToSeconds(durationText: string): number {
		if (StringUtils.isEmptyOrWhitespace(durationText)) {
			return 0;
		}

		const matchResult = new RegExp(NumericValueFormatter.DURATION_PATTERN).exec(durationText);

		let resultSeconds = 0;
		if (matchResult?.groups && Object.values(matchResult.groups).filter(v => v).length > 0) {
			resultSeconds = resultSeconds + Number.parseInt(matchResult.groups['days'] ?? '0') * 24 * 60 * 60;
			resultSeconds = resultSeconds + Number.parseInt(matchResult.groups['hours'] ?? '0') * 60 * 60;
			resultSeconds = resultSeconds + Number.parseInt(matchResult.groups['minutes'] ?? '0') * 60;
			resultSeconds = resultSeconds + Number.parseInt(matchResult.groups['seconds'] ?? '0');
			resultSeconds = resultSeconds + Number.parseInt(matchResult.groups['millis'] ?? '0') / 1000;

			return resultSeconds;
		} else {
			return NaN;
		}
	}
}
