import { DateUtils } from 'ts/commons/DateUtils';

/**
 * An object that has all the necessary field for a commit descriptor, but not necessarily the methods of
 * CommitDescriptor.
 */
type CommitDescriptorLike = {
	/** The timestamp. null indicates HEAD */
	timestamp: number | null;
	/** The branch name. null indicates default branch */
	branchName: string | null;
};

/** Describes a single commit. */
export class UnresolvedCommitDescriptor {
	/** The timestamp used to identify the latest revision. */
	public static HEAD_REVISION_TIMESTAMP = 'HEAD';

	/**
	 * @param timestamp The timestamp (may be null to indicate branch head).
	 * @param branchName The branch name (may be null to indicate default branch)
	 * @param previous Number of revisions to go back. Indicating how many parent commits this should go up in the
	 *   commit hierarchy.
	 */
	public constructor(
		public timestamp: number | null = null,
		public branchName: string | null = null,
		/** Previous Indicating how many parent commits this should go up in the commit hierarchy. */
		public previous = 0
	) {}

	public toString(): string {
		let timestamp = UnresolvedCommitDescriptor.HEAD_REVISION_TIMESTAMP;
		if (this.timestamp != null) {
			timestamp = this.timestamp.toString();
		}
		if (this.previous) {
			timestamp += 'p' + this.previous;
		}
		if (this.branchName != null && this.branchName.trim() !== '') {
			return this.branchName + ':' + timestamp;
		}
		return timestamp.toString();
	}

	/** @returns The timestamp or null to indicate the head commit. */
	public getTimestamp(): number | null {
		return this.timestamp;
	}

	/** @returns The branch name or null to indicate the default branch. */
	public getBranchName(): string | null {
		return this.branchName;
	}

	/** Determines whether the commit is at the most recent revision (independent of current branch). */
	public isLatestRevision(): boolean {
		return this.timestamp === null;
	}

	/** Determines whether the commit is on the default branch. */
	public isDefaultBranch(): boolean {
		return this.branchName === null;
	}

	/** Determines whether the commit is at the most recent revision on the default branch. */
	public isLatestOnDefaultBranch(): boolean {
		return this.isLatestRevision() && this.isDefaultBranch();
	}

	/**
	 * Creates a new commit descriptor for the commit before the given one. In contrast to just appending 'p1' to the
	 * commit this method can also handle the case when the initial commit already has the previous field set to
	 * non-zero.
	 */
	public static getPreviousCommit(commit?: UnresolvedCommitDescriptor | null): UnresolvedCommitDescriptor {
		if (commit == null) {
			commit = UnresolvedCommitDescriptor.createLatestOnDefaultBranch();
		}
		return new UnresolvedCommitDescriptor(commit.timestamp, commit.branchName, commit.previous + 1);
	}

	/** Creates a new commit descriptor for the latest commit on the default branch. */
	public static createLatestOnDefaultBranch(): UnresolvedCommitDescriptor {
		return new UnresolvedCommitDescriptor(null, null);
	}

	/** Creates a new commit descriptor with the default branch explicitly set in case it is currently null. */
	public static withExplicitDefaultBranch(
		commit: UnresolvedCommitDescriptor | null,
		defaultBranch: string | null
	): UnresolvedCommitDescriptor {
		if (commit == null) {
			return new UnresolvedCommitDescriptor(null, defaultBranch);
		}
		if (commit.branchName === null) {
			return new UnresolvedCommitDescriptor(commit.timestamp, defaultBranch);
		}
		return commit;
	}

	/** Creates a commit descriptor with the hours, minutes and seconds set to 0. */
	public static withTruncatedDate(commit: UnresolvedCommitDescriptor | null): UnresolvedCommitDescriptor {
		const pinnedTimestampCommit = UnresolvedCommitDescriptor.toPinnedTimestampCommitDescriptor(commit, null);
		const truncatedTimestamp = DateUtils.getTimestampForDays(0, pinnedTimestampCommit.timestamp);
		return new UnresolvedCommitDescriptor(
			truncatedTimestamp,
			pinnedTimestampCommit.branchName,
			pinnedTimestampCommit.previous
		);
	}

	/**
	 * Returns true if the commit given as first parameter is earlier than the second. If at least one of the commits is
	 * not given false is returned.
	 */
	public static firstCommitEarlierThanSecond(
		commit: UnresolvedCommitDescriptor | null,
		baselineCommit: UnresolvedCommitDescriptor | null
	): boolean {
		let commitTimestamp = null;
		let baselineTimestamp = null;
		if (commit === null || baselineCommit === null) {
			return false;
		}
		commitTimestamp = commit.getTimestamp();
		baselineTimestamp = baselineCommit.getTimestamp();
		if (baselineTimestamp === null && commitTimestamp !== null) {
			return true;
		}
		return commitTimestamp !== null && commitTimestamp < Number(baselineTimestamp);
	}

	/**
	 * Wraps an object that looks like a CommitDescriptor into a CommitDescriptor. This is useful for wrapping the
	 * results from service calls.
	 */
	public static wrap(value: CommitDescriptorLike): UnresolvedCommitDescriptor;
	public static wrap(value: CommitDescriptorLike | undefined): UnresolvedCommitDescriptor | undefined;
	public static wrap(value: CommitDescriptorLike | null): UnresolvedCommitDescriptor | null;
	public static wrap(value: CommitDescriptorLike | null | undefined): UnresolvedCommitDescriptor | null | undefined;
	public static wrap(value: CommitDescriptorLike | null | undefined): UnresolvedCommitDescriptor | null | undefined {
		if (value == null) {
			return value;
		}
		if (value instanceof UnresolvedCommitDescriptor) {
			return value;
		}
		if (!('timestamp' in value)) {
			throw new Error('Expected object to have timestamp parameter!');
		}
		const timestamp = this.wrapTimestamp(value.timestamp);
		return new UnresolvedCommitDescriptor(timestamp, value.branchName);
	}

	/**
	 * Wraps the given timestamp so that it contains a safe value, i.e. it replaces the timestamp by 'null' if is larger
	 * then {@link Number.MAX_SAFE_INTEGER}. This will be resolved back to {@link HEAD_REVISION_TIMESTAMP} in
	 * {@link toString} when this is passed as value in a service call.
	 */
	private static wrapTimestamp(timestamp: number | null): number | null {
		const maxSafeValue = Number.MAX_SAFE_INTEGER;
		if (Number(timestamp) >= maxSafeValue) {
			// Use 'HEAD' timestamp by passing null
			return null;
		}
		return timestamp;
	}

	/**
	 * Parses a commit descriptor from a string. This may return null in case of invalid input.
	 *
	 * @param commitDescriptorString The commit descriptor string
	 */
	public static fromString(commitDescriptorString: string): UnresolvedCommitDescriptor | null {
		const match = commitDescriptorString.match(/^(?:(.+?):)?(HEAD|\d+)(?:p(\d+))?$/);
		if (!match) {
			return null;
		}
		const branchName = match[1]!;
		let timestamp = null;
		if (match[2] !== UnresolvedCommitDescriptor.HEAD_REVISION_TIMESTAMP) {
			timestamp = parseFloat(match[2]!);
			if (isNaN(timestamp)) {
				return null;
			}
			if (match[3] != null) {
				return new UnresolvedCommitDescriptor(timestamp, branchName, parseInt(match[3]));
			}
		}
		return new UnresolvedCommitDescriptor(timestamp, branchName);
	}

	/**
	 * Creates a commit descriptor that points to the HEAD commit at the given branch.
	 *
	 * @param branchName The branch where the commit should reside
	 * @returns A valid commit descriptor or null (if branchName was null)
	 */
	public static latestOnBranch(branchName: string): UnresolvedCommitDescriptor;
	public static latestOnBranch(branchName: string | null): UnresolvedCommitDescriptor | null;
	public static latestOnBranch(branchName: string | null): UnresolvedCommitDescriptor | null {
		if (branchName != null) {
			return new UnresolvedCommitDescriptor(null, branchName);
		}
		return null;
	}

	/**
	 * Creates a commit descriptor for a timestamp and optionally adds a branch name from another reference commit.
	 *
	 * @param timestamp The timestamp, null indicates branch head.
	 * @param referenceCommit Optional reference commit containing the branch name.
	 * @returns The commit descriptor for the timestamp.
	 */
	public static createCommitFromTimestamp(
		timestamp: number | null,
		referenceCommit?: UnresolvedCommitDescriptor | null
	): UnresolvedCommitDescriptor {
		const branchName = referenceCommit?.branchName ?? null;
		return new UnresolvedCommitDescriptor(timestamp, branchName);
	}

	/**
	 * Ensures that the given commit descriptor contains a branch and timestamp. If no branch is set, it is set to the
	 * given defaultBranchName (also works if the given defaultBranchName is null or undefined). If no timestamp is set,
	 * it is set to the current timestamp. If the given commit descriptor is null, a new commit descriptor (with
	 * defaultBranchName and NOW) is returned.
	 *
	 * @param originalCommitDescriptor
	 * @param defaultBranchName
	 */
	public static toPinnedTimestampCommitDescriptor(
		originalCommitDescriptor: UnresolvedCommitDescriptor | null,
		defaultBranchName: string | null
	): UnresolvedCommitDescriptor {
		const now = Date.now();
		if (originalCommitDescriptor != null) {
			const timestamp = originalCommitDescriptor.isLatestRevision() ? now : originalCommitDescriptor.timestamp;
			const branchName = originalCommitDescriptor.isDefaultBranch()
				? defaultBranchName
				: originalCommitDescriptor.branchName;
			return new UnresolvedCommitDescriptor(timestamp, branchName, originalCommitDescriptor.previous);
		} else {
			return new UnresolvedCommitDescriptor(now, defaultBranchName);
		}
	}

	/**
	 * Determines if two nullable commits are semantically equal.
	 *
	 * @returns If the commits are equal.
	 */
	public static equals(
		commit1: UnresolvedCommitDescriptor | null,
		commit2: UnresolvedCommitDescriptor | null
	): boolean {
		if (commit1 === commit2) {
			return true;
		}
		if (commit1 === null || commit2 === null) {
			// Top case handles both being null.
			return false;
		}
		return (
			commit1.getBranchName() === commit2.getBranchName() &&
			commit1.getTimestamp() === commit2.getTimestamp() &&
			commit1.previous === commit2.previous
		);
	}
}
