import type { JSX } from 'react';
import type { Context, ReactNode } from 'react';
import React from 'react';
import { useProjectInfos } from 'ts/base/hooks/ProjectsInfosHook';
import type { ExtendedProjectInfo } from 'ts/data/ExtendedProjectInfo';

/** React context that holds the ProjectInfo object of the currently selected project or null if no project is selected. */
export const ProjectInfoContext: Context<ExtendedProjectInfo | null> = React.createContext<ExtendedProjectInfo | null>(
	null
);

/** Props for ProjectProvider. */
type ProjectProviderProps = { projectId: string; children: ReactNode };

/**
 * Provides the given project ID to all of its children. The project can be retrieved via useProject(). By default it
 * will be set to the project specified in the navigation hash by the PerspectiveContextProviders component, which is
 * used when mounting a component via ReactUtils#append or ReactUtils#render.
 */
export function ProjectProvider({ projectId, children }: ProjectProviderProps): JSX.Element {
	const projectInfos = useProjectInfos();
	return (
		<ProjectInfoContext.Provider value={projectInfos.findProjectInfo(projectId)}>
			{children}
		</ProjectInfoContext.Provider>
	);
}
