import type { JSX } from 'react';
import { Navigate } from 'react-router-dom';
import { usePerspectiveContext } from 'ts/base/hooks/PerspectiveContextHook';
import { useAccessibleViews } from 'ts/base/hooks/UseAccessibleViews';
import { useKeyboardShortcutRegistry } from 'ts/base/hooks/UseKeyboardShortcut';
import { useNavigationHash } from 'ts/base/hooks/UseNavigationHash';
import {
	PerspectiveSettingsBar,
	PerspectiveSettingsBarSkeleton
} from 'ts/base/perspective/topbar/PerspectiveSettingsBar';
import { useProjectIds } from 'ts/base/perspective/topbar/UseProjectIds';
import { ProjectResolver } from 'ts/base/ProjectResolver';
import { absoluteLocationToArtificialPath } from 'ts/base/routing/PerspectiveHashHistory';
import { usePerspectiveContextQuery } from 'ts/base/services/PerspectiveContext';
import { TeamscaleViewContextWrapper } from 'ts/base/TeamscaleViewContextWrapper';
import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { Assertions } from 'ts/commons/Assertions';
import { NavigateToIssueDialog } from 'ts/commons/dialog/NavigateToIssueDialog';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import { ProjectAndUniformPath } from 'ts/commons/ProjectAndUniformPath';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';

/** Props for TeamscalePerspectiveProjectRedirector. */
type TeamscalePerspectiveProjectRedirectorProps = {
	perspectiveDescriptor: PerspectiveViewDescriptorBase;
};

/**
 * This asynchronously resolves the view descriptor, fetches the perspective context, navigates to the resolved project
 * in case it differs from what is represented in the URL and instantiates the perspective settings bar.
 */
export function TeamscalePerspectiveProjectRedirector({
	perspectiveDescriptor
}: TeamscalePerspectiveProjectRedirectorProps): JSX.Element | null {
	const context = usePerspectiveContext();
	const hash = useNavigationHash();
	const viewDescriptor = useViewDescriptor(perspectiveDescriptor, hash);
	const projectId = perspectiveDescriptor.getProject(context, hash, viewDescriptor);
	const freshPerspectiveContext = usePerspectiveContextQuery();
	const projects = useProjectIds(hash, viewDescriptor);
	useKeyboardShortcutRegistry(viewDescriptor);

	if (needsNavigationToCurrentProject(projectId, hash)) {
		// Project in navigation hash deviates from determined project
		hash.setProjectAndPath(ProjectAndUniformPath.of(projectId, ''));
		return (
			<Navigate replace to={absoluteLocationToArtificialPath(new URL(hash.toString(), window.location.href))} />
		);
	}
	assertProjectAndHashAreInSync(hash, projectId);
	if (projectId !== null) {
		ProjectResolver.setCurrentProject(projectId);
	}

	if (!projects.isLoaded) {
		return <PerspectiveSettingsBarSkeleton />;
	}

	const effectiveViewDescriptor = getEffectiveViewDescriptor(
		viewDescriptor,
		context,
		hash,
		projects.requestedProjectIds
	);
	if (!freshPerspectiveContext.isFetchedAfterMount || !projects.isLoadedAfterMount) {
		return <PerspectiveSettingsBar viewDescriptor={effectiveViewDescriptor} projectIds={projects} key="preview" />;
	}
	return (
		<>
			<PerspectiveSettingsBar viewDescriptor={effectiveViewDescriptor} projectIds={projects} key="loaded" />
			<TeamscaleViewContextWrapper
				key={hash.toString()}
				hash={hash}
				viewDescriptor={effectiveViewDescriptor}
				context={freshPerspectiveContext.data!}
				projectIds={projects.existingProjectIds}
			/>
			<NavigateToIssueDialog />
		</>
	);
}

function useViewDescriptor(perspectiveDescriptor: PerspectiveViewDescriptorBase, navigationHash: NavigationHash) {
	const accessibleViews = useAccessibleViews(perspectiveDescriptor);
	return determineViewDescriptor(accessibleViews, navigationHash);
}

function determineViewDescriptor(viewDescriptors: ViewDescriptor[], hash: NavigationHash) {
	const viewDescriptor = PerspectiveViewDescriptorBase.findViewDescriptor(
		viewDescriptors,
		hash.getViewName() ?? '',
		hash.getAction() ?? undefined
	);
	if (viewDescriptor === null) {
		Assertions.fail("You don't have the necessary permissions to access any view in this perspective!");
	}
	return viewDescriptor;
}

function assertProjectAndHashAreInSync(hash: NavigationHash, project: string | null) {
	Assertions.assert(
		(project ?? '') === hash.getProject(),
		`The project specified in the navigation hash (${hash
			.getProjectAndPath()
			.getProject()}) is not in sync with the programmatically determined target project (${
			project ?? ''
		}). The navigation hash should always reflect the current project as the views directly rely only on it.`
	);
}

/**
 * Determines whether a navigation to the given project is needed in case the history token points to another project or
 * no project is set yet.
 */
function needsNavigationToCurrentProject(projectId: string | null, hash: NavigationHash): boolean {
	return hash.getProject() !== (projectId ?? '');
}

const NO_PROJECTS_VIEW_DESCRIPTOR: ViewDescriptor = {
	anchor: '',
	name: '',
	requiresProject: true,
	hasCustomAnalysisWarning: true,
	viewCreator: () => import('./view/NoProjectsView')
};

function getEffectiveViewDescriptor(
	viewDescriptor: ViewDescriptor,
	context: ExtendedPerspectiveContext,
	hash: NavigationHash,
	requestedProjectIds: string[]
): ViewDescriptor {
	const noProjectsExist = context.getAllProjects().length === 0;
	const selectedProjectDoesNotExist = hash.getProject() !== '' && !context.projectExists(hash.getProject());
	const allRequiredProjectsInInitialAnalysis =
		ArrayUtils.intersection(context.projectsInfo.initialProjects, requestedProjectIds).length > 0 &&
		ArrayUtils.intersection(context.projectsInfo.projects, requestedProjectIds).length === 0;
	if (
		viewDescriptor.requiresProject &&
		(noProjectsExist || selectedProjectDoesNotExist || allRequiredProjectsInInitialAnalysis)
	) {
		return NO_PROJECTS_VIEW_DESCRIPTOR;
	}
	return viewDescriptor;
}
