import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { usePerspectiveContext } from 'ts/base/hooks/PerspectiveContextHook';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import { DashboardUtils } from 'ts/perspectives/dashboard/DashboardUtils';
import type { LastDashboardOpenedByUserOption } from 'typedefs/LastDashboardOpenedByUserOption';

/** Project IDs with loading state. */
export type ProjectIds = {
	isLoaded: boolean;
	isLoadedAfterMount: boolean;
	requestedProjectIds: string[];
	existingProjectIds: string[];
};

/**
 * Loads the project IDs for which data is shown in the view. By default, this is the project from the navigation hash,
 * but in dashboards that reference multiple projects it will be all projects that are referenced from the currently
 * selected dashboard regardless of the project selector selection. This influences the branches that are shown in the
 * perspective settings bar.
 */
export function useProjectIds(hash: NavigationHash, viewDescriptor: ViewDescriptor): ProjectIds {
	const context = usePerspectiveContext();
	const allProjects = context.getAllProjects();
	const userOptions = context.userInfo.userOptions;
	const option = userOptions[DashboardUtils.LAST_ACCESSED_DASHBOARD_OPTION];
	const result = useQuery({
		queryKey: ['projects', hash.getProject(), hash.getId(), viewDescriptor, allProjects, option],
		queryFn: async ({ signal }) => {
			const requestedProjectIds = await loadProjects(hash, viewDescriptor, allProjects, option, signal);
			const existingProjectIds = requestedProjectIds.filter(project => context.projectExists(project));
			return { requestedProjectIds, existingProjectIds };
		},
		throwOnError: true,
		refetchOnMount: 'always',
		placeholderData: keepPreviousData
	});
	if (result.isSuccess) {
		return {
			isLoaded: true,
			isLoadedAfterMount: result.isFetchedAfterMount,
			...result.data
		};
	} else {
		return { isLoaded: false, isLoadedAfterMount: false, requestedProjectIds: [], existingProjectIds: [] };
	}
}

/**
 * Determines the projects that are shown in the current view. This only returns meaningful data for views that have
 * ViewDescriptor#requireProjects. Typically, this is the currently selected project. For dashboards, it is all projects
 * that are referenced in the currently shown dashboard. For other views that allow to select all projects it is all
 * projects if "All projects" is selected (search perspective).
 */
export function loadProjects(
	hash: NavigationHash,
	viewDescriptor: ViewDescriptor,
	allProjects: string[],
	option: LastDashboardOpenedByUserOption,
	signal: AbortSignal
): Promise<string[]> {
	if (viewDescriptor.getProjects) {
		return viewDescriptor.getProjects(hash, option, signal);
	} else if (hash.getProject() === '') {
		return Promise.resolve(allProjects);
	} else {
		return Promise.resolve([hash.getProject()]);
	}
}
