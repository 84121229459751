import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import type { ArchitectureCommitUploadInfo } from 'typedefs/ArchitectureCommitUploadInfo';
import type { ArchitectureUploadInfo } from 'typedefs/ArchitectureUploadInfo';

/** Data wrapper for architecture commit upload infos. */
export class CommitArchitectureCommitUploadInfo {
	/** Contains detailed change information for an architecture upload. */
	public architectureUploadInfo: ArchitectureUploadInfo;

	/** The upload commit. */
	public commit: UnresolvedCommitDescriptor;

	public constructor(architectureCommitUploadInfo: ArchitectureCommitUploadInfo) {
		this.architectureUploadInfo = architectureCommitUploadInfo.architectureUploadInfo;
		this.commit = UnresolvedCommitDescriptor.wrap(architectureCommitUploadInfo.commit);
	}

	/** A text representing the changes/additons and deletions from the architecture upload info. */
	public getChangeInfoText(): string {
		const infoWriter = (prefix: string, list: string[]): string => {
			if (ArrayUtils.isEmpty(list)) {
				return '';
			}
			return prefix + ': ' + StringUtils.concat(list, ', ');
		};
		const changeInfoText =
			infoWriter('Changed/Added', this.architectureUploadInfo.uniformPaths) +
			'\n' +
			infoWriter('Deleted', this.architectureUploadInfo.deletedArchitectures);
		if (StringUtils.isEmptyOrWhitespace(changeInfoText)) {
			return 'No changes';
		}
		return changeInfoText;
	}

	/**
	 * @returns True if this architecture commit upload info represents an architecture upload info for the given upload
	 *   commit.
	 */
	public static isUploadForCommit(
		commitArchitectureCommitUploadInfo: CommitArchitectureCommitUploadInfo | null,
		uploadCommit: UnresolvedCommitDescriptor | null
	): boolean {
		return (
			commitArchitectureCommitUploadInfo != null &&
			UnresolvedCommitDescriptor.equals(commitArchitectureCommitUploadInfo.commit, uploadCommit)
		);
	}
}
