import type { Location } from 'history';
import { useContext, useRef } from 'react';
import {
	EHashReloadBehavior,
	NavigationHashReloadBehaviorContext
} from 'ts/base/context/NavigationHashReloadBehaviorContext';
import type { LocationState } from 'ts/base/routing/PerspectiveHashRouter';
import { useHistoryStore } from 'ts/base/routing/PerspectiveHashRouter';

/** Returns the artificial location of the current page. */
export function useLocation(): Location {
	const hashBehavior = useContext(NavigationHashReloadBehaviorContext);
	const ref = useRef({
		disableUpdate: false,
		location: getLocation(useHistoryStore.getState(), hashBehavior)
	});

	return useHistoryStore(state => {
		const newLocation = getLocation(state, hashBehavior);
		if (newLocation === ref.current.location) {
			return ref.current.location;
		}
		if (!state.wasSilentUpdate && hashBehavior === EHashReloadBehavior.RELOAD_ONLY_SILENTLY_APPLIED_CHANGES) {
			ref.current.disableUpdate = true;
		}
		if (!ref.current.disableUpdate) {
			ref.current.location = newLocation;
		}
		return ref.current.location;
	});
}

function getLocation(state: LocationState, hashBehavior: EHashReloadBehavior) {
	if (hashBehavior === EHashReloadBehavior.RELOAD_IGNORE_SILENTLY_APPLIED_CHANGES) {
		return state.locationWithoutSilentUpdates;
	} else {
		return state.latestLocation;
	}
}
