import { useEffect, useState } from 'react';
import { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import { ToastNotification } from 'ts/commons/ToastNotification';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import { EFeatureToggle } from 'typedefs/EFeatureToggle';
import type { WhatsNewAcknowledgeOption } from 'typedefs/WhatsNewAcknowledgeOption';

const WHATS_NEW_ACKNOWLEDGED_VERSION_OPTION_NAME = 'whatsnew.acknowledged.version';

/** Shows a What's New notification unless it has already been acknowledged by the current user */
export function useWhatsNewNotification(perspectiveContext: ExtendedPerspectiveContext | undefined) {
	const [hasShownWhatsNew, setHasShownWhatsNew] = useState(false);
	useEffect(() => {
		if (perspectiveContext && !hasShownWhatsNew) {
			const lastAcknowledgedVersionOption =
				perspectiveContext.userInfo.userOptions[WHATS_NEW_ACKNOWLEDGED_VERSION_OPTION_NAME];
			const currentVersion = parseMinorVersion(perspectiveContext.teamscaleInfo.version);
			if (shouldShowWhatsNewNotification(lastAcknowledgedVersionOption, currentVersion, perspectiveContext)) {
				showWhatsNewNotification(currentVersion.replace('_', '.'));
				setHasShownWhatsNew(true);
				acknowledgeCurrentVersionForWhatsNew(perspectiveContext);
			}
		}
	}, [perspectiveContext, hasShownWhatsNew]);
}

function shouldShowWhatsNewNotification(
	lastAcknowledgedVersionOption: WhatsNewAcknowledgeOption,
	currentVersion: string,
	perspectiveContext: ExtendedPerspectiveContext
): boolean {
	const isDevMode = perspectiveContext.teamscaleInfo.enabledFeatureToggles.includes(
		EFeatureToggle.ENABLE_DEV_MODE.name
	);
	const isTestMode = perspectiveContext.teamscaleInfo.enabledFeatureToggles.includes(
		EFeatureToggle.ENABLE_TEST_MODE.name
	);
	if (isDevMode && !isTestMode) {
		return false;
	}
	if (!lastAcknowledgedVersionOption.acknowledgedVersion) {
		// we also show the notification for first time users who might as well be interested in the latest features
		return true;
	}
	const lastAcknowledgedVersion = parseMinorVersion(lastAcknowledgedVersionOption.acknowledgedVersion);
	return currentVersion !== lastAcknowledgedVersion;
}

function showWhatsNewNotification(version: string) {
	ToastNotification.info(
		'Discover What\'s New in <a href="documentation/changelog" target="_blank">Teamscale ' + version + '</a>.',
		ToastNotification.STICKY_TOASTER_OPTIONS
	);
}

function acknowledgeCurrentVersionForWhatsNew(perspectiveContext: ExtendedPerspectiveContext) {
	new TeamscaleServiceClient().setCurrentUserOptionAsync(
		perspectiveContext,
		WHATS_NEW_ACKNOWLEDGED_VERSION_OPTION_NAME,
		{
			acknowledgedVersion: perspectiveContext.teamscaleInfo.version
		}
	);
}

function parseMinorVersion(fullVersionString: string): string {
	const regex = /VERSION_(\d+_\d+)_\d+/;
	return fullVersionString.match(regex)![1]!;
}
