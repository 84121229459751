import type {
	GetLanguageRulesForProjectQueryParams,
	GetLanguageRulesQueryParams,
	GetTestCoveragePostBody
} from 'api/ApiDefinition';
import { HttpStatus } from 'api/HttpStatus';
import { QUERY, type QueryOperation } from 'api/Query';
import { ServiceCallError } from 'api/ServiceCallError';
import type { UploadProgress } from 'api/ServiceClientImplementation';
import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { UserOptions } from 'custom-types/UserOptions';
import type { Callback } from 'ts/base/Callback';
import { ReactUtils } from 'ts/base/ReactUtils';
import type { SortOptions } from 'ts/base/table/SortableTable';
import type { AnalysisStateWithProjectAndBranch } from 'ts/commons/AnalysisStateWarningUtils';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { Assertions } from 'ts/commons/Assertions';
import { EnumUtils } from 'ts/commons/EnumUtils';
import type { CommitFilterSettings } from 'ts/commons/filters/commits/CommitFilterSettingsProvider';
import type { FindingsFilter } from 'ts/commons/filters/findings/FindingsFilter';
import { ObjectUtils } from 'ts/commons/ObjectUtils';
import type { Options, OptionValue } from 'ts/commons/option/OptionsComponent';
import { IssueQueryInputHandler } from 'ts/commons/query/IssueQueryInputHandler';
import type { QueryableEntityType } from 'ts/commons/query/QueryableEntityType';
import { SortingUtils } from 'ts/commons/SortingUtils';
import { StringUtils } from 'ts/commons/StringUtils';
import type { TestGapOptions } from 'ts/commons/TestGapOptions';
import { UIUtils } from 'ts/commons/UIUtils';
import { Zippy } from 'ts/commons/Zippy';
import { CommitArchitectureCommitUploadInfo } from 'ts/data/CommitArchitectureCommitUploadInfo';
import { CommitRepositoryLogEntry } from 'ts/data/CommitRepositoryLogEntry';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import type { SimulinkBlockData } from 'ts/perspectives/metrics/simulink/SimulinkBlockData';
import type { VOTING_OPTION_KIND, VotingOptionType } from 'ts/perspectives/project/components/VotingIndicator';
import type { CodeSearchTreemapOptions } from 'ts/perspectives/search/SearchService';
import type { ExternalXCloneClass } from 'ts/typedefs/ExternalXCloneClass';
import type { UnlinkedChangesWrapper } from 'ts/typedefs/UnlinkedChangesWrapper';
import type { AnalysisGroup } from 'typedefs/AnalysisGroup';
import type { AnalysisProgressDescriptor } from 'typedefs/AnalysisProgressDescriptor';
import type { AnalysisState } from 'typedefs/AnalysisState';
import type { ArchitectureAssessmentInfo } from 'typedefs/ArchitectureAssessmentInfo';
import type { ArchitectureCommitUploadInfo } from 'typedefs/ArchitectureCommitUploadInfo';
import type { ArchitectureComponentAssignment } from 'typedefs/ArchitectureComponentAssignment';
import type { ArchitectureInfo } from 'typedefs/ArchitectureInfo';
import type { ArchitectureOverviewInfo } from 'typedefs/ArchitectureOverviewInfo';
import type { ArchitectureWithCommitCount } from 'typedefs/ArchitectureWithCommitCount';
import type { AssessedReviewStatus } from 'typedefs/AssessedReviewStatus';
import type { BaselineInfo } from 'typedefs/BaselineInfo';
import type { BenchmarkResult } from 'typedefs/BenchmarkResult';
import type { BlacklistingOption } from 'typedefs/BlacklistingOption';
import type { BranchesInfo } from 'typedefs/BranchesInfo';
import type { ChangeRegion } from 'typedefs/ChangeRegion';
import type { CodeCityNode } from 'typedefs/CodeCityNode';
import type { CodeFileInfo } from 'typedefs/CodeFileInfo';
import type { CommitAlerts } from 'typedefs/CommitAlerts';
import type { CommitData } from 'typedefs/CommitData';
import type { CommitDescriptor, CommitDescriptor as DataCommitDescriptor } from 'typedefs/CommitDescriptor';
import type { CommitWithUserName } from 'typedefs/CommitWithUserName';
import type { ConnectorConfiguration } from 'typedefs/ConnectorConfiguration';
import type { CoverageSourceQueryParameters } from 'typedefs/CoverageSourceQueryParameters';
import type { DashboardTemplateDescriptor } from 'typedefs/DashboardTemplateDescriptor';
import type { DependencyWithOccurrenceLocation } from 'typedefs/DependencyWithOccurrenceLocation';
import type { DerivedTestCoverageInfo } from 'typedefs/DerivedTestCoverageInfo';
import type { DotNetVersionInfo } from 'typedefs/DotNetVersionInfo';
import type { EAnalysisTool } from 'typedefs/EAnalysisTool';
import type { EArchitectureUploadType } from 'typedefs/EArchitectureUploadType';
import type { EAuditExportLanguageEntry } from 'typedefs/EAuditExportLanguage';
import type { EAuditExportTableEntry } from 'typedefs/EAuditExportTable';
import type { EBasicPermissionScope } from 'typedefs/EBasicPermissionScope';
import type { EBlacklistingOptionEntry } from 'typedefs/EBlacklistingOption';
import { EBlacklistingOption } from 'typedefs/EBlacklistingOption';
import type { ECommitAuthorSortingOrder } from 'typedefs/ECommitAuthorSortingOrder';
import type { EExtendedResourceTypeEntry } from 'typedefs/EExtendedResourceType';
import type { EFindingBlacklistOperation } from 'typedefs/EFindingBlacklistOperation';
import type { EFindingBlacklistType } from 'typedefs/EFindingBlacklistType';
import type { EIssuesExportFormatEntry } from 'typedefs/EIssuesExportFormat';
import type { EIssueTgaFilterOptionEntry } from 'typedefs/EIssueTgaFilterOption';
import type { ELanguage, ELanguageEntry } from 'typedefs/ELanguage';
import { EProjectScheduleCommand } from 'typedefs/EProjectScheduleCommand';
import type { EQueryTypeEntry } from 'typedefs/EQueryType';
import type { EResourceTypeEntry } from 'typedefs/EResourceType';
import type { ESearchSuggestionTypeEntry } from 'typedefs/ESearchSuggestionType';
import type { ETokenClass } from 'typedefs/ETokenClass';
import type { ETypeEntry } from 'typedefs/EType';
import type { EvaluatedMetricThresholdPath } from 'typedefs/EvaluatedMetricThresholdPath';
import type { ExecutionUnit } from 'typedefs/ExecutionUnit';
import type { ExternalAnalysisCommitStatus } from 'typedefs/ExternalAnalysisCommitStatus';
import type { ExternalAnalysisGroup } from 'typedefs/ExternalAnalysisGroup';
import type { ExternalAnalysisPartitionInfo } from 'typedefs/ExternalAnalysisPartitionInfo';
import type { ExternalAnalysisStatusInfo } from 'typedefs/ExternalAnalysisStatusInfo';
import type { ExternalCredentialsUsageInfo } from 'typedefs/ExternalCredentialsUsageInfo';
import type { ExternalFindingsDescription } from 'typedefs/ExternalFindingsDescription';
import type { ExternalStorageBackendOption } from 'typedefs/ExternalStorageBackendOption';
import type { ExternalXCloneStatus } from 'typedefs/ExternalXCloneStatus';
import type { FindingBlacklistInfo } from 'typedefs/FindingBlacklistInfo';
import type { FindingBlacklistRequestBody } from 'typedefs/FindingBlacklistRequestBody';
import type { FindingChurnList } from 'typedefs/FindingChurnList';
import type { FindingDelta } from 'typedefs/FindingDelta';
import type { FindingResolutionResult } from 'typedefs/FindingResolutionResult';
import type { FindingsNotificationRules } from 'typedefs/FindingsNotificationRules';
import type { FindingsTreemapWrapper } from 'typedefs/FindingsTreemapWrapper';
import type { FormattedTokenElementInfo } from 'typedefs/FormattedTokenElementInfo';
import type { GetLinkRolesResponse } from 'typedefs/GetLinkRolesResponse';
import type { GitHubRepository } from 'typedefs/GitHubRepository';
import type { GitHubRepositorySettingsDescription } from 'typedefs/GitHubRepositorySettingsDescription';
import type { GloballyEnforceDefaultStorageOption } from 'typedefs/GloballyEnforceDefaultStorageOption';
import type { GroupAssessment } from 'typedefs/GroupAssessment';
import type { ImportedLinksAndTypeResolvedSpecItem } from 'typedefs/ImportedLinksAndTypeResolvedSpecItem';
import type { IssueHierarchy } from 'typedefs/IssueHierarchy';
import type { IssueQueryResult } from 'typedefs/IssueQueryResult';
import type { IssueTgaParameters } from 'typedefs/IssueTgaParameters';
import type { LanguageProcessingInfo } from 'typedefs/LanguageProcessingInfo';
import type { LineBasedMethodInfo } from 'typedefs/LineBasedMethodInfo';
import type { LineCoverageInfo } from 'typedefs/LineCoverageInfo';
import type { MergeRequestParentInfoTransport } from 'typedefs/MergeRequestParentInfoTransport';
import type { MethodHistoryEntriesWrapper } from 'typedefs/MethodHistoryEntriesWrapper';
import type { MethodTreeMapNode } from 'typedefs/MethodTreeMapNode';
import type { MetricDeltaValue } from 'typedefs/MetricDeltaValue';
import type { MetricDirectoryEntry } from 'typedefs/MetricDirectoryEntry';
import type { MetricDirectorySchema } from 'typedefs/MetricDirectorySchema';
import type { MetricDistributionEntry } from 'typedefs/MetricDistributionEntry';
import type { MetricDistributionWithDelta } from 'typedefs/MetricDistributionWithDelta';
import type { MetricNotificationRules } from 'typedefs/MetricNotificationRules';
import type { MetricsForThresholdProfile } from 'typedefs/MetricsForThresholdProfile';
import type { MetricThresholdConfiguration } from 'typedefs/MetricThresholdConfiguration';
import type { MetricTrendEntry } from 'typedefs/MetricTrendEntry';
import type { OpenIdEndpointInfo } from 'typedefs/OpenIdEndpointInfo';
import type { OptionDescriptor } from 'typedefs/OptionDescriptor';
import type { ParseLogEntry } from 'typedefs/ParseLogEntry';
import type { PartitionedTestSet } from 'typedefs/PartitionedTestSet';
import type { PerformanceMetricsEntry } from 'typedefs/PerformanceMetricsEntry';
import type { PermissionLookup } from 'typedefs/PermissionLookup';
import type { PerspectiveContext } from 'typedefs/PerspectiveContext';
import type { PolarionWorkItemLinkRolesResult } from 'typedefs/PolarionWorkItemLinkRolesResult';
import type { PolarionWorkItemTypeResult } from 'typedefs/PolarionWorkItemTypeResult';
import type { PostponedRollback } from 'typedefs/PostponedRollback';
import type { PostponedRollbackCounts } from 'typedefs/PostponedRollbackCounts';
import type { PreCommit3Result } from 'typedefs/PreCommit3Result';
import type { PreprocessorExpansionsTransport } from 'typedefs/PreprocessorExpansionsTransport';
import type { PrioritizableTest } from 'typedefs/PrioritizableTest';
import type { ProbeCoverageInfo } from 'typedefs/ProbeCoverageInfo';
import type { ProjectBranchingConfiguration } from 'typedefs/ProjectBranchingConfiguration';
import type { ProjectConfiguration } from 'typedefs/ProjectConfiguration';
import type { ProjectConnectorStatus } from 'typedefs/ProjectConnectorStatus';
import type { ProjectIdEntry } from 'typedefs/ProjectIdEntry';
import type { ProjectInfo } from 'typedefs/ProjectInfo';
import type { ProjectPartitionsInfo } from 'typedefs/ProjectPartitionsInfo';
import type { ProjectRole } from 'typedefs/ProjectRole';
import type { ProjectsConnectorState } from 'typedefs/ProjectsConnectorState';
import type { ProjectsState } from 'typedefs/ProjectsState';
import type { ProjectThresholdConfigurationsOption } from 'typedefs/ProjectThresholdConfigurationsOption';
import type { ProjectUpdateResult } from 'typedefs/ProjectUpdateResult';
import type { QueryTrendResult } from 'typedefs/QueryTrendResult';
import type { RefactoringSuggestions } from 'typedefs/RefactoringSuggestions';
import type { RepositoryActivitySummary } from 'typedefs/RepositoryActivitySummary';
import type { RepositoryLogEntry } from 'typedefs/RepositoryLogEntry';
import type { RepositoryLogFileHistoryEntry } from 'typedefs/RepositoryLogFileHistoryEntry';
import type { RepositorySummary } from 'typedefs/RepositorySummary';
import type { ReviewComment } from 'typedefs/ReviewComment';
import type { ReviewUploadInfo } from 'typedefs/ReviewUploadInfo';
import type { RoleAssignmentWithGlobalInfo } from 'typedefs/RoleAssignmentWithGlobalInfo';
import type { RoleChange } from 'typedefs/RoleChange';
import type { RulesContainer } from 'typedefs/RulesContainer';
import type { SearchResultContainer } from 'typedefs/SearchResultContainer';
import type { SearchSuggestion } from 'typedefs/SearchSuggestion';
import type { SimulinkModelComparisonResult } from 'typedefs/SimulinkModelComparisonResult';
import type { SinglePreprocessorExpansionTransport } from 'typedefs/SinglePreprocessorExpansionTransport';
import type { SpecItemCodeReference } from 'typedefs/SpecItemCodeReference';
import type { SpecItemGraph } from 'typedefs/SpecItemGraph';
import type { SpecItemReferenceMapping } from 'typedefs/SpecItemReferenceMapping';
import type { StoredQueryDescriptor } from 'typedefs/StoredQueryDescriptor';
import type { SubjectRoleAssignments } from 'typedefs/SubjectRoleAssignments';
import type { TestCoveragePartitionInfo } from 'typedefs/TestCoveragePartitionInfo';
import type { TestExecutionWithPartition } from 'typedefs/TestExecutionWithPartition';
import type { TestGapTreeMapWrapper } from 'typedefs/TestGapTreeMapWrapper';
import type { TestHistoryWrapper } from 'typedefs/TestHistoryWrapper';
import type { TestImplementation } from 'typedefs/TestImplementation';
import type { TestMinimizationJobRun } from 'typedefs/TestMinimizationJobRun';
import type { TestPathExecutionWrapper } from 'typedefs/TestPathExecutionWrapper';
import type { TgaSummary } from 'typedefs/TgaSummary';
import type { TgaTableEntry } from 'typedefs/TgaTableEntry';
import type { TokenElementChurnWithOriginInfo } from 'typedefs/TokenElementChurnWithOriginInfo';
import type { TokenElementInfo } from 'typedefs/TokenElementInfo';
import type { TrackedFinding } from 'typedefs/TrackedFinding';
import type { TrackedFindingWithDiffInfo } from 'typedefs/TrackedFindingWithDiffInfo';
import type { TreeMapNode } from 'typedefs/TreeMapNode';
import type { UsageTreeMapWrapper } from 'typedefs/UsageTreeMapWrapper';
import type { User } from 'typedefs/User';
import type { UserBatchOperation } from 'typedefs/UserBatchOperation';
import type { UserData } from 'typedefs/UserData';
import type { UserResolvedDashboardDescriptor } from 'typedefs/UserResolvedDashboardDescriptor';
import type { UserResolvedFindingBlacklistInfo } from 'typedefs/UserResolvedFindingBlacklistInfo';
import type { UserResolvedRetrospective } from 'typedefs/UserResolvedRetrospective';
import type { UserResolvedTeamscaleIssue } from 'typedefs/UserResolvedTeamscaleIssue';
import { ErrorManager } from '../ErrorManager';
import type { ErrorHandler } from './ServiceClient';
import { ServiceClient } from './ServiceClient';
import type { URLBuilder } from './URLBuilder';
import { url } from './URLBuilder';

/** Data class for IRoles form the roles schema together with all RoleAssignmentWithGlobalInfos. */
export type RolesWithAssignments<T> = {
	/** The available roles. */
	roles: T[];
	/** The associated assignments. */
	roleAssignments: RoleAssignmentWithGlobalInfo[];
};

/** The Teamscale service client. */
export class TeamscaleServiceClient extends ServiceClient {
	/** Group name used for architecture conformance analysis. */
	private static readonly ARCHITECTURE_CONFORMANCE_GROUP_NAME = 'Architecture Conformance';

	/**
	 * @param errorHandler A function called in case of errors. Parameters are error code, error message and error
	 *   description.
	 */
	public constructor(errorHandler?: ErrorHandler) {
		super(new ErrorManager(errorHandler ?? TeamscaleServiceClient.defaultErrorHandler));
	}

	/** Default error handler which appends the error message directly to the root of the dom. */
	public static defaultErrorHandler(error: ServiceCallError): void {
		Zippy.renderErrorAndPrepareToggler(error, renderedElement => document.body.appendChild(renderedElement));
	}

	/**
	 * Creates a base URLBuilder for tree maps and code cities.
	 *
	 * @param uniformPath
	 * @param areaMetric The metric index
	 * @param colorMetric The metric index
	 * @param color Color to use for numerical metrics
	 * @param commit
	 * @param includeFileRegexes Regular expressions for included files.
	 * @param excludeFileRegexes Regular expressions for excluded files.
	 */
	private static createTreemapBaseURLBuilder(
		urlBuilder: URLBuilder,
		areaMetric: number | null,
		colorMetric: number | null,
		color?: number[] | null,
		commit?: number | string | UnresolvedCommitDescriptor | null,
		includeFileRegexes?: string[],
		excludeFileRegexes?: string[] | null
	): URLBuilder {
		urlBuilder.append('area-metric', areaMetric);
		urlBuilder.append('color-metric', colorMetric);
		urlBuilder.appendMultiple('included-files-regexes', includeFileRegexes);
		urlBuilder.appendMultiple('excluded-files-regexes', excludeFileRegexes);
		if (color != null) {
			urlBuilder.append('color', (color[0]! << 16) + (color[1]! << 8) + color[2]!);
		}
		if (commit != null) {
			urlBuilder.append('t', commit);
		}
		return urlBuilder;
	}

	/**
	 * Retrieves the dashboard with given name.
	 *
	 * @param dashboardId The ID of the dashboard.
	 */
	public getDashboard(dashboardId: string): Promise<UserResolvedDashboardDescriptor | null> {
		return QUERY.getDashboard(dashboardId)
			.fetch()
			.catch<UserResolvedDashboardDescriptor | null>(TeamscaleServiceClient.handle404AsNull);
	}

	/**
	 * Lists the available dashboards asynchronously.
	 *
	 * @param forProject If set and valid project, only the dashboards for this project will be returned. {@code null}
	 *   or invalid values will return all dashboards.
	 */
	public listDashboardsAsync(forProject?: string | null): Promise<UserResolvedDashboardDescriptor[]> {
		return QUERY.getAllDashboards({ project: forProject ?? undefined }).fetch();
	}

	/** Provides search suggestions for the given project. */
	public searchSuggestions(
		query: string,
		projectId: string | undefined
	): Promise<Record<ESearchSuggestionTypeEntry, SearchSuggestion[]>> {
		const autoCompleteUrl = url`api/search/autocomplete`;
		autoCompleteUrl.append('token', query);
		autoCompleteUrl.append('project', projectId);
		return this.get(autoCompleteUrl);
	}

	/**
	 * Search code, commits, and issues within a specific project or within all projects.
	 *
	 * @param page The index of the result page to load
	 * @param resultsPerPage The maximum number of results for the current page (0 = all results)
	 * @param sources The sources to search
	 */
	public search(
		query: string | null,
		project: string | null,
		page: number | string | null,
		sources: EQueryTypeEntry[] | null,
		resultsPerPage?: number,
		path?: string,
		treemapOptions?: CodeSearchTreemapOptions
	): Promise<SearchResultContainer> {
		const urlBuilder = url`api/search`;
		urlBuilder.append('query', query);
		urlBuilder.append('project', project);
		urlBuilder.append('page', page);
		urlBuilder.append('limit-per-page', resultsPerPage);
		urlBuilder.append('path', path);
		urlBuilder.appendMultiple('source', sources);
		urlBuilder.append('area-metric', treemapOptions?.areaMetric);
		urlBuilder.append('color-metric', treemapOptions?.colorMetric);
		urlBuilder.append('width', treemapOptions?.treemapWidth);
		urlBuilder.append('height', treemapOptions?.treemapHeight);
		urlBuilder.append('is-color-gradation-active', treemapOptions?.isColorGraduationActive);
		return this.get<SearchResultContainer>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns a download link to a CSV with the search results for the given query.
	 *
	 * @param query The query to pass to the service projects
	 */
	public getSearchResultCsvUrl(
		query: string | null,
		project: string | null,
		source: EQueryTypeEntry,
		path?: string
	): string {
		const urlBuilder = url`api/search/csv`;
		urlBuilder.append('query', query);
		urlBuilder.append('project', project);
		urlBuilder.append('path', path);
		urlBuilder.append('source', source);
		return urlBuilder.getURL();
	}

	/** Get all template descriptors as List<DashboardTemplateDescriptor> */
	public listDashboardTemplates(): Promise<DashboardTemplateDescriptor[]> {
		return QUERY.getAllDashboardTemplates().fetch().catch(this.getDefaultErrorHandler());
	}

	/** Uploads the given form data to the metrics threshold configuration import service. */
	public importMetricThresholdConfiguration(metricThresholdConfigurations: File[]): Promise<void> {
		const formData = new FormData();
		for (const config of metricThresholdConfigurations) {
			formData.append('metric-threshold-configuration-data', config);
		}
		return this.post(url`api/metric-thresholds/import`, formData);
	}

	/** Starts a download for the project configuration with the given name. */
	public exportProjectConfiguration(name: string): void {
		window.location.href = url`api/projects/${name}/configuration/export`.getURL();
	}

	/** Uploads the given form data to the project configuration import service. */
	public importProjectConfiguration(
		projectConfigurations: File[],
		uploadProgressCallback?: (event: ProgressEvent) => void
	): Promise<void> {
		const formData = new FormData();
		for (const config of projectConfigurations) {
			formData.append('project-configuration', config);
		}
		return this.post(url`api/projects/import`, formData, {
			uploadProgressCallback
		});
	}

	/**
	 * Exports a project backup for the given project.
	 *
	 * @returns The ID of the backup export, which can be used to query the backup export progress.
	 */
	public exportProjectBackup(projectId: string): Promise<string> {
		const urlSearchParams = new URLSearchParams();
		urlSearchParams.set('include-project', projectId);
		return this.post<string>(url`api/backups/export`, urlSearchParams).catch(this.getDefaultErrorHandler());
	}

	/** Downloads the source code for the given uniform path in the given project at the given time as a file. */
	public downloadSourceCode(
		project: string,
		path: string,
		commit: UnresolvedCommitDescriptor | null,
		representation = 'TEXT'
	): void {
		const urlBuilder = url`api/projects/${project}/source-code-download/${path}`;
		if (commit != null) {
			urlBuilder.append('t', commit);
		}
		urlBuilder.append('representation', representation);
		window.location.href = urlBuilder.getURL();
	}

	/**
	 * Retrieves the finding with the given id.
	 *
	 * @param project
	 * @param id
	 * @param commit The commit for which to show the code.
	 */
	public getFinding(
		project: string,
		id: string | number,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<TrackedFinding | null> {
		const urlBuilder = url`api/projects/${project}/findings/${String(id)}`;
		if (commit != null) {
			urlBuilder.append('t', commit);
		}
		return this.get<TrackedFinding>(urlBuilder).catch(() => null);
	}

	/** Retrieves the finding with the given id. */
	public getFindingWithDiffInfo(
		project: string,
		id: string | number,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<TrackedFindingWithDiffInfo> {
		const urlBuilder = url`api/projects/${project}/findings/${String(id)}/with-diff-info`;
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/** Retrieves the issue with the given id. */
	public getIssue(project: string, id: string | number): Promise<UserResolvedTeamscaleIssue> {
		return this.get(url`api/projects/${project}/issues/${String(id)}`);
	}

	/**
	 * Retrieves the overall number of issues in the given project.
	 *
	 * @param project The project
	 */
	public getIssueCount(project: string): Promise<number> {
		return this.get<number>(url`api/projects/${project}/issues/count`).catch(this.getDefaultErrorHandler());
	}

	/** Retrieves the overall number of spec items in the given project. */
	public getSpecItemCount(project: string, commit: UnresolvedCommitDescriptor | null | undefined): Promise<number> {
		const urlBuilder = url`api/projects/${project}/spec-items/count`;
		urlBuilder.append('t', commit);
		return this.get<number>(urlBuilder);
	}

	/** Returns the columns available for spec items in the given project. */
	public getKnownSpecItemColumns(projectId: string, commit?: UnresolvedCommitDescriptor): Promise<string[]> {
		const urlBuilder = url`api/projects/${projectId}/spec-item-query/columns`;
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/** Performs a query for spec items. */
	public performSpecItemQuery(
		project: string,
		query: string,
		startIndex: number,
		maxResult?: number,
		sortOptions?: SortOptions,
		commit?: UnresolvedCommitDescriptor
	): Promise<IssueQueryResult> {
		const urlBuilder = url`api/projects/${project}/spec-item-query`;
		urlBuilder.append('t', commit);
		return this.performQuery(urlBuilder, query, startIndex, maxResult, sortOptions);
	}

	/** Performs query to the issue/spec item query endpoint. */
	private performQuery<T>(
		urlBuilder: URLBuilder,
		query: string,
		startIndex: number,
		maxResult?: number,
		sortOptions?: SortOptions
	): Promise<T> {
		urlBuilder.append('query', query);
		urlBuilder.append('start', startIndex);
		urlBuilder.append('max', maxResult);
		urlBuilder.append('sort-by', sortOptions?.sortByField);
		urlBuilder.append('sort-order', sortOptions?.sortOrder.name);
		return this.get(urlBuilder);
	}

	/** Retrieves the issue finding churn for the issue. */
	public getIssueFindingChurn(
		project: string,
		issueId: string,
		excludeResolvedFindings: boolean,
		excludeFlaggedFindings: boolean,
		maxFindingsCount: number
	): Promise<FindingChurnList> {
		const urlBuilder = url`api/projects/${project}/issues/${issueId}/finding-churn`;
		urlBuilder.append('exclude-resolved-findings', excludeResolvedFindings);
		urlBuilder.append('exclude-flagged-findings', excludeFlaggedFindings);
		urlBuilder.append('max', maxFindingsCount);
		return this.get(urlBuilder);
	}

	/**
	 * Retrieves the hierarchy of a given issue.
	 *
	 * @param project Project to which the issue belongs.
	 * @param issueId ID of the issue for which to retrieve the hierarchy.
	 */
	public getIssueHierarchy(project: string, issueId: string): Promise<IssueHierarchy> {
		return this.get<IssueHierarchy>(url`api/projects/${project}/issues/${issueId}/hierarchy`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Return the implementation for a given test. */
	public getTestImplementation(
		project: string,
		uniformPathToTest: string,
		endCommit?: UnresolvedCommitDescriptor | null
	): Promise<TestImplementation> {
		const urlBuilder = url`api/projects/${project}/test-implementations/${uniformPathToTest}`;
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/** Return the test implementation path for a given test execution path. */
	public getTestImplementationPath(
		project: string,
		testExecutionPath: string,
		endCommit?: UnresolvedCommitDescriptor | null
	): Promise<string | null> {
		const urlBuilder = url`api/projects/${project}/test-executions/${testExecutionPath}/implementation`;
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/** Return a list of test paths for a given file. */
	public getTestPathsAndExecResults(
		project: string,
		uniformPathToFile: string,
		endCommit?: UnresolvedCommitDescriptor | null
	): Promise<TestPathExecutionWrapper[] | null> {
		const urlBuilder = url`api/projects/${project}/code/${uniformPathToFile}/tests`;
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/** Return the execution unit with the given path. */
	public getExecutionUnit(
		project: string,
		partition: string,
		executionUnitPath: string,
		endCommit?: UnresolvedCommitDescriptor | null
	): Promise<ExecutionUnit> {
		const urlBuilder = url`api/projects/${project}/execution-units/${executionUnitPath}/partitions/${partition}`;
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/**
	 * Return the partitions in which a specified test exists.
	 *
	 * @param project The project.
	 * @param testExecutionPath The uniform path to the test for which the test history should be retrieved.
	 * @param endCommit The commit until which test executions should be respected.
	 * @returns List of partition names.
	 */
	public getTestExecutionPartitions(
		project: string,
		testExecutionPath: string,
		endCommit?: UnresolvedCommitDescriptor | null
	): Promise<string[]> {
		const urlBuilder = url`api/projects/${project}/test-executions/${testExecutionPath}/partitions`;
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/**
	 * Return the execution unit in which a specified test exists.
	 *
	 * @param project The project.
	 * @param testExecution The uniform path to the test for which the test history should be retrieved.
	 * @param endCommit The commit until which test executions should be respected.
	 */
	public getTestExecutionUnit(
		project: string,
		partition: string,
		testExecution: string,
		endCommit?: UnresolvedCommitDescriptor | null
	): Promise<string | null> {
		const urlBuilder = url`api/projects/${project}/test-executions/${testExecution}/partitions/${partition}/execution-unit`;
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/**
	 * Returns the test executions for a test implementation path. Returns empty list if there are no known test
	 * executions for the implementation.
	 *
	 * @param project The project.
	 * @param testImplementationPath The uniform path to the test implementation for which the test executions should be
	 *   retrieved.
	 * @param endCommit The commit until which test executions should be respected.
	 */
	public getTestExecutions(
		project: string,
		testImplementationPath: string,
		endCommit: UnresolvedCommitDescriptor | null
	): Promise<string[]> {
		const urlBuilder = url`api/projects/${project}/test-implementations/${testImplementationPath}/executions`;
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/**
	 * Return the history of executions of a specified test used in the test detail view.
	 *
	 * @param project The project.
	 * @param testExecution The uniform path to the test for which the test history should be retrieved.
	 * @param partition The partition of the test.
	 * @param endCommit The commit until which test executions should be respected.
	 * @returns Test history and meta information about e.g. successful or failed test executions.
	 */
	public getTestHistory(
		project: string,
		testExecution: string,
		partition: string,
		endCommit?: UnresolvedCommitDescriptor | null
	): Promise<TestHistoryWrapper> {
		const urlBuilder = url`api/projects/${project}/test-executions/${testExecution}/history`;
		urlBuilder.append('partition', partition);
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/**
	 * Retrieves the commits belonging to the given issue.
	 *
	 * @returns The commits done in the context of the given issue id.
	 */
	public async getRepositoryLogEntriesByIssue(
		project: string,
		issueId: string | number
	): Promise<UnresolvedCommitDescriptor[]> {
		const commits = await this.get<DataCommitDescriptor[]>(
			url`api/projects/${project}/issues/${String(issueId)}/commits`
		);
		return commits.map(commit => UnresolvedCommitDescriptor.wrap(commit));
	}

	/**
	 * Returns the impacted tests for the given issue with the corresponding commit. The commit is required to be able
	 * to navigate to the test view.
	 */
	public getImpactedTestsByIssue(
		project: string,
		id: string | number,
		includeChildIssues: boolean,
		branch: string,
		partitions?: string[]
	): Promise<PrioritizableTest[]> {
		const urlBuilder = url`api/projects/${project}/issues/${String(id)}/impacted-tests`;
		urlBuilder.append('include-child-issues', includeChildIssues);
		urlBuilder.append('branch', branch);
		urlBuilder.appendMultiple('partition', partitions);
		return this.get(urlBuilder);
	}

	/**
	 * Returns the line coverage information for the given element.
	 *
	 * @param project
	 * @param uniformPath
	 * @param pretty Whether the line numbers are adjusted for pretty-printed code
	 * @param partitions Which should be used to gather the coverage data. Omitting it or an empty array means all will
	 *   be taken into consideration.
	 * @param commit
	 * @returns Promise with line-based test coverage
	 */
	public getLineCoverage(
		project: string,
		uniformPath: string,
		body: GetTestCoveragePostBody
	): Promise<LineCoverageInfo | null> {
		return QUERY.getTestCoveragePost(project, uniformPath, body).fetch().catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the line coverage partition information for the given element.
	 *
	 * @returns Promise with test coverage partitions (java type TestCoveragePartitionInfo)
	 */
	public getLineCoveragePartitions(
		project: string,
		uniformPath: string,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<TestCoveragePartitionInfo[]> {
		const urlBuilder = url`api/projects/${project}/test-coverage-partitions/${uniformPath}`;
		urlBuilder.append('t', commit);
		return this.get<TestCoveragePartitionInfo[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the probe-based coverage information for the given element.
	 *
	 * @param project
	 * @param uniformPath
	 * @param commit The commit for which to retrieve the data. Latest if not given.
	 * @returns Promise with probe coverage info.
	 */
	public getProbeCoverage(
		project: string,
		uniformPath: string,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<ProbeCoverageInfo | null> {
		const urlBuilder = url`api/projects/${project}/probe-coverage/${uniformPath}`;
		urlBuilder.append('t', commit);
		return this.get<ProbeCoverageInfo | null>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns whether a given language is supported by our shallow parsers.
	 *
	 * @param language
	 * @returns Promise with the language processing information.
	 */
	public getLanguageProcessingInfo(language: string): Promise<LanguageProcessingInfo> {
		const urlBuilder = url`api/language-info/${language}`;
		return this.get<LanguageProcessingInfo>(urlBuilder);
	}

	/**
	 * Returns the methods of the file.
	 *
	 * @param project
	 * @param uniformPath
	 * @param pretty Whether the line numbers are adjusted for pretty-printed code
	 * @param commit Commit at which to return the methods from
	 * @param partitions Which should be used to gather the coverage data. Omitting it or an empty array means all will
	 * @returns A promise with a list of methods
	 */
	public getMethodsForFile(
		project: string,
		uniformPath: string,
		pretty: boolean,
		commit?: UnresolvedCommitDescriptor | null,
		partitions?: string[]
	): Promise<LineBasedMethodInfo[]> {
		const urlBuilder = url`api/projects/${project}/code/${uniformPath}/methods`;
		urlBuilder.append('pretty', pretty);
		urlBuilder.append('t', commit);
		urlBuilder.appendMultiple('partitions', partitions);
		return this.get(urlBuilder);
	}

	/**
	 * Returns the test coverage information for the given element.
	 *
	 * @param uniformPath The uniform path of the file, which contains the method
	 * @param startOffset The character-based offset in file on which the method starts (at the specified time)
	 * @param endOffset The character-based offset in file on which the method ends (at the specified time)
	 * @returns A list of tests executing the given method grouped by partition.
	 */
	public getTestsForMethod(
		project: string,
		uniformPath: string,
		startOffset: number,
		endOffset: number,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<PartitionedTestSet | null> {
		const urlBuilder = url`api/projects/${project}/code/${uniformPath}/methods/${String(startOffset)}-${String(
			endOffset
		)}/tests`;
		urlBuilder.append('t', commit);
		return this.get<PartitionedTestSet>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns the test coverage information for the given Simulink element. */
	public getSimulinkTestCoverage(
		project: string,
		uniformPath: string,
		partitions: string[],
		commit?: UnresolvedCommitDescriptor | null
	): Promise<DerivedTestCoverageInfo | null> {
		const urlBuilder = url`api/projects/${project}/simulink/test-coverage/${uniformPath}`;
		urlBuilder.append('t', commit);
		urlBuilder.appendMultiple('partitions', partitions);
		return this.get<DerivedTestCoverageInfo | null>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves the findings churn list for a given commit.
	 *
	 * @param project The project
	 * @param commit The commit
	 * @returns FindingsChurnList loaded from backend
	 */
	public getFindingChurnList(
		project: string,
		commit: UnresolvedCommitDescriptor,
		maxFindings: number
	): Promise<FindingChurnList> {
		const urlBuilder = url`api/projects/${project}/finding-churn/list`;
		urlBuilder.append('t', commit);
		urlBuilder.append('max', maxFindings);
		return this.get<FindingChurnList>(urlBuilder);
	}

	/**
	 * Retrieves the findings delta for a given uniform path and commit range.
	 *
	 * @param project
	 * @param uniformPath
	 * @param commit1
	 * @param commit2
	 * @param findingsFilter The filter to apply to the delta
	 * @param numericDeltaOnly Whether to only return a numeric delta
	 * @param findingBlacklistingOption Which types of findings should be considered.
	 */
	public getFindingDelta(
		project: string,
		uniformPath: string,
		commit1: UnresolvedCommitDescriptor,
		commit2: UnresolvedCommitDescriptor | null,
		findingsFilter: FindingsFilter,
		isSpecItemDelta?: boolean,
		numericDeltaOnly?: boolean,
		findingBlacklistingOption?: EBlacklistingOptionEntry | null,
		group?: string | null
	): Promise<FindingDelta> {
		return QUERY.getFindingDelta(project, {
			t1: commit1,
			t2: commit2 ?? undefined,
			'uniform-path': uniformPath,
			...findingsFilter.toQueryParams(),
			'numeric-delta-only': numericDeltaOnly,
			blacklisted: findingBlacklistingOption ?? EBlacklistingOption.ALL.name,
			group: group ?? undefined,
			'only-spec-item-findings': isSpecItemDelta
		})
			.fetch()
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves the findings delta for a given merge request.
	 *
	 * @param project
	 * @param source The source commit of the merge request
	 * @param target The target commit of the merge request
	 * @param cacheKey Optional key for the merge-base info cache on the server
	 * @param findingsFilter The filter to apply to the delta
	 * @param callback
	 */
	public getMergeRequestFindingChurn(
		project: string,
		source: UnresolvedCommitDescriptor,
		target: UnresolvedCommitDescriptor,
		cacheKey: string | null,
		findingsFilter: FindingsFilter
	): Promise<FindingDelta> {
		return QUERY.getMergeRequestFindingChurn(project, {
			source,
			target,
			'merge-base-cache-key': cacheKey ?? undefined,
			...findingsFilter.toQueryParams(),
			blacklisted: EBlacklistingOption.ALL.name
		})
			.fetch()
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves the merge-base information for a merge request.
	 *
	 * @param project
	 * @param source The source commit of the merge request
	 * @param target The target commit of the merge request
	 * @param callback The callback is called with an object of Java type
	 *   MergeRequestParentInfoTransport.MergeRequestParentInfoTransport.
	 */
	public getMergeBase(
		project: string,
		source: UnresolvedCommitDescriptor,
		target: UnresolvedCommitDescriptor
	): Promise<MergeRequestParentInfoTransport> {
		const urlBuilder = url`api/projects/${project}/merge-requests/parent-info`;
		urlBuilder.append('source', source);
		urlBuilder.append('target', target);
		urlBuilder.append('merge-base-only', true);
		return this.get(urlBuilder);
	}

	/**
	 * Retrieves the path- or type dependencies for a given project, uniform path and commit. Can also return inverse
	 * path dependencies but not inverse type dependencies.
	 *
	 * @param project
	 * @param uniformPath
	 * @param commit
	 * @param inverse Determines if inverse dependencies are used.
	 * @param callback
	 */
	private getDependencies(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null,
		inverse: boolean
	): Promise<DependencyWithOccurrenceLocation[] | null> {
		const urlBuilder = url`api/projects/${project}/dependencies/${uniformPath}`;
		urlBuilder.append('t', commit);
		urlBuilder.append('inverse', inverse);
		return this.get<DependencyWithOccurrenceLocation[] | null>(urlBuilder);
	}

	/** Retrieves the path dependencies for a given project, uniform path and commit. */
	public getPathDependencies(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<DependencyWithOccurrenceLocation[] | null> {
		return this.getDependencies(project, uniformPath, commit, false);
	}

	/**
	 * Retrieves the inverse path dependencies for a given project, uniform path and commit.
	 *
	 * @param project
	 * @param uniformPath
	 * @param commit
	 * @param callback The callback function with one argument of type Array.<string>.
	 */
	public getInversePathDependencies(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<DependencyWithOccurrenceLocation[] | null> {
		return this.getDependencies(project, uniformPath, commit, true);
	}

	/** Retrieves the component assignments for the given uniform path in all available architectures in the project. */
	public getArchitectureComponentAssignments(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<ArchitectureComponentAssignment[]> {
		const urlBuilder = url`api/projects/${project}/architectures/components`;
		urlBuilder.append('t', commit);
		urlBuilder.append('uniform-path', uniformPath);
		return this.get<ArchitectureComponentAssignment[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns all partitions of the given project that contain testwise coverage. */
	public getTestwiseCoveragePartitions(
		project: string,
		commit: UnresolvedCommitDescriptor | null,
		issueId?: string
	): Promise<string[]> {
		const urlBuilder = url`api/projects/${project}/test-coverage/testwise/partitions`;
		urlBuilder.append('t', commit);
		urlBuilder.append('issue-id', issueId);
		return this.get(urlBuilder);
	}

	/**
	 * Returns the commit alerts for the given commits in a given project.
	 *
	 * @param project The project
	 * @param commits The commits for which the commit alerts should be returned
	 */
	public getCommitAlerts(
		project: string,
		commits: UnresolvedCommitDescriptor[]
	): Promise<Array<CommitAlerts | null>> {
		return QUERY.getCommitAlerts(project, { commit: commits }).fetch().catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the treemap for the given uniform path using the specified metric and assessment.
	 *
	 * @param project The project
	 * @param uniformPath
	 * @param areaMetric The metric index to determine the area of displayed nodes
	 * @param colorMetric The metric index to determine color of displayed nodes
	 * @param width Width to render tree map
	 * @param height Height to render tree map
	 * @param color Color to use for numerical metrics
	 * @param includeFileRegexes Regular expressions for included files.
	 * @param excludeFileRegexes Regular expressions for excluded files.
	 * @param endCommit
	 * @param callback The callback function with one argument of Java type TreeMapNode
	 * @param endCommit
	 * @param minValue Minimum value for the color range
	 * @param maxValue Maximum value for the color range
	 */
	public getTreeMap(
		project: string,
		uniformPath: string,
		areaMetric: number | null,
		colorMetric: number | null,
		width: number,
		height: number,
		color: number[] | null,
		includeFileRegexes: string[] | undefined,
		excludeFileRegexes: string[] | undefined,
		endCommit?: UnresolvedCommitDescriptor | null,
		minValue?: number | null,
		maxValue?: number | null,
		partitions?: string[],
		enableColorBlindMode?: boolean
	): Promise<TreeMapNode> {
		const urlBuilder = TeamscaleServiceClient.createTreemapBaseURLBuilder(
			url`api/projects/${project}/metrics/treemap`,
			areaMetric,
			colorMetric,
			color,
			endCommit,
			includeFileRegexes,
			excludeFileRegexes
		);
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		urlBuilder.append('min-value-for-color', minValue);
		urlBuilder.append('max-value-for-color', maxValue);
		urlBuilder.appendMultiple('partition', partitions);
		urlBuilder.append('all-partitions', partitions === undefined);
		urlBuilder.append('color-blind-mode', enableColorBlindMode);

		return this.get<TreeMapNode>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the TGA assessment trend for the given project and uniform path. Allows specifying baseline and end
	 * timestamp as well as partitions to use.
	 */
	public getTestGapTrend(testGapOptions: TestGapOptions): Promise<MetricTrendEntry[]> {
		return QUERY.getTgaTrend(testGapOptions.project, testGapOptions.toQueryParams())
			.fetch()
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves the usage treemap wrapper for the given uniform path.
	 *
	 * @param project The project.
	 * @param uniformPath The uniform path to focus on.
	 * @param baselineTimestamp Timestamp of the baseline to use.
	 * @param headTimestamp Timestamp of the revision until which to consider trace and change information.
	 * @param width Width to render tree map.
	 * @param height Height to render tree map.
	 */
	public getUsageTreeMap(
		project: string,
		uniformPath: string,
		baselineTimestamp: UnresolvedCommitDescriptor | null,
		headTimestamp: UnresolvedCommitDescriptor | null,
		width: number,
		height: number
	): Promise<UsageTreeMapWrapper> {
		const urlBuilder = url`api/projects/${project}/code-usage/treemap`;
		urlBuilder.append('baseline', baselineTimestamp);
		urlBuilder.append('end', headTimestamp);
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		urlBuilder.append('uniform-path', uniformPath);
		return this.get<UsageTreeMapWrapper>(urlBuilder);
	}

	/**
	 * Downloads a CSV file summarizing the current usage state for the given uniform path.
	 *
	 * @param project The project.
	 * @param uniformPath The uniform path to focus on.
	 * @param baselineTimestamp Timestamp of the baseline to use.
	 * @param headTimestamp Timestamp of the revision until which to consider trace and change information.
	 */
	public downloadUsageResultsAsCSV(
		project: string,
		uniformPath: string,
		baselineTimestamp: UnresolvedCommitDescriptor | null,
		headTimestamp: UnresolvedCommitDescriptor | null
	): void {
		const urlBuilder = url`api/projects/${project}/code-usage/csv`;
		urlBuilder.append('baseline', baselineTimestamp);
		urlBuilder.append('end', headTimestamp);
		urlBuilder.append('uniform-path', uniformPath);
		window.location.href = urlBuilder.getURL();
	}

	/**
	 * Retrieves the TGA treemap wrapper for the given uniform path.
	 *
	 * @param testGapOptions The general TGA options
	 * @param width Width to render tree map.
	 * @param height Height to render tree map.
	 * @param excludeUnchangedMethods Whether to hide unchanged methods.
	 * @param handleErrorsWithPromise
	 */
	public async getTGATreeMap(
		testGapOptions: TestGapOptions,
		width: number,
		height: number,
		excludeUnchangedMethods: boolean,
		handleErrorsWithPromise?: boolean
	): Promise<TestGapTreeMapWrapper> {
		if (width === 0 || height === 0) {
			throw new Error('Attempted to render treemap with size 0.');
		}
		const params = testGapOptions.toQueryParams();
		params['width'] = width;
		params['height'] = height;
		params['exclude-unchanged-methods'] = excludeUnchangedMethods;
		const promise = QUERY.getTestGapTreeMap(testGapOptions.project, params).fetch();
		if (handleErrorsWithPromise) {
			return promise;
		} else {
			return promise.catch(this.getDefaultErrorHandler());
		}
	}

	/** Returns the TGA summary for all non-issue changes. */
	public getNonIssueTgaCommits(
		project: string,
		width: number,
		height: number,
		baselineCommit: UnresolvedCommitDescriptor,
		endCommit: UnresolvedCommitDescriptor | null,
		coverageSourceParameters: CoverageSourceQueryParameters
	): Promise<UnlinkedChangesWrapper> {
		const urlBuilder = url`api/projects/${project}/unlinked-changes/treemap`;
		urlBuilder.append('end', endCommit);
		urlBuilder.append('baseline', baselineCommit);
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		urlBuilder.appendAll(ServiceClient.convertToKebabCaseURLSearchParams(coverageSourceParameters));
		return this.get(urlBuilder);
	}

	/** Checks if unlinked changes exist since a given baseline. */
	public unlinkedChangesExist(
		project: string,
		baselineCommit: UnresolvedCommitDescriptor,
		coverageSourceParameters: CoverageSourceQueryParameters
	): Promise<boolean> {
		const urlBuilder = url`api/projects/${project}/unlinked-changes`;
		urlBuilder.append('baseline', baselineCommit);
		urlBuilder.appendAll(ServiceClient.convertToKebabCaseURLSearchParams(coverageSourceParameters));
		return this.get(urlBuilder);
	}

	/**
	 * Returns the aggregated TGA summary for all issues matching the given issue query.
	 *
	 * If no branch is explicitly given, a branch is auto-selected for each issue based on its last commit.
	 */
	public getIssueQueryTgaSummary(
		project: string,
		issueQuery: string,
		issueTgaParameters: IssueTgaParameters,
		coverageSourceParameters: CoverageSourceQueryParameters
	): Promise<TgaSummary> {
		const urlBuilder = url`api/projects/${project}/issue-query/tga-summary`;
		urlBuilder.append('query', IssueQueryInputHandler.escapeIssueQuery(issueQuery));
		urlBuilder.append('branch-name', issueTgaParameters.branchName);
		urlBuilder.append('auto-select-branch', issueTgaParameters.autoSelectBranch);
		urlBuilder.append('include-child-issues', issueTgaParameters.includeChildIssues);
		urlBuilder.appendAll(ServiceClient.convertToKebabCaseURLSearchParams(coverageSourceParameters));
		return this.get(urlBuilder);
	}

	/**
	 * Return a delta map showing the difference of the methods tested by the test between the two commits
	 *
	 * @param project The project
	 * @param testExecution The path to the test. Starting with -test-execution-
	 * @param partition The partition of the test
	 * @param width The width of the tree map to be rendered
	 * @param height The height of the tree map to be rendered
	 * @param commit The first commit
	 * @param commit2 The second (later) commit
	 * @param excludeUnchangedMethods If only methods that have changed should be shown
	 */
	public getTestSpecificMethodsChangedTreeMap(
		project: string,
		testExecution: string,
		partition: string,
		width: number,
		height: number,
		commit: UnresolvedCommitDescriptor,
		commit2: UnresolvedCommitDescriptor,
		excludeUnchangedMethods: boolean
	): Promise<MethodTreeMapNode> {
		const urlBuilder = url`api/projects/${project}/test-executions/${testExecution}/related-changes/treemap`;
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		urlBuilder.append('baseline', commit);
		urlBuilder.append('end', commit2);
		urlBuilder.append('partitions', partition);
		urlBuilder.append('exclude-unchanged-methods', excludeUnchangedMethods);
		return this.get<MethodTreeMapNode>(urlBuilder);
	}

	/** Downloads a CSV file summarizing the current test state for the given uniform path. */
	public async downloadTGAResultsAsCSV(
		testGapOptions: TestGapOptions,
		excludeUnchangedMethods: boolean
	): Promise<void> {
		const params = testGapOptions.toQueryParams();
		params['exclude-unchanged-methods'] = excludeUnchangedMethods;
		try {
			// Needs to be downloaded programmatically because the corresponding URL with query parameters might be too long to survive the reverse proxy
			const csv = await QUERY.getTestGapDataAsCsv(testGapOptions.project, params).fetch();
			const FileSaver = (await import('file-saver')).default;
			FileSaver.saveAs(csv, 'TgaData.csv');
		} catch (e) {
			await this.getDefaultErrorHandler()(e);
		}
	}

	/** Returns all partitions for all visible projects that can be used for a TGA. */
	public getGlobalTestCoveragePartitions(): Promise<ProjectPartitionsInfo[]> {
		return this.get<ProjectPartitionsInfo[]>(url`api/test-coverage/line-based/partitions`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Returns all partitions for a project that can be used for a TGA at the specified commit.
	 *
	 * @deprecated This API is already migrated to the OpenAPI generator
	 */
	public getTestCoveragePartitions(project: string, commit?: UnresolvedCommitDescriptor | null): Promise<string[]> {
		const urlBuilder = url`api/projects/${project}/test-coverage/line-based/partitions`;
		urlBuilder.append('t', commit);
		return this.get<string[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns all partitions for a project that can be used for a TGA. */
	public getAllTestCoveragePartitions(project: string): Promise<string[]> {
		return this.get<string[]>(url`api/projects/${project}/test-coverage/line-based/partitions/all`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Returns all partitions of a project for which test executions have been uploaded. */
	public getAllTestExecutionPartitions(project: string, commit: UnresolvedCommitDescriptor): Promise<string[]> {
		const urlBuilder = url`api/projects/${project}/test-executions/partitions/all`;
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/**
	 * Returns the list of CodeCityElement items for the given uniform path using the specified metric and assessment.
	 *
	 * @param areaMetric The metric index
	 * @param heightMetric The metric index
	 * @param colorMetric The metric index
	 * @param color Color to use for numerical metrics
	 * @param includeFileRegexes Regular expressions for included files.
	 * @param excludeFileRegexes Regular expressions for excluded files.
	 */
	public getCodeCity(
		project: string,
		uniformPath: string,
		areaMetric: number | null,
		heightMetric: number,
		colorMetric: number | null,
		color: number[],
		includeFileRegexes: string[],
		excludeFileRegexes: string[] | null,
		enableColorBlindMode: boolean,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<CodeCityNode> {
		const urlBuilder = TeamscaleServiceClient.createTreemapBaseURLBuilder(
			url`api/projects/${project}/code-city`,
			areaMetric,
			colorMetric,
			color,
			commit,
			includeFileRegexes,
			excludeFileRegexes
		);
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('height', heightMetric);
		urlBuilder.append('color-blind-mode', enableColorBlindMode);
		return this.get<CodeCityNode>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns list of metrics for each file.
	 *
	 * @param metricIndices The indices of the metrics used to display the scatter plot
	 */
	public getScatterPlot(
		project: string,
		uniformPath: string,
		metricIndices: number[],
		commit?: UnresolvedCommitDescriptor | null
	): Promise<MetricDirectoryEntry[]> {
		const urlBuilder = url`api/projects/${project}/metrics-scatter-plot`;
		urlBuilder.appendMultiple('metric-indices', metricIndices);
		urlBuilder.append('t', commit);
		urlBuilder.append('uniform-path', uniformPath);
		return this.get<MetricDirectoryEntry[]>(urlBuilder);
	}

	/** Retrieves a single system version info. Throws a 404 if the version does not exist. */
	public getSystemVersionInfo(project: string, versionName: string): Promise<DotNetVersionInfo> {
		return this.get(url`api/projects/${project}/dot-net-versions/${versionName}`);
	}

	/**
	 * Retrieves the system performance metrics.
	 *
	 * @param maxSeconds Max number of seconds of trend data to be returned.
	 */
	public getSystemPerformanceMetricsTrend(maxSeconds: number): Promise<PerformanceMetricsEntry[]> {
		const urlBuilder = url`api/performance-metrics-trend`;
		urlBuilder.append('max-seconds', maxSeconds);
		return this.get<PerformanceMetricsEntry[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Lists a baseline's detailed info.
	 *
	 * @param project The name of the project
	 * @param baseline The name of the baseline
	 */
	public getBaselineInfo(project: string, baseline: string): Promise<BaselineInfo> {
		return this.get(url`api/projects/${project}/baselines/${baseline}`);
	}

	/**
	 * Returns the code-review baseline of a project
	 *
	 * @param project The name of the project
	 */
	public getCodeReviewBaseline(project: string): Promise<number> {
		return this.get(url`api/projects/${project}/baseline`);
	}

	/**
	 * Fetches the (line-based) change regions for a file.
	 *
	 * @param project The project to get the changes for.
	 * @param uniformPath The uniform path of the element to get the changes for.
	 * @param baseline The name of a baseline or a baseline timestamp; if provided, only changes after the baseline are
	 *   returned.
	 * @param commit If provided, returns the changes as seen from a historic version identified via this timestamp.
	 */
	public getCodeChangeRegions(
		project: string,
		uniformPath: string,
		baseline?: string | number | null,
		commit?: number | string | UnresolvedCommitDescriptor | null
	): Promise<ChangeRegion[]> {
		const urlBuilder = url`api/projects/${project}/code-changes/${uniformPath}`;
		urlBuilder.append('baseline', baseline);
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/**
	 * Retrieves a flagged finding. Can return null in case the finding is not marked as tolerated.
	 *
	 * @param project The name of the project
	 * @param findingId The ID of the finding
	 * @param commit The branch/timestamp where the finding should be retrieved, or null for latest finding on default
	 *   branch
	 * @param handleErrorsWithPromise If true and the service call fails, the reject method of the promise is called
	 *   with the response data. When set to <code>true</code>, attach an error handler using '.catch(serviceError =>
	 *   ...)' directly to the method call. The error object passed to catch will be of type {@link ServiceCallError}. If
	 *   this is set to false and the service call fails, a red error page will be shown.
	 */
	public getFlaggedFindingInfo(
		project: string,
		findingId: string,
		commit: UnresolvedCommitDescriptor | null,
		handleErrorsWithPromise?: boolean
	): Promise<UserResolvedFindingBlacklistInfo | null> {
		const promise = QUERY.getFlaggedFindingInfo(project, findingId, { t: commit ?? undefined }).fetch();
		if (handleErrorsWithPromise) {
			return promise;
		} else {
			return promise.catch(this.getDefaultErrorHandler());
		}
	}

	/**
	 * Retrieves the flagging information for a list of findings.
	 *
	 * @param project The name of the project
	 * @param findingIds The IDs of the findings
	 * @param commit The branch/timestamp until where the findings should be retrieved, or null for latest findings on
	 *   default branch
	 */
	public getFlaggingInformationForFindings(
		project: string,
		findingIds: string[],
		commit: UnresolvedCommitDescriptor | null,
		onlySpecItemFindings?: boolean
	): Promise<FindingBlacklistInfo[]> {
		return QUERY.getFlaggedFindingsInfos(
			project,
			{ t: commit ?? undefined, 'only-spec-item-findings': onlySpecItemFindings },
			findingIds
		).fetch();
	}

	/**
	 * Retrieves all blacklisted findings with the provided type.
	 *
	 * @param project The name of the project
	 * @param toCommit The branch/timestamp until where the findings should be retrieved, or the target commit of a
	 *   merge request.
	 * @param fromCommit The branch/timestamp starting from where the findings should be retrieved, or the source commit
	 *   of a merge request.
	 * @param mergeBaseCacheKey The cacheStorageKey of the MergeRequestParentInfoTransport in case the commits are part
	 *   of a merge request.
	 * @param handleErrorsWithPromise If true and the service call fails, the reject method of the promise is called
	 *   with the response data. When set to <code>true</code>, attach an error handler using '.catch(serviceError =>
	 *   ...)' directly to the method call. The error object passed to catch will be of type {@link ServiceCallError}. If
	 *   this is set to false and the service call fails, a red error page will be shown.
	 */
	public getAllFlaggedFindingInfos(
		project: string,
		toCommit: UnresolvedCommitDescriptor,
		fromCommit?: UnresolvedCommitDescriptor,
		mergeBaseCacheKey?: string,
		onlySpecItemFindings?: boolean,
		handleErrorsWithPromise?: boolean
	): Promise<FindingBlacklistInfo[]> {
		const promise = QUERY.getFlaggedFindings(project, {
			'merge-base-cache-key': mergeBaseCacheKey ?? undefined,
			from: fromCommit ?? undefined,
			to: toCommit,
			'only-spec-item-findings': onlySpecItemFindings
		}).fetch();
		if (handleErrorsWithPromise) {
			return promise;
		} else {
			return promise.catch(this.getDefaultErrorHandler());
		}
	}

	/**
	 * Flags/unflags multiple findings.
	 *
	 * @param commit The branch/timestamp where the finding should be marked, or null for HEAD on default branch
	 * @param handleErrorsWithPromise If true and the service call fails, the reject method of the promise is called
	 *   with the response data. When set to <code>true</code>, attach an error handler using '.catch(serviceError =>
	 *   ...)' directly to the method call. The error object passed to catch will be of type {@link ServiceCallError}. If
	 *   this is set to false and the service call fails, a red error page will be shown.
	 */
	public flagFindings(
		blacklistType: EFindingBlacklistType,
		blacklistOperation: EFindingBlacklistOperation,
		project: string,
		findingIds: string[],
		findingBlacklistInfo: FindingBlacklistInfo | null,
		commit: UnresolvedCommitDescriptor | null,
		handleErrorsWithPromise?: boolean
	): Promise<void> {
		const promise = QUERY.flagFindings(
			project,
			{ t: commit ?? undefined, operation: blacklistOperation.name, type: blacklistType.name },
			{
				blacklistInfo: findingBlacklistInfo,
				findingIds
			} as FindingBlacklistRequestBody
		).fetch();

		if (handleErrorsWithPromise) {
			return promise;
		} else {
			return promise.catch(this.getDefaultErrorHandler());
		}
	}

	/**
	 * Returns the resource findings.
	 *
	 * @param project
	 * @param uniformPath
	 * @param commit
	 * @param pretty Whether to adjust finding locations for pretty printed code
	 * @param isFile If true, then we filter the findings for the exact uniform path. This prevents findings to show up
	 *   files where the given path is a prefix of, for example `foo.h` any `foo.hpp`
	 */
	public getResourceFindings(
		project: string,
		uniformPath: string,
		commit?: UnresolvedCommitDescriptor | null,
		pretty?: boolean,
		onlySpecItemFindings?: boolean,
		isFile?: boolean
	): Promise<TrackedFinding[]> {
		return QUERY.getFindings(project, {
			'uniform-path': uniformPath,
			'only-spec-item-findings': onlySpecItemFindings,
			t: commit ?? undefined,
			pretty,
			'included-paths': isFile ? [uniformPath] : undefined
		})
			.fetch()
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns all findings recursively for the given path using the given category and group as a filter (both may be
	 * null).
	 *
	 * @param project The project
	 * @param uniformPath The uniform path
	 * @param findingsFilter
	 * @param commit The timestamp
	 * @param baseline? The name of the current baseline, or its timestamp
	 * @param includeChangedFindings? Whether to include findings in changed code
	 * @param onlyChangedFindings? Whether to view findings in changed code only
	 * @param blacklisting? The blacklisting option
	 * @param sortBy? One of message, location, group or a finding property
	 * @param sortOrder? One of ascending or descending
	 * @param pretty? Whether to adjust finding locations for pretty printed code
	 * @param start? Start index of findings to return
	 * @param all? If this is true, all findings are returned, regardless of the other given parameters.
	 * @param qualifiedName? If given, the qualified name filter will be applied.
	 * @param max? Limits the number of findings that are returned
	 * @param isFile If true, then we filter the findings for the exact uniform path. This prevents findings to show up
	 *   files where the given path is a prefix of, for example `foo.h` any `foo.hpp`
	 */
	public getResourceFindingsList(
		project: string,
		uniformPath: string,
		findingsFilter: FindingsFilter,
		commit: UnresolvedCommitDescriptor | null | undefined,
		baseline: string | number | null,
		includeChangedFindings?: boolean | null,
		onlyChangedFindings?: boolean | null,
		blacklisting?: EBlacklistingOptionEntry | null,
		pretty?: boolean,
		qualifiedName?: string | null
	): Promise<TrackedFinding[]> {
		findingsFilter.addIncludedPath(uniformPath);
		return QUERY.getFindings(project, {
			'uniform-path': uniformPath,
			baseline: StringUtils.getStringFromNullableObject(baseline),
			'include-changed-code-findings': includeChangedFindings ?? undefined,
			'only-changed-code-findings': onlyChangedFindings ?? undefined,
			t: commit ?? undefined,
			blacklisted: blacklisting ?? undefined,
			'only-spec-item-findings': false,
			pretty,
			all: true,
			'qualified-name': qualifiedName ?? undefined,
			...findingsFilter.toQueryParams()
		})
			.fetch()
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves findings for a file by the indicated ids.
	 *
	 * @param project Teamscale project
	 * @param findingIds List of Teamscale finding ids.
	 * @param commit Timestamp and branch info
	 * @param handleErrorWithPromise
	 */
	public getResourceFindingsByIds(
		project: string,
		findingIds: string[],
		commit?: UnresolvedCommitDescriptor | null,
		onlySpecItemFindings?: boolean,
		handleErrorWithPromise?: boolean
	): Promise<Array<TrackedFinding | null>> {
		const urlBuilder = url`api/projects/${project}/findings/list/with-ids`;
		urlBuilder.append('t', commit);
		urlBuilder.append('only-spec-item-findings', onlySpecItemFindings);
		const promise = this.post<Array<TrackedFinding | null>>(urlBuilder, findingIds);
		if (handleErrorWithPromise) {
			return promise;
		} else {
			return promise.catch(this.getDefaultErrorHandler());
		}
	}

	/**
	 * Returns the architecture assessment for the specified architecture file.
	 *
	 * @param project The project
	 * @param architecturePath The uniform path of the architecture file
	 * @param timestamp An optional timestamp for time-traveling
	 */
	public getArchitectureAssessment(
		project: string,
		architecturePath: string,
		timestamp?: number | string | UnresolvedCommitDescriptor | null
	): Promise<ArchitectureAssessmentInfo> {
		const urlBuilder = url`api/projects/${project}/architectures/assessments/${architecturePath}`;
		urlBuilder.append('t', timestamp);
		return this.get<ArchitectureAssessmentInfo>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the lookup mapping from types to file for the given project.
	 *
	 * @param project The project
	 * @param timestamp An optional timestamp for time-traveling
	 */
	public getTypeToFileLookup(
		project: string,
		timestamp?: number | string | UnresolvedCommitDescriptor | null
	): Promise<Map<string, string>> {
		const urlBuilder = url`api/projects/${project}/architectures/assessments/type-to-file-lookup`;
		urlBuilder.append('t', timestamp);
		return this.get<Record<string, string>>(urlBuilder).then(typeToFileLookup =>
			ObjectUtils.toMap(typeToFileLookup)
		);
	}

	/**
	 * Validates the given regex.
	 *
	 * @param regEx The regex to be checked for validity
	 * @returns Returns null (valid) or an error message.
	 */
	public validateRegEx(regEx: string): Promise<string | null> {
		const urlBuilder = url`api/validate-regex`;
		urlBuilder.append('regex', regEx);
		return this.get(urlBuilder);
	}

	/** Lists all configured authentication servers. */
	public listAllAuthenticationServers(): Promise<Record<string, string[]>> {
		return QUERY.getAvailableServers().fetch().catch(this.getDefaultErrorHandler());
	}

	/** Returns the configured external findings groups. */
	public getExternalFindingsGroups(): Promise<ExternalAnalysisGroup[]> {
		return this.get<ExternalAnalysisGroup[]>(url`api/external-findings/groups`);
	}

	/** Returns the configured external findings descriptions. */
	public getExternalFindingsDescriptions(): Promise<ExternalFindingsDescription[]> {
		return this.get<ExternalFindingsDescription[]>(url`api/external-findings/descriptions`);
	}

	/** Returns the quality indicators configured in the given project. */
	public getQualityIndicators(project: string): Promise<string[]> {
		return this.get<string[]>(url`api/projects/${project}/configuration/quality-indicators`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Returns the merged metric schema of all analysis profiles. This returns an array of schemas (one for each path).
	 *
	 * @deprecated This API is already migrated to the OpenAPI generator
	 * @param uniformPaths If given, the schemas for these paths are returned, otherwise only the root schema.
	 */
	public getGlobalMetricSchema(uniformPaths?: string[]): Promise<MetricDirectorySchema[]> {
		const urlBuilder = url`api/metric-schema`;
		urlBuilder.appendMultiple('uniform-path', uniformPaths || ['']);
		return this.get<MetricDirectorySchema[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the names of available metric threshold configurations.
	 *
	 * @deprecated This API is already migrated to the OpenAPI generator
	 */
	public getMetricThresholdConfigurationNames(includeDefaultConfigurations: boolean): Promise<string[]> {
		return QUERY.getAllMetricThresholdConfigurationNames({
			'include-default-configurations': includeDefaultConfigurations
		}).fetch();
	}

	/**
	 * Returns the names of available metric threshold configurations.
	 *
	 * @deprecated This API is already migrated to the OpenAPI generator
	 */
	public getMetricThresholdConfigurations(
		includeDefaultConfigurations: boolean
	): Promise<MetricThresholdConfiguration[]> {
		const urlBuilder = url`api/metric-threshold-configurations`;
		urlBuilder.append('include-default-configurations', includeDefaultConfigurations);
		return this.get<MetricThresholdConfiguration[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the metric threshold configuration promise for the given name.
	 *
	 * @deprecated This API is already migrated to the OpenAPI generator
	 */
	public getMetricThresholdConfiguration(
		name: string,
		loadWithBaseConfigurations: boolean,
		projectName?: string
	): Promise<MetricThresholdConfiguration> {
		const urlBuilder = url`api/metric-threshold-configurations/${name}`;
		urlBuilder.append('load-with-base-configurations', loadWithBaseConfigurations);
		urlBuilder.append('project-name', projectName);
		return this.get<MetricThresholdConfiguration>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Creates a metric threshold configuration. */
	public createMetricThresholdConfiguration(
		metricThresholdConfiguration: MetricThresholdConfiguration
	): Promise<void> {
		return this.post(url`api/metric-threshold-configurations`, metricThresholdConfiguration);
	}

	/**
	 * Saves a metric threshold configuration.
	 *
	 * @deprecated This API is already migrated to the OpenAPI generator
	 */
	public saveMetricThresholdConfiguration(metricThresholdConfiguration: MetricThresholdConfiguration): Promise<void> {
		return this.put(url`api/metric-threshold-configurations`, metricThresholdConfiguration);
	}

	/**
	 * Deletes a metric threshold configuration.
	 *
	 * @deprecated This API is already migrated to the OpenAPI generator
	 */
	public deleteMetricThresholdConfiguration(name: string): Promise<void> {
		return this.delete<void>(url`api/metric-threshold-configurations/${name}`).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Get deriving metric threshold configurations. Necessary to check if metric threshold configuration can be
	 * deleted. A configuration cannot be deleted if configurations exist that inherit it.
	 */
	public getDerivingMetricThresholdConfigurations(name: string): Promise<string[]> {
		return this.get<string[]>(url`api/metric-threshold-configurations/${name}/deriving-configuration-names`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Returns the project configuration for a project.
	 *
	 * @param unEncodedProjectId The ID of the project to obtain the config for. Will be url-encoded by this method.
	 */
	public getProjectConfiguration(unEncodedProjectId: string): Promise<ProjectConfiguration> {
		const urlBuilder = url`api/projects/${unEncodedProjectId}/configuration`;
		return this.get<ProjectConfiguration>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Gets the connectors configuration of projects: for all projects or the specified project ids only */
	public getProjectsConnectorsConfig(): Promise<Map<string, ConnectorConfiguration[]>> {
		return this.get<Record<string, ConnectorConfiguration[]>>(url`api/project-connectors`)
			.then(projectsConnectorsConfig => ObjectUtils.toMap(projectsConnectorsConfig))
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Performs a re-analysis of the specified project.
	 *
	 * @param projectId The ID of the project re-analyze
	 * @param onlyFindingsSchemaUpdate Whether to only update the findings and metrics schema without re-analysis
	 */
	public reanalyzeProject(projectId: string, onlyFindingsSchemaUpdate?: boolean | null): Promise<void> {
		const urlBuilder = url`api/projects/${projectId}/reanalysis`;
		urlBuilder.append('only-findings-schema-update', onlyFindingsSchemaUpdate);
		return this.post<void>(urlBuilder, true);
	}

	/**
	 * Creates a project based on a project configuration. Request errors result in a rejected promise and must be
	 * handled there.
	 *
	 * @param projectConfiguration Object describing the configuration.
	 * @param copyDataFromProject An optional project id to copy data from
	 * @param handleErrorsWithPromise If true and the service call fails, the reject method of the promise is called
	 *   with the response data. When set to <code>true</code>, attach an error handler using '.catch(serviceError =>
	 *   ...)' directly to the method call. The error object passed to catch will be of type {@link ServiceCallError}. If
	 *   this is set to false and the service call to create the project fails, a red error page will be shown.
	 */
	public createProject(
		projectConfiguration: ProjectConfiguration,
		copyDataFromProject?: string | null,
		handleErrorsWithPromise?: boolean
	): Promise<void> {
		const urlBuilder = url`api/projects`;
		urlBuilder.append('copy-data-from-project', copyDataFromProject);
		const promise = this.post<void>(urlBuilder, projectConfiguration);
		if (handleErrorsWithPromise) {
			return promise;
		} else {
			return promise.catch(this.getDefaultErrorHandler());
		}
	}

	/**
	 * Lists the projects to which the user has access.
	 *
	 * @param includeDeleting Whether to include projects marked as deleted or not (default is false)
	 * @param includeReanalyzing Whether to include reanalyzing projects as deleted or not (default is false)
	 */
	public getProjectInfos(includeDeleting?: boolean, includeReanalyzing?: boolean): Promise<ProjectInfo[]> {
		const urlBuilder = url`api/projects`;
		urlBuilder.append('include-deleting', includeDeleting);
		urlBuilder.append('include-reanalyzing', includeReanalyzing);
		return this.get<ProjectInfo[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns the project info for the given project id. */
	public getProjectInfo(projectId: string): Promise<ProjectInfo> {
		const urlBuilder = url`api/projects`;
		urlBuilder.appendToPath(projectId);
		return this.get<ProjectInfo>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Retrieve all GitHub repositories the current user has access to. */
	public getNotOwnedAdminGitHubRepositories(): Promise<GitHubRepository[]> {
		return this.get<GitHubRepository[]>(url`api/repositories/github/admin-access`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Retrieve all GitHub repositories the current user has access to. */
	public getOwnedGithubRepositories(): Promise<GitHubRepository[]> {
		return this.get<GitHubRepository[]>(url`api/repositories/github/owned`).catch(this.getDefaultErrorHandler());
	}

	/** Retrieve all programming languages of a GitHub repository. */
	public getProgrammingLanguages(project: string): Promise<string[]> {
		return this.get<string[]>(url`api/repositories/github/languages?project=${project}`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Creates a project based on a GitHub repository. */
	public createGitHubProject(
		owner: string,
		name: string,
		settings: GitHubRepositorySettingsDescription
	): Promise<string> {
		const urlBuilder = url`api/repositories/github`;
		urlBuilder.append('owner', owner);
		urlBuilder.append('name', name);
		return this.post(urlBuilder, settings);
	}

	/** Retrieves the voting settings for a project based on a GitHub repository. */
	public getRepositorySettings(project: string): Promise<GitHubRepositorySettingsDescription> {
		return this.get<GitHubRepositorySettingsDescription>(url`api/repositories/github/${project}/settings`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Updates the voting settings for a project based on a GitHub repository. */
	public updateRepositorySettings(
		project: string,
		settings: GitHubRepositorySettingsDescription
	): Promise<GitHubRepositorySettingsDescription> {
		return this.put(url`api/repositories/github/${project}/settings`, settings);
	}

	/**
	 * Retrieves credentials used by the project with the given project ids.
	 *
	 * @param projectsIds The IDs of the projects.
	 */
	public getProjectCredentialsUsage(projectsIds: string[]): Promise<ExternalCredentialsUsageInfo> {
		const urlBuilder = url`api/projects/credentials`;
		urlBuilder.appendMultiple('project', projectsIds);
		return this.get<ExternalCredentialsUsageInfo>(urlBuilder);
	}

	/**
	 * Saves a project configuration.
	 *
	 * @param projectConfiguration Object describing the configuration.
	 * @param forceReanalyze Whether to perform a re-analyze of the project if required after changing certain
	 *   parameters.
	 * @param skipProjectValidation Whether to skip the project validation.
	 */
	public saveProjectConfiguration(
		projectConfiguration: ProjectConfiguration,
		forceReanalyze?: boolean,
		skipProjectValidation?: boolean
	): Promise<boolean> {
		const urlBuilder = url`api/projects/${Assertions.assertString(projectConfiguration.internalId)}/configuration`;
		urlBuilder.append('reanalyze-if-required', forceReanalyze);
		urlBuilder.append('skip-project-validation', skipProjectValidation);
		return this.put(urlBuilder, projectConfiguration);
	}

	/** Adds the given review comment. */
	public addReviewComment(
		project: string,
		reviewComment: ReviewComment,
		commit: UnresolvedCommitDescriptor | null
	): Promise<void> {
		const urlBuilder = url`api/projects/${project}/findings/review-findings`;
		urlBuilder.append('t', commit);
		return this.post<void>(urlBuilder, [reviewComment]).catch(this.getDefaultErrorHandler());
	}

	/** Resolves the given review finding. */
	public resolveReviewFinding(
		project: string,
		findingId: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<void> {
		const urlBuilder = url`api/projects/${project}/review-findings/${findingId}/resolution`;
		urlBuilder.append('t', commit);
		return this.post<void>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Changes the message of the given review finding. */
	public changeReviewFindingMessage(
		project: string,
		findingId: string,
		newMessage: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<void> {
		const urlBuilder = url`api/projects/${project}/review-findings/${findingId}/message`;
		urlBuilder.append('t', commit);
		urlBuilder.append('message', newMessage);
		return this.post<void>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Checks whether review findings are enabled for the given project. */
	public areReviewFindingsEnabled(project: string): Promise<boolean> {
		return this.get(url`api/projects/${project}/feature/review-finding`);
	}

	/** Obtains the findings notification rules for the given user. */
	public getFindingsNotificationRules(): Promise<FindingsNotificationRules | null> {
		return this.get<FindingsNotificationRules | null>(url`api/notification-rules/findings`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Stores the findings notification rules for the given user.
	 *
	 * @returns 'success' or the error message
	 */
	public setFindingsNotificationRules(notificationRules: FindingsNotificationRules): Promise<string> {
		return this.put<string>(url`api/notification-rules/findings`, notificationRules).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Obtains the metric notification rules for the given user. */
	public getMetricNotificationRules(): Promise<MetricNotificationRules | null> {
		return this.get<MetricNotificationRules | null>(url`api/notification-rules/metric`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Stores the metric notification rules for the given user. */
	public setMetricNotificationRules(notificationRules: MetricNotificationRules): Promise<void> {
		return this.put<void>(url`api/notification-rules/metric`, notificationRules).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Returns a metric file distribution for the given metric.
	 *
	 * @param project The projects name
	 * @param uniformPath The uniform path to calculate metrics for
	 * @param metricName The name of the desired metric
	 * @param fileRegexes An array of regular expression strings, that is used to group files according to their uniform
	 *   path
	 * @param callback The callback function for handling retrieved results
	 * @param commit An optional commit for time-travelling
	 */
	public getMetricFileDistribution(
		project: string,
		uniformPath: string,
		metricName: string,
		fileRegexes: string[],
		commit?: UnresolvedCommitDescriptor | null
	): Promise<number[]> {
		const urlBuilder = url`api/projects/${project}/metrics/file-distribution`;
		urlBuilder.append('t', commit);
		urlBuilder.append('metric-name', metricName);
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.appendMultiple('file-regexps', fileRegexes);
		return this.get<number[]>(urlBuilder);
	}

	/** Returns the parse log for a single file. */
	public getElementParseLog(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<ParseLogEntry[]> {
		const urlBuilder = url`api/projects/${project}/parse-log/element`;
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('t', commit);
		return this.get<ParseLogEntry[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves an external link for the given uniform path (or null). This only works for ABAP files imported from an
	 * SAP system and will return null for non ABAP files.
	 */
	public getAbapExternalLink(
		project: string,
		uniformPath: string,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<string | null> {
		const urlBuilder = url`api/projects/${project}/external-link/${uniformPath}`;
		urlBuilder.append('t', commit);
		return this.get<string | null>(urlBuilder)
			.catch<string | null>(TeamscaleServiceClient.handle404AsNull)
			.catch(this.getDefaultErrorHandler());
	}

	/** Fetches the project option schema. */
	public getProjectOptionSchema(project: string): Promise<OptionDescriptor[]> {
		return this.get<OptionDescriptor[]>(url`api/projects/${project}/options/schema`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Fetches all set project options. */
	public getProjectOptions(project: string): Promise<Record<string, OptionValue>> {
		return this.get<Record<string, OptionValue>>(url`api/projects/${project}/options`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Fetches the ProjectThresholdConfigurationsOption. */
	public getProjectThresholdConfigurationOption(project: string): Promise<ProjectThresholdConfigurationsOption> {
		const urlBuilder = url`api/projects/${project}/options/threshold.configurations`;
		return this.get<ProjectThresholdConfigurationsOption>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Sets a specific project option. */
	public setProjectOption(project: string, optionId: string, optionValue: Record<string, unknown>): Promise<void> {
		return this.put(url`api/projects/${project}/options/${optionId}`, optionValue);
	}

	/** Deletes a specific project option. */
	public deleteProjectOption(project: string, optionId: string): Promise<void> {
		const urlBuilder = url`api/projects/${project}/options/${optionId}`;
		return this.delete(urlBuilder);
	}

	/**
	 * Lists the Simulink model corresponding to the given uniformPath
	 *
	 * @param project Current project
	 * @param uniformPath UniformPath of the current simulink file
	 * @param commit The commit descriptor for which to do the assessment
	 * @param subsystem Qualified name of the subsystem. If null only the topmost layer of the simulink model will be
	 *   fetched.
	 */
	public getSimulinkModel(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor,
		subsystem: string | null
	): Promise<SimulinkBlockData> {
		const urlBuilder = url`api/projects/${project}/simulink/model/${uniformPath}`;
		urlBuilder.append('t', commit);
		if (subsystem) {
			urlBuilder.append('sub-system', subsystem);
		}
		return this.get<SimulinkBlockData>(urlBuilder);
	}

	/**
	 * Returns the result of a simulink model comparison.
	 *
	 * @param project Current project
	 * @param leftUniformPath UniformPath of the left simulink file
	 * @param leftLocation Qualified name of the left subsystem
	 * @param rightUniformPath UniformPath of the right simulink file
	 * @param rightLocation Qualified name of the right subsystem
	 * @param commit The commit descriptor for which to do the comparison
	 */
	public getSimulinkModelComparison(
		project: string,
		leftUniformPath: string,
		leftLocation: string,
		rightUniformPath: string,
		rightLocation: string,
		commit: UnresolvedCommitDescriptor
	): Promise<SimulinkModelComparisonResult> {
		const urlBuilder = url`api/projects/${project}/simulink/comparison`;
		urlBuilder.append('t', commit);
		urlBuilder.append('left-path', leftUniformPath);
		urlBuilder.append('right-path', rightUniformPath);
		urlBuilder.append('left-location', leftLocation);
		urlBuilder.append('right-location', rightLocation);
		return this.get<SimulinkModelComparisonResult>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns an architecture analysis group if it is enabled for the current project. Otherwise, throws an error. */
	public getArchitectureAnalysisGroup(project: string): Promise<AnalysisGroup | ServiceCallError> {
		return this.get(
			url`api/projects/${project}/configuration/analysis-groups/${TeamscaleServiceClient.ARCHITECTURE_CONFORMANCE_GROUP_NAME}`
		);
	}

	/**
	 * Returns the assessment of the provided architecture.
	 *
	 * @param project Current project.
	 * @param architecture Data object of the architecture, which should be assessed.
	 * @param commit The commit descriptor for which to do the assessment
	 */
	public getArchitectureAssessmentForArchitecture(
		project: string,
		architecture: ArchitectureInfo,
		commit: UnresolvedCommitDescriptor | null,
		typeSearchQuery?: string
	): Promise<ArchitectureAssessmentInfo> {
		const urlBuilder = url`api/projects/${project}/architectures/assessments`;
		urlBuilder.append('t', commit);
		urlBuilder.append('type-search', typeSearchQuery);
		return this.post<ArchitectureAssessmentInfo>(urlBuilder, architecture);
	}

	/** Determines the architecture upload info with the actual architecture commit before or at the given commit. */
	public getArchitectureCommitUploadInfo(
		project: string,
		commit: UnresolvedCommitDescriptor
	): Promise<CommitArchitectureCommitUploadInfo | null> {
		return this.get<ArchitectureCommitUploadInfo | null>(
			url`api/projects/${project}/external-uploads/architectures/${commit.toString()}`
		).then(architectureCommitUploadInfo => {
			if (architectureCommitUploadInfo == null) {
				return null;
			}
			return new CommitArchitectureCommitUploadInfo(architectureCommitUploadInfo);
		});
	}

	/**
	 * Deletes all commits of the given architecture (identified by uniform path). This means that the full history of
	 * creating/editing/deleting this architecture is deleted.
	 */
	public deleteAllArchitectureCommits(project: string, architectureUniformPath: string): Promise<void> {
		const urlBuilder = url`api/projects/${project}/architectures/${architectureUniformPath}/all-commits`;
		urlBuilder.append('message', 'Deleted all commits of ' + architectureUniformPath);
		return this.delete(urlBuilder);
	}

	/**
	 * Creates a deletion commit of the given architecture (identified by uniform path) if the given commit descriptor
	 * does not refer to an existing add/change/delete commit of the architecture. Deletes the architecture commit if
	 * the given commit descriptor refers to an existing add/change/delete commit of the architecture.
	 *
	 * @param project The project of the architecture
	 * @param architectureUniformPath The uniformPath of the architecture
	 * @param commit The commit descriptor of the change to be discarded.
	 */
	public deleteArchitecture(
		project: string,
		architectureUniformPath: string,
		commit: UnresolvedCommitDescriptor
	): Promise<void> {
		const urlBuilder = url`api/projects/${project}/architectures/${architectureUniformPath}`;
		urlBuilder.append('t', commit);
		urlBuilder.append('message', 'Deleted ' + architectureUniformPath);
		return this.delete(urlBuilder);
	}

	/**
	 * Obtains refactoring suggestions for the given finding.
	 *
	 * @param project
	 * @param finding
	 * @param commit The commit for which suggest a refactoring. May be null or undefined to get the latest
	 *   default-branch revision.
	 */
	public getExtractMethodSuggestions(
		project: string,
		findingId: string,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<RefactoringSuggestions | null> {
		const urlBuilder = url`api/projects/${project}/findings/${findingId}/extract-method-suggestions`;
		urlBuilder.append('t', commit);
		return this.get<RefactoringSuggestions | null>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Sends a test mail to check if all mail server settings are correct. */
	public sendTestMail(): Promise<void> {
		return this.post(url`api/mail-settings/test`);
	}

	/**
	 * Calls the service to randomly select files for the file picker.
	 *
	 * @param project The project name.
	 * @param path The sub-path.
	 * @param minFileSize Minimum file size.
	 * @param maxFileSize Maximum file size.
	 * @param regexSearch Filter results
	 */
	public getRandomFilesFromPicker(
		project: string,
		path: string,
		minFileSize: number,
		maxFileSize: number,
		regexSearch: string
	): Promise<CodeFileInfo[]> {
		const urlBuilder = url`api/projects/${project}/random-files`;
		urlBuilder.append('min', minFileSize);
		urlBuilder.append('max', maxFileSize);
		urlBuilder.append('regex', regexSearch);
		urlBuilder.append('uniform-path', path);
		return this.get<CodeFileInfo[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Calls the service to fetch included source files from solution files.
	 *
	 * @param project The project name.
	 */
	public extractDotNetSourcePaths(project: string): Promise<string[]> {
		return this.get<string[]>(url`api/projects/${project}/audit/dotnet-source-paths-extractor`);
	}

	/**
	 * Calls the service to export audit result data.
	 *
	 * @param project Project name.
	 * @param uniformPath The (architecture) path.
	 */
	public getAuditResultData(project: string, uniformPath: string): string {
		const urlBuilder = url`api/projects/${project}/audit/data-export/${uniformPath}`;
		return urlBuilder.getURL();
	}

	/**
	 * Calls the service to get all project files as ZIP.
	 *
	 * @param project Project name.
	 * @param uniformPath The (architecture) path.
	 * @param asZip Whether ZIP with all files or CSV with all file paths
	 */
	public getAllProjectFiles(project: string, uniformPath: string, asZip: boolean): string {
		let endpoint;
		if (asZip) {
			endpoint = 'snapshot.zip';
		} else {
			endpoint = 'file-paths.csv';
		}
		const urlBuilder = url`api/projects/${project}/project-files/${endpoint}`;
		urlBuilder.append('uniform-path', uniformPath);
		return urlBuilder.getURL();
	}

	/**
	 * Exports code search match list as a downloadable CSV file.
	 *
	 * @param project The project
	 * @param path The uniform path
	 * @param searchTerm The search term in regex format
	 * @param tokenClasses The token classes as list of comma separated strings
	 */
	public getExportCodeSearchMatchListLink(
		project: string,
		path: string,
		searchTerm: string,
		tokenClasses: ETokenClass[]
	): string {
		const urlBuilder = url`api/projects/${project}/audit/code-search/export`;
		urlBuilder.append('uniform-path', path);
		urlBuilder.append('search-term', searchTerm);
		urlBuilder.appendMultiple('token-classes', EnumUtils.getValues(tokenClasses));
		return urlBuilder.getURL();
	}

	/**
	 * Returns all commits per author for the given parameters.
	 *
	 * @param project The project which should be tracked
	 * @param path The path which should be tracked
	 * @param numberOfCommits The minimum number of commits an author needs to be tracked
	 * @param sortingOrder The order in which the authors should be sorted
	 * @param startCommit From this commit on the commits will be tracked.
	 * @param endCommit Until this commit the commits will be tracked.
	 */
	public getCommitTrackingData(
		project: string,
		path: string,
		numberOfCommits: number,
		sortingOrder: ECommitAuthorSortingOrder,
		startCommit: UnresolvedCommitDescriptor | null,
		endCommit: UnresolvedCommitDescriptor | null
	): Promise<CommitData> {
		const urlBuilder = url`api/projects/${project}/commit-chart`;
		urlBuilder.append('uniform-path', path);
		urlBuilder.append('t1', startCommit);
		urlBuilder.append('t2', endCommit);
		urlBuilder.append('commits', numberOfCommits);
		urlBuilder.append('order', sortingOrder.name);
		return this.get<CommitData>(urlBuilder);
	}

	/**
	 * Returns a metric assessment for the given parameters.
	 *
	 * @param project The currently selected project.
	 * @param uniformPath The path for which to receive data
	 * @param commit The commit for which to retrieve data. Can be <code>null</code>.
	 * @param configurationName Name of the threshold configuration that should be used. Should not be
	 *   <code>null</null>.
	 * @param baseline The commit to which the delta and trends should be calculated. Can be <code>null</code>.
	 */
	public getMetricAssessment(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null,
		configurationName: string | null,
		baseline: UnresolvedCommitDescriptor | null | number
	): Promise<GroupAssessment[]> {
		const urlBuilder = url`api/projects/${project}/metric-assessments`;
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('t', commit);
		urlBuilder.append('configuration-name', configurationName);
		urlBuilder.append('baseline', baseline);
		return this.get<GroupAssessment[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns a CSV export of the metric assessment for the given parameters.
	 *
	 * @param project The currently selected project.
	 * @param uniformPath The path for which to receive data
	 * @param commit The commit for which to retrieve data. Can be <code>null</code>.
	 * @param configurationName Name of the assessment profile that should be used. Can be <code>null</null>.
	 * @param baseline The commit to which the delta and trends should be calculated. Can be <code>null</code>.
	 */
	public getMetricAssessmentExport(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null,
		configurationName: string,
		baseline: UnresolvedCommitDescriptor | null
	): void {
		const urlBuilder = url`api/projects/${project}/export-metric-assessment`;
		urlBuilder.append('t', commit);
		urlBuilder.append('configuration-name', configurationName);
		urlBuilder.append('uniform-path', uniformPath);
		if (baseline != null) {
			urlBuilder.append('baseline', baseline.getTimestamp());
		}
		window.location.href = urlBuilder.getURL();
	}

	/**
	 * Calls the project-specific service to retrieve the benchmark results.
	 *
	 * @param metricName The name of the metric to retrieve the benchmark results.
	 */
	public getBenchmarkResults(
		metricName: string,
		notAnonymizedProjectIds: string[],
		selectedAnonymizedProjectIds: string[]
	): Promise<BenchmarkResult[]> {
		return QUERY.getMetricBenchmark({
			'metric-name': metricName,
			projects: notAnonymizedProjectIds,
			'anonymized-projects': selectedAnonymizedProjectIds
		})
			.fetch()
			.catch(this.getDefaultErrorHandler());
	}

	/** Calls the project-specific service to download the benchmark results. */
	public getBenchmarkResultsAsCsv(
		metricName: string,
		notAnonymizedProjectIds: string[],
		selectedAnonymizedProjectIds: string[]
	): string {
		const urlBuilder = url`api/audit/metric-benchmark.csv`;
		urlBuilder.append('metric-name', metricName);
		urlBuilder.appendMultiple('projects', notAnonymizedProjectIds);
		urlBuilder.appendMultiple('anonymized-projects', selectedAnonymizedProjectIds);
		return urlBuilder.getURL();
	}

	/**
	 * Calls the service to retrieve the Latex table.
	 *
	 * @param project The application project.
	 * @param path The path.
	 * @param tableTypes Types of the tables to export.
	 * @param language Language to use.
	 * @param testProject The test project for the application project.
	 * @param allProject Project containing both application and test.
	 */
	public getAuditLatexTable(
		project: string,
		path: string,
		tableTypes: EAuditExportTableEntry[],
		language: EAuditExportLanguageEntry,
		testProject: string,
		allProject: string
	): Promise<string> {
		let query: QueryOperation<string>;
		if (testProject === 'None' || allProject === 'None' || testProject === '' || allProject === '') {
			query = QUERY.getSingleProjectLatexTable(project, {
				'table-type': tableTypes,
				language,
				'uniform-path': path
			});
		} else {
			query = QUERY.getMultiProjectLatexTable({
				'table-type': tableTypes,
				language,
				application: project,
				test: testProject,
				all: allProject
			});
		}
		return query.fetch().catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the review status for a file.
	 *
	 * @returns Can return <code>null</code> if the review feature isn't active at all.
	 */
	public getReviewStatus(
		project: string,
		uniformPath: string,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<AssessedReviewStatus | null> {
		const urlBuilder = url`api/projects/${project}/${uniformPath}/review-status`;
		urlBuilder.append('t', commit);
		return this.get<AssessedReviewStatus | null>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns the review status for a file. */
	public setReviewStatus(
		project: string,
		uniformPath: string,
		reviewStatus: ReviewUploadInfo,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<void> {
		const urlBuilder = url`api/projects/${project}/${uniformPath}/review-status`;
		urlBuilder.append('t', commit);
		return this.post(urlBuilder, reviewStatus);
	}

	/** Updates the metric schema for external metrics in the project */
	public updateExternalMetricSchema(project: string): Promise<void> {
		return this.post<void>(url`api/projects/${project}/metric-update`);
	}

	/**
	 * Validates a connector
	 *
	 * @param connector Java type ConnectorConfiguration
	 * @param projectId The ID of the project to validate
	 * @returns 'success' or the error message
	 */
	public validateConnector(connector: ConnectorConfiguration, projectId: string): Promise<string | null> {
		const urlBuilder = url`api/validate-connector`;
		urlBuilder.append('project-id', projectId);
		return this.put<string>(urlBuilder, connector);
	}

	/** Validates branching configuration settings. */
	public validateBranchingConfiguration(
		branchingConfiguration: ProjectBranchingConfiguration
	): Promise<string | null> {
		return this.put<string>(url`api/branching-configuration/validation`, branchingConfiguration);
	}

	/** Retrieve the analysis-completed projects and projects currently being analyzed. */
	public getProjectsState(): Promise<ProjectsState> {
		return this.get<ProjectsState>(url`api/project-analysis-states`).catch(this.getDefaultErrorHandler());
	}

	/** Retrieve the projects connector states. */
	public getProjectsConnectorState(): Promise<ProjectsConnectorState> {
		return this.get(url`api/project-analysis-states/connectors`);
	}

	/** Retrieve the projects' postponed rollback counts. */
	public getPostponedRollbackCounts(): Promise<PostponedRollbackCounts> {
		return this.get(url`api/project-analysis-states/postponed-rollbacks`);
	}

	/** Retrieves the list of partitions for the given project. */
	public getExternalAnalysisPartitionInfos(project: string): Promise<ExternalAnalysisPartitionInfo[]> {
		return this.get<ExternalAnalysisPartitionInfo[]>(
			url`api/projects/${project}/external-analysis/status/partitions`
		).catch(this.getDefaultErrorHandler());
	}

	/** Retrieves the status of the connectors for a project */
	public getProjectConnectorStatuses(projectId: string): Promise<ProjectConnectorStatus[]> {
		return this.get(url`api/projects/${projectId}/connectors/statuses`);
	}

	/** Retrieves the postponed rollbacks for a project */
	public getPostponedRollbacks(projectId: string): Promise<PostponedRollback[]> {
		return this.get(url`api/projects/${projectId}/postponed-rollbacks`);
	}

	/** Executes a postponed rollback. */
	public executePostponedRollback(projectId: string, rollbackId: string): Promise<void> {
		return this.post(url`api/projects/${projectId}/postponed-rollbacks/execute/${rollbackId}`);
	}

	/** Retrieves the external analysis commits stored for a single partition. */
	public getExternalAnalysisCommitInfosForPartition(
		project: string,
		partition: string
	): Promise<ExternalAnalysisCommitStatus[]> {
		return this.get<ExternalAnalysisCommitStatus[]>(
			url`api/projects/${project}/external-analysis/status/partitions/${partition}`
		).catch(this.getDefaultErrorHandler());
	}

	/** Retrieves the external analysis commits stored for a list of partition in the given timeframe. */
	public getExternalAnalysisCommitInfos(
		project: string,
		partitions: string[],
		baselineTimestamp: number,
		endCommit: CommitDescriptor
	): Promise<Record<string, ExternalAnalysisCommitStatus[]>> {
		const urlBuilder = url`api/projects/${project}/external-analysis/status/commit-infos`;
		urlBuilder.appendMultiple('partitions', partitions);
		// We wrap baseline and end to also support the value LATEST for both
		urlBuilder.append(
			'baseline',
			UnresolvedCommitDescriptor.wrap(new UnresolvedCommitDescriptor(baselineTimestamp))
		);
		urlBuilder.append('end', UnresolvedCommitDescriptor.wrap(endCommit));
		return this.get(urlBuilder);
	}

	/** Retrieves details for a single external analysis commit. */
	public getExternalAnalysisCommitDetails(
		project: string,
		branchName: string,
		timestamp: number | string
	): Promise<ExternalAnalysisStatusInfo> {
		return this.get<ExternalAnalysisStatusInfo>(
			url`api/projects/${project}/external-analysis/status/commits/${branchName + ':' + timestamp}`
		).catch(this.getDefaultErrorHandler());
	}

	/** Retrieves external architecture uploads. */
	public getExternalArchitectureUploadsForProject(projectId: string): Promise<ArchitectureWithCommitCount[]> {
		return this.get<ArchitectureWithCommitCount[]>(
			url`api/projects/${projectId}/external-uploads/architectures`
		).catch(this.getDefaultErrorHandler());
	}

	/** Retrieves external architecture commit infos for an architecture. */
	public getExternalArchitectureUploadCommits(project: string, architecture: string): Promise<CommitWithUserName[]> {
		return this.get<CommitWithUserName[]>(
			url`api/projects/${project}/external-uploads/architectures/${architecture}/commits`
		).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Deletes the external analysis result upload at a given timestamp/branch in a given project.
	 *
	 * @param project Project.
	 * @param branch The branchname of the upload to be deleted.
	 * @param timestamp The timestamp of the upload to be deleted.
	 */
	public deleteExternalAnalysisResultUploadsForProject(
		project: string,
		branch: string,
		timestamp: string
	): Promise<void> {
		const commit = branch + ':' + timestamp;
		return this.delete(url`api/projects/${project}/external-analysis/commits/${commit}`);
	}

	/** Deletes all external analysis result uploads for a given partition. */
	public deleteExternalAnalysisResultUploadsForProjectByPartition(project: string, partition: string): Promise<void> {
		return this.delete(url`api/projects/${project}/external-analysis/partitions/${partition}`);
	}

	/**
	 * Gets the TokenElementInfo for a given uniform path.
	 *
	 * @param project Project.
	 * @param uniformPath The uniform path
	 * @param commit The commit for which to retrieve data.
	 */
	public getTokenElementInfo(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<TokenElementInfo | null> {
		const urlBuilder = url`api/projects/${project}/content`;
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('t', commit);
		return this.get<TokenElementInfo>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Triggers a pre-commit analysis for the current user and the given data.
	 *
	 * @param codeByFilePaths The file contents by their path, which does not have to exist in the project
	 * @param preCommitId Optional: the commit id that will be part of the pre-commit branch name. Can be used to define
	 *   a fixed branch (so follow-up queries overwrite existing pre-commit results)
	 */
	public triggerPreCommit3Analysis(
		project: string,
		codeByFilePaths: Record<string, string>,
		preCommitId?: string
	): Promise<PreCommit3Result> {
		const urlBuilder = url`api/projects/${project}/pre-commit3`;
		urlBuilder.append('commit', preCommitId);
		return this.put(urlBuilder, codeByFilePaths);
	}

	/**
	 * Queries the pre-commit results for the given pre-commit token.
	 *
	 * @param preCommitToken The token created by Teamscale when the precommit analysis had been triggered.
	 */
	public getPreCommit3Result(project: string, preCommitToken: string): Promise<PreCommit3Result> {
		const urlBuilder = url`api/projects/${project}/pre-commit3/${preCommitToken}`;
		return this.get(urlBuilder);
	}

	/**
	 * Returns the file content of the given path on the (artificial) pre-commit branch in Teamscale.
	 *
	 * @param preCommitId The commit id that had been chosen when triggering the pre-commit analysis. Using a fixed
	 *   pre-commit ID will overwrite the results of previous pre-commit queries that used the same id.
	 */
	public getTokenElementInfoForPreCommit(
		project: string,
		preCommitId: string,
		uniformPath: string
	): Promise<FormattedTokenElementInfo> {
		const urlBuilder = url`api/projects/${project}/content/formatted/pre-commit`;
		urlBuilder.append('preCommitId', preCommitId);
		urlBuilder.append('uniform-path', uniformPath);
		return this.get<FormattedTokenElementInfo>(urlBuilder);
	}

	/**
	 * Returns the preprocessorExpansions of an element.
	 *
	 * @param project Project.
	 * @param uniformPath The uniform path
	 * @param commit The commit for which to retrieve data.
	 * @returns Promise returning the PreprocessorExpansionsTransport
	 */
	public getPreprocessorExpansions(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<PreprocessorExpansionsTransport> {
		const urlBuilder = url`api/projects/${project}/preprocessor-expansions/${uniformPath}`;
		if (commit) {
			urlBuilder.append('t', commit);
		}
		return this.get<PreprocessorExpansionsTransport>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns a specific Preprocessor expansion group of an file. This is a group of tokens that are expanded together
	 * by the preprocessor (e.g., one macro call including the parameters and parentheses).
	 *
	 * @param project Project.
	 * @param uniformPath The uniform path
	 * @param commit The commit for which to retrieve data.
	 * @param expansionGroupId The number of the expansion id (determine via #getPreprocessorExpansions)
	 * @returns Promise returning the SinglePreprocessorExpansionTransport
	 */
	public getPreprocessorExpansionGroup(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor,
		expansionGroupId: number
	): Promise<SinglePreprocessorExpansionTransport> {
		const urlBuilder = url`api/projects/${project}/preprocessor-expansions/${uniformPath}/expansions/${
			'' + expansionGroupId
		}`;
		urlBuilder.append('t', commit);
		return this.get<SinglePreprocessorExpansionTransport>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the ownership treemap for the given uniform path using the specified area metric and color.
	 *
	 * @param excludeMergeCommits Exclude merge commits from ownership
	 * @param excludeImportCommits Exclude import commits from ownership
	 * @param areaMetric The metric index to determine the area of displayed nodes
	 * @param width Width to render tree map
	 * @param height Height to render tree map
	 * @param color Color to use for ownership
	 */
	public getCodeOwnershipTreeMap(
		project: string,
		uniformPath: string,
		excludeMergeCommits: boolean,
		excludeImportCommits: boolean,
		areaMetric: number,
		width: number,
		height: number,
		color: number[],
		commit?: UnresolvedCommitDescriptor | null,
		baseline?: UnresolvedCommitDescriptor | null
	): Promise<TreeMapNode> {
		const urlBuilder = url`api/projects/${project}/code-ownership/treemap`;
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('exclude-merge-commits', excludeMergeCommits);
		urlBuilder.append('exclude-import-commits', excludeImportCommits);
		urlBuilder.append('area-metric', areaMetric);
		urlBuilder.append('color', (color[0]! << 16) + (color[1]! << 8) + color[2]!);
		urlBuilder.append('t', commit);
		urlBuilder.append('baseline', baseline);
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		return this.get(urlBuilder);
	}

	/**
	 * Loads the contents of the metrics table
	 *
	 * @deprecated This API is already migrated to the OpenAPI generator
	 * @param project The project
	 * @param commit The commit
	 */
	public getMetricsForThresholdProfiles(
		project: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<MetricsForThresholdProfile[]> {
		const urlBuilder = url`api/metric-threshold-configurations/metrics`;
		urlBuilder.append('project', project);
		urlBuilder.append('t', commit);

		return this.get<MetricsForThresholdProfile[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Loads the metric names that should be hidden per default.
	 *
	 * @param project The project
	 * @param uniformPath The uniform path
	 * @param optionName The option name for which the default hidden metrics should be returned.
	 * @returns The hidden metrics by name.
	 */
	public getDefaultHiddenMetrics(
		project: string,
		uniformPathType: ETypeEntry,
		optionName: string
	): Promise<string[]> {
		const urlBuilder = url`api/projects/${project}/default-hidden-metrics/${uniformPathType}`;
		urlBuilder.append('option-name', optionName);
		return this.get<string[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the repository log entries for the given array of commits.
	 *
	 * @param project The project
	 * @param commits The commits
	 * @param privacyAware Whether only commits by the current user shall be returned based on the privacy server
	 *   option.
	 */
	public getRepositoryLogEntries(
		project: string,
		commits: UnresolvedCommitDescriptor[],
		privacyAware?: boolean,
		mergeRequestId?: string,
		split?: boolean
	): Promise<CommitRepositoryLogEntry[]> {
		const queryParams = {
			commit: commits,
			'privacy-aware': privacyAware,
			'merge-request-id': mergeRequestId
		};
		let logEntriesPromise;
		if (split) {
			logEntriesPromise = QUERY.getRepositoryLogWithSplitAggregates(project, queryParams).fetch();
		} else {
			logEntriesPromise = QUERY.getRepositoryLog(project, queryParams).fetch();
		}

		return logEntriesPromise
			.then(logEntries => CommitRepositoryLogEntry.wrapRepositoryLogEntries(logEntries))
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the log entry for the latest (HEAD) commit for a given project and branch.
	 *
	 * @param project
	 * @param branch
	 * @param privacyAware Whether only a commit by the current user shall be returned based on the privacy server
	 *   option.
	 */
	public getLatestCommitRepositoryLogEntry(
		project: string,
		branch: string,
		privacyAware?: boolean
	): Promise<CommitRepositoryLogEntry | null> {
		return QUERY.findLogEntriesInRange(project, {
			t: UnresolvedCommitDescriptor.latestOnBranch(branch),
			'entry-count': 1,
			'privacy-aware': privacyAware
		})
			.fetch()
			.then(logEntries => {
				if (ArrayUtils.isEmpty(logEntries)) {
					return null;
				}
				return new CommitRepositoryLogEntry(logEntries[0]!);
			})
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Performs a query for issues.
	 *
	 * @param sortField Field to sort issues: issue-id, subject
	 * @param sortOrder Ascending or descending
	 */
	public performIssueQuery(
		project: string,
		query: string,
		startIndex: number,
		maxResult?: number,
		sortOptions?: SortOptions
	): Promise<IssueQueryResult> {
		return this.performQuery(
			url`api/projects/${project}/issue-query`,
			IssueQueryInputHandler.escapeIssueQuery(query),
			startIndex,
			maxResult,
			sortOptions
		);
	}

	/**
	 * Performs a query for issues with TGA information attached.
	 *
	 * @param sortField Field to sort issues: 'issue-id', 'subject', 'changed-methods-count', 'test-gap-ratio',
	 *   'number-of-test-gaps'
	 * @param sortOrder Ascending or descending
	 * @param tgaFilter 'All issues', 'Only issues with changes', 'Only issues with Test Gaps'
	 */
	public performIssueQueryWithTga(
		project: string,
		query: string,
		startIndex: number,
		maxResult: number,
		sortOptions: SortOptions,
		tgaFilter: EIssueTgaFilterOptionEntry,
		issueTgaParameters: IssueTgaParameters,
		coverageSourceParameters: CoverageSourceQueryParameters
	): Promise<IssueQueryResult> {
		const urlBuilder = url`api/projects/${project}/issue-query/with-tga`;
		urlBuilder.append('tga-filter', tgaFilter);
		urlBuilder.appendAll(ServiceClient.convertToKebabCaseURLSearchParams(coverageSourceParameters));
		urlBuilder.appendAll(ServiceClient.convertToKebabCaseURLSearchParams(issueTgaParameters));
		return this.performQuery(
			urlBuilder,
			IssueQueryInputHandler.escapeIssueQuery(query),
			startIndex,
			maxResult,
			sortOptions
		);
	}

	/** Performs a validation for a query. */
	public performQueryValidation(
		project: string,
		query: string,
		queryableEntityType: QueryableEntityType
	): Promise<boolean> {
		const urlBuilder = url`api/projects/${project}`;
		urlBuilder.appendToPath(...queryableEntityType.validationEndpoint.split('/'));
		urlBuilder.append('query', IssueQueryInputHandler.escapeIssueQuery(query));
		return this.get<boolean>(urlBuilder);
	}

	/** Returns the issue trend. */
	public getIssueTrend(project: string, query: string): Promise<QueryTrendResult> {
		const urlBuilder = url`api/projects/${project}/issue-query/trend`;
		urlBuilder.append('query', IssueQueryInputHandler.escapeIssueQuery(query));
		urlBuilder.append('max', 0);
		return this.get(urlBuilder);
	}

	/** Returns the columns available for issues in the given project. */
	public getKnownIssueColumns(projectId: string): Promise<string[]> {
		return this.get(url`api/projects/${projectId}/issue-query/columns`);
	}

	/**
	 * Returns the issue treemap.
	 *
	 * @param project
	 * @param uniformPath
	 * @param areaMetric The metric index to determine the area of displayed nodes
	 * @param width Width to render tree map
	 * @param height Height to render tree map
	 * @param includeFileRegexes Regular expressions for included files.
	 * @param excludeFileRegexes Regular expressions for excluded files.
	 * @param query Query for the issues for which to show the treemap.
	 * @param callback The callback function with one argument of Java type TreeMapNode
	 */
	public getIssueTreeMap(
		project: string,
		uniformPath: string,
		areaMetric: number,
		width: number,
		height: number,
		includeFileRegexes: string[],
		excludeFileRegexes: string[],
		query: string,
		callback: Callback<TreeMapNode>
	): void {
		const urlBuilder = TeamscaleServiceClient.createTreemapBaseURLBuilder(
			url`api/projects/${project}/issues/treemap`,
			areaMetric,
			areaMetric,
			undefined,
			null,
			includeFileRegexes,
			excludeFileRegexes
		);
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('width', width);
		urlBuilder.append('height', height);
		urlBuilder.append('query', IssueQueryInputHandler.escapeIssueQuery(query));
		this.withCallback(this.get<TreeMapNode>(urlBuilder), callback);
	}

	/** Downloads the CSV file for the issue trend. */
	public downloadIssueTrendAsCSV(project: string, query: string): string {
		const urlBuilder = url`api/projects/${project}/issue-query/trend/download`;
		urlBuilder.append('query', IssueQueryInputHandler.escapeIssueQuery(query));
		return urlBuilder.getURL();
	}

	/** Returns all configured issue queries. */
	public getIssueQueries(project: string): Promise<StoredQueryDescriptor[]> {
		return this.get<StoredQueryDescriptor[]>(url`api/projects/${project}/issues/queries`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Creates/overrides a stored issue query. */
	public createStoredIssueQuery(project: string, issueQueryDescriptor: StoredQueryDescriptor): Promise<void> {
		return this.post(url`api/projects/${project}/issues/queries`, issueQueryDescriptor);
	}

	/** Deletes a stored issue query. */
	public deleteStoredIssueQuery(project: string, issueQueryName: string): Promise<void> {
		return this.delete(url`api/projects/${project}/issues/queries/${issueQueryName}`);
	}

	/** Get TGA metrics. */
	public getTestGapTable(testGapOptions: TestGapOptions): Promise<TgaTableEntry[]> {
		return QUERY.getTgaTable(testGapOptions.project, testGapOptions.toQueryParams())
			.fetch()
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns an AI generated summary of a file.
	 *
	 * @param project The project.
	 * @param uniformPath The uniform path of the file for which to get the outline.
	 * @param engine The name of the AI engine to use
	 * @param callback The callback that receives the outline.
	 * @param commit
	 */
	public getAiSummary(
		project: string,
		uniformPath: string,
		engine: string,
		callback: Callback<string>,
		commit: UnresolvedCommitDescriptor | null
	): void {
		const urlBuilder = url`api/projects/${project}/ai/${engine}/content/summary`;
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('t', commit);
		this.withCallback(this.get<string>(urlBuilder), callback);
	}

	/**
	 * Returns an AI generated resolution for a finding.
	 *
	 * @param project The project.
	 * @param findingId The id of the finding to find a resolution for.
	 * @param engine The name of the AI engine to use
	 * @param callback The callback that receives the outline.
	 * @param commit
	 */
	public getAiFindingResolution(
		project: string,
		findingId: string,
		engine: string,
		callback: Callback<FindingResolutionResult>,
		commit: UnresolvedCommitDescriptor | null
	): void {
		const urlBuilder = url`api/projects/${project}/ai/${engine}/findings/${findingId}/resolution`;
		urlBuilder.append('t', commit);
		this.withCallback(this.get<FindingResolutionResult>(urlBuilder), callback);
	}

	/**
	 * Imports a backup with the given form data.
	 *
	 * @param formData
	 * @param uploadProgressCallback Is called with the progress of the upload.
	 * @returns The id of the backup import process, which can be used to query the backup import state.
	 */
	public importBackup(formData: FormData, uploadProgressCallback?: (event: ProgressEvent) => void): Promise<string> {
		return this.post(url`api/backups/import`, formData, {
			uploadProgressCallback
		});
	}

	/**
	 * Gets the current analysis state for the given branch name. If the branch name is null, this method returns the
	 * analysis state of the project.
	 *
	 * @param projectId
	 * @param branchName
	 * @param callback
	 * @param suppressForbiddenErrors Whether to suppress the automatic redirect to the login page on a 403 Forbidden
	 *   error (true) or whether to allow it (false, the default). Not redirecting makes it possible to selectively
	 *   handle permission errors that affect only some components of the page instead of aborting wholesale by
	 *   switching to the login page.
	 */
	public async getBranchAnalysisState(
		projectId: string,
		branchName: string | null,
		suppressForbiddenErrors: boolean | null = false
	): Promise<AnalysisStateWithProjectAndBranch> {
		return this.get<AnalysisState>(url`api/projects/${projectId}/branch-analysis-state/${branchName ?? ''}`)
			.catch(error => {
				if (!suppressForbiddenErrors || error.statusCode !== HttpStatus.FORBIDDEN) {
					this.getErrorManager().handleError(error);
				}
				return new Promise<AnalysisState>(() => 0);
			})
			.then(analysisState => ({ ...analysisState, branchName, projectId }));
	}

	/** Loads the project roles and role assignments for the project ID. */
	public getProjectRoles(projectId: string): Promise<RolesWithAssignments<ProjectRole>> {
		return this.get<RolesWithAssignments<ProjectRole>>(url`api/roles/project-role-assignments/${projectId}`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Sends the role change for the project id to the server. If projectId is null, the role assignment is applied
	 * globally to all projects.
	 */
	public changeProjectRolesAsync(projectId: string | null, roleChange: RoleChange): Promise<void> {
		if (projectId !== null) {
			return this.post<void>(url`api/roles/project-role-assignments/${projectId}`, roleChange).catch(
				this.getDefaultErrorHandler()
			);
		}
		return this.post<void>(url`api/roles/project-role-assignments`, roleChange).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Loads the permission lookup for the permission scope.
	 *
	 * @param permissionScope
	 */
	public getPermissions(permissionScope: EBasicPermissionScope): Promise<PermissionLookup> {
		return QUERY.getPermissionLookup({ 'permission-scope': permissionScope.name })
			.fetch()
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Retrieves the TGA treemap wrapper for the given uniform path.
	 *
	 * @param project
	 * @param uniformPath
	 * @param findingsFilter
	 * @param width Width to render tree map.
	 * @param height Height to render tree map.
	 * @param baselineCommit The baseline commit. Can be null to indicate no baseline.
	 * @param includeChangedFindings Whether to include findings in changed code
	 * @param onlyChangedFindings Whether to view findings in changed code only
	 * @param endCommit The end commit, which also determines the branch. Can be null to indicate no HEAD and default
	 *   branch.
	 * @param mainColor For the treemap. If not specified, the server default will be used.
	 * @param blacklistFilter The blacklist findingsFilter can be null to use all non blacklisted findings.
	 */
	public getFindingsTreemap(
		project: string,
		uniformPath: string,
		findingsFilter: FindingsFilter,
		width: number,
		height: number,
		baselineCommit: UnresolvedCommitDescriptor | null | undefined,
		includeChangedFindings: boolean | null | undefined,
		onlyChangedFindings: boolean | null | undefined,
		endCommit: UnresolvedCommitDescriptor | null | undefined,
		mainColor: string | null,
		blacklistFilter: EBlacklistingOption
	): Promise<FindingsTreemapWrapper> {
		return QUERY.getFindingsTreemap(project, {
			...findingsFilter.toQueryParams(),
			'uniform-path': uniformPath,
			baseline: StringUtils.getStringFromNullableObject(baselineCommit),
			'include-changed-code-findings': includeChangedFindings ?? undefined,
			'only-changed-code-findings': onlyChangedFindings ?? undefined,
			t: endCommit ?? undefined,
			width,
			height,
			color: mainColor ?? undefined,
			blacklisted: blacklistFilter.name
		})
			.fetch()
			.catch(this.getDefaultErrorHandler());
	}

	/** Queries the subjectRoleAssignments of the user. */
	public getSubjectRolesAssignments(subjectId: string, subjectType: string): Promise<SubjectRoleAssignments[]> {
		return this.get<SubjectRoleAssignments[]>(url`api/subject-role-assignments/${subjectType}/${subjectId}`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Returns the current cross clone detection status. */
	public getXCloneDetectionStatus(project: string): Promise<ExternalXCloneStatus> {
		return this.get<ExternalXCloneStatus>(url`api/projects/${project}/audit/external-x-clones/status`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Runs the X clone detection by calling the corresponding service, which then schedules the trigger. */
	public runXCloneDetection(
		project: string,
		uniformPath: string,
		externalPath: string,
		include: string,
		exclude: string,
		minLength: number
	): Promise<string> {
		return this.post<string>(url`api/projects/${project}/audit/external-x-clones`, {
			uniformPath,
			externalPath,
			include,
			exclude,
			minLength
		}).catch(this.getDefaultErrorHandler());
	}

	/** Returns the currently detected cross clone classes. */
	public getDetectedXCloneClasses(project: string): Promise<ExternalXCloneClass[]> {
		return this.get(url`api/projects/${project}/audit/external-x-clones/classes`);
	}

	/** Returns local file content. */
	public getLocalFileContent(path: string): Promise<FormattedTokenElementInfo> {
		return QUERY.getExternalFileWithPreprocessing(path).fetch();
	}

	/** Triggers the regeneration of the SAML Service Provider certificate for the configured option. */
	public regenerateSamlServiceProviderCertificate(name: string): Promise<boolean> {
		const urlBuilder = url`api/auth/saml/sp-configuration/certificate/generate`;
		urlBuilder.append('name', name);
		return this.post<boolean>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns the SAML metadata from an external identity provider. */
	public getSamlIdentityProviderMetadata(idpUrl: string): Promise<string> {
		return QUERY.getSamlIdentityProviderMetadata({ 'idp-url': idpUrl }).fetch();
	}

	/** Returns the OpenID Connect endpoints based on the issuer URL. */
	public getOpenIdEndpoints(issuer: string): Promise<OpenIdEndpointInfo> {
		return QUERY.getOpenIdEndpoints({ issuer }).fetch();
	}

	/**
	 * Fetches the branches of some Version Controlled System (VCS) repository
	 *
	 * @param connectorConfig Connector configuration with infos for retrieving branches
	 * @param projectId The id of the project to which the connector configuration belongs
	 */
	public getRepositoryBranches(
		connectorConfig: ConnectorConfiguration,
		projectId: string | undefined
	): Promise<string[]> {
		return QUERY.previewRepositoryBranches({ projectId }, connectorConfig).fetch();
	}

	/**
	 * Fetches the files of some Version Controlled System (VCS) repository
	 *
	 * @param connectorConfig Connector configuration with infos for retrieving files
	 * @param projectId The id of the project to which the connector configuration belongs
	 */
	public getRepositoryFiles(connectorConfig: ConnectorConfiguration, projectId: string): Promise<string[]> {
		return QUERY.previewRepositoryFiles({ projectId }, connectorConfig).fetch();
	}

	/** Returns the health state of the system, plus critical status messages (if applicable). */
	public getSystemHealthState(): Promise<{
		healthy: boolean;
		statusMessage: string;
	}> {
		return QUERY.getHealthStatus({ 'critical-only': true })
			.fetch()
			.then(statusLine => {
				// The health check service guarantees that the output contains a
				// numerical return code (RC) which reflects the health state of the
				// system
				const match = statusLine.match('RC: (\\d)\\)[\\n\\r]*(.+)');
				if (match !== null && match.length === 3) {
					// A return code of "2" indicates a critical health state
					return { healthy: match[1] !== '2', statusMessage: match[2]! };
				}
				return { healthy: true, statusMessage: '' };
			});
	}

	/**
	 * Checks whether a connection to the Teamscale server is possible (without actually querying any data). Potential
	 * exceptions are caught by this method and handled as "no connection possible".
	 *
	 * @returns <code>true</code> if the server can be reached, <code>false</code> in case of connection errors or
	 *   exceptions.
	 */
	public canReachServer(): Promise<boolean> {
		return new Promise(resolve => {
			const httpRequest = new XMLHttpRequest();
			httpRequest.onload = (): void => resolve(true);
			httpRequest.onerror = (): void => resolve(false);
			try {
				httpRequest.open('GET', '', true);
				httpRequest.setRequestHeader('Content-type', 'application/json; charset=utf-8');
				httpRequest.send();
			} catch (e) {
				resolve(false);
			}
		});
	}

	/**
	 * Loads all available rules for the given set of languages and tools. If a project or analysis profile is given,
	 * the rules from this project or analysis profile are loaded. Disabled rules and rules from disabled analysis
	 * groups are always included.
	 */
	public getRules(
		languages: ELanguage[],
		tools: EAnalysisTool[],
		project?: string,
		analysisProfile?: string,
		createMode?: boolean
	): Promise<RulesContainer> {
		const params: GetLanguageRulesQueryParams = {
			languages: EnumUtils.getValues(languages),
			tools: EnumUtils.getValues(tools)
		};
		let query: QueryOperation<RulesContainer>;
		if (project != null) {
			const projectParams: GetLanguageRulesForProjectQueryParams =
				params as GetLanguageRulesForProjectQueryParams;
			projectParams.includeDisabledRules = true;
			projectParams.includeDisabledGroups = true;
			query = QUERY.getLanguageRulesForProject(project, projectParams);
		} else if (analysisProfile != null) {
			query = QUERY.getLanguageRulesForAnalysisProfile(analysisProfile, {
				...params,
				includeDisabledRules: true,
				includeDisabledGroups: true,
				createMode
			});
		} else {
			query = QUERY.getLanguageRules(params);
		}
		return query.fetch();
	}

	/** Creates a session for uploading external analysis results. */
	public uploadExternalAnalysisReportToSession(
		project: string,
		sessionId: string,
		format: string,
		report: File,
		uploadProgressCallback?: UploadProgress
	): Promise<string> {
		const urlBuilder = url`api/projects/${project}/external-analysis/session/${sessionId}/report`;
		urlBuilder.append('format', format);
		const formData = new FormData();
		formData.append('report', report);
		return this.post(urlBuilder, formData, { uploadProgressCallback });
	}

	/**
	 * Deletes a project.
	 *
	 * @param projectName The name of the project to be deleted.
	 * @param deleteAllAssignments Deletes additional project information like role assignments
	 * @param deleteAllDashboards Deletes additional project information like dashboards
	 * @param callback Callback provides string "success"
	 */
	public deleteProject(
		projectName: string,
		deleteAllAssignments: boolean,
		deleteAllDashboards: boolean,
		callback: Callback<void>
	): void {
		const urlBuilder = url`api/projects/${projectName}`;
		urlBuilder.append('delete-all-assignments', deleteAllAssignments);
		urlBuilder.append('delete-all-dashboards', deleteAllDashboards);
		this.withCallback(this.delete<void>(urlBuilder), callback);
	}

	/** Updates a project. The project must already exist. */
	public updateProject(projectInfo: ProjectInfo): Promise<ProjectUpdateResult> {
		return this.put(url`api/projects/${projectInfo.internalId!}`, projectInfo);
	}

	/**
	 * Used to (un)pause a project .
	 *
	 * @param projectId The id of the project
	 * @param pause Whether to pause (true) or unpause (false)
	 */
	public setProjectSchedulePausing(projectId: string, pause: boolean): Promise<void> {
		const urlBuilder = url`api/scheduler/projects/${projectId}`;
		let command;
		if (pause) {
			command = EProjectScheduleCommand.PAUSE;
		} else {
			command = EProjectScheduleCommand.UNPAUSE;
		}
		return this.put(urlBuilder, command.name);
	}

	/** Returns the content of an element. */
	public getFormattedContent(
		project: string,
		uniformPath: string,
		commit?: string | null | UnresolvedCommitDescriptor,
		pretty?: boolean
	): Promise<FormattedTokenElementInfo | null> {
		const urlBuilder = url`api/projects/${project}/content/formatted`;
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('t', commit);
		urlBuilder.append('pretty', pretty);
		return this.get<FormattedTokenElementInfo>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns the resource metrics. */
	public getResourceMetrics(
		project: string,
		uniformPath: string,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<MetricDirectoryEntry> {
		const urlBuilder = url`api/projects/${project}/metrics`;
		urlBuilder.append('t', commit);
		urlBuilder.append('uniform-path', uniformPath);
		return this.get<MetricDirectoryEntry>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the resource metric distribution. The callback function is called with a list of Java type
	 * MetricDistributionEntry.
	 *
	 * @param project The name of the project
	 * @param uniformPath The uniform path for which to calculate the metric distribution.
	 * @param principalMetricIndex The 'selected' metric index with respect to which the metric distribution is
	 *   calculated.
	 * @param metricIndexes The list of metric indexes to calculate the distribution.
	 * @param boundaries The list of boundaries from which consecutive intervals are calculated and used to calculate
	 *   the distribution.
	 * @param commit An optional commit to retrieve the data.
	 */
	public getResourceMetricDistribution(
		project: string,
		uniformPath: string,
		principalMetricIndex: number,
		metricIndexes: number[],
		boundaries: number[],
		commit?: UnresolvedCommitDescriptor | null
	): Promise<MetricDistributionEntry[]> {
		const urlBuilder = url`api/projects/${project}/metric-distribution`;
		urlBuilder.append('t', commit);
		urlBuilder.append('uniform-path', uniformPath);
		TeamscaleServiceClient.appendMetricDistributionParameters(
			urlBuilder,
			principalMetricIndex,
			metricIndexes,
			boundaries
		);
		return this.get<MetricDistributionEntry[]>(urlBuilder);
	}

	/**
	 * Returns the resource metric distribution with delta. The callback function is called with an object of Java type
	 * MetricDistributionWithDelta. The delta is the difference between end and start metric distribution values for the
	 * provided commits. If no end commit is provided the time is set to now.
	 *
	 * @param project The name of the project.
	 * @param uniformPath The uniform path to calculate the metric distribution for.
	 * @param principalMetricIndex The 'selected' metric index with respect to which the metric distribution is
	 *   calculated.
	 * @param metricIndexes The list of metric indexes to calculate the distribution.
	 * @param boundaries The list of boundaries from which consecutive intervals are calculated and used to calculate
	 *   the distribution.
	 * @param startCommit The commit for the start metric distribution.
	 * @param endCommit Optional commit for the end metric distribution. The current time is used if not provided.
	 */
	public getResourceMetricDistributionWithDelta(
		project: string,
		uniformPath: string,
		principalMetricIndex: number,
		metricIndexes: number[],
		boundaries: number[],
		startCommit: UnresolvedCommitDescriptor,
		endCommit?: UnresolvedCommitDescriptor | null
	): Promise<MetricDistributionWithDelta> {
		const urlBuilder = url`api/projects/${project}/metric-distribution/delta`;
		TeamscaleServiceClient.appendMetricDistributionParameters(
			urlBuilder,
			principalMetricIndex,
			metricIndexes,
			boundaries
		);
		urlBuilder.append('t1', startCommit);
		urlBuilder.append('t2', endCommit);
		urlBuilder.append('uniform-path', uniformPath);
		return this.get<MetricDistributionWithDelta>(urlBuilder);
	}

	/**
	 * Returns metric hotspots for the given resource.
	 *
	 * @param project The name of the project
	 * @param uniformPath The uniform path for which to calculate the metric distribution.
	 * @param metricIndexes The list of metric indexes to calculate the distribution.
	 * @param numResults The number of results to return
	 * @param scoreCutoff The score at which to perform a cutoff (i.e. files with this score or higher will no longer be
	 *   displayed)
	 * @param commit An optional commit to retrieve the data.
	 */
	public getResourceMetricHotspots(
		project: string,
		uniformPath: string,
		metricIndexes: number[],
		numResults: number,
		scoreCutoff: number,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<MetricDirectoryEntry[]> {
		const urlBuilder = url`api/projects/${project}/metrics/hotspots`;
		urlBuilder.append('t', commit);
		urlBuilder.appendMultiple('metric-indexes', metricIndexes);
		urlBuilder.append('num-results', numResults);
		urlBuilder.append('score-cutoff', scoreCutoff);
		urlBuilder.append('uniform-path', uniformPath);
		return this.get<MetricDirectoryEntry[]>(urlBuilder);
	}

	/**
	 * Returns the metrics schema for the project.
	 *
	 * @param uniformPathType Optional, can be <code>null</code> for 'CODE'
	 */
	public getMetricsSchema(project: string, uniformPathType?: ETypeEntry | null): Promise<MetricDirectorySchema> {
		return this.get<MetricDirectorySchema>(
			url`api/projects/${project}/metric-schema/${uniformPathType ?? 'CODE'}`
		).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Fetches and returns all metric schemas of the given project. The returned type is a record that maps the
	 * respective EType#name of each metric schema to the schema itself.
	 */
	public getMetricsSchemas(project: string): Promise<Record<ETypeEntry, MetricDirectorySchema>> {
		return this.get<Record<string, MetricDirectorySchema>>(url`api/projects/${project}/metric-schemas`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Returns commits along a history trend for given timestamps.
	 *
	 * @param branch The branch on which the history shall be retrieved
	 */
	public getCommitsOnHistoryTrend(
		project: string,
		branch: string | null,
		timestamps: number[]
	): Promise<UnresolvedCommitDescriptor[]> {
		const urlBuilder = url`api/projects/${project}/metrics/history/commits`;
		urlBuilder.append('branch', branch);
		urlBuilder.appendMultiple('timestamp', timestamps);
		return this.get<UnresolvedCommitDescriptor[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Returns the threshold path for a given threshold in the defined time span. */
	public getMetricThresholdPathForThreshold(
		project: string,
		configuration: string,
		thresholdGroupName: string,
		thresholdDisplayName: string,
		startTimestamp: number | null,
		endTimestamp: number | null,
		commit: UnresolvedCommitDescriptor | null,
		callback: Callback<EvaluatedMetricThresholdPath>
	): void {
		const urlBuilder = url`api/projects/${project}/metric-threshold-path-for-threshold`;
		urlBuilder.append('configuration', configuration);
		urlBuilder.append('thresholdGroupName', thresholdGroupName);
		urlBuilder.append('thresholdDisplayName', thresholdDisplayName);
		urlBuilder.append('start', startTimestamp);
		urlBuilder.append('end', endTimestamp);
		urlBuilder.append('t', commit);
		this.withCallback(this.get<EvaluatedMetricThresholdPath>(urlBuilder), callback);
	}

	/**
	 * Returns the threshold path for a metric in a threshold configuration. A heuristic tries to find the best matching
	 * threshold within the configuration.
	 *
	 * @param project Name of the project
	 * @param configurationName Name of the threshold configuration
	 * @param metricName Name of the metric
	 * @param path Path without project prefix
	 * @param callback Callback
	 * @param startTimestamp
	 * @param endTimestamp
	 */
	public getMetricThresholdPathForMetric(
		project: string,
		configurationName: string,
		metricName: string,
		path: string,
		startTimestamp: number | null,
		endTimestamp: number | null
	): Promise<EvaluatedMetricThresholdPath> {
		const urlBuilder = url`api/projects/${project}/metric-threshold-path-for-metric`;
		urlBuilder.append('configuration', configurationName);
		urlBuilder.append('metricName', metricName);
		urlBuilder.append('path', path);
		urlBuilder.append('start', startTimestamp);
		urlBuilder.append('end', endTimestamp);
		return this.get<EvaluatedMetricThresholdPath>(urlBuilder);
	}

	/**
	 * Returns the resource metrics history until a given commit.
	 *
	 * @param project
	 * @param uniformPath
	 * @param callback
	 * @param endCommit The end commit or null
	 * @param startCommit Commit from which the history should start
	 */
	public getResourceMetricsHistoryUntil(
		project: string,
		uniformPath: string,
		endCommit: UnresolvedCommitDescriptor | null,
		startCommit?: UnresolvedCommitDescriptor | null,
		partitions?: string[]
	): Promise<MetricTrendEntry[]> {
		const urlBuilder = url`api/projects/${project}/metrics/history`;
		if (startCommit != null) {
			urlBuilder.append('start', startCommit.toString());
		}
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('end', endCommit?.toString());
		urlBuilder.append('t', endCommit?.toString());
		urlBuilder.appendMultiple('partition', partitions);
		urlBuilder.append('all-partitions', partitions === undefined);
		return this.get(urlBuilder);
	}

	/**
	 * Returns the repository activity summary for the given project.
	 *
	 * @param project The project name
	 * @returns The requested repository activity summary
	 */
	public getRepositoryActivitySummary(project: string): Promise<RepositoryActivitySummary> {
		return QUERY.getRepositorySummary(project, {})
			.fetch()
			.catch(this.getDefaultErrorHandler()) as Promise<RepositoryActivitySummary>;
	}

	/** Returns the repository activity summary for the given project and only considers code commits. */
	public getRepositoryActivitySummaryForCodeCommits(project: string): Promise<RepositoryActivitySummary> {
		return QUERY.getRepositorySummary(project, { 'code-commits-only': true })
			.fetch()
			.catch(this.getDefaultErrorHandler()) as Promise<RepositoryActivitySummary>;
	}

	/**
	 * Returns a simple the repository summary for the given project.
	 *
	 * @param project The project name
	 * @returns The requested repository summary
	 */
	public getSimpleRepositorySummary(project: string): Promise<RepositorySummary> {
		return QUERY.getRepositorySummary(project, { 'only-first-and-last': true })
			.fetch()
			.catch(this.getDefaultErrorHandler());
	}

	/** Returns the repository log file entries for the given commits in a given project. */
	public async getRepositoryLogFileEntries(
		project: string,
		commits: UnresolvedCommitDescriptor[]
	): Promise<TokenElementChurnWithOriginInfo[]> {
		return QUERY.getAffectedFilesForCommits(project, { commit: commits })
			.fetch()
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the repository log file entries for a given uniform path in a given project.
	 *
	 * @param project The project
	 * @param uniformPath The uniform path
	 * @param commit The commit
	 * @param commitFilterSettings
	 * @param numberOfEntries
	 */
	public async getResourceHistoryEntries(
		project: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor | null,
		commitFilterSettings: CommitFilterSettings | null,
		numberOfEntries = 0
	): Promise<RepositoryLogFileHistoryEntry[]> {
		return QUERY.getResourceHistory(project, uniformPath, {
			t: commit ?? undefined,
			...commitFilterSettings,
			'number-of-entries': numberOfEntries
		})
			.fetch()
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the latest repository log entry for a given element in a given project at or before the given commit.
	 *
	 * @param project The project
	 * @param uniformPath The uniform path
	 * @param endCommit The commit; if not given, return latest on default branch
	 * @param excludeNonCodeCommits Whether non-code commits (e.g., external uploads code reviews) should be regarded
	 *   for the last change
	 */
	public getLastChangeEntryForResource(
		project: string,
		uniformPath: string,
		endCommit: UnresolvedCommitDescriptor | null | undefined,
		excludeNonCodeCommits: boolean
	): Promise<RepositoryLogEntry | null> {
		const urlBuilder = url`api/projects/${project}/repository/last-change/${uniformPath}`;
		urlBuilder.append('t', endCommit);
		urlBuilder.append('exclude-non-code-commits', excludeNonCodeCommits);
		return this.get<RepositoryLogEntry | null>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Compares the metrics for the given uniform path for the given commits. */
	public getMetricDeltas(
		project: string,
		uniformPath: string,
		commit1: UnresolvedCommitDescriptor | string | number,
		commit2: UnresolvedCommitDescriptor | string | number | null
	): Promise<MetricDeltaValue[]> {
		const urlBuilder = url`api/projects/${project}/metrics/delta`;
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('t1', commit1);
		urlBuilder.append('t2', commit2);
		return this.get<MetricDeltaValue[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the extended types of the given uniform path.
	 *
	 * @param project The project of the uniform path.
	 * @param uniformPath The uniform path to look up.
	 * @param commit The commit for which to retrieve the data. Latest if not given.
	 */
	public getExtendedResourceTypes(
		project: string,
		uniformPath: string,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<EExtendedResourceTypeEntry[]> {
		const urlBuilder = url`api/projects/${project}/ext-resource-type/${uniformPath}`;
		urlBuilder.append('t', commit);
		return this.get<EExtendedResourceTypeEntry[]>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * @param project The project of the uniform path.
	 * @param uniformPath The uniform path to look up.
	 * @param timestamp An optional timestamp.
	 * @param children If this is true, the callback will be called with a map from the name of the children of the
	 *   given uniform path to their type. The type of a container child will be the type of its
	 *   deepestRelativePathWithMoreThanOneChild. If the parameter is false, returns the type of the given uniform
	 *   path.
	 * @param checkDefaultBranch Determines if the default branch is also checked for the resource if it was not found
	 *   on the current branch
	 * @returns The type of the given uniform path.
	 */
	public getResourceType(
		project: string,
		uniformPath: string,
		timestamp: number | string | UnresolvedCommitDescriptor | null,
		children: true,
		checkDefaultBranch?: boolean
	): Promise<Record<string, EResourceTypeEntry>>;
	public getResourceType(
		project: string,
		uniformPath: string,
		timestamp: number | string | UnresolvedCommitDescriptor | null,
		children?: false | null,
		checkDefaultBranch?: boolean
	): Promise<EResourceTypeEntry | null>;
	public getResourceType(
		project: string,
		uniformPath: string,
		timestamp?: number | string | UnresolvedCommitDescriptor | null,
		children?: boolean | null,
		checkDefaultBranch = true
	): Promise<EResourceTypeEntry | Record<string, EResourceTypeEntry> | null> {
		let urlBuilder = url`api/projects/${project}/resource-type`;
		if (children) {
			urlBuilder = url`api/projects/${project}/resource-type/children`;
		}
		urlBuilder.append('t', timestamp);
		urlBuilder.append('uniform-path', uniformPath);
		urlBuilder.append('check-default-branch', checkDefaultBranch);
		return this.get<EResourceTypeEntry | Record<string, EResourceTypeEntry> | null>(urlBuilder).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Adds error handling to convert 404 not found responses to a null value. */
	private static handle404AsNull<T>(error: unknown): Promise<T | null> {
		if (error instanceof ServiceCallError && error.statusCode === HttpStatus.NOT_FOUND) {
			return Promise.resolve(null);
		}
		return Promise.reject(error);
	}

	/**
	 * Lists the identifiers of available architecture assessments.
	 *
	 * @param project
	 * @param endCommit An optional timestamp for time-traveling
	 * @returns Called with Java type List<ArchitectureOverviewInfo>
	 */
	public listArchitectureAssessmentIdentifiers(
		project: string,
		endCommit?: number | string | UnresolvedCommitDescriptor | null
	): Promise<ArchitectureOverviewInfo[]> {
		const urlBuilder = url`api/projects/${project}/architectures/assessments`;
		urlBuilder.append('t', endCommit);
		return this.get(urlBuilder);
	}

	/**
	 * Returns any pending architecture uploads as a map from path to EArchitectureUploadType.
	 *
	 * @param project
	 * @param commit An optional timestamp for time-traveling
	 */
	public getArchitectureUploadProgress(
		project: string,
		commit?: number | string | UnresolvedCommitDescriptor | null
	): Promise<Record<string, keyof typeof EArchitectureUploadType>> {
		const urlBuilder = url`api/projects/${project}/architecture-analysis-progress`;
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/**
	 * Finds the commits that belong to a certain revision. Calls the RepositoryTimestampByRevisionService
	 *
	 * @param project The project.
	 * @param revision The revision.
	 */
	public getCommitsForRevision(project: string, revision: string): Promise<DataCommitDescriptor[]> {
		return this.get<DataCommitDescriptor[]>(url`api/projects/${project}/revision/${revision}/commits`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Enables or disables Voting or Detailed Line Commenting for all connectors of the given project.
	 *
	 * @param projectId The project id.
	 * @param votingOption The kind of voting option (Voting or Line Commenting).
	 * @param enable Whether to enable (<code>true</code>) or disable (<code>false</code>) the option.
	 */
	public enableVotingOption(
		projectId: string,
		votingOption: (typeof VOTING_OPTION_KIND)[VotingOptionType],
		enable: boolean
	): Promise<void> {
		const urlBuilder = url`api/projects/${projectId}/connectors/voting-options/${votingOption.id}`;
		urlBuilder.append('enable', enable);
		return this.put<void>(urlBuilder);
	}

	/**
	 * Looks up information about a specific user.
	 *
	 * @param userName The name of the user for which details should be fetched.
	 * @param handleErrorsWithPromise If true and the service call fails, the reject method of the promise is called
	 *   with the response data. When set to <code>true</code>, attach an error handler using '.catch(serviceError =>
	 *   ...)' directly to the method call. The error object passed to catch will be of type {@link ServiceCallError}. If
	 *   this is set to false and the service call fails, a red error page will be shown.
	 * @returns The user information. Will be <code>null</code> if the user was not found or is not accessible by the
	 *   current user.
	 */
	public getUserDetails(userName: string, handleErrorsWithPromise?: boolean): Promise<User | null> {
		const promise = this.get<User | null>(url`api/users/${userName}`);
		if (handleErrorsWithPromise) {
			return promise;
		} else {
			return promise.catch(this.getDefaultErrorHandler());
		}
	}

	/** Creates a new user or overwrites an existing one. */
	public updateUser(userData: UserData): Promise<void> {
		return this.put<void>(url`api/users/${userData.username}`, userData).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Changes the crop dimensions of an avatar.
	 *
	 * @param username
	 * @param avatarOffsetX The offset of the avatar from the left in pixels.
	 * @param avatarOffsetY The offset of the avatar from the top in pixels.
	 * @param avatarSize The size of the avatar in pixels.
	 * @returns String "success" if creation succeeded.
	 */
	public changeAvatarCropDimensions(
		username: string,
		avatarOffsetX: number,
		avatarOffsetY: number,
		avatarSize: number
	): Promise<string> {
		const urlBuilder = url`api/avatars/${username}`;
		urlBuilder.append('avatarOffsetX', avatarOffsetX);
		urlBuilder.append('avatarOffsetY', avatarOffsetY);
		urlBuilder.append('avatarSize', avatarSize);
		return this.put<string>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/** Deletes a user. */
	public deleteUser(username: string): Promise<string | string[]> {
		return this.delete<string>(url`api/users/${username}`).catch(this.getDefaultErrorHandler());
	}

	/** Deletes the list of given users. */
	public deleteUsers(usernames: string[]): Promise<string> {
		const userBatchOperation: UserBatchOperation = {
			usersToDelete: usernames
		};
		return this.post(url`api/users`, userBatchOperation);
	}

	/** Fetches all set server options. */
	public getServerOptions(): Promise<Options> {
		return this.get<Options>(url`api/options/server`).catch(this.getDefaultErrorHandler());
	}

	/** Fetches current default external storage backend option. */
	public getDefaultExternalStorageBackendOption(): Promise<ExternalStorageBackendOption> {
		return this.get<ExternalStorageBackendOption>(url`api/options/server/default-external-storage-backend`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Sets current default external storage backend option. */
	public setDefaultExternalStorageBackendOption(newValue: ExternalStorageBackendOption): Promise<void> {
		return this.setServerOption('default-external-storage-backend', newValue);
	}

	/** Fetches globally enforce default storage option. */
	public getGloballyEnforceDefaultStorageOption(): Promise<GloballyEnforceDefaultStorageOption> {
		return this.get<GloballyEnforceDefaultStorageOption>(
			url`api/options/server/enforce-global-default-storage`
		).catch(this.getDefaultErrorHandler());
	}

	/** Sets globally enforce default storage option. */
	public setGloballyEnforceDefaultStorageOption(newValue: GloballyEnforceDefaultStorageOption): Promise<void> {
		return this.setServerOption('enforce-global-default-storage', newValue);
	}

	/** Fetches and returns the blacklisting option. */
	public getBlacklistingOption(): Promise<BlacklistingOption> {
		return this.get<BlacklistingOption>(url`api/options/server/ts.blacklisting-option`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/**
	 * Sets a specific server option.
	 *
	 * @param saveIfValidationFails Whether settings should be saved even if validation fails, defaults to false
	 */
	public setServerOption(optionId: string, optionValue: OptionValue, saveIfValidationFails?: boolean): Promise<void> {
		const urlBuilder = url`api/options/server/${optionId}`;
		urlBuilder.append('save-if-validation-fails', saveIfValidationFails);
		return this.put(urlBuilder, optionValue);
	}

	/** Deletes a specific server option. */
	public deleteServerOption(optionId: string): Promise<void> {
		const urlBuilder = url`api/options/server/${optionId}`;
		return this.delete(urlBuilder);
	}

	/** Fetches all set user options for the specified user. */
	public getUserOptions(username: string): Promise<UserOptions> {
		return this.get<UserOptions>(url`api/users/${username}/options`).catch(this.getDefaultErrorHandler());
	}

	/** Fetches the user option schema. */
	public getUserOptionSchemaAsync(): Promise<OptionDescriptor[]> {
		return this.get(url`api/users/options/schema`);
	}

	/** Sets a specific user option. */
	public setUserOptionAsync(
		username: string,
		optionId: string,
		optionValue: UserOptions[keyof UserOptions]
	): Promise<void> {
		const urlBuilder = url`api/users/${username}/options/${optionId}`;
		return this.put(urlBuilder, optionValue);
	}

	/** Sets a specific user option for the current user, which is retrieved from the perspective context. */
	public async setCurrentUserOptionAsync<T extends keyof UserOptions>(
		perspectiveContext: PerspectiveContext,
		optionId: T,
		optionValue: UserOptions[T]
	): Promise<void> {
		await this.setUserOptionAsync(perspectiveContext.userInfo.currentUser.username, optionId, optionValue);
		const cachedPerspectiveContext = ReactUtils.queryClient.getQueryData<ExtendedPerspectiveContext>(
			QUERY.getPerspectiveContext().queryKey
		);
		if (cachedPerspectiveContext) {
			cachedPerspectiveContext.userInfo.userOptions[optionId] = optionValue;
			ReactUtils.queryClient.setQueryData(QUERY.getPerspectiveContext().queryKey, cachedPerspectiveContext);
		}
	}

	/** Returns the global analysis progress as a mapping from project IDs to their analysis progress */
	public getGlobalAnalysisProgress(): Promise<Record<string, AnalysisProgressDescriptor[]>> {
		return QUERY.getGlobalAnalysisProgress().fetch().catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns a {@link BranchesInfo} for the given project. The startOffset and limit parameters can be used to achieve
	 * pagination (i.e., get only branches 0-100, 100-200, ...).
	 *
	 * @param project The project
	 * @param startOffset The index of the start of the pagination window (default is 0)
	 * @param limit The size of the pagination window (i.e., the max number of branches returned)
	 * @param onlyLiveBranches Whether to only return currently live branches (default is false)
	 */
	public getBranchesInfoForProject(
		project: string,
		startOffset = 0,
		limit?: number,
		onlyLiveBranches = false
	): Promise<BranchesInfo> {
		return QUERY.getBranchesGetRequest(project, {
			'start-offset': startOffset,
			limit,
			'only-live-branches': onlyLiveBranches
		})
			.fetch()
			.catch(this.getDefaultErrorHandler());
	}

	/** Returns the default branch name of the project. */
	public getFirstUIBranchNameForProject(project: string): Promise<string> {
		return QUERY.getFirstUIBranchNameGetRequest(project).fetch().catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the branches that are contained in the project as {@link BranchesInfo}.
	 *
	 * @param project The project
	 * @param branches The branches that should be checked. If this is an empty list all branches will be returned.
	 */
	public getFilteredBranchesInfoForProject(project: string, branches: Array<string | null>): Promise<BranchesInfo> {
		const params = { filter: branches.filter(branch => branch != null) as string[] };
		return QUERY.getBranchesGetRequest(project, params).fetch();
	}

	/** Notifies the server that the current user selected the given branch in the branch selector. */
	public async registerBranchSelection(projectId: string, branchName: string): Promise<void> {
		if (StringUtils.isEmptyOrWhitespace(projectId)) {
			return;
		}
		return this.post<void>(url`api/projects/${projectId}/recent-branches/${branchName}`).catch(() => {
			return;
		});
	}

	/** Retrieves the project's languages. */
	public getProjectLanguages(projectId: string): Promise<ELanguageEntry[]> {
		return this.get<ELanguageEntry[]>(url`api/projects/${projectId}/languages`).catch(
			this.getDefaultErrorHandler()
		);
	}

	/** Gets the spec item by spec item ID. */
	public getSpecItem(
		projectId: string,
		specItemId: string,
		commit?: UnresolvedCommitDescriptor | null
	): Promise<ImportedLinksAndTypeResolvedSpecItem> {
		const urlBuilder = url`api/projects/${projectId}/spec-items/${specItemId}`;
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/** Gets the available spec item query descriptors. */
	public getStoredSpecItemQueryDescriptors(projectId: string): Promise<StoredQueryDescriptor[]> {
		return this.get(url`api/projects/${projectId}/spec-items/queries`);
	}

	/** Creates/overrides a spec item query. */
	public createStoredSpecItemQuery(project: string, specItemQueryDescriptor: StoredQueryDescriptor): Promise<void> {
		return this.post(url`api/projects/${project}/spec-items/queries`, specItemQueryDescriptor);
	}

	/** Deletes the given spec item query. */
	public deleteSpecItemQuery(project: string, specItemQueryName: string): Promise<void> {
		return this.delete(url`api/projects/${project}/spec-items/queries/${specItemQueryName}`);
	}

	/** Gets the available test query descriptors. */
	public getTestQueryMetrics(projectId: string): Promise<StoredQueryDescriptor[]> {
		return this.get(url`api/projects/${projectId}/tests/queries`);
	}

	/** Creates/overrides a test query. */
	public createTestQueryMetric(project: string, testQueryDescriptor: StoredQueryDescriptor): Promise<void> {
		return this.post(url`api/projects/${project}/tests/queries`, testQueryDescriptor);
	}

	/** Deletes the given test query. */
	public deleteTestQueryMetric(project: string, testQueryName: string): Promise<void> {
		return this.delete(url`api/projects/${project}/tests/queries/${testQueryName}`);
	}

	/** Gets the spec item code references by spec item ID. */
	public getSpecItemCodeReferences(
		projectId: string,
		specItemId: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<SpecItemCodeReference[]> {
		const urlBuilder = url`api/projects/${projectId}/spec-items/${specItemId}/code-references`;
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/** Gets the spec item test executions by spec item ID. */
	public getSpecItemTestExecutions(
		projectId: string,
		specItemId: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<TestExecutionWithPartition[]> {
		const urlBuilder = url`api/projects/${projectId}/spec-items/${specItemId}/test-executions`;
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/** Fetches the work item link roles provided by the configured Polarion account. */
	public async fetchPolarionWorkItemLinkRoles(
		connector: ConnectorConfiguration
	): Promise<PolarionWorkItemLinkRolesResult> {
		const accountName = connector.options!['Account']!;
		const urlBuilder = url`api/spec-items/polarion/${accountName}/work-items/link-roles`;
		return this.put<PolarionWorkItemLinkRolesResult>(urlBuilder, connector);
	}

	/** Fetches the work item types provided by the configured Polarion account. */
	public async fetchPolarionWorkItemTypes(connector: ConnectorConfiguration): Promise<PolarionWorkItemTypeResult> {
		const accountName = connector.options!['Account']!;
		const urlBuilder = url`api/spec-items/polarion/${accountName}/work-items/types`;
		return this.put<PolarionWorkItemTypeResult>(urlBuilder, connector);
	}

	/** Fetches the work item types provided by the configured connector. */
	public async fetchWorkItemLinkRoles(connector: ConnectorConfiguration): Promise<GetLinkRolesResponse> {
		const accountName = connector.options!['Account']!;
		const urlBuilder = url`api/spec-items/default/${accountName}/schema/link-roles`;
		return this.put<GetLinkRolesResponse>(urlBuilder, connector);
	}

	/** Retrieves the spec item graph for the given query. */
	public getSpecItemQueryGraph(
		project: string,
		query: string,
		commit: UnresolvedCommitDescriptor
	): Promise<SpecItemGraph> {
		const urlBuilder = url`api/projects/${project}/spec-items/graph`;
		urlBuilder.append('query', query);
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/**
	 * Retrieves the list of signals for a Simulink type dependency.
	 *
	 * @param projectName The project's name
	 * @param dependencySource The dependency's source
	 * @param dependencyTarget The dependency's target
	 */
	public getSimulinkSignalsForDependency(
		projectName: string,
		dependencySource: string,
		dependencyTarget: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<string[]> {
		const urlBuilder = url`api/projects/${projectName}/simulink/dependencies/signals`;
		urlBuilder.append('source', dependencySource);
		urlBuilder.append('target', dependencyTarget);
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/**
	 * Resolves a commit descriptor. Useful if dealing with Commit descriptors that have the previous attribute set.
	 *
	 * @param projectName The project's name
	 * @param commit The commit to resolve
	 */
	public resolveCommitDescriptor(
		projectName: string,
		commit: UnresolvedCommitDescriptor
	): Promise<DataCommitDescriptor> {
		return this.get(url`api/projects/${projectName}/commit-resolver/${commit.toString()}`);
	}

	/** Notifies the server that the current user selected the given project in the project selector. */
	public async registerProjectSelection(projectId: string): Promise<void> {
		if (StringUtils.isEmptyOrWhitespace(projectId)) {
			return;
		}
		return this.post<void>(url`api/projects/${projectId}/visited`).catch(() => {
			return;
		});
	}

	/**
	 * Finds the initial commit which added the file at the given path. Requires a commit from the branch on which the
	 * service should look for the initial commit of the file.
	 */
	public findInitialCommitForPath(
		projectName: string,
		uniformPath: string,
		commit: UnresolvedCommitDescriptor
	): Promise<DataCommitDescriptor | null> {
		return QUERY.findInitialCommit(projectName, commit, { uniformPath }).fetch();
	}

	/** Performs a query for spec items. */
	public getCodeReferencesAndTestExecutionForSpecItemQuery(
		project: string,
		query: string,
		commit: UnresolvedCommitDescriptor | null
	): Promise<SpecItemReferenceMapping> {
		const urlBuilder = url`api/projects/${project}/spec-items/references`;
		urlBuilder.append('query', query);
		urlBuilder.append('t', commit);
		return this.get(urlBuilder);
	}

	/**
	 * Appends the parameters for the principal metric index, metric indexes and boundaries to the URL builder.
	 *
	 * @param urlBuilder The urlBuilder to append to.
	 * @param principalMetricIndex The 'selected' metric index with respect to which the metric distribution is
	 *   calculated.
	 * @param metricIndexes The list of metric indexes to calculate the distribution.
	 * @param boundaries The list of boundaries from which consecutive intervals are calculated and used to calculate
	 *   the distribution.
	 */
	private static appendMetricDistributionParameters(
		urlBuilder: URLBuilder,
		principalMetricIndex: number,
		metricIndexes: number[],
		boundaries: number[]
	): void {
		urlBuilder.append('principal-metric-index', principalMetricIndex);
		urlBuilder.appendMultiple('metric-indexes', metricIndexes);
		urlBuilder.appendMultiple('boundaries', boundaries);
	}

	/** Returns a promise for all sap abap connection identifiers of the instance. */
	public getSapConnectionIdentifiers(): Promise<string[]> {
		return this.get<string[]>(url`api/sap-connection-identifiers`).catch(this.getDefaultErrorHandler());
	}

	/** Returns a promise for a list of all primary project ids to which the user has access. */
	public getPrimaryProjectIds(): Promise<string[]> {
		return this.get<string[]>(url`api/projects/ids`).catch(this.getDefaultErrorHandler());
	}

	/** Returns a promise for a mapping from primary project ids to the internal and public ids. * */
	public getAllProjectIds(): Promise<Map<string, ProjectIdEntry>> {
		return this.get<Record<string, ProjectIdEntry>>(url`api/project-ids/`)
			.then(result => ObjectUtils.toMap(result))
			.catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the methods that are covered by a test with method wise test coverage data.
	 *
	 * @param firstCommit The first commit, commits before this are not considered
	 * @param lastCommit The last commit to be considered
	 * @param partitions Optional, the partitions to be used
	 */
	public getMethodChangelogForTest(
		project: string,
		testExecutionPath: string,
		firstCommit: UnresolvedCommitDescriptor,
		lastCommit: UnresolvedCommitDescriptor,
		partitions?: string[]
	): Promise<MethodHistoryEntriesWrapper> {
		const urlBuilder = url`api/projects/${project}/tests/${testExecutionPath}/executed-methods-changelog`;
		urlBuilder.append('baseline', firstCommit);
		urlBuilder.append('end', lastCommit);
		partitions?.forEach(partition => urlBuilder.append('partitions', partition));
		return this.get<MethodHistoryEntriesWrapper>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Query the status of the given test minimization job.
	 *
	 * @param projectId The id of the project the job is supposed to be executed for.
	 * @param jobId The id of the job.
	 * @returns The current execution status of the job.
	 */
	public getTestMinimizationJobStatus(projectId: string, jobId: string): Promise<TestMinimizationJobRun | null> {
		const urlBuilder = url`api/projects/${projectId}/minimized-tests/jobs/${jobId}`;
		return this.get<TestMinimizationJobRun>(urlBuilder).catch<TestMinimizationJobRun | null>(
			TeamscaleServiceClient.handle404AsNull
		);
	}

	/**
	 * Stop the given minimization job on the server.
	 *
	 * @param projectId The id of the project the job is to be executed for.
	 * @param jobId The id of the job to stop.
	 */
	public stopAndDeleteMinimizationJob(projectId: string, jobId: string): Promise<void> {
		const urlBuilder = url`api/projects/${projectId}/minimized-tests/jobs/${jobId}`;
		return this.delete<void>(urlBuilder).catch(this.getDefaultErrorHandler());
	}

	/**
	 * Returns the URL to download a CSV file containing all methods executed by one or multiple test executions
	 * specified by a test query.
	 *
	 * @param project The project.
	 * @param query The query to match the test executions
	 * @param partitions The partitions that should be taken into account, or undefined to select all partitions
	 * @param endCommit The commit until which test executions should be respected.
	 */
	public getExecutedMethodsByTestCaseCsvUrl(
		project: string,
		query: string,
		partitions?: string[],
		endCommit?: UnresolvedCommitDescriptor | null
	): string {
		const urlBuilder = url`api/projects/${project}/test-query/executed-methods.csv`;
		urlBuilder.append('query', IssueQueryInputHandler.escapeIssueQuery(query));
		urlBuilder.appendMultiple('partition', partitions);
		urlBuilder.append('all-partitions', partitions === undefined);
		urlBuilder.append('t', endCommit);
		return urlBuilder.getURL();
	}

	/** Returns true if the SAP JCo library has been loaded, otherwise false. */
	public sapJcoLibraryLoaded(): Promise<boolean> {
		return this.get<boolean>(url`api/sap-jco-loaded`).catch(this.getDefaultErrorHandler());
	}

	/** Triggers the download of the issue list. */
	public downloadIssuesList(
		format: EIssuesExportFormatEntry,
		projectId: string,
		query: string,
		tgaFilter: EIssueTgaFilterOptionEntry,
		coverageSourceParameters: CoverageSourceQueryParameters,
		issueTgaParameters: IssueTgaParameters
	): void {
		const urlBuilder = url`api/projects/${projectId}/issue-query/with-tga/export/${format}`;
		urlBuilder.append('query', query);
		urlBuilder.append('tga-filter', tgaFilter);
		urlBuilder.append('sort-by', 'id');
		urlBuilder.append('sort-order', SortingUtils.ASCENDING_ORDER);
		urlBuilder.append('start', 0);
		urlBuilder.append('max', -1);
		urlBuilder.appendAll(ServiceClient.convertToKebabCaseURLSearchParams(coverageSourceParameters));
		urlBuilder.appendAll(ServiceClient.convertToKebabCaseURLSearchParams(issueTgaParameters));
		UIUtils.downloadFile(urlBuilder.getURL(), 'issues-' + projectId + '.' + format.toLowerCase());
	}

	/** Loads the Swagger API JSON */
	public async getApiSpecification(includeInternalApi: boolean): Promise<Blob> {
		const urlBuilder = url`openapi.json`;
		urlBuilder.append('include-internal', includeInternalApi);
		return this.get<Blob>(urlBuilder);
	}

	/** Returns all retrospectives visible to the user. */
	public async getQualityRetrospectives(project: string): Promise<UserResolvedRetrospective[]> {
		return this.get<UserResolvedRetrospective[]>(url`api/projects/${project}/retrospectives`);
	}

	/** Returns retrospective by id. */
	public async getQualityRetrospective(project: string, retrospectiveId: string): Promise<UserResolvedRetrospective> {
		return this.get<UserResolvedRetrospective>(url`api/projects/${project}/retrospectives/${retrospectiveId}`);
	}
}
