import type { Context } from 'react';
import React from 'react';

/** Describes how the useLocation/useNavigationHash hooks will behave. */
export enum EHashReloadBehavior {
	/**
	 * Re-renders only when the navigation was triggered via NavigationHash#applyUrlWithoutReload, but should not
	 * receive updates that will trigger a re-load of the whole view anyway and therefore certain invariants would no
	 * longer hold. This is the default in components that are mounted via ReactUtils.render/append as part of a view.
	 */
	RELOAD_ONLY_SILENTLY_APPLIED_CHANGES,

	/**
	 * Returns the navigation hash of the current view, but ignoring any updates caused by
	 * NavigationHash#applyUrlWithoutReload. This is used in the high level view instantiation components to not reload
	 * the whole view when a few arguments change and where the view takes care of updating the relevant pieces on its
	 * own.
	 */
	RELOAD_IGNORE_SILENTLY_APPLIED_CHANGES
}

/** Provides the hash reload behavior that should be used in the current component. */
export const NavigationHashReloadBehaviorContext: Context<EHashReloadBehavior> =
	React.createContext<EHashReloadBehavior>(EHashReloadBehavior.RELOAD_IGNORE_SILENTLY_APPLIED_CHANGES);
