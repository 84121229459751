import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { url } from 'ts/base/client/URLBuilder';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

type QueryParams = Record<string, UnresolvedCommitDescriptor | null | string | number | boolean | undefined | string[]>;

/**
 * Renders a URL which can be parsed by the NavigationHash class. All parameters are automatically URL-escaped, which
 * ensures that the resulting text is safe for usage as a URL.
 *
 * @param perspective The perspective to link to
 * @param view The view to show
 * @param project The project for which to show the perspective
 * @param uniformPath The uniform path for which to show the view or null if not required
 * @param queryParams Additional parameters as a literal object. The keys of the object are turned into the query
 *   parameter names, and the values are serialized to a string value. If the value of a parameter is null or undefined,
 *   it is omitted from the generated link.
 */
export function linkTo(
	perspective: ETeamscalePerspective,
	view: ViewDescriptor,
	project: string | null | undefined,
	uniformPath: string | null | undefined,
	queryParams?: QueryParams
): string;
export function linkTo(
	perspective: ETeamscalePerspective,
	view: ViewDescriptor,
	project: string | null | undefined,
	queryParams?: QueryParams
): string;
export function linkTo(perspective: ETeamscalePerspective, view: ViewDescriptor, queryParams?: QueryParams): string;
export function linkTo(
	perspective: ETeamscalePerspective,
	view: ViewDescriptor,
	...varArgs: Array<QueryParams | string | null | undefined>
): string {
	const urlBuilder = url`${view.anchor}`;
	for (const argument of varArgs) {
		if (argument == null || typeof argument === 'string') {
			urlBuilder.appendToPath(argument ?? '');
		}
	}
	if (view.action) {
		urlBuilder.append('action', view.action);
	}
	const lastArgument = varArgs.at(-1);
	if (lastArgument != null && typeof lastArgument !== 'string') {
		Object.entries(lastArgument).forEach(([key, value]) => {
			if (Array.isArray(value)) {
				if (value.length === 0) {
					urlBuilder.append(`${key}-empty`, true);
				} else {
					urlBuilder.appendMultiple(key, value);
				}
			} else {
				urlBuilder.append(key, value);
			}
		});
	}
	return perspective.page + '#' + urlBuilder.getURL();
}
