import { useSyncExternalStore } from 'react';

/** Hook that always provides the current window size. */
export function useWindowSize(): {
	width: number;
	height: number;
} {
	const windowWidth = useSyncExternalStore(onWindowSizeChange, getWindowWidthSnapshot);
	const windowHeight = useSyncExternalStore(onWindowSizeChange, getWindowHeightSnapshot);
	return { width: windowWidth, height: windowHeight };
}

/**
 * Hook that allows you to derive a state based in the current window width. This approach allows to only re-render the
 * component when the result of the selector function differs from the previous result.
 */
export function useWindowWidth<T extends number | boolean>(widthSelector: (width: number) => T): T {
	return useSyncExternalStore(onWindowSizeChange, () => windowWidthSnapshot(widthSelector));
}

function windowWidthSnapshot<T extends number | boolean>(selector: (width: number) => T): T {
	return selector(window.innerWidth);
}

function onWindowSizeChange(onChange: () => void) {
	window.addEventListener('resize', onChange);
	return () => window.removeEventListener('resize', onChange);
}

function getWindowWidthSnapshot() {
	return window.innerWidth;
}

function getWindowHeightSnapshot() {
	return window.innerHeight;
}
