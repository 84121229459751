import { QUERY } from 'api/Query';

/** Display text for declaration of accessibility. */
export const DECLARATION_OF_ACCESSIBILITY_DISPLAY_NAME = 'Barrierefreiheit';
// Keep in sync with DeclarationOfAccessibilityPerspectiveService
/** {@link DECLARATION_OF_ACCESSIBILITY_LINK} (without '.html' suffix) */
export const DECLARATION_OF_ACCESSIBILITY_LINK_WITHOUT_HTML = 'accessibility';
/** Page under which the declaration of accessibility can be accessed */
export const DECLARATION_OF_ACCESSIBILITY_LINK = DECLARATION_OF_ACCESSIBILITY_LINK_WITHOUT_HTML + '.html';

/**
 * Provides a react hook, which returns {@code true} in case a declaration of accessibility exists and can be accessed
 * via {@link DECLARATION_OF_ACCESSIBILITY_LINK}.
 */
export function useDeclarationOfAccessibilityExistence(): boolean {
	const declaration = QUERY.getDeclarationOfAccessibility({}).useSuspendingQuery({ staleTime: 60 * 60 * 1_000 });
	return declaration != null;
}
