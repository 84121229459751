import { QUERY } from 'api/Query';
import type { JSX } from 'react';
import { Button, Icon, Loader, Message } from 'semantic-ui-react';
import type { Callback } from 'ts/base/Callback';
import { useProjectIfExists } from 'ts/base/hooks/UseProject';
import { useBaselines } from 'ts/base/services/BaselineServices';
import { DateUtils } from 'ts/commons/DateUtils';
import { GitTagFormatter } from 'ts/commons/time/GitTagFormatter';
import { TimetravelUtils } from 'ts/commons/TimetravelUtils';
import type { ExtendedProjectInfo } from 'ts/data/ExtendedProjectInfo';
import type { ProjectSpecificBaselineInfo } from 'ts/perspectives/findings/baselines/ProjectSpecificBaselineInfo';

/** Props for TimeTravelButton. */
type TimeTravelButtonProps = {
	contentName: string;
	timestamp: number | null;
	until: boolean;
};

/** Props for TimeTravelButton with jump and clear events. */
export type TimeTravelButtonPropsWithEvents = TimeTravelButtonProps & {
	onJump: () => Promise<void>;
	onClear: Callback<void>;
};

/**
 * Component displaying the currently selected point in time as well as opening the dialog for selecting a new point in
 * time to travel to.
 */
export function TimeTravelButton({
	contentName,
	timestamp,
	until,
	onJump,
	onClear
}: TimeTravelButtonPropsWithEvents): JSX.Element | null {
	return (
		<div id="timetravel-item" className="item">
			{timestamp ? (
				<Message
					warning
					id="jump-to-time-button"
					style={{ minWidth: '80px !important' }}
					className="button"
					onClick={() => {
						void onJump();
					}}
					onDismiss={event => {
						onClear();
						event.stopPropagation();
					}}
				>
					<TimeTravelButtonText contentName={contentName} until={until} timestamp={timestamp} />
				</Message>
			) : (
				<Button
					secondary
					id="jump-to-time-button"
					title="Travel in time, i.e. see how the content looked in the past"
					onClick={() => void onJump()}
				>
					<TimeTravelIcon />
					{until ? `All ${contentName}` : `Latest ${contentName}`}
				</Button>
			)}
		</div>
	);
}

type TimeTravelButtonTextProps = {
	contentName: string;
	until: boolean;
	timestamp: number | null;
};

function TimeTravelButtonText({ contentName, until, timestamp }: TimeTravelButtonTextProps) {
	return (
		<>
			<TimeTravelIcon />
			{contentName} {until ? 'until' : 'at'} <SelectedGitTagOrBaseline timestamp={timestamp} />
			{DateUtils.formatTimestamp(timestamp)}
		</>
	);
}

type SelectedGitTagOrBaselineProps = { timestamp: number | null };

function SelectedGitTagOrBaseline({ timestamp }: SelectedGitTagOrBaselineProps) {
	const project = useProjectIfExists();
	const baselines = useBaselines(project !== null ? [project.primaryId] : null);
	const selectedBaseline = getStoredBaselineIfValid(baselines, timestamp);
	return (
		<>
			{selectedBaseline}
			<SelectedGitTag project={project} timestamp={timestamp} />
		</>
	);
}

/**
 * Returns the baseline that was last stored in the local storage, if it is still valid (meaning the currently selected
 * timestamp still matches).
 */
function getStoredBaselineIfValid(
	baselines: ProjectSpecificBaselineInfo[] | undefined,
	timestamp: number | null
): string | null {
	let storedBaseline = TimetravelUtils.getLastSelectedBaselineFromStorage();
	const matchedBaselineInfo = baselines?.find(baseline => baseline.name === storedBaseline?.name);
	if (timestamp !== matchedBaselineInfo?.timestamp) {
		// The stored baseline is not corresponding to the selected timestamp anymore ==> invalidate baseline
		storedBaseline = undefined;
	}
	return storedBaseline ? storedBaseline.name + ' at ' : null;
}

type SelectedGitTagProps = {
	project: ExtendedProjectInfo | null;
	timestamp: number | null;
};

/**
 * Returns the git tag that was last stored in the local storage, if it is still valid (meaning the currently selected
 * timestamp still matches).
 */
function SelectedGitTag({ project, timestamp }: SelectedGitTagProps) {
	const projectId = project?.primaryId ?? '';
	const matchedGitTagQuery = useLastSelectedGitTag(project);
	const matchedCommitQuery = QUERY.resolveTag(projectId, matchedGitTagQuery.data!).useQuery({
		enabled: !!projectId && matchedGitTagQuery.isSuccess && !!matchedGitTagQuery.data
	});
	const isFetching = matchedGitTagQuery.isFetching || matchedCommitQuery.isFetching;
	if (isFetching) {
		return <Loader active inline size="mini" />;
	}
	if (timestamp !== matchedCommitQuery.data?.timestamp) {
		return undefined;
	}
	return new GitTagFormatter().format(matchedGitTagQuery.data!) + ' at ';
}

function useLastSelectedGitTag(project: ExtendedProjectInfo | null) {
	const storedGitTag = TimetravelUtils.getLastSelectedGitTagFromStorage();
	const projectId = project?.primaryId ?? '';
	const tagName = storedGitTag?.name ?? '';
	return QUERY.getGitTagByName(projectId, tagName, {}).useQuery({
		enabled: !!projectId && !!tagName && storedGitTag?.project === projectId
	});
}

function TimeTravelIcon(): JSX.Element | null {
	return <Icon name="wait" />;
}
