import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { Data } from 'plotly.js';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { ExtendedFlotOptions } from 'ts/commons/charts/data_types/ExtendedFlotOptions';
import type { MetricChartOptions } from 'ts/commons/charts/MetricChartOptions';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { ProjectAndUniformPath } from 'ts/commons/ProjectAndUniformPath';
import { EDeltaView } from 'ts/perspectives/delta/EDeltaView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/**
 * Updates the delta perspective link
 *
 * @param plot The plot that can be either a Flot or Plotly
 * @param margins The margin timestamps
 * @param deltaPerspectiveLink The link to navigate to delta perspective view
 * @param options Basic chart options
 * @param targetViewName The target view within the delta perspective
 */
export async function updateDeltaPerspectiveLink(
	plot: Data[] | jquery.flot.plot,
	margins: { from: number; to: number },
	deltaPerspectiveLink: HTMLAnchorElement | null,
	options: MetricChartOptions,
	targetViewName?: ViewDescriptor
): Promise<void> {
	if (!deltaPerspectiveLink) {
		// No need to update if we are not displaying the link
		return;
	}
	if (options.project === null) {
		return;
	}
	const hash = configureHash(targetViewName ?? null, margins.from, margins.to, options);
	const timestamps: number[] = [];
	if (margins.from !== -1) {
		timestamps.push(margins.from);
	}
	if (margins.to !== -1) {
		timestamps.push(margins.to);
	}
	if (timestamps[0] === undefined || timestamps[1] === undefined) {
		return;
	}
	const commits = await options.client.getCommitsOnHistoryTrend(options.project, options.branchName, timestamps);
	if (margins.from !== -1) {
		hash.set('from', UnresolvedCommitDescriptor.wrap(commits.shift())!.toString());
	}
	if (margins.to !== -1) {
		hash.set('to', UnresolvedCommitDescriptor.wrap(commits.shift())!.toString());
	}
	deltaPerspectiveLink.href = hash.toString();
}

/**
 * Calculates the margin timestamps of flot plot
 *
 * @param plot
 * @param flotOptions
 */
export function calculateMarginTimestamps(
	plot: jquery.flot.plot,
	flotOptions: ExtendedFlotOptions
): { from: number; to: number } {
	const plotData = plot.getData();
	if (plotData.length === 0 || plotData[0]!.data.length === 0) {
		return { from: -1, to: -1 };
	}
	const data = plotData[0]!.data;
	return {
		from: Math.round(flotOptions.xaxis!.min || data[0]![0]!),
		to: Math.round(flotOptions.xaxis!.max || data[data.length - 1]![0]!)
	};
}

/**
 * Configures a navigation hash to navigate to the delta perspective for a given interval (from, to)
 *
 * @param targetViewName The target view within the delta perspective.
 * @param from The start timestamp.
 * @param to The end timestamp.
 */
function configureHash(
	targetView: ViewDescriptor | null,
	from: number,
	to: number,
	options: MetricChartOptions
): NavigationHash {
	if (targetView === null) {
		if (from !== -1 && to !== -1) {
			targetView = EDeltaView.FILE;
		} else {
			targetView = EDeltaView.PARAMETER;
		}
	}
	const hash = NavigationHash.inView(ETeamscalePerspective.DELTA, targetView);
	hash.setProjectAndPath(ProjectAndUniformPath.of(options.project, options.path));
	return hash;
}
