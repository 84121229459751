import { Modal, ScrollArea } from '@mantine/core';
import { MUTATION } from 'api/Mutation';
import { QUERY } from 'api/Query';
import { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import * as userAgent from 'ts-closure-library/lib/useragent/useragent';
import { ModalActionButtons } from 'ts/commons/modal/ModalActionButtons';
import { NavigationUtils } from 'ts/commons/NavigationUtils';

type TeamscaleSupportModalProps = {
	onClose: () => void;
};

/** Renders the modal for teamscale support. */
export function TeamscaleSupportModal({ onClose }: TeamscaleSupportModalProps): JSX.Element {
	const [problemDescription, setProblemDescription] = useState<string>('');
	const userAgentString = userAgent.getUserAgentString();
	const { mutate: createSupportRequest } = MUTATION.createSupportRequest.useMutation({
		onSuccess: () => NavigationUtils.updateLocation(QUERY.downloadSupportRequest().url)
	});

	const onFormSubmit = () => {
		createSupportRequest({ body: { problemDescription, userAgent: userAgentString } });
		onClose();
	};

	return (
		<Modal
			scrollAreaComponent={ScrollArea.Autosize}
			opened
			onClose={onClose}
			title="Create Support Request"
			size="xl"
		>
			<div>
				<p className="lead">
					In case you encounter problems with Teamscale, you can create a support request and send it to the
					Teamscale team. Please describe the problem in the text field below. Data about your Teamscale
					installation will be gathered automatically and attached to the request. This includes information
					about your operating system, Java and all Teamscale configuration settings. This helps us to analyze
					your problem more effectively.
				</p>
				<Form id="teamscale-suport" onSubmit={onFormSubmit}>
					<Form.TextArea
						label="Problem description"
						rows={10}
						data-testid="description-text"
						value={problemDescription}
						onChange={(e, data) => setProblemDescription(data.value as string)}
					/>
				</Form>
				<p className="lead">
					Please send the generated support request to{' '}
					<a href="mailto:support@teamscale.com?subject=Teamscale%20Support%20Request">
						support@teamscale.com
					</a>
					.
				</p>
			</div>
			<ModalActionButtons>
				<Button
					primary
					type="submit"
					content="Save"
					className="!w-40"
					data-testid="save-request"
					form="teamscale-suport"
				/>
				<Button content="Cancel" className="!w-40" onClick={onClose} />
			</ModalActionButtons>
		</Modal>
	);
}
