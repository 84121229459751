import type { JSX } from 'react';
import clsx from 'clsx';
import type { HTMLAttributes } from 'react';
import type { StrictLabelProps } from 'semantic-ui-react';
import { Icon, Label } from 'semantic-ui-react';
import styles from './CounterLabel.module.less';

/** Props for CounterLabel. */
type CounterLabelProps = Omit<
	HTMLAttributes<HTMLDivElement> & StrictLabelProps & { count: number | string; loading?: boolean },
	'children'
>;

/** Shows a circular label that is normally used to show the count of some entity. */
export function CounterLabel({ count, loading, className, ...labelProps }: CounterLabelProps): JSX.Element {
	return (
		<Label circular className={clsx(styles.lightLabel, className)} {...labelProps}>
			{loading ? (
				<span>
					<Icon loading name="spinner" />
				</span>
			) : (
				count
			)}
		</Label>
	);
}
