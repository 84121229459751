import type { JSX } from 'react';
import type { ComponentType, ReactNode } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import type { ServiceErrorFallbackProps } from 'ts/commons/ErrorFallback';
import { ErrorFallback } from 'ts/commons/ErrorFallback';

/** Props for ErrorBoundary. */
export type ErrorBoundaryProps = {
	children: ReactNode;

	/** The component to render in case an error was thrown in a wrapped component. */
	errorFallback?: ComponentType<FallbackProps>;

	/**
	 * The component to render in case a service call error was thrown in a wrapped component. This will only take
	 * effect if errorFallback is not set.
	 */
	serviceErrorFallback?: ComponentType<ServiceErrorFallbackProps>;
};

/**
 * Provides error boundary for React components in Teamscale that handles query reset and provides a specific fallback
 * handing for service errors.
 */
export function ErrorBoundary({ children, errorFallback, serviceErrorFallback }: ErrorBoundaryProps): JSX.Element {
	const { reset } = useQueryErrorResetBoundary();

	function BaseAndServiceErrorHandler({ error, resetErrorBoundary }: FallbackProps) {
		return (
			<ErrorFallback
				error={error}
				resetErrorBoundary={resetErrorBoundary}
				serviceErrorComponent={serviceErrorFallback}
			/>
		);
	}

	const errorFallbackToUse = errorFallback ?? BaseAndServiceErrorHandler;
	return (
		<ReactErrorBoundary onReset={reset} FallbackComponent={errorFallbackToUse}>
			{children}
		</ReactErrorBoundary>
	);
}
