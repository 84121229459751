import type { JSX } from 'react';
import { Form, Input } from 'semantic-ui-react';

import * as strings from 'ts-closure-library/lib/string/string';
import type { Callback } from 'ts/base/Callback';
import { useInputWithDefault } from 'ts/commons/time/components/TimePickerUtils';
import { EPointInTimeType } from 'ts/commons/time/EPointInTimeType';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';

function validateAndExtractPointInTime(
	timespanInput: number,
	setTypedPointInTime: Callback<TypedPointInTime>
): Promise<string | undefined> {
	if (!strings.isNumeric(timespanInput) || timespanInput < 0) {
		return Promise.resolve('Please enter a non-negative integer.');
	}
	setTypedPointInTime(TimeUtils.days(timespanInput));
	return Promise.resolve(undefined);
}

function setInitialValue(defaultValue: TypedPointInTime | null): string | undefined {
	if (defaultValue != null && defaultValue.type === EPointInTimeType.TIMESPAN) {
		return defaultValue.value.days.toString();
	}
	return '0';
}

const VALIDATION_OPTIONS = {
	required: 'Please enter a non-negative integer.',
	valueAsNumber: true
};

/** A component for picking a timespan in days. */
export function TimespanPicker(): JSX.Element | null {
	const [selectedValue, setSelectedValue] = useInputWithDefault(
		'days',
		validateAndExtractPointInTime,
		setInitialValue,
		VALIDATION_OPTIONS
	);

	return (
		<Form.Field>
			<Input
				label={{ basic: true, content: 'days' }}
				labelPosition="right"
				data-testid="days-input"
				type="number"
				value={selectedValue}
				onChange={(event, { value }) => setSelectedValue(value)}
				input={{ 'data-autofocus': true, autoFocus: true }}
			/>
		</Form.Field>
	);
}
