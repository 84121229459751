import * as selection from 'ts-closure-library/lib/dom/selection';
import { InputHandler } from 'ts-closure-library/lib/ui/ac/inputhandler';
import type { Callback } from 'ts/base/Callback';
import { Assertions } from 'ts/commons/Assertions';

/** Manages the interaction between the IssueQueryAutoComplete object and the issue-query input field. */
export class IssueQueryInputHandler extends InputHandler {
	/** Called when the value of the input field has been programmatically altered due to a auto-completion. */
	private changeCallback?: Callback<string>;

	public constructor(private readonly autoCompletionInfo: { replacementLength: number; inAutoComplete: boolean }) {
		super(null, null, false);
	}

	/** @see #changeCallback */
	public setOnChangeCallback(changeCallback: Callback<string> | undefined): void {
		this.changeCallback = changeCallback;
	}

	/**
	 * Parses a text area or input box for the currently highlighted token.
	 *
	 * Overwrites the default method in InputHandler with a much simpler implementation since we don't need most
	 * features.
	 *
	 * @returns Token to complete.
	 */
	protected override parseToken(): string {
		return IssueQueryInputHandler.escapeIssueQuery(this.getValue()).substring(0, this.getCursorPosition());
	}

	/**
	 * Sets the value of the current active element.
	 *
	 * @param value The new value.
	 */
	public override setValue(value: string): void {
		const activeElement = Assertions.assertInstanceOf(this.getActiveElement(), HTMLInputElement);
		this.autoCompletionInfo.inAutoComplete = true;
		let result = activeElement.value;
		const cursorPosition = selection.getStart(activeElement);
		const tail = result.substring(cursorPosition);
		result = result.substring(0, cursorPosition - this.autoCompletionInfo.replacementLength) + value + tail;
		activeElement.value = result;
		selection.setStart(activeElement, cursorPosition + this.autoCompletionInfo.replacementLength);
		selection.setEnd(activeElement, cursorPosition + this.autoCompletionInfo.replacementLength);
		this.setCursorPosition(result.length - tail.length);
		this.changeCallback?.(result);
	}

	/** Sanitizes paths in queries that contain backslashes. */
	public static escapeIssueQuery(dirtyQuery: string): string {
		return dirtyQuery.replace(/\\/g, '\\\\');
	}
}
