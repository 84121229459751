import * as events from 'ts-closure-library/lib/events/eventhandler';
import * as strings from 'ts-closure-library/lib/string/string';
import { EventType as TableEventType, TableSorter } from 'ts-closure-library/lib/ui/tablesorter';
import type { AssessmentValue } from 'ts/commons/components/MetricFormatters';
import { tsdom } from 'ts/commons/tsdom';
import { EMetricValueType } from 'typedefs/EMetricValueType';
import { ArrayUtils } from './ArrayUtils';
import { DateUtils } from './DateUtils';
import { NumericValueFormatter } from './formatter/NumericValueFormatter';
import { PathUtils } from './PathUtils';
import { SmartTableSorter } from './SmartTableSorter';

/** Utility methods for sorting */
export class SortingUtils {
	/** The string used for metrics that are not available (also referenced from soy templates). */
	public static NOT_AVAILABLE_STRING = 'N/A';

	/**
	 * Defines the sort order for assessments, from most severe to the least severe assessment. The values are derived
	 * from the zero-based color indices in ETrafficLightColor. So red(0) is the most severe assessment, followed by
	 * orange(1), yellow(2), baseline/gray(4), and unknown(5). Green(3) is not included, as it has no severity at all.
	 */
	private static readonly ASSESSMENT_SORT_ORDER: number[][] = [[0, 1, 2, 4, 5]];

	/**
	 * Defines the sort order for test-gap and similar counter sets, from most severe to the least severe assessment.
	 * The first group comprises red (2) and orange (1), the second of green (0). Gray (0) is not part of any group.
	 */
	private static readonly TESTGAP_SORT_ORDER: number[][] = [[2, 1], [0]];

	/** Constant for ascending order */
	public static ASCENDING_ORDER = 'ascending';

	/**
	 * Makes a code table sortable.
	 *
	 * @param table
	 * @param valueTypes The value types, from table column 1 (i.e., excluding the path) to column n
	 * @param numberOfPathColumn To determine which column contains the paths. ( Default is 0)
	 */
	public static makeCodeTableSortable(table: HTMLTableElement, valueTypes: string[], numberOfPathColumn = 0): void {
		const sorter = new SmartTableSorter(table);

		// We expect the first column to be always the path
		sorter.setSortFunction(numberOfPathColumn, (a, b) => {
			return PathUtils.pathComparatorWithPackageSeparator(a as string, b as string);
		});
		valueTypes.forEach((valueType, index) => {
			if (index >= numberOfPathColumn) {
				index++;
			}

			// As the path column is not included in valueTypes, the column index is equal to (<valueTypes_list_index>
			// + 1)
			const columnIndex = index;
			const sortFunction = SortingUtils.getSortFunctionByValueType(valueType);
			sorter.setSortFunction(columnIndex, sortFunction);
		});

		// Initialize table, sort w.r.t path (column 0) if nothing is stored for this table.
		sorter.decorate(table, 0);
		events.listen(sorter, TableEventType.SORT, function () {
			SortingUtils.makeSummaryRowsFirstRows(table);
		});
	}

	/** Returns a sort function based on the value type. */
	public static getSortFunctionByValueType(
		valueType: string
	): ((a: AssessmentValue, b: AssessmentValue) => number) | ((a: string, b: string) => number) {
		switch (valueType) {
			case EMetricValueType.ASSESSMENT.name:
				return SortingUtils.sortAssessments;
			case EMetricValueType.COUNTER_SET.name:
				return SortingUtils.sortCounterSets;
			case 'STRING':
				return (a: string, b: string) => {
					return a.localeCompare(b);
				};
			case 'DATETIME':
				return (a: string, b: string) => {
					return TableSorter.numericSort(
						DateUtils.parseTimestampFromDate(a + ''),
						DateUtils.parseTimestampFromDate(b + '')
					);
				};
			case EMetricValueType.TIMESTAMP.name:
			default:
				return TableSorter.numericSort;
		}
	}

	/**
	 * Move the summary rows (if present) of the given table to the top of the table.
	 *
	 * @param table The table to process.
	 */
	public static makeSummaryRowsFirstRows(table: HTMLTableElement): void {
		for (const tBody of table.tBodies) {
			const archSummaryRow = tsdom.findElementByClass('arch-summary-row', tBody);
			if (archSummaryRow != null) {
				tBody.removeChild(archSummaryRow);
				tBody.insertBefore(archSummaryRow, tBody.rows[0]!);
			}
			const summaryRow = tsdom.findElementByClass('first-row', tBody);
			if (summaryRow != null) {
				tBody.removeChild(summaryRow);
				tBody.insertBefore(summaryRow, tBody.rows[0]!);
			}
		}
	}

	/**
	 * Sorts the given assessment strings in the order {@link MetricsUtils.ASSESSMENT_SORT_ORDER} Each string parameter
	 * is expected to be in the order of ETrafficLightColor: red, orange, yellow, green, baseline (or gray) and unknown.
	 * Of the two parameters, the bigger is the one with greater green value and lesser values of other colors.
	 *
	 * @param firstValue First assessment value
	 * @param secondValue Second assessment value
	 * @returns 0 if both are equal, -1 if firstValue is less than secondValue and 1 otherwise
	 */
	public static sortAssessments(firstValue: string, secondValue: string): number {
		return SortingUtils.sortAssessmentsOrCountersets(firstValue, secondValue, SortingUtils.ASSESSMENT_SORT_ORDER);
	}

	/**
	 * Sorts the given counter-set strings in the order {@link MetricsUtils.TESTGAP_SORT_ORDER} Each string parameter is
	 * expected to be in the order of ETestGapState: green (tested churn), orange (untested changes), red (untested
	 * addition) and gray (no changes) Of the two parameters, the bigger is the one with greater green value and lesser
	 * values of other colors.
	 *
	 * @param firstValue First counter-set value
	 * @param secondValue Second counter-set value
	 * @returns 0 if both are equal, -1 if firstValue is less than secondValue and 1 otherwise
	 */
	public static sortCounterSets(firstValue: string, secondValue: string): number {
		return SortingUtils.sortAssessmentsOrCountersets(firstValue, secondValue, SortingUtils.TESTGAP_SORT_ORDER);
	}

	/**
	 * Sorts the given assessment/counter-set strings using the provided sorting order.
	 *
	 * @param firstValue First assessment or counter-set value
	 * @param secondValue Second assessment or counter-set value
	 * @param sortOrder Groups of zero-based indices for colors indicating sorting order.
	 * @returns 0 if both are equal, -1 if firstValue is less than secondValue and 1 otherwise
	 */
	private static sortAssessmentsOrCountersets(
		firstValue: string,
		secondValue: string,
		sortOrder: number[][]
	): number {
		const firstIsEmpty =
			strings.caseInsensitiveEquals(firstValue, SortingUtils.NOT_AVAILABLE_STRING) || firstValue === '';
		const secondIsEmpty =
			strings.caseInsensitiveEquals(secondValue, SortingUtils.NOT_AVAILABLE_STRING) || secondValue === '';
		if (firstIsEmpty && !secondIsEmpty) {
			return -1;
		} else if (!firstIsEmpty && secondIsEmpty) {
			return 1;
		} else if (firstIsEmpty && secondIsEmpty) {
			return 0;
		}
		const firstValueList = firstValue.split(/, /).map(n => NumericValueFormatter.parse(n));
		const secondValueList = secondValue.split(/, /).map(n => NumericValueFormatter.parse(n));
		const firstValueListSum = ArrayUtils.sum(firstValueList);
		const secondValueListSum = ArrayUtils.sum(secondValueList);

		// If at least one of the assessments has no entries,
		// the following calculations would divide by 0
		if (firstValueListSum === 0 || secondValueListSum === 0) {
			return firstValueListSum - secondValueListSum;
		}
		const firstValuePercentages = ArrayUtils.toPercentages(firstValueList, firstValueListSum)!;
		const secondValuePercentages = ArrayUtils.toPercentages(secondValueList, secondValueListSum)!;
		return SortingUtils.compareSeverities(firstValuePercentages, secondValuePercentages, sortOrder);
	}

	/**
	 * Compares left-hand side with right-hand side using the given sorting order which lists the indexes from most
	 * severe to least severe, in one or more groups.
	 *
	 * @param lhs
	 * @param rhs
	 * @param sortOrder Groups of zero-based indices for colors indicating sorting order.
	 */
	private static compareSeverities(lhs: number[], rhs: number[], sortOrder: number[][]): number {
		function accumulateByGroup(inputArray: number[]): number[][] {
			return sortOrder.map(subSortOrder => {
				const permutedArray = subSortOrder.map(index => inputArray[index]!);
				return ArrayUtils.accumulateLeft(permutedArray);
			});
		}

		const groupedLhs = accumulateByGroup(lhs);
		const groupedRhs = accumulateByGroup(rhs);

		// Compare the two arrays of arrays lexicographically
		return SortingUtils.compare3(groupedLhs, groupedRhs);
	}

	/**
	 * 3-way array compare function.
	 *
	 * @param arr1 The first array to compare.
	 * @param arr2 The second array to compare.
	 * @returns Negative number, zero, or a positive number depending on whether the first argument is less than, equal
	 *   to, or greater than the second.
	 */
	private static compare3(arr1: number[][], arr2: number[][]): number {
		const l = Math.min(arr1.length, arr2.length);
		for (let i = 0; i < l; i++) {
			const result = ArrayUtils.defaultCompare(arr1[i]!.toString(), arr2[i]!.toString());
			if (result !== 0) {
				return result;
			}
		}
		return ArrayUtils.defaultCompare(arr1.length, arr2.length);
	}

	/** Compares a and b using [String.localCompare], but this function can be used in a lambda directly. */
	public static localCompare(a: string, b: string): number {
		return a.localeCompare(b);
	}
}
