import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { GetBranchesGetRequestQueryParams } from 'api/ApiDefinition';
import type { QueryOperation } from 'api/Query';
import { QUERY } from 'api/Query';
import type { ServiceCallError } from 'api/ServiceCallError';
import type { Callback } from 'ts/base/Callback';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import type { BranchesInfo } from 'typedefs/BranchesInfo';

/** Returns the default branch for one or multiple projects. */
export function useDefaultBranch(
	projectIds: string[],
	options?: Omit<UseQueryOptions<string, ServiceCallError>, 'queryKey' | 'queryFn' | 'initialData'>
): UseQueryResult<string | null> {
	return getDefaultBranchForProjects(projectIds).useQuery(options);
}

/** Returns the default branch name for one or multiple projects. */
export function getDefaultBranchForProjects(projects: string[]): QueryOperation<string> {
	if (projects.length === 1) {
		return QUERY.getFirstUIBranchNameGetRequest(projects[0]!);
	}
	return QUERY.getGlobalFirstUIBranchGetRequest({ projects }) as QueryOperation<string>;
}

/** The number of branches to fetch from the server. */
export const BRANCH_SELECTOR_PAGINATION_LIMIT = 1000;

/** Returns a single {@link BranchesInfo} for one or multiple projects at once. */
export function getBranchesInfoForProjects(
	projects: string[],
	params: GetBranchesGetRequestQueryParams
): QueryOperation<BranchesInfo> {
	if (projects.length === 1) {
		const project = projects[0]!;
		return QUERY.getBranchesGetRequest(project, params);
	} else {
		return QUERY.getGlobalBranchesGetRequest({ ...params, projects });
	}
}

/** Returns the list of recently visited branches for one or multiple projects at once. */
export function listRecentBranchesForProjects(projects: string[]): QueryOperation<string[]> {
	if (projects.length === 1) {
		return QUERY.getRecentBranches(projects[0]!);
	}
	return QUERY.getGlobalRecentBranchesGetRequest({ projects });
}

/** Registers the branch name passed to the returned function as recently selected at the server. */
export function useAddRecentlySelectedBranch(project: string | undefined): Callback<string> {
	const client = useTeamscaleServiceClient();
	const queryClient = useQueryClient();
	const invalidateRecentBranchesProject = async () => {
		if (project != null) {
			await QUERY.getRecentBranches(project).invalidate();
		}
	};
	const { mutate: registerSelectedBranch } = useMutation({
		mutationFn: (branchName: string) => client.registerBranchSelection(project!, branchName),
		onSuccess: () =>
			Promise.all([
				invalidateRecentBranchesProject(),
				queryClient.invalidateQueries({
					queryKey: QUERY.getGlobalRecentBranchesGetRequest({}).queryKey.slice(0, 3)
				})
			])
	});
	if (project == null) {
		return () => undefined;
	}
	return registerSelectedBranch;
}
