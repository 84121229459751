// This file was automatically generated by the Soy compiler.
// Please don't edit this file by hand.
// source: ts/base/perspective/sidebar/left/NavTooltipTemplate.soy

/**
 * @fileoverview Templates in namespace ts.base.perspective.sidebar.NavTooltipTemplate.
 * @suppress {missingRequire} TODO(b/152440355)
 */



import * as $googSoy from 'ts-closure-library/lib/soy/soy';
import * as google from 'ts-closure-library/lib/google';
import * as goog_soy_data from 'soyutils/soyutils_usegoog';
import * as soy from 'soyutils/soyutils_usegoog';


/**
 * @param {!$navTooltip.Params} opt_data
 * @param {(?$googSoy.IjData|?Object<string, *>)=} opt_ijData
 * @return {!goog_soy_data.SanitizedHtml}
 */
const $navTooltip = function(opt_data, opt_ijData) {
  const $ijData = /** @type {!$googSoy.IjData} */ (opt_ijData);
  return $navTooltip$(soy.$$internalCallMarkerDoNotUse, $ijData, opt_data.perspective, opt_data.isActivePerspective, opt_data.viewsWithAnchors, opt_data.id, opt_data.project, opt_data.mainPerspectiveLink, opt_data.selectedView, opt_data.anchorLinkContentAfterProjectName);
};
/**
 * @param {!Object} $$areYouAnInternalCaller
 * @param {!$googSoy.IjData} $ijData
 * @param {?} perspective
 * @param {boolean} isActivePerspective
 * @param {?} viewsWithAnchors
 * @param {string} id
 * @param {?=} project
 * @param {null|string|undefined=} mainPerspectiveLink
 * @param {?=} selectedView
 * @param {?=} anchorLinkContentAfterProjectName
 * @return {!goog_soy_data.SanitizedHtml}
 * @suppress {checkTypes}
 */
const $navTooltip$ = function($$areYouAnInternalCaller, $ijData, perspective, isActivePerspective, viewsWithAnchors, id, project, mainPerspectiveLink, selectedView, anchorLinkContentAfterProjectName) {
  soy.$$areYouAnInternalCaller($$areYouAnInternalCaller);
  if (false && soy.$$stubsMap['ts.base.perspective.sidebar.NavTooltipTemplate.navTooltip']) {
    return soy.$$stubsMap['ts.base.perspective.sidebar.NavTooltipTemplate.navTooltip']({perspective: perspective, isActivePerspective: isActivePerspective, viewsWithAnchors: viewsWithAnchors, id: id, project: project, mainPerspectiveLink: mainPerspectiveLink, selectedView: selectedView, anchorLinkContentAfterProjectName: anchorLinkContentAfterProjectName}, $ijData);
  }
  soy.assertParamType(typeof isActivePerspective === 'boolean', 'isActivePerspective', isActivePerspective, '@param', 'boolean');
  soy.assertParamType(typeof id === 'string', 'id', id, '@param', 'string');
  soy.assertParamType(mainPerspectiveLink == null || typeof mainPerspectiveLink === 'string', 'mainPerspectiveLink', mainPerspectiveLink, '@param', 'null|string|undefined');
  let $output = '<a href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(mainPerspectiveLink)) + '" class="item" id="' + soy.$$escapeHtmlAttribute(id) + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.base.perspective.sidebar.NavTooltipTemplate.navTooltip ts/base/perspective/sidebar/left/NavTooltipTemplate.soy:24"' : '') + '><strong>' + soy.$$escapeHtml(perspective.displayName) + '</strong></a>' + (viewsWithAnchors.length > 0 ? '<div class="ui marginless divider"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.base.perspective.sidebar.NavTooltipTemplate.navTooltip ts/base/perspective/sidebar/left/NavTooltipTemplate.soy:27"' : '') + '></div>' : '');
  const subView26235List = viewsWithAnchors;
  const subView26235ListLen = subView26235List.length;
  for (let subView26235Index = 0; subView26235Index < subView26235ListLen; subView26235Index++) {
    const subView26235Data = subView26235List[subView26235Index];
    $output += '<a class="item ' + (soy.$$coerceToBoolean(selectedView) && soy.$$equals(selectedView, subView26235Data.anchor) && isActivePerspective || !selectedView && isActivePerspective && subView26235Index == 0 ? ' active' : '') + ' tooltip-item-' + soy.$$escapeHtmlAttribute(subView26235Data.anchor) + ' perspective-link" href="' + soy.$$escapeHtmlAttribute(soy.$$filterNormalizeUri(perspective.simpleName + '.html#' + subView26235Data.anchor)) + '/' + (soy.$$coerceToBoolean(project) && soy.$$coerceToBoolean(subView26235Data.requiresProject) ? soy.$$escapeHtmlAttribute(project) : '') + (subView26235Data.keepPathAndArgumentsOfCurrentViewForSubviews ? '/' + soy.$$escapeHtmlAttribute(anchorLinkContentAfterProjectName) : '') + '"' + (false && soy.$$getDebugSoyTemplateInfo() ? ' data-debug-soy="ts.base.perspective.sidebar.NavTooltipTemplate.navTooltip ts/base/perspective/sidebar/left/NavTooltipTemplate.soy:32"' : '') + '>' + soy.$$escapeHtml(subView26235Data.name) + (subView26235Data.isBeta ? '<span class="beta-label">Beta</span>' : '') + '</a>';
  }
  return soy.VERY_UNSAFE.ordainSanitizedHtml($output);
};
export { $navTooltip as navTooltip };
export { $navTooltip$ as navTooltip$ };
/**
 * @typedef {{
 *  perspective: ?,
 *  isActivePerspective: boolean,
 *  viewsWithAnchors: ?,
 *  id: string,
 *  project?: (?|undefined),
 *  mainPerspectiveLink?: (null|string|undefined),
 *  selectedView?: (?|undefined),
 *  anchorLinkContentAfterProjectName?: (?|undefined),
 * }}
 */
$navTooltip.Params;
if (false) {
  /**
   * @nocollapse
   * @type {string}
   */
  $navTooltip.soyTemplateName = 'ts.base.perspective.sidebar.NavTooltipTemplate.navTooltip';
}
