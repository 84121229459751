import * as NavTooltipTemplate from 'soy/base/perspective/sidebar/left/NavTooltipTemplate.soy.generated';
import * as dom from 'ts-closure-library/lib/dom/dom';
import * as events from 'ts-closure-library/lib/events/eventhandler';
import { AnchoredPosition } from 'ts-closure-library/lib/positioning/anchoredposition';
import { Corner } from 'ts-closure-library/lib/positioning/positioning';
import * as style from 'ts-closure-library/lib/style/style';
import { EventType } from 'ts-closure-library/lib/ui/popupbase';
import { Tooltip } from 'ts-closure-library/lib/ui/tooltip';
import { hydrateLinksIn } from 'ts/base/soy/SoyRenderer';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { UIUtils } from 'ts/commons/UIUtils';
import type { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Tooltip used for teamscale navigation. We use a separate class, as we need to override the position. */
export class SidebarTooltip extends Tooltip {
	/** Stores if the sidebar item is the selected one. */
	private readonly isSelectedItem: boolean;

	/**
	 * @param element Element to display tooltip for.
	 * @param activePerspective The perspective currently in use
	 * @param perspective The perspective to render the tooltip for.
	 * @param linkHeight The height of the link element on which the tooltip will be placed
	 */
	public constructor(
		private readonly element: HTMLElement,
		private readonly activePerspective: ETeamscalePerspective,
		perspective: ETeamscalePerspective,
		private readonly iconOnlySidebar: boolean,
		linkHeight: number,
		accessibleSubviews: ViewDescriptor[],
		mainPerspectiveLink: string,
		viewName: string | null
	) {
		super(element);
		this.isSelectedItem = activePerspective.name === perspective.name;
		this.element.classList.toggle('active', this.isSelectedItem);
		this.setMargin(-linkHeight, 0, 0, 0);
		this.setShowDelayMs(100);
		this.className += ' right top ui popup vertical borderless menu navigation-popup';
		this.setTooltipSubviews(element, perspective, accessibleSubviews, mainPerspectiveLink, viewName);
		events.listen(this, EventType.BEFORE_HIDE, () => {
			if (!this.isSelectedItem) {
				element.classList.remove('active');
			}
		});

		// Don't hide immediately if mouse moves out of menu region
		this.setHideDelayMs(600);
	}

	public override showPopupElement(): void {
		// Mark sidebar item on hover
		this.element.classList.add('active');

		// Don't show popup if we have a full-width sidebar and
		// (a) the perspective has no subviews
		// (b) the sidebar item is already selected anyways
		if (!this.iconOnlySidebar && (this.isSelectedItem || parseInt(this.element.dataset.subviews!) === 0)) {
			return;
		}

		// Adjust the popup position if there is not enough vertical space
		const popupElement = this.getElement() as HTMLElement;
		const position = style.getPosition(this.element);
		const tooltipHeight = style.getSize(popupElement).height;
		const windowHeight = dom.getViewportSize().height;
		if (position.y + tooltipHeight > windowHeight) {
			popupElement.style.top = windowHeight - tooltipHeight + 'px';
		}
		super.showPopupElement();
	}

	/**
	 * Sets the tooltip menu for a sidebar entry.
	 *
	 * @param element The target element
	 */
	public setTooltipSubviews(
		element: HTMLElement,
		perspective: ETeamscalePerspective,
		accessibleSubviews: ViewDescriptor[],
		mainPerspectiveLink: string,
		viewName: string | null
	): void {
		const project = NavigationHash.getCurrent().getProject();
		this.renderTooltipContent(perspective, accessibleSubviews, mainPerspectiveLink, element, project, viewName);

		element.dataset['subviews'] = String(accessibleSubviews.length);
		const popupElement = dom.getRequiredElement(element.id + '-popup').parentElement;
		events.listen(popupElement, 'click', () => this.setVisible(false));
	}

	/** Updates and renders the tooltip HTML content. */
	private renderTooltipContent(
		perspective: ETeamscalePerspective,
		subviewsAndAnchors: ViewDescriptor[],
		mainPerspectiveLink: string,
		linkElement: Element,
		project: string | null,
		viewName: string | null
	) {
		const currentHash = NavigationHash.getCurrent();
		const anchorLinkContentAfterProjectName =
			currentHash.getProjectAndPath().getPath() + currentHash.getArgumentsString();

		const safeTooltipHtml = UIUtils.renderAsSafeHtml(NavTooltipTemplate.navTooltip, {
			perspective,
			isActivePerspective: this.activePerspective === perspective,
			viewsWithAnchors: subviewsAndAnchors,
			id: linkElement.id + '-popup',
			mainPerspectiveLink,
			project,
			selectedView: viewName,
			anchorLinkContentAfterProjectName
		});
		this.setSafeHtml(safeTooltipHtml);
		const element = this.getElement()!;
		hydrateLinksIn(element);
	}

	public override getPositioningStrategy(): AnchoredPosition {
		const activeElement = this.getActiveElement();
		return new AnchoredPosition(activeElement, Corner.BOTTOM_RIGHT);
	}
}
