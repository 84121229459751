import { useQuery } from '@tanstack/react-query';
import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { JSX } from 'react';
import type { DropdownItemProps } from 'semantic-ui-react';
import { Message } from 'semantic-ui-react';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { useTimePickerContext } from 'ts/commons/time/components/TimePickerContext';
import { DefinedPointInTimeDropdown, useControlledSelectValue } from 'ts/commons/time/components/TimePickerUtils';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';
import type { CommitRepositoryLogEntry } from 'ts/data/CommitRepositoryLogEntry';
import type { CommitDescriptor } from 'typedefs/CommitDescriptor';

function convertLogEntryToOption(item: CommitRepositoryLogEntry): DropdownItemProps {
	const repositoryLogEntry = item.repositoryLogEntry;
	const key =
		repositoryLogEntry.repositoryIdentifier +
		'#' +
		item.project +
		'#' +
		repositoryLogEntry.message +
		'#' +
		repositoryLogEntry.author;
	const text = `Repository "${
		repositoryLogEntry.repositoryIdentifier
	}" in project "${item.project!}": ${repositoryLogEntry.message!} (${repositoryLogEntry.author})`;

	return {
		key,
		text,
		value: JSON.stringify({
			revision: item.shortRevision,
			timestamp: item.commit.timestamp
		})
	};
}

function extractPointInTime(value: string): TypedPointInTime | null {
	const { revision, timestamp } = JSON.parse(value);
	if (revision == null || isNaN(timestamp)) {
		return null;
	}
	return TimeUtils.revision(revision, timestamp);
}

function useAmbiguousRevisionCommits(ambiguousRevision?: AmbiguousRevision): CommitRepositoryLogEntry[] | undefined {
	const client = useTeamscaleServiceClient();
	const { data: logEntries } = useQuery({
		queryKey: ['ambiguousRevision', ambiguousRevision],
		queryFn: () => {
			const promisedLogEntries = ambiguousRevision!.commitsWithProjects.map(async commitWithProject => {
				const logEntries = await client.getRepositoryLogEntries(commitWithProject.project, [
					UnresolvedCommitDescriptor.wrap(commitWithProject.commit)
				]);
				logEntries.forEach(logEntry => {
					logEntry.project = commitWithProject.project;
				});
				return logEntries;
			});
			return Promise.all(promisedLogEntries).then(values => values.flat());
		},
		enabled: ambiguousRevision != null
	});

	return logEntries;
}

/**
 * Stores a revision string and all the commits that refer to this revision. It can be used when multiple projects or
 * repositories refer to the same revision to decide which one must be used for the time picker.
 */
export type AmbiguousRevision = {
	revision: string;
	commitsWithProjects: Array<{
		commit: CommitDescriptor;
		project: string;
	}>;
};

/**
 * A component for selecting a commit. This tab is used when the {@link RevisionPicker} has ambiguous results on the
 * revision. The user can select which project, repository and commit refers to the correct revision. This tab is
 * expected to be shown only if the ambiguous revision is set.
 */
export function AmbiguousRevisionPicker(): JSX.Element | null {
	const { ambiguousRevision } = useTimePickerContext();
	const [selectedValue, setSelectedValue] = useControlledSelectValue('commit', extractPointInTime, () => undefined);
	const logEntries = useAmbiguousRevisionCommits(ambiguousRevision);
	if (ambiguousRevision == null || logEntries == null) {
		return null;
	}
	return (
		<>
			<Message info>
				{'There are several matches for revision '}
				{ambiguousRevision.revision}
				{' in different repositories. Please select the appropriate repository.'}
			</Message>
			<DefinedPointInTimeDropdown
				fluid
				testId="ambiguous-revision-select"
				selectedValue={selectedValue}
				setSelectedValue={setSelectedValue}
				displayObjects={logEntries}
				optionMapper={convertLogEntryToOption}
			/>
		</>
	);
}
