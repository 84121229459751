import type { Action, History, Location, Update } from 'history';
import type { JSX, ReactNode } from 'react';
import { Router } from 'react-router-dom';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { ObjectUtils } from 'ts/commons/ObjectUtils';
import type { Mutate, StoreApi, UseBoundStore } from 'zustand';
import { create } from 'zustand';
import { createPerspectiveHashHistory } from './PerspectiveHashHistory';

/** The possible props for the PerspectiveHashRouter. */
type PerspectiveHashRouterProps = {
	children?: ReactNode;
};

/**
 * The global React Router history object which should be used as a low level primitive to efficiently navigate in the
 * UI.
 */
export const HISTORY: History = createPerspectiveHashHistory(window);

/** Type of the state stored in useHistoryStore. */
export type LocationState = {
	action: Action;
	locationWithoutSilentUpdates: Location;
	latestLocation: Location;
	wasSilentUpdate: boolean;
	setLocation: (update: Update) => void;
};

/** The type returned by the createHistoryStore function. */
type LocationsStore = UseBoundStore<Mutate<StoreApi<LocationState>, []>>;

/**
 * Store that handles silent updates next to the regular updates and serves as main access point for the history state
 * for the application.
 */
export const useHistoryStore = createHistoryStore();

function createHistoryStore(): LocationsStore {
	const location = HISTORY.location;
	const store = create<LocationState>(set => ({
		action: HISTORY.action,
		locationWithoutSilentUpdates: location,
		latestLocation: location,
		wasSilentUpdate: false,
		setLocation({ action, location }: Update) {
			set(oldState => {
				const isSilentUrlChange =
					HISTORY.location.state === NavigationHash.DO_NOT_RELOAD_VIEW_STATE && action === 'REPLACE';
				const oldLatestLocation = oldState.latestLocation;
				const locationHasChanged = !ObjectUtils.deepEqual(oldLatestLocation, location);
				if (!locationHasChanged && oldState.action === action) {
					return {};
				}
				if (isSilentUrlChange) {
					return {
						action,
						latestLocation: location,
						wasSilentUpdate: true
					};
				} else {
					return {
						action,
						latestLocation: location,
						locationWithoutSilentUpdates: location,
						wasSilentUpdate: false
					};
				}
			});
		}
	}));
	HISTORY.listen(update => store.getState().setLocation(update));
	return store;
}

/**
 * A Router component that uses a PerspectiveHashHistory to manipulate the browser history. We use this to be able to
 * keep the old URLs working.
 */
export function PerspectiveHashRouter({ children }: PerspectiveHashRouterProps): JSX.Element {
	const state = useHistoryStore(state => ({ location: state.locationWithoutSilentUpdates, action: state.action }));

	return (
		<Router navigationType={state.action} location={state.location} navigator={HISTORY}>
			{children}
		</Router>
	);
}
