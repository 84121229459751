import { QUERY } from 'api/Query';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { StringUtils } from 'ts/commons/StringUtils';
import {
	DashboardPerspectiveSettingsBarAddition,
	StandaloneDashboardAddDropdownButton
} from 'ts/perspectives/dashboard/DashboardPerspectiveSettingsBarAddition';
import { DashboardResolver } from 'ts/perspectives/dashboard/DashboardResolver';
import { WidgetUtils } from 'ts/perspectives/dashboard/widgets/WidgetUtils';
import type { LastDashboardOpenedByUserOption } from 'typedefs/LastDashboardOpenedByUserOption';

async function getProjects(
	hash: NavigationHash,
	option: LastDashboardOpenedByUserOption,
	signal: AbortSignal
): Promise<string[]> {
	const projectForServerCall = StringUtils.getNullIfEmpty(hash.getProject());
	try {
		const dashboards = await QUERY.getAllDashboards({ project: projectForServerCall ?? undefined }).fetch(signal);
		const dashboardId = new DashboardResolver(hash, option).determineDashboardIdToShow(dashboards);
		if (signal.aborted) {
			return [];
		}
		ensureIdMatchesHash(dashboardId, hash);
		if (dashboardId === null) {
			return [];
		}
		const dashboardDescriptor = await QUERY.getDashboard(dashboardId).fetch(signal);
		return WidgetUtils.getProjects(dashboardDescriptor);
	} catch (error) {
		return [];
	}
}

function ensureIdMatchesHash(dashboardId: string | null, hash: NavigationHash) {
	if (hash.getId() !== dashboardId) {
		if (dashboardId === null) {
			hash.remove(NavigationHash.ID_PARAMETER);
		} else {
			hash.setId(dashboardId);
		}
		hash.navigate(true);
	}
}

/** Defines all sub-views of the dashboard perspective */
export const EDashboardPerspectiveView = {
	DASHBOARD_SHOW: {
		name: '',
		anchor: '',
		requiresProject: true,
		showAllProjects: true,
		hasSmallPadding: true,
		viewCreator: () => import('./show/ShowDashboardView'),
		additionalPerspectiveSettingsComponent: <DashboardPerspectiveSettingsBarAddition showEditMenu />,
		getProjects,
		timeTravel: {
			contentName: 'Data'
		}
	} as ViewDescriptor,
	DASHBOARD_NEW: {
		name: 'New dashboard',
		anchor: 'new',
		requiresProject: true,
		showAllProjects: true,
		hasSmallPadding: true,
		viewCreator: () => import('./sidebar/DashboardSidebarCreateView'),
		additionalPerspectiveSettingsComponent: <DashboardPerspectiveSettingsBarAddition />,
		timeTravel: {
			contentName: 'Data'
		}
	} as ViewDescriptor,
	DASHBOARD_EDIT: {
		name: 'Edit dashboard',
		anchor: 'edit',
		requiresProject: true,
		showAllProjects: true,
		hasSmallPadding: true,
		viewCreator: () => import('./sidebar/DashboardSidebarEditView'),
		additionalPerspectiveSettingsComponent: <DashboardPerspectiveSettingsBarAddition />,
		getProjects,
		timeTravel: {
			contentName: 'Data'
		}
	} as ViewDescriptor,
	TEMPLATES: {
		name: '',
		anchor: 'templates',
		requiresProject: false,
		showAllProjects: true,
		view: () => import('./templates/DashboardTemplatesView'),
		additionalPerspectiveSettingsComponent: <StandaloneDashboardAddDropdownButton />
	} as ViewDescriptor,
	SHARING: {
		name: '',
		anchor: 'sharing',
		requiresProject: true,
		showAllProjects: true,
		hasCustomAnalysisWarning: true,
		viewCreator: () => import('./DashboardSharingView'),
		additionalPerspectiveSettingsComponent: <DashboardPerspectiveSettingsBarAddition showEditMenu />
	} as ViewDescriptor
};
