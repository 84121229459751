import type { UseQueryOptions } from '@tanstack/react-query';
import { useQueries, useSuspenseQuery } from '@tanstack/react-query';
import type { JSX, ReactNode } from 'react';
import { Icon, Loader, Message } from 'semantic-ui-react';
import type { TeamscaleServiceClient } from 'ts/base/client/TeamscaleServiceClient';
import { useProjectInfos } from 'ts/base/hooks/ProjectsInfosHook';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { useCommit } from 'ts/base/hooks/UseCommit';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import type { AnalysisStateWithProjectAndBranch } from 'ts/commons/AnalysisStateWarningUtils';
import { AnalysisStateWarningUtils } from 'ts/commons/AnalysisStateWarningUtils';
import { Assertions } from 'ts/commons/Assertions';
import { TimeFormatter } from 'ts/commons/time/TimeFormatter';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import type { EAnalysisStateEntry } from 'typedefs/EAnalysisState';
import { EAnalysisState } from 'typedefs/EAnalysisState';
import styles from './AnalysisStateInfo.module.less';

type AnalysisStateInfoProps = {
	viewDescriptor: ViewDescriptor;
	projectIds: string[];
	defaultBranchName: string | null;
};

/**
 * Checks if the project is still in history/initial/rollback analysis and appends a warning in case the project may not
 * be up-to-date.
 */
export function AnalysisStateInfo({
	viewDescriptor,
	projectIds,
	defaultBranchName
}: AnalysisStateInfoProps): JSX.Element | null {
	const commit = useCommit();
	if (!viewDescriptor.requiresProject || viewDescriptor.hasCustomAnalysisWarning) {
		return null;
	}
	if (projectIds.length === 1) {
		let branchName = null;
		if (viewDescriptor.timeTravel) {
			branchName = commit.getBranchName() ?? Assertions.assertString(defaultBranchName);
		}
		return <DefaultAnalysisWarning projectId={projectIds[0]!} branchName={branchName} />;
	} else {
		return <AnalysisStateWarningMultiWrapper projectIds={projectIds} />;
	}
}

type DefaultAnalysisWarningProps = {
	projectId: string;
	branchName: string | null;
};

/** Loads the state from the server and shows the warning if applicable. */
function DefaultAnalysisWarning({ projectId, branchName }: DefaultAnalysisWarningProps): JSX.Element | null {
	const client = useTeamscaleServiceClient();
	const analysisState = useSuspenseQuery({
		queryKey: ['branch-analysis-state', projectId, branchName],
		queryFn: () => client.getBranchAnalysisState(projectId, branchName)
	}).data;
	if (AnalysisStateWarningUtils.finishedAnalysisWithCommits(analysisState)) {
		return null;
	}
	if (AnalysisStateWarningUtils.finishedAnalysisWithoutCommits(analysisState)) {
		return <NoCommitsFoundWarning analysisInfo={analysisState} />;
	} else {
		return <AnalysisStateWarning analysisInfo={analysisState} />;
	}
}

type NoCommitsFoundWarningProps = {
	analysisInfo: AnalysisStateWithProjectAndBranch;
};

/** Shows a warning that analysis for a project or branch is completed, but no commits have been found and analyzed. */
function NoCommitsFoundWarning({ analysisInfo }: NoCommitsFoundWarningProps): JSX.Element {
	const projectInfos = useProjectInfos();
	const projectName = projectInfos.getProjectName(analysisInfo.projectId);
	return (
		<Message warning icon className={styles.analysisStateWarning}>
			<Icon loading color="orange" name="cog" className="indeterminate" />
			{' No commits have been found for '}
			{analysisInfo.branchName ? (
				<code>
					<Icon name="fork" />
					{analysisInfo.branchName}
				</code>
			) : (
				<>
					{'project '}
					<code>{projectName}</code>
				</>
			)}
			. Please check the project configuration.
		</Message>
	);
}

type AnalysisStateWarningProps = {
	analysisInfo: AnalysisStateWithProjectAndBranch;
};

/** Shows a warning that a project or branch is in a certain state (rollback, history analysis...) */
function AnalysisStateWarning({ analysisInfo }: AnalysisStateWarningProps): JSX.Element {
	return (
		<InAnalysisMessage>
			<ul>
				<DetermineAnalysisStateWarning {...analysisInfo} />
			</ul>
		</InAnalysisMessage>
	);
}

function getReadableNameFromAnalysisState(state: EAnalysisStateEntry): string {
	switch (state) {
		case EAnalysisState.LIVE_ANALYSIS.name:
			return 'Finished analysis';
		case EAnalysisState.HISTORY_ANALYSIS.name:
			return 'Analyzing commit history';
		case EAnalysisState.CATCHUP_LIVE_ANALYSIS.name:
			return 'Analyzing new commits';
		case EAnalysisState.ROLLBACK_ANALYSIS.name:
			return 'Re-analyzing commit history due to a rollback';
		default:
			return 'Analyzing first commit';
	}
}

type AnalysisStateWarningMultiWrapperProps = {
	projectIds: string[];
};

function getAnalysisStateQuery(
	client: TeamscaleServiceClient,
	projectId: string,
	branchName: string | null
): UseQueryOptions<AnalysisStateWithProjectAndBranch> {
	return {
		queryKey: ['analysis-state', projectId, branchName],
		queryFn: () => client.getBranchAnalysisState(projectId, branchName, true),
		throwOnError: true
	};
}

/**
 * Checks if the projects are in history/initial/rollback analysis and returns a warning if at least one project is not
 * up-to-date or null otherwise.
 */
function AnalysisStateWarningMultiWrapper({ projectIds }: AnalysisStateWarningMultiWrapperProps): JSX.Element | null {
	const branchName = useCommit().getBranchName();
	const client = useTeamscaleServiceClient();
	const results = useQueries({
		queries: projectIds.map(projectId => getAnalysisStateQuery(client, projectId, branchName))
	});
	if (!results.every(result => result.isSuccess)) {
		return null;
	}
	if (results.length === 1 && AnalysisStateWarningUtils.finishedAnalysisWithoutCommits(results[0]!.data!)) {
		return <NoCommitsFoundWarning analysisInfo={results[0]!.data!} />;
	}
	const projectAnalysisStates = results
		.filter(result => !AnalysisStateWarningUtils.finishedAnalysis(result.data!))
		.map(result => result.data!);
	if (projectAnalysisStates.length === 0) {
		return null;
	}

	return <AnalysisStateWarningMulti analysisInfos={projectAnalysisStates} />;
}

type AnalysisStateWarningMultiProps = {
	analysisInfos: AnalysisStateWithProjectAndBranch[];
};

/** Shows a warning that the projects are in an analysis state. */
function AnalysisStateWarningMulti({ analysisInfos }: AnalysisStateWarningMultiProps): JSX.Element {
	return (
		<InAnalysisMessage>
			<ul>
				{analysisInfos.map(info => {
					return <DetermineAnalysisStateWarning key={info.projectId} {...info} />;
				})}
			</ul>
		</InAnalysisMessage>
	);
}

type DetermineAnalysisStateWarningProps = AnalysisStateWithProjectAndBranch;

/** Determines the description of the given state. */
function DetermineAnalysisStateWarning({
	state,
	timestamp,
	branchName,
	projectId,
	rollbackId
}: DetermineAnalysisStateWarningProps): JSX.Element {
	const projectInfos = useProjectInfos();
	const projectName = projectInfos.getProjectName(projectId);
	return (
		<li>
			{getReadableNameFromAnalysisState(state)}
			{' of project '}
			<i>{projectName}</i>
			{branchName ? (
				<>
					{' for branch '}
					<i>{branchName}</i>
				</>
			) : null}
			{timestamp > 0 ? (
				<>
					{' (currently at '}
					{TimeFormatter.simple(TimeUtils.timestamp(timestamp))})
				</>
			) : null}
			.{state === EAnalysisState.ROLLBACK_ANALYSIS.name && rollbackId ? ` Rollback ID: ${rollbackId}` : null}
		</li>
	);
}

export function InAnalysisMessage({ children }: { children: ReactNode }): JSX.Element {
	return (
		<Message icon warning className={styles.analysisStateWarning}>
			<Loader active inline size="small" />
			<Message.Content>{children}</Message.Content>
		</Message>
	);
}
