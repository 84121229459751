import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { linkTo } from 'ts/commons/links/LinkTo';
import { EModelComparePerspectiveView } from 'ts/perspectives/model_compare/EModelComparePerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Returns a URL for the model compare perspective. */
export function modelCompare(
	project: string,
	params: {
		t: UnresolvedCommitDescriptor;
		leftPath: string;
		leftLocation: string;
		rightPath: string;
		rightLocation: string;
	}
) {
	return linkTo(ETeamscalePerspective.MODEL_COMPARE, EModelComparePerspectiveView.MODEL_COMPARE, project, params);
}
