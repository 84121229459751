import { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import * as LinkTemplate from 'soy/commons/LinkTemplate.soy.generated';
import { Links } from 'ts/commons/links/Links';
import { NavigationHash } from 'ts/commons/NavigationHash';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { ProjectAndUniformPath } from 'ts/commons/ProjectAndUniformPath';
import { StringUtils } from 'ts/commons/StringUtils';
import type { CommitRepositoryLogEntry } from 'ts/data/CommitRepositoryLogEntry';
import { EResourceType } from 'typedefs/EResourceType';
import type { PerspectiveContext } from 'typedefs/PerspectiveContext';
import type { TeamscaleServiceClient } from '../client/TeamscaleServiceClient';

/** Bundles the functionality of the global search bar in the main navigation menu of Teamscale. */
export class GlobalSearchHandler {
	/** The prefix of queries searching for revisions */
	public static readonly REVISION_SEARCH_PREFIX = 'rev:';

	/** The prefix of queries searching for files */
	public static readonly FILE_SEARCH_PREFIX = 'file:';

	/** The prefix of queries searching for issues */
	public static readonly ISSUE_SEARCH_PREFIX = 'issue:';

	/** The separator of queries searching for issues and revisions */
	private static readonly SEARCH_SEPARATOR = ' \u2022 ';

	/** Uniform path if the search bar is shown in a project-specific context, null otherwise. */
	private uniformPath: ProjectAndUniformPath | null = null;

	/**
	 * @param client The Service client used for requests
	 * @param perspectiveContext The context of the perspective for which this search bar is shown
	 */
	public constructor(
		private readonly client: TeamscaleServiceClient,
		private readonly perspectiveContext: PerspectiveContext
	) {}

	/**
	 * Tries to navigate to a perspective based on a search query.
	 *
	 * @param query The search query.
	 */
	public handleSearchQuery(query: string): void {
		if (query.startsWith(GlobalSearchHandler.FILE_SEARCH_PREFIX)) {
			this.handleFileNavigation(query);
			return;
		} else if (query.startsWith(GlobalSearchHandler.REVISION_SEARCH_PREFIX)) {
			this.handleRevisionNavigation(query);
			return;
		} else if (query.startsWith(GlobalSearchHandler.ISSUE_SEARCH_PREFIX)) {
			this.handleIssueNavigation(query);
			return;
		}
		GlobalSearchHandler.handleQueryWithoutPrefix(query);
	}

	/**
	 * Handles a search query that has no prefixes (e.g. "file:" or "rev:" or "issue:").
	 *
	 * @param query The search query that has no prefixes.
	 */
	private static handleQueryWithoutPrefix(query: string): void {
		const navHash = NavigationHash.getCurrent();
		if (navHash.getViewName() === 'results') {
			GlobalSearchHandler.updateNavHashWithSources(navHash);
			navHash.set('query', query);
			navHash.navigate();
			return;
		}

		let url = Links.searchView();

		if (!StringUtils.isEmptyOrWhitespace(query)) {
			// Search is project specific when used from a project specific perspective, after selecting a project.
			// Global otherwise.
			url = Links.searchResults(navHash.getProject(), query);
		}
		NavigationUtils.updateLocation(url);
	}

	/** Handles the file navigation from the search field for the given "file:"-query */
	private handleFileNavigation(query: string): void {
		let path = StringUtils.stripPrefix(query, GlobalSearchHandler.FILE_SEARCH_PREFIX);
		path = path.trim();
		this.uniformPath = ProjectAndUniformPath.parse(path);
		if (this.perspectiveContext.projectsInfo.projects.includes(this.uniformPath.getProject())) {
			this.client
				.getResourceType(this.uniformPath.getProject(), this.uniformPath.getPath(), null)
				.then(resourceType => {
					if (resourceType === EResourceType.FILE.name) {
						const url = LinkTemplate.code({
							project: this.uniformPath!.getProject(),
							uniformPath: this.uniformPath!.getPath()
						});
						NavigationUtils.updateLocation(url);
					} else {
						GlobalSearchHandler.handleQueryWithoutPrefix(path);
					}
				});
		} else {
			GlobalSearchHandler.handleQueryWithoutPrefix(path);
		}
	}

	/**
	 * Handles the revision navigation from the search field for the given "rev:"-query.
	 *
	 * If the query does not provide project or repositoryId (which are needed to identify the revision uniquely) a
	 * common search without the prefix is triggered.
	 */
	private handleRevisionNavigation(query: string): void {
		const input = StringUtils.stripPrefix(query, GlobalSearchHandler.REVISION_SEARCH_PREFIX);
		const parts = input.split(GlobalSearchHandler.SEARCH_SEPARATOR);
		if (parts.length !== 3) {
			GlobalSearchHandler.handleQueryWithoutPrefix(input);
			return;
		}
		const revision = parts[0]!.trim();
		const project = parts[1]!.trim();
		const repositoryId = parts[2]!.trim();
		this.client.getCommitsForRevision(project, revision).then(async commits => {
			const commitDescriptors = commits.map(UnresolvedCommitDescriptor.wrap) as UnresolvedCommitDescriptor[];
			const logEntries = await this.client.getRepositoryLogEntries(project, commitDescriptors);
			GlobalSearchHandler.jumpToRevisionCallback(project, repositoryId, commitDescriptors, logEntries);
		});
	}

	/**
	 * Jumps to the activity perspective, showing a revision that belongs to the given project and repository
	 * identifier.
	 *
	 * @param project The project
	 * @param repositoryId The identifier of the repository in which the revision is located.
	 * @param commits A list of commits that belong to possibly matching revisions.
	 * @param entries Contains log entries that belong to possibly matching revisions
	 */
	private static jumpToRevisionCallback(
		project: string,
		repositoryId: string,
		commits: UnresolvedCommitDescriptor[],
		entries: Array<CommitRepositoryLogEntry | null>
	): void {
		for (let i = 0; i < entries.length; i++) {
			const entry = entries[i];
			if (entry != null && entry.repositoryLogEntry.repositoryIdentifier === repositoryId) {
				const url = LinkTemplate.commitDetails({ project, commit: commits[i]! });
				NavigationUtils.updateLocation(url);
				return;
			}
		}
	}

	/** Handles the issue navigation from the search field for the given "issue:"-query. */
	private handleIssueNavigation(query: string): void {
		const input = StringUtils.stripPrefix(query, GlobalSearchHandler.ISSUE_SEARCH_PREFIX);
		const parts = input.split(GlobalSearchHandler.SEARCH_SEPARATOR);
		if (parts.length === 2) {
			const url = LinkTemplate.issue({ project: parts[1]!.trim(), id: parts[0]!.trim() });
			NavigationUtils.updateLocation(url);
		} else {
			GlobalSearchHandler.handleQueryWithoutPrefix(input);
		}
	}

	/**
	 * Static method that updates the given {@link NavigationHash} with the current sources.
	 *
	 * @param navHash The hash to update.
	 * @returns The updated navigation hash
	 */
	public static updateNavHashWithSources(navHash: NavigationHash): NavigationHash {
		const checkBoxes = Array.from(document.getElementsByName('source-filters') as NodeListOf<HTMLInputElement>);
		const activeFilters = checkBoxes.filter(box => box.checked).map(box => box.id);
		navHash.setArray('source', activeFilters);
		return navHash;
	}
}
