import type { JSX } from 'react';
import { Dropdown, Label } from 'semantic-ui-react';
import { useDefaultAdminEnabledWarning } from 'ts/base/perspective/topbar/warnings/DefaultAdminEnabledWarning';
import { useDefaultBackupSettingsWarning } from 'ts/base/perspective/topbar/warnings/DefaultBackupSettingsWarning';
import { useInMemoryWarning } from 'ts/base/perspective/topbar/warnings/InMemoryWarning';
import { useLicenseWarning } from 'ts/base/perspective/topbar/warnings/LicenseWarning';
import { useMemoryWarning } from 'ts/base/perspective/topbar/warnings/MemoryWarning';
import { useShadowModeWarning } from 'ts/base/perspective/topbar/warnings/ShadowModeWarning';
import { useSystemHealthWarning } from 'ts/base/perspective/topbar/warnings/SystemHealthWarning';
import { useUserLimitWarning } from 'ts/base/perspective/topbar/warnings/UserLimitWarning';
import styles from './GlobalWarnings.module.less';

/** The default stale time (10 seconds) to use for queries fetching global warnings. */
export const DEFAULT_STALE_TIME = 10 * 1_000;

/**
 * A long stale time (1 hour) to use for queries fetching global warnings that are hard to compute and/or do not change
 * very frequently.
 */
export const LONG_STALE_TIME = 60 * 60 * 1_000;

/** Renders the 'global' Teamscale warning section for the perspective settings bar (e.g. a missing license) */
export function GlobalWarnings(): JSX.Element | null {
	// The warnings are displayed in the order of importance
	let warnings = Array<JSX.Element | null>();
	warnings.push(useSystemHealthWarning());
	warnings.push(useLicenseWarning());
	warnings.push(useUserLimitWarning());
	warnings.push(useShadowModeWarning());
	warnings.push(useInMemoryWarning());
	warnings.push(useDefaultAdminEnabledWarning());
	warnings.push(useDefaultBackupSettingsWarning());
	warnings.push(useMemoryWarning());
	warnings = warnings.filter(warning => warning != null);

	if (warnings.length === 0) {
		return null;
	}

	const [firstWarning, ...additionalWarnings] = warnings;
	return (
		<Dropdown
			closeOnChange={false}
			trigger={
				<div className={styles.dropdownTrigger}>
					{firstWarning}
					{additionalWarnings.length > 0 ? (
						<Label circular size="mini" content={`+${additionalWarnings.length}`} />
					) : null}
				</div>
			}
			icon={false}
			floating
			className="min-w-0"
		>
			<Dropdown.Menu>
				{additionalWarnings.map((warning, index) => {
					return (
						<Dropdown.Item className={styles.warningsDropdownItem} key={index}>
							{warning}
						</Dropdown.Item>
					);
				})}
			</Dropdown.Menu>
		</Dropdown>
	);
}
