import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { linkTo } from 'ts/commons/links/LinkTo';
import { EFindingsPerspectiveView } from 'ts/perspectives/findings/EFindingsPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Returns a URL to the details of a finding. */
export function findingDetails(
	project: string,
	findingId: string,
	params?: {
		commit?: UnresolvedCommitDescriptor | null;
		searchParamsId?: string;
	}
) {
	return linkTo(ETeamscalePerspective.FINDINGS, EFindingsPerspectiveView.FINDING_DETAIL, project, {
		id: findingId,
		searchParamsId: params?.searchParamsId,
		t: params?.commit
	});
}
