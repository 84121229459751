import { EProjectPerspectiveView } from 'ts/perspectives/project/EProjectPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';
import { linkTo } from './LinkTo';

/** Returns a URL for viewing the list of projects. */
export function showProjects() {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROJECTS);
}

/** Returns a URL for viewing the list of sample projects. */
export function showSampleProjects() {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.SAMPLES);
}

/** Returns a URL for viewing a project config. */
export function showProjectConfiguration(projectId: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROJECTS, { name: projectId });
}

/** Returns a URL for viewing a project's project options. */
export function showProjectOptions(projectId: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROJECTS, {
		name: projectId,
		action: 'options'
	});
}

/** Returns a URL for a project's postponed rollbacks page. */
export function showProjectPostponedRollbacks(projectId: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROJECTS, {
		name: projectId,
		action: 'postponed-rollbacks'
	});
}

/** Returns a URL for a project's postponed rollbacks page. */
export function showProjectConnectorStatus(projectId: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROJECTS, {
		name: projectId,
		action: 'connectors'
	});
}

/** Returns a URL for editing a project. */
export function editProjectConfiguration(projectId: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROJECTS, {
		name: projectId,
		action: 'edit'
	});
}

/** Returns a URL for viewing the running profilers. */
export function showProfilers() {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROFILERS);
}

/** Returns a URL for viewing a profiler config. */
export function showProfilerConfiguration(
	configurationId: string,
	params?: { profilerId?: string; showInstallationInstructions?: boolean }
) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROFILER_CONFIGURATION_SHOW, {
		name: configurationId,
		profiler: params?.profilerId,
		installationInstructions: params?.showInstallationInstructions
	});
}

/** Returns a URL for creating a profiler config. */
export function createProfilerConfiguration() {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROFILER_CONFIGURATION_EDIT);
}

/** Returns a URL for editing a profiler config. */
export function editProfilerConfiguration(configurationId: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROFILER_CONFIGURATION_EDIT, {
		name: configurationId
	});
}

/** Returns a URL for copying a profiler config. */
export function copyProfilerConfiguration(configurationId: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROFILER_CONFIGURATION_COPY, {
		name: configurationId
	});
}

/** Returns a URL for editing a profiler config's permissions. */
export function editProfilerConfigurationRoles(configurationId: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROFILER_CONFIGURATION_EDIT_ROLES, {
		name: configurationId
	});
}

/** Returns a URL for viewing the analysis profiles. */
export function showAnalysisProfiles() {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.ANALYSIS_PROFILES);
}

/**
 * Returns a URL for viewing an analysis profile.
 *
 * @param analysisProfileName The name of the analysis profile.
 */
export function showAnalysisProfile(analysisProfileName: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.ANALYSIS_PROFILES, {
		name: analysisProfileName,
		action: 'profile-check-explorer'
	});
}

/**
 * Returns a URL for editing an analysis profile.
 *
 * @param editorView 'edit-check-explorer' --> editing in check explorer, 'edit' --> editing in old analysis profile
 *   editor
 */
export function editAnalysisProfile(
	analysisProfileName: string,
	editorView: 'create-check-explorer' | 'edit-check-explorer' | 'edit',
	params?: { rule: string | undefined }
) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.ANALYSIS_PROFILES, {
		name: analysisProfileName,
		action: editorView,
		...params
	});
}

/** Returns a URL for copying an analysis profile. */
export function copyAnalysisProfile(newAnalysisProfileName: string, analysisProfileToCopy: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.ANALYSIS_PROFILES, {
		name: newAnalysisProfileName,
		action: 'copy',
		analysisProfileToCopy
	});
}

/** Returns a URL for editing the roles of an analysis profile. */
export function editAnalysisProfileRolesLink(analysisProfileName: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.ANALYSIS_PROFILES, {
		name: analysisProfileName,
		action: 'roles'
	});
}

/** Returns a URL for showing all external metrics. */
export function showExternalMetrics() {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.EXTERNAL_METRICS);
}

/** Returns a URL for showing an external metric. */
export function showExternalMetric(externalMetricName: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.EXTERNAL_METRICS, {
		name: externalMetricName
	});
}

/** Returns a URL for editing an external metric. */
export function editExternalMetric(externalMetricName: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.EXTERNAL_METRICS, {
		name: externalMetricName,
		action: 'edit'
	});
}

/** Returns a URL for showing all external findings. */
export function showExternalFindings() {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.EXTERNAL_FINDINGS);
}

/** Returns a URL for showing an external finding. */
export function showExternalFinding(externalFindingName: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.EXTERNAL_FINDINGS, {
		name: externalFindingName
	});
}

/** Returns a URL for editing a metric threshold configuration. */
export function editMetricThresholdConfiguration(metricThresholdConfigurationName: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.THRESHOLDS, {
		name: metricThresholdConfigurationName,
		action: 'edit'
	});
}

/** Returns a URL for editing the roles of a metric threshold configuration. */
export function editMetricThresholdConfigurationRolesLink(metricThresholdConfigurationName: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.THRESHOLDS, {
		name: metricThresholdConfigurationName,
		action: 'roles'
	});
}

/** Returns a URL for copying a metric threshold configuration. */
export function copyMetricThresholdConfiguration(metricThresholdConfigurationName: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.THRESHOLDS, {
		name: metricThresholdConfigurationName,
		action: 'copy'
	});
}

/** Returns a URL for editing the options of a project. */
export function editProjectOptions(project: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROJECTS, {
		name: project,
		action: 'options'
	});
}

/** Returns a URL for editing a project. */
export function editProject(project: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROJECTS, {
		name: project,
		action: 'edit'
	});
}

/** Returns a URL for copying a project. */
export function copyProject(project: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROJECTS, {
		name: project,
		action: 'copy'
	});
}

/** Returns a URL for editing the roles assigned for a project. */
export function editProjectRolesLink(project: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROJECTS, {
		name: project,
		action: 'roles'
	});
}

/** Returns a URL for creating a project. */
export function addProject() {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROJECTS, {
		name: ':new'
	});
}

/** Returns a URL for showing external-analysis uploads for a project. */
export function showExternalAnalysisUploads(project: string) {
	return linkTo(ETeamscalePerspective.PROJECTS, EProjectPerspectiveView.PROJECTS, {
		name: project,
		action: 'showExternalAnalysisUploads'
	});
}
