import { useSuspenseQuery } from '@tanstack/react-query';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import { type JSX, useEffect } from 'react';
import { Button, Dimmer, Icon, Input, List, Loader, Message } from 'semantic-ui-react';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { SuspendingErrorBoundary } from 'ts/base/SuspendingErrorBoundary';
import { CommitSelector } from 'ts/commons/CommitSelector';
import type {
	ExposedPathEntitySelectionContext,
	PathEntitySelectionContextProviderProps
} from 'ts/commons/dialog/PathEntitySelectionContext';
import {
	PathEntitySelectionContextProvider,
	usePathEntitySelectionContext
} from 'ts/commons/dialog/PathEntitySelectionContext';

import styles from 'ts/commons/dialog/PathEntitySelectionModal.module.css';
import { ModalActionButtons } from 'ts/commons/modal/ModalActionButtons';
import { ProjectAndUniformPath } from 'ts/commons/ProjectAndUniformPath';
import { StringUtils } from 'ts/commons/StringUtils';
import { UniformPath } from 'ts/commons/UniformPath';
import { EType } from 'typedefs/EType';

/** A boolean check result along with a reason for or against a `true` result. */
export type PathCheckResult = {
	isAllowed: boolean;
	reason?: string;
};

type PathEntitySelectionModalInternalProps = {
	/** Callback for when the project/path/commit was selected and the dialog is closed using the "OK" button */
	onSave: (project: string | undefined, path: UniformPath, commit: UnresolvedCommitDescriptor) => void;

	/** Callback for when the dialog is closed using the "OK", "Cancel" or X buttons */
	onClose: () => void;

	/** Disables the commit selector, meaning it is not shown in the dialog */
	disableCommitSelector?: boolean;

	/** Path ETypes that should not be displayed */
	forbiddenUniformPathTypes?: EType[];
};

type FileListProps = {
	forbiddenUniformPathTypes: EType[];
};

/** Main properties for the path entity selection modal */
type PathEntitySelectionModalProps = PathEntitySelectionContextProviderProps & PathEntitySelectionModalInternalProps;

/**
 * Base component for a dialog providing the possibility to select a project, a path, as well as a commit therein. Any
 * additionally given props will be handed through to the internal "SaveCancelModal"
 *
 * There are already components for basically all possible permutation of this, see:
 *
 * - PathSelectionModal
 * - ProjectAndPathSelectionModal
 * - ProjectSelectionModal
 * - GlobalPathSelectionModal
 */
export function PathEntitySelectionModalContent({
	initialPath,
	initialProject,
	initialCommit,
	forbiddenUniformPathTypes = [],
	projectsSelectable,
	disableDirNavigation,
	useEntries,
	showWarningIfBranchIsChanged,
	...props
}: PathEntitySelectionModalProps): JSX.Element {
	return (
		<PathEntitySelectionContextProvider
			initialProject={initialProject}
			initialPath={initialPath}
			initialCommit={initialCommit}
			forbiddenUniformPathTypes={forbiddenUniformPathTypes}
			projectsSelectable={projectsSelectable}
			disableDirNavigation={disableDirNavigation}
			useEntries={useEntries}
			showWarningIfBranchIsChanged={showWarningIfBranchIsChanged}
		>
			<PathEntitySelectionModalContentInternal forbiddenUniformPathTypes={forbiddenUniformPathTypes} {...props} />
		</PathEntitySelectionContextProvider>
	);
}

/** Path checker that prohibits architecture paths */
export const DO_NOT_ALLOW_ARCHITECTURE_PATH_TYPES = [EType.ARCHITECTURE];

/** Path checker that prohibits test related paths */
export const DO_NOT_ALLOW_TEST_PATH_TYPES = [EType.TEST_EXECUTION, EType.TEST_IMPLEMENTATION, EType.TEST_QUERY];

/** Path checker that prohibits paths that are not implemented in findings. */
export const DO_NOT_ALLOW_UNIMPLEMENTED_FINDINGS_PATH_TYPES = [
	EType.NON_CODE,
	EType.TEST_IMPLEMENTATION,
	EType.TEST_QUERY,
	EType.ISSUE_ITEM,
	EType.ISSUE_QUERY,
	EType.EXECUTION_UNIT,
	EType.TEST_EXECUTION
];

/** Path checker that prohibits paths that are neither code nor architecture. */
export const IS_CODE_PATH_TYPE = EType.values.filter(type => type !== EType.CODE);

function PathEntitySelectionModalContentInternal({
	disableCommitSelector = false,
	onSave,
	onClose,
	forbiddenUniformPathTypes = []
}: PathEntitySelectionModalInternalProps): JSX.Element {
	const context = usePathEntitySelectionContext();

	return (
		<>
			{!disableCommitSelector && context.validProject ? (
				<SuspendingErrorBoundary>
					<CommitSelector
						initialCommit={context.selectedCommit}
						onChange={context.setCommit}
						currentProject={context.selectedProject}
					/>
					<BranchSelectorWarning />
				</SuspendingErrorBoundary>
			) : null}

			<PathSelectionInput />
			<br />
			<FileList forbiddenUniformPathTypes={forbiddenUniformPathTypes} />
			<ModalActionButtons>
				<Button
					primary
					content="OK"
					className="!w-40"
					data-testid="path-selection-modal-ok"
					disabled={context.containsError || (context.projectsSelectable && !context.validProject)}
					onClick={() => {
						onSave(context.selectedProject, context.selectedPath, context.selectedCommit);
						onClose();
					}}
				/>
				<Button content="Cancel" onClick={onClose} className="!w-40" />
			</ModalActionButtons>
		</>
	);
}

/**
 * Input element displaying the currently selected path. Also provides the possibility to manually change the path, as
 * well as validation and displaying of any errors.
 */
function PathSelectionInput(): JSX.Element {
	const context = usePathEntitySelectionContext();

	let pathString = context.livePath.toString();
	if (context.projectsSelectable) {
		if (StringUtils.isEmptyOrWhitespace(context.selectedProject)) {
			pathString = '';
		} else if (!context.validProject && context.selectedPath.isEmpty()) {
			pathString = context.selectedProject;
		} else {
			pathString = context.selectedProject + '/' + StringUtils.stripPrefix(context.livePath.toString(), '/');
		}
	}

	let label = 'Path';
	if (context.projectsSelectable && !context.validProject) {
		label = 'Project';
	}

	const invalidPath = !context.isAllowed(pathString);

	return (
		<Input
			className="selected-path"
			type="text"
			label={label}
			size="small"
			value={pathString}
			fluid
			error={context.containsError || invalidPath}
			onChange={e => {
				if (context.projectsSelectable) {
					const projectAndPath = ProjectAndUniformPath.parse(e.target.value);
					context.setProject(projectAndPath.getProject());
					context.setPathDebounced(projectAndPath.getUniformPath());
				} else {
					context.setPathDebounced(new UniformPath(e.target.value));
				}
			}}
		/>
	);
}

/** Wrapper to track the current status of the process of fetching data. */
export type FileListQuery = {
	isLoading: boolean;
	isError: boolean;
	data: FileListEntry[];
};

/** Empty file list query result */
export const FILE_LIST_QUERY_EMPTY: FileListQuery = { isLoading: false, isError: false, data: [] };

/** Erroneous file list query result */
export const FILE_LIST_QUERY_ERROR: FileListQuery = { isLoading: false, isError: true, data: [] };

/** File list query result is still pending. */
export const FILE_LIST_QUERY_LOADING: FileListQuery = { isLoading: true, isError: false, data: [] };

/** Creates FileListQueryResult for the given data */
export function fileListQueryData(data: FileListEntry[]): FileListQuery {
	return { isLoading: false, isError: false, data };
}

/** Type as a wrapper for all necessary information for an entry in the file list */
export type FileListEntry = {
	name: string;
	project?: string;
	path: UniformPath;
	isContainer: boolean;
	icon: JSX.Element;
};

/** Selectable list of all possible entries for the currently selected path/project. */
function FileList({ forbiddenUniformPathTypes }: FileListProps): JSX.Element {
	const context = usePathEntitySelectionContext();

	let entries = context.useEntries(context.selectedProject!, context.selectedPath, context.selectedCommit, true);
	const containsError = entries.isError;

	const setError = context.setError;
	useEffect(() => {
		setError(containsError);
	}, [setError, containsError]);

	entries = useTextCompletionForEntries(context, entries);

	const isFile = (entries.data.length === 0 && !context.containsError) || false;

	let currentPath = context.selectedPath.getPath();
	if (!StringUtils.isEmptyOrWhitespace(context.selectedProject)) {
		currentPath = context.selectedProject + '/' + currentPath;
	}
	if (entries.isLoading) {
		// Dimmer currently necessary because a normal loader is not visible in a modal due to a bug
		// https://github.com/Semantic-Org/Semantic-UI-React/issues/3133
		return (
			<Dimmer active inverted>
				<Loader />
			</Dimmer>
		);
	}

	return (
		<div className="file-list-container" data-path={currentPath}>
			<List selection>
				{!context.disableDirNavigation &&
					!isRoot(context.selectedProject, context.selectedPath, context.projectsSelectable) && (
						<List.Item
							className={styles.listEntry}
							onClick={() => {
								if (context.selectedPath.isProjectRoot()) {
									context.setProject('');
								} else {
									context.setPath(getParent(context.selectedPath));
								}
							}}
							icon={<Icon name="folder" />}
							content=".."
						/>
					)}
				{entries.data
					.filter(entry => !entry.path.isAnyOfTypes(forbiddenUniformPathTypes))
					.map(entry => (
						<List.Item
							className={styles.listEntry}
							key={entry.project + '/' + entry.path.getPath()}
							onClick={() => {
								context.setPath(entry.path);
								context.setProject(entry.project);
							}}
							icon={entry.icon}
							content={entry.name}
						/>
					))}
			</List>
			{isFile ? (
				<Message>
					<Icon name="file" />
					The current path is a file
				</Message>
			) : null}
		</div>
	);
}

/**
 * Fetches the entries for the parent path of the currently selected project and path and filters the resulting entries
 * by prefix matching them with the current path or project if the path is empty.
 *
 * Enables the path input to be used as a search bar for selecting a project or a path.
 *
 * Any queries for this function are only done in case the currently selected project or path is invalid, which would be
 * the case if you manually type in half a path, for example.
 */
function useTextCompletionForEntries(context: ExposedPathEntitySelectionContext, entries: FileListQuery) {
	const containsError = entries.isError;
	const projectHasChanged = context.selectedPath.isProjectRoot();

	let parentProject = context.selectedProject || '';
	if (projectHasChanged) {
		parentProject = '';
	}

	const parentEntries = context.useEntries(
		parentProject,
		getParent(context.selectedPath),
		context.selectedCommit,
		containsError
	);

	if (containsError && !parentEntries.isError) {
		parentEntries.data = parentEntries.data.filter(entry => {
			if (projectHasChanged) {
				return entry.project?.startsWith(context.selectedProject || '');
			} else {
				return entry.path.getBasename().startsWith(context.selectedPath.getBasename());
			}
		});

		return parentEntries;
	}

	return entries;
}

/** Gets the parent of the given path or returns path if it already is the root path. */
function getParent(path: UniformPath): UniformPath {
	if (path.isProjectRoot()) {
		return path;
	}
	return path.getParentPath();
}

/**
 * Checks if the given path is the root path. If the path "/", but projects are selectable and a project is given then
 * it is not a root path
 */
function isRoot(project: string | undefined, path: UniformPath, projectsSelectable: boolean | undefined): boolean {
	if (!projectsSelectable) {
		return path.isProjectRoot();
	}
	return StringUtils.isEmptyOrWhitespace(project) && path.isProjectRoot();
}

/** IDs of the projects to which the user has access. */
export function useProjectIds(): string[] | undefined {
	// We should probably use "useProjectInfos()" instead, but this would require access to the perspective context,
	// which is probably impossible to hand through the widget spaghetti code right now without touching about 50 files
	const client = useTeamscaleServiceClient();
	return useSuspenseQuery({
		queryKey: ['project-ids'],
		queryFn: () => client.getPrimaryProjectIds()
	}).data;
}

function BranchSelectorWarning(): JSX.Element | null {
	const { showBranchSelectorWarning } = usePathEntitySelectionContext();
	if (!showBranchSelectorWarning) {
		return null;
	}
	return (
		<Message warning>
			You have changed the branch in the branch selector. Note that this value will be used only while selecting
			the path, but will not be stored. The view always uses the branch selector from the top bar.
		</Message>
	);
}
