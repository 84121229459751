import type { GetFindingsQueryParams } from 'api/ApiDefinition';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { ViewDescriptor } from 'ts/base/view/ViewDescriptor';
import { linkTo } from 'ts/commons/links/LinkTo';
import { EDeltaView } from 'ts/perspectives/delta/EDeltaView';
import { type EBlacklistingOptionEntry } from 'typedefs/EBlacklistingOption';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/**
 * Returns a URL to the delta perspective.
 *
 * @param project The project for which to show the perspective.
 * @param uniformPath The uniform path for which to show the view or null if not required.
 * @param params
 * @param params.viewName The subview to navigate to on the delta perspective.
 * @param params.startTimestamp The start timestamp.
 * @param params.endTimestamp The end timestamp.
 * @param params.mergeMode Whether to display the delta perspective in merge delta mode.
 * @param params.isSpecItemChurn Whether the churn contains spec item findings only.
 * @param params.group The user group to filter the delta to.
 * @param params.kioskMode If given and true, the dashboard will be shown in kiosk mode.
 */
export function delta(
	project: string,
	uniformPath: string | undefined,
	params: {
		viewName: ViewDescriptor;
		startTimestamp?: UnresolvedCommitDescriptor;
		endTimestamp?: UnresolvedCommitDescriptor | undefined | null;
		mergeMode?: boolean;
		isSpecItemChurn?: boolean;
		group?: string;
		kioskMode?: boolean;
	}
) {
	return linkTo(ETeamscalePerspective.DELTA, params.viewName, project, uniformPath, {
		from: params.startTimestamp,
		to: params.endTimestamp,
		showMergeFindings: params.mergeMode || false,
		isSpecItemDelta: params.isSpecItemChurn || false,
		kioskViewMode: params.kioskMode,
		group: params.group
	});
}

/** The parameters that can be passed to a view that performs findings filtering via the URL. */
export type DeltaFindingFilterOptions = Pick<
	GetFindingsQueryParams,
	| 'filter'
	| 'assessment-filters'
	| 'included-paths'
	| 'excluded-paths'
	| 'guideline-rules'
	| 'regex'
	| 'exclude-regex'
	| 'blacklistRationale'
	| 'guideline'
	| 'added-to-task'
	| 'invert'
> & {
	'filter-option': EBlacklistingOptionEntry;
};

/**
 * Returns a URL to the delta > findings view.
 *
 * @param project The project for which to show the perspective.
 * @param uniformPath The uniform path for which to show the view or null if not required.
 * @param params
 * @param params.startTimestamp The start timestamp.
 * @param params.endTimestamp The end timestamp.
 * @param params.mergeMode Whether to display the delta perspective in merge delta mode.
 * @param params.isSpecItemChurn Whether the churn contains spec item findings only.
 * @param params.group The user group to filter the delta to.
 * @param params.kioskMode If given and true, the dashboard will be shown in kiosk mode.
 * @param params.findingFilter The finding filter options that should be applied.
 */
export function deltaFindings(
	project: string,
	uniformPath: string | undefined,
	params: {
		startTimestamp?: UnresolvedCommitDescriptor;
		endTimestamp?: UnresolvedCommitDescriptor | undefined | null;
		mergeMode?: boolean;
		isSpecItemChurn?: boolean;
		group?: string;
		kioskMode?: boolean;
		findingFilter?: DeltaFindingFilterOptions;
	}
) {
	return linkTo(ETeamscalePerspective.DELTA, EDeltaView.FINDING, project, uniformPath, {
		from: params.startTimestamp,
		to: params.endTimestamp,
		showMergeFindings: params.mergeMode || false,
		isSpecItemDelta: params.isSpecItemChurn || false,
		kioskViewMode: params.kioskMode,
		group: params.group,
		...params.findingFilter
	});
}
