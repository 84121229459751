import { QUERY } from 'api/Query';
import { type JSX, useState } from 'react';
import { ArrayUtils } from 'ts/commons/ArrayUtils';
import { GitTagDropdown } from 'ts/commons/time/components/git_tags/GitTagDropdown';
import { GitTagProjectDropdown } from 'ts/commons/time/components/git_tags/GitTagProjectDropdown';
import { GitTagRepositoryDropdown } from 'ts/commons/time/components/git_tags/GitTagRepositoryDropdown';
import { useTimePickerContext } from 'ts/commons/time/components/TimePickerContext';
import { useControlledSelectValue } from 'ts/commons/time/components/TimePickerUtils';
import { EPointInTimeType } from 'ts/commons/time/EPointInTimeType';
import { TimeUtils } from 'ts/commons/time/TimeUtils';
import type { TypedPointInTime } from 'ts/commons/time/TypedPointInTime';
import type { GitTag } from 'typedefs/GitTag';

/** A component for selecting a git tag. */
export function GitTagPicker(): JSX.Element | null {
	const { projects } = useTimePickerContext();
	const [userSelectedProject, setUserSelectedProject] = useState<string | null>(null);
	const project = userSelectedProject ?? ArrayUtils.getOnlyElementOrNull(projects);
	const repositories = useRepositories(project);
	const [userSelectedRepository, setUserSelectedRepository] = useState<string | null>(null);
	const repository = userSelectedRepository ?? ArrayUtils.getOnlyElementOrNull(repositories.data);
	const defaultValue = useTimePickerContext().defaultValue;
	const [selectedGitTag, setSelectedGitTag] = useControlledSelectValue('git-tag', extractPointInTime, () =>
		setInitialValue(defaultValue)
	);
	return (
		<>
			<GitTagProjectDropdown
				projects={projects}
				selectedProject={project}
				setSelectedProject={setUserSelectedProject}
			/>
			<GitTagRepositoryDropdown
				repositories={repositories}
				selectedRepository={repository}
				setSelectedRepository={setUserSelectedRepository}
			/>
			<GitTagDropdown
				project={project}
				repository={repository}
				selectedGitTag={selectedGitTag}
				setSelectedGitTag={setSelectedGitTag}
			/>
		</>
	);
}

function useRepositories(project: string | null) {
	return QUERY.getGitConnectorIds(project ?? '').useQuery({
		enabled: !!project,
		throwOnError: false
	});
}

function extractPointInTime(value: string): TypedPointInTime | null {
	const tag: GitTag = JSON.parse(value);
	return TimeUtils.gitTag(tag);
}

function setInitialValue(defaultValue: TypedPointInTime | null): string | undefined {
	if (defaultValue !== null && defaultValue.type === EPointInTimeType.GIT_TAG) {
		return JSON.stringify(defaultValue.value);
	}
	return undefined;
}
