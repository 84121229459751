import { MUTATION } from 'api/Mutation';
import type { Dispatch } from 'react';
import { Button, Message } from 'semantic-ui-react';
import { useTeamscaleInfo } from 'ts/base/hooks/TeamscaleInfoHook';
import { useHashState } from 'ts/base/hooks/UseHashState';
import { ReactUtils } from 'ts/base/ReactUtils';
import { ViewPage } from 'ts/commons/components/ViewPage';
import { StringUtils } from 'ts/commons/StringUtils';
import { ActiveUsersModal, SectionTable } from 'ts/perspectives/system/SystemInfoView';
import type { License } from 'typedefs/License';
import type { LicenseInfo } from 'typedefs/LicenseInfo';
import type { SystemInfoSection } from 'typedefs/SystemInfoSection';

/** Extended version of License. */
type ExtendedLicense = License & {
	numUsersFormatted: string;
};

/** Inspect license information. */
export default function SystemLicenseView() {
	const [showHint, setShowHint] = useHashState('showHint', false);
	const topBarButtons = (
		<>
			<ActiveUsersModal />
			<ReloadLicenseButton setShowHint={setShowHint} />
		</>
	);
	return (
		<ViewPage title="License Information" buttons={topBarButtons} variant="small-max-width">
			{showHint ? <RestartHintMessage /> : null}
			<SystemLicenseErrors />
			<LicenseInfoTable />
		</ViewPage>
	);
}

/** Props for {@link ReloadLicenseButton} */
type ReloadLicenseButtonProps = {
	setShowHint: Dispatch<boolean>;
};

function ReloadLicenseButton({ setShowHint }: ReloadLicenseButtonProps) {
	const licenseInfoMutation = MUTATION.getLicenseInfo.useMutation({
		onSuccess: () => {
			setShowHint(true);
			void ReactUtils.queryClient.invalidateQueries();
		}
	});
	return (
		<Button
			icon="sync alternate"
			content="Reload license"
			loading={licenseInfoMutation.isPending}
			onClick={() => licenseInfoMutation.mutate({ queryParams: { reload: true } })}
		/>
	);
}

/** Shows system license errors. */
function SystemLicenseErrors() {
	const errors = useTeamscaleInfo().licenseInfo.errors;
	if (errors.length === 0) {
		return null;
	}
	return (
		<Message error>
			<h4>License Error</h4>
			<ul>
				{errors.map(error => (
					<li key={error}>{error}</li>
				))}
			</ul>
		</Message>
	);
}

/** Shows license information. */
function LicenseInfoTable() {
	const licenseInfo = useTeamscaleInfo().licenseInfo;
	const extendedLicense = formatLicense(licenseInfo);
	if (!extendedLicense) {
		return null;
	}
	const numericValue = -1;
	const section: SystemInfoSection = {
		caption: '',
		entries: [
			{ name: 'Licensee', value: extendedLicense.licensee, numericValue },
			{ name: 'Valid until', value: extendedLicense.validTo, numericValue },
			{ name: 'Number of Users', value: extendedLicense.numUsers + '', numericValue },
			{ name: 'License Format Version', value: extendedLicense.licenseVersion + '', numericValue },
			{ name: 'Location', value: licenseInfo.licenseLocation + '', numericValue }
		]
	};
	return <SectionTable section={section} />;
}

/** Shows the warning to restart Teamscale if necessary. */
function RestartHintMessage() {
	return (
		<Message warning>
			<h5>
				{'When installing a license after the first start of Teamscale or installing a new license with additional\n' +
					'\t\t\t\tfeatures, Teamscale needs to be restarted for the changes to take effect.'}
			</h5>
		</Message>
	);
}

/** Augments the license (if any) with fields for display in the UI. */
function formatLicense(licenseInfo: LicenseInfo): ExtendedLicense | null {
	if (!licenseInfo.license) {
		return null;
	}
	licenseInfo.license.licensee = StringUtils.decodeUTF8(licenseInfo.license.licensee);
	return wrapLicenseIfExists(licenseInfo.license);
}

/** Extends the license with human-readable fields. */
export function wrapLicenseIfExists(license: License | null | undefined): ExtendedLicense | null {
	if (license !== undefined && license !== null) {
		return wrapLicense(license);
	}
	return null;
}

function wrapLicense(license: License): ExtendedLicense {
	let numUsersFormatted = String(license.numUsers);
	if (license.numUsers > 99999999) {
		numUsersFormatted = 'Unlimited';
	}
	return { ...license, numUsersFormatted };
}
