/**
 * Teamscale REST API
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * Do not edit the class manually.
 */

import type { QueryOperation } from './Query';
import type { MutationOperation } from './Mutation';
import type { UnresolvedCommitDescriptor } from 'custom-types/UnresolvedCommitDescriptor';
import type { ABAPLintConfigurationFileImportSummary } from 'typedefs/ABAPLintConfigurationFileImportSummary';
import type { AbapFileMetadata } from 'typedefs/AbapFileMetadata';
import type { AccessKeyDTO } from 'typedefs/AccessKeyDTO';
import type { AnalysisProfile } from 'typedefs/AnalysisProfile';
import type { AnalysisProfileEditVersion } from 'typedefs/AnalysisProfileEditVersion';
import type { AnalysisProfileHistoryVersionComparison } from 'typedefs/AnalysisProfileHistoryVersionComparison';
import type { AnalysisProfileMetadata } from 'typedefs/AnalysisProfileMetadata';
import type { AnalysisProfileUsageInfoWithProjects } from 'typedefs/AnalysisProfileUsageInfoWithProjects';
import type { AnalysisProfileWithProjects } from 'typedefs/AnalysisProfileWithProjects';
import type { AnalysisProgressDescriptor } from 'typedefs/AnalysisProgressDescriptor';
import type { AppInstallationInfo } from 'typedefs/AppInstallationInfo';
import type { ArchitectureInfoWithUniformPath } from 'typedefs/ArchitectureInfoWithUniformPath';
import type { AuthenticatorMappingReply } from 'typedefs/AuthenticatorMappingReply';
import type { AvatarData } from 'typedefs/AvatarData';
import type { Backup } from 'typedefs/Backup';
import type { BackupExportStatus } from 'typedefs/BackupExportStatus';
import type { BackupImportStatus } from 'typedefs/BackupImportStatus';
import type { BackupJobSummary } from 'typedefs/BackupJobSummary';
import type { BaselineInfo } from 'typedefs/BaselineInfo';
import type { BenchmarkResult } from 'typedefs/BenchmarkResult';
import type { BranchesInfo } from 'typedefs/BranchesInfo';
import type { CancelTriggerRequestBody } from 'typedefs/CancelTriggerRequestBody';
import type { ClangTidyOutsourcedAnalysisRequestParameters } from 'typedefs/ClangTidyOutsourcedAnalysisRequestParameters';
import type { ClangTidyResultsTransport } from 'typedefs/ClangTidyResultsTransport';
import type { CodeSearchResultsWrapper } from 'typedefs/CodeSearchResultsWrapper';
import type { CommitAlerts } from 'typedefs/CommitAlerts';
import type { CommitDescriptor } from 'typedefs/CommitDescriptor';
import type { CommitTreeNodeData } from 'typedefs/CommitTreeNodeData';
import type { CommitterDisplayName } from 'typedefs/CommitterDisplayName';
import type { ConfigurationTemplate } from 'typedefs/ConfigurationTemplate';
import type { ConnectorConfiguration } from 'typedefs/ConnectorConfiguration';
import type { ConnectorDescriptorTransport } from 'typedefs/ConnectorDescriptorTransport';
import type { ContainerInfo } from 'typedefs/ContainerInfo';
import type { CopyrightLicenseInfo } from 'typedefs/CopyrightLicenseInfo';
import type { CoverageSourceInfo } from 'typedefs/CoverageSourceInfo';
import type { Credentials } from 'typedefs/Credentials';
import type { DashboardDescriptor } from 'typedefs/DashboardDescriptor';
import type { DashboardTemplateDescriptor } from 'typedefs/DashboardTemplateDescriptor';
import type { DeclarationOfAccessibilityContext } from 'typedefs/DeclarationOfAccessibilityContext';
import type { DependencyEntry } from 'typedefs/DependencyEntry';
import type { DetailedInstanceComparisonValue } from 'typedefs/DetailedInstanceComparisonValue';
import type { DetailedJavaScriptErrorLog } from 'typedefs/DetailedJavaScriptErrorLog';
import type { DetailedServiceLog } from 'typedefs/DetailedServiceLog';
import type { DetailedWorkerLog } from 'typedefs/DetailedWorkerLog';
import type { DiffDescription } from 'typedefs/DiffDescription';
import type { DotNetVersionInfo } from 'typedefs/DotNetVersionInfo';
import type { EAnalysisStateEntry } from 'typedefs/EAnalysisState';
import type { EAnalysisToolEntry } from 'typedefs/EAnalysisTool';
import type { EArchitectureFormatEntry } from 'typedefs/EArchitectureFormat';
import type { EAuditExportLanguageEntry } from 'typedefs/EAuditExportLanguage';
import type { EAuditExportTableEntry } from 'typedefs/EAuditExportTable';
import type { EAuthenticationToolEntry } from 'typedefs/EAuthenticationTool';
import type { EBasicPermissionEntry } from 'typedefs/EBasicPermission';
import type { EBasicPermissionScopeEntry } from 'typedefs/EBasicPermissionScope';
import type { EBlacklistingOptionEntry } from 'typedefs/EBlacklistingOption';
import type { EClangTidyOutsourcedAnalysisStatusEntry } from 'typedefs/EClangTidyOutsourcedAnalysisStatus';
import type { ECommitAttributeEntry } from 'typedefs/ECommitAttribute';
import type { ECommitTypeEntry } from 'typedefs/ECommitType';
import type { EDeleteModeEntry } from 'typedefs/EDeleteMode';
import type { EExternalDataExportGranularityEntry } from 'typedefs/EExternalDataExportGranularity';
import type { EExternalDataExportTargetEntry } from 'typedefs/EExternalDataExportTarget';
import type { EFindingBlacklistOperationEntry } from 'typedefs/EFindingBlacklistOperation';
import type { EFindingBlacklistTypeEntry } from 'typedefs/EFindingBlacklistType';
import type { EFindingEnablementEntry } from 'typedefs/EFindingEnablement';
import type { EFindingsExportFormatEntry } from 'typedefs/EFindingsExportFormat';
import type { EInstanceComparisonStatusEntry } from 'typedefs/EInstanceComparisonStatus';
import type { EInstanceComparisonTypeEntry } from 'typedefs/EInstanceComparisonType';
import type { ELanguageEntry } from 'typedefs/ELanguage';
import type { ELogLevelEntry } from 'typedefs/ELogLevel';
import type { EMergeRequestStatusEntry } from 'typedefs/EMergeRequestStatus';
import type { EReportFormatEntry } from 'typedefs/EReportFormat';
import type { ERepositoryConnectorEntry } from 'typedefs/ERepositoryConnector';
import type { ERepresentationEntry } from 'typedefs/ERepresentation';
import type { EResourceTypeEntry } from 'typedefs/EResourceType';
import type { ESchedulerCommandEntry } from 'typedefs/ESchedulerCommand';
import type { ESchedulingModeEntry } from 'typedefs/ESchedulingMode';
import type { ESonarLintIndependentAnalysisStatusEntry } from 'typedefs/ESonarLintIndependentAnalysisStatus';
import type { ESortOrderEntry } from 'typedefs/ESortOrder';
import type { ETaskResolutionEntry } from 'typedefs/ETaskResolution';
import type { ETaskSortPropertyEntry } from 'typedefs/ETaskSortProperty';
import type { ETaskStatusEntry } from 'typedefs/ETaskStatus';
import type { ETestPrioritizationStrategyEntry } from 'typedefs/ETestPrioritizationStrategy';
import type { ETokenClassEntry } from 'typedefs/ETokenClass';
import type { ETrafficLightColorEntry } from 'typedefs/ETrafficLightColor';
import type { ETypeEntry } from 'typedefs/EType';
import type { EventAnnouncement } from 'typedefs/EventAnnouncement';
import type { ExceptionsTree } from 'typedefs/ExceptionsTree';
import type { ExtendedFindingsWithCount } from 'typedefs/ExtendedFindingsWithCount';
import type { ExtendedMergeRequest } from 'typedefs/ExtendedMergeRequest';
import type { ExtendedMergeRequestsInfo } from 'typedefs/ExtendedMergeRequestsInfo';
import type { ExtendedRepositoryLogEntry } from 'typedefs/ExtendedRepositoryLogEntry';
import type { ExternalAnalysisGroup } from 'typedefs/ExternalAnalysisGroup';
import type { ExternalAnalysisPartitionInfo } from 'typedefs/ExternalAnalysisPartitionInfo';
import type { ExternalCredentialsData } from 'typedefs/ExternalCredentialsData';
import type { ExternalFindingsDescription } from 'typedefs/ExternalFindingsDescription';
import type { ExternalInstanceComparisonSnapshotDto } from 'typedefs/ExternalInstanceComparisonSnapshotDto';
import type { ExternalStorageBackendData } from 'typedefs/ExternalStorageBackendData';
import type { ExternalToolIssueCustomFieldResult } from 'typedefs/ExternalToolIssueCustomFieldResult';
import type { FileGroup } from 'typedefs/FileGroup';
import type { FilteredTreeMapWrapper } from 'typedefs/FilteredTreeMapWrapper';
import type { FindingBlacklistInfo } from 'typedefs/FindingBlacklistInfo';
import type { FindingBlacklistRequestBody } from 'typedefs/FindingBlacklistRequestBody';
import type { FindingChurnCount } from 'typedefs/FindingChurnCount';
import type { FindingDelta } from 'typedefs/FindingDelta';
import type { FindingResolutionResult } from 'typedefs/FindingResolutionResult';
import type { FindingTypeDescription } from 'typedefs/FindingTypeDescription';
import type { FindingsSummaryInfo } from 'typedefs/FindingsSummaryInfo';
import type { FindingsTreemapWrapper } from 'typedefs/FindingsTreemapWrapper';
import type { FormattedTokenElementInfo } from 'typedefs/FormattedTokenElementInfo';
import type { GetLinkRolesResponse } from 'typedefs/GetLinkRolesResponse';
import type { GetTaskToFindingMappingDefaultResponseInner } from 'typedefs/GetTaskToFindingMappingDefaultResponseInner';
import type { GitTag } from 'typedefs/GitTag';
import type { GlobalRole } from 'typedefs/GlobalRole';
import type { IncludeExcludePatterns } from 'typedefs/IncludeExcludePatterns';
import type { IndicatorsAndGroups } from 'typedefs/IndicatorsAndGroups';
import type { InstanceComparisonComputationMetadata } from 'typedefs/InstanceComparisonComputationMetadata';
import type { InstanceComparisonResultBase } from 'typedefs/InstanceComparisonResultBase';
import type { InstanceComparisonSnapshotDto } from 'typedefs/InstanceComparisonSnapshotDto';
import type { JavaScriptError } from 'typedefs/JavaScriptError';
import type { JobDescriptor } from 'typedefs/JobDescriptor';
import type { JobQueueCountWithDelayedJobs } from 'typedefs/JobQueueCountWithDelayedJobs';
import type { Language } from 'typedefs/Language';
import type { LateExternalUploadProcessingInfo } from 'typedefs/LateExternalUploadProcessingInfo';
import type { LicenseInfo } from 'typedefs/LicenseInfo';
import type { LineCoverageInfo } from 'typedefs/LineCoverageInfo';
import type { LoadProfile } from 'typedefs/LoadProfile';
import type { LoginContext } from 'typedefs/LoginContext';
import type { MergeRequestDelta } from 'typedefs/MergeRequestDelta';
import type { MethodHistoryEntriesWrapper } from 'typedefs/MethodHistoryEntriesWrapper';
import type { MethodLocation } from 'typedefs/MethodLocation';
import type { MetricDirectorySchema } from 'typedefs/MetricDirectorySchema';
import type { MetricSchemaChangeEntry } from 'typedefs/MetricSchemaChangeEntry';
import type { MetricTablePage } from 'typedefs/MetricTablePage';
import type { MetricThresholdConfiguration } from 'typedefs/MetricThresholdConfiguration';
import type { MetricTrendEntry } from 'typedefs/MetricTrendEntry';
import type { MetricsForThresholdProfile } from 'typedefs/MetricsForThresholdProfile';
import type { OpenIdEndpointInfo } from 'typedefs/OpenIdEndpointInfo';
import type { OptionDescriptor } from 'typedefs/OptionDescriptor';
import type { OutlineElement } from 'typedefs/OutlineElement';
import type { PairListStringFileSummaryInfoRecord } from 'typedefs/PairListStringFileSummaryInfoRecord';
import type { ParseLogEntry } from 'typedefs/ParseLogEntry';
import type { ParseLogOverviewEntry } from 'typedefs/ParseLogOverviewEntry';
import type { PartitionInfo } from 'typedefs/PartitionInfo';
import type { PasswordChangeRequest } from 'typedefs/PasswordChangeRequest';
import type { PermissionLookupEBasicPermission } from 'typedefs/PermissionLookupEBasicPermission';
import type { PerspectiveContext } from 'typedefs/PerspectiveContext';
import type { PreviousNextSiblings } from 'typedefs/PreviousNextSiblings';
import type { PrioritizableTest } from 'typedefs/PrioritizableTest';
import type { PrioritizableTestCluster } from 'typedefs/PrioritizableTestCluster';
import type { ProcessInformation } from 'typedefs/ProcessInformation';
import type { ProfilerConfiguration } from 'typedefs/ProfilerConfiguration';
import type { ProfilerInfo } from 'typedefs/ProfilerInfo';
import type { ProfilerRegistration } from 'typedefs/ProfilerRegistration';
import type { ProjectBranchingConfiguration } from 'typedefs/ProjectBranchingConfiguration';
import type { ProjectConfiguration } from 'typedefs/ProjectConfiguration';
import type { ProjectDescription } from 'typedefs/ProjectDescription';
import type { ProjectInfo } from 'typedefs/ProjectInfo';
import type { ProjectLogLevelFrequencies } from 'typedefs/ProjectLogLevelFrequencies';
import type { ProjectNotificationRules } from 'typedefs/ProjectNotificationRules';
import type { ProjectRole } from 'typedefs/ProjectRole';
import type { ProjectSchedulingFilter } from 'typedefs/ProjectSchedulingFilter';
import type { QualityArtifactParameters } from 'typedefs/QualityArtifactParameters';
import type { QualityReport } from 'typedefs/QualityReport';
import type { QueryParserFailure } from 'typedefs/QueryParserFailure';
import type { RedundantLiteralInfo } from 'typedefs/RedundantLiteralInfo';
import type { ReportSlideData } from 'typedefs/ReportSlideData';
import type { RepositoryLogEntryAggregate } from 'typedefs/RepositoryLogEntryAggregate';
import type { RepositoryLogFileHistoryEntry } from 'typedefs/RepositoryLogFileHistoryEntry';
import type { RepositorySummary } from 'typedefs/RepositorySummary';
import type { ResolvedTask } from 'typedefs/ResolvedTask';
import type { Retrospective } from 'typedefs/Retrospective';
import type { RiskMethods } from 'typedefs/RiskMethods';
import type { RoleChange } from 'typedefs/RoleChange';
import type { RoleSchemaData } from 'typedefs/RoleSchemaData';
import type { RolesWithAssignments } from 'typedefs/RolesWithAssignments';
import type { RulesContainer } from 'typedefs/RulesContainer';
import type { RunningProfilerInfo } from 'typedefs/RunningProfilerInfo';
import type { RunningProfilersResult } from 'typedefs/RunningProfilersResult';
import type { SamlAuthenticationOption } from 'typedefs/SamlAuthenticationOption';
import type { SamlServiceProviderConfiguration } from 'typedefs/SamlServiceProviderConfiguration';
import type { SelectedTest } from 'typedefs/SelectedTest';
import type { ShortLogResponseShortInteractionLog } from 'typedefs/ShortLogResponseShortInteractionLog';
import type { ShortLogResponseShortJavaScriptErrorLog } from 'typedefs/ShortLogResponseShortJavaScriptErrorLog';
import type { ShortLogResponseShortLogEntryBase } from 'typedefs/ShortLogResponseShortLogEntryBase';
import type { ShortLogResponseShortServiceLog } from 'typedefs/ShortLogResponseShortServiceLog';
import type { ShortLogResponseShortWorkerLog } from 'typedefs/ShortLogResponseShortWorkerLog';
import type { SonarLintFindingTransport } from 'typedefs/SonarLintFindingTransport';
import type { SonarLintIndependentAnalysisRequestParameters } from 'typedefs/SonarLintIndependentAnalysisRequestParameters';
import type { SonarQualityProfileImportSummary } from 'typedefs/SonarQualityProfileImportSummary';
import type { SpecItemReferenceElement } from 'typedefs/SpecItemReferenceElement';
import type { SpecItemReferenceMapping } from 'typedefs/SpecItemReferenceMapping';
import type { SpecItemRelationReference } from 'typedefs/SpecItemRelationReference';
import type { Stacktrace } from 'typedefs/Stacktrace';
import type { SubjectRoleAssignments } from 'typedefs/SubjectRoleAssignments';
import type { SufficientRamResponse } from 'typedefs/SufficientRamResponse';
import type { SupportRequestData } from 'typedefs/SupportRequestData';
import type { SwiftLintConfigurationImportSummary } from 'typedefs/SwiftLintConfigurationImportSummary';
import type { SystemProcessInfo } from 'typedefs/SystemProcessInfo';
import type { Task } from 'typedefs/Task';
import type { TaskWithDetailedFindings } from 'typedefs/TaskWithDetailedFindings';
import type { TasksWithCount } from 'typedefs/TasksWithCount';
import type { TeamscaleIssueStatus } from 'typedefs/TeamscaleIssueStatus';
import type { TeamscaleUploadWizardInput } from 'typedefs/TeamscaleUploadWizardInput';
import type { TeamscaleVersionContainer } from 'typedefs/TeamscaleVersionContainer';
import type { TestCoverageOverlayData } from 'typedefs/TestCoverageOverlayData';
import type { TestGapTreeMapWrapper } from 'typedefs/TestGapTreeMapWrapper';
import type { TestListDescriptor } from 'typedefs/TestListDescriptor';
import type { TestMinimizationJobRun } from 'typedefs/TestMinimizationJobRun';
import type { TestMinimizationRequestOptions } from 'typedefs/TestMinimizationRequestOptions';
import type { TestMinimizationResult } from 'typedefs/TestMinimizationResult';
import type { TestQueryResult } from 'typedefs/TestQueryResult';
import type { TestWithClusterId } from 'typedefs/TestWithClusterId';
import type { TgaTableEntry } from 'typedefs/TgaTableEntry';
import type { TokenElementChurnWithOriginInfo } from 'typedefs/TokenElementChurnWithOriginInfo';
import type { TrackedFinding } from 'typedefs/TrackedFinding';
import type { TreeMapNode } from 'typedefs/TreeMapNode';
import type { TriggerGraphNode } from 'typedefs/TriggerGraphNode';
import type { UsageDataPreview } from 'typedefs/UsageDataPreview';
import type { UsageDataReportingOption } from 'typedefs/UsageDataReportingOption';
import type { UserActivity } from 'typedefs/UserActivity';
import type { UserBatchOperation } from 'typedefs/UserBatchOperation';
import type { UserGroup } from 'typedefs/UserGroup';
import type { UserResolvedDashboardDescriptor } from 'typedefs/UserResolvedDashboardDescriptor';
import type { UserResolvedFindingBlacklistInfo } from 'typedefs/UserResolvedFindingBlacklistInfo';
import type { UserResolvedQualityReport } from 'typedefs/UserResolvedQualityReport';
import type { UserResolvedSpecItem } from 'typedefs/UserResolvedSpecItem';
import type { UserResolvedTeamscaleIssue } from 'typedefs/UserResolvedTeamscaleIssue';
import type { UserWithActivity } from 'typedefs/UserWithActivity';
import type { ValidationErrorResponseEntity } from 'typedefs/ValidationErrorResponseEntity';
import type { WidgetContext } from 'typedefs/WidgetContext';
import type { WorkerGroupStatus } from 'typedefs/WorkerGroupStatus';

export type AutocompleteIssueIdQueryParams = {
	'search'?: string
};

export type AutocompleteUserGroupQueryParams = {
	'token'?: string, 
	'regex'?: boolean, 
	'max_matches'?: number
};

export type AutocompleteUserNameQueryParams = {
	'token'?: string, 
	'regex'?: boolean, 
	'max_matches'?: number
};

export type ComputeMetricTableQueryParams = {
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number, 
	/**
	 * Name of the field to sort entries by
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to compute the metric table for
	 */
	'uniform-path'?: string, 
	/**
	 * The name of the metric threshold configuration to use
	 */
	'configuration-name': string, 
	/**
	 * Allows to return only the metrics that are in the threshold profile.
	 */
	'limit-to-profile'?: boolean, 
	/**
	 * Allows to limit the considered metrics to the given list.
	 */
	'metrics'?: Array<string>, 
	/**
	 * Allows to specify which partitions should be considered. This only has an effect for test and test execution metrics.
	 */
	'partition'?: Array<string>, 
	/**
	 * Includes data from all partitions.
	 */
	'all-partitions'?: boolean
};

export type CreateBackupBody = {
	/**
	 * Include global data in the backup.
	 */
	'backup-global'?: boolean, 
	/**
	 * The backup path. If this is not set, a new internal path will be generated.
	 */
	'backup-path'?: string, 
	/**
	 * The branch (pattern) for which rules are applied. May be present multiple times.
	 */
	'export-option-branch'?: Array<string>, 
	/**
	 * The start date for export of external data. May be present multiple times.
	 */
	'export-option-date'?: Array<string>, 
	/**
	 * The granularity to apply for export of external data (DISCARD, DAILY, WEEKLY, PRESERVE). May be present multiple times.
	 */
	'export-option-granularity'?: Array<EExternalDataExportGranularityEntry>, 
	/**
	 * The partition (pattern) for which rules are applied. May be present multiple times.
	 */
	'export-option-partition'?: Array<string>, 
	/**
	 * The partition (pattern) for which rules are applied. May be present multiple times.
	 */
	'export-option-project'?: Array<string>, 
	/**
	 * The target to apply for export of external data. May be present multiple times.
	 */
	'export-option-target'?: Array<EExternalDataExportTargetEntry>, 
	/**
	 * Includes all user visible projects in the backup.
	 */
	'include-all-visible-projects'?: boolean, 
	/**
	 * Include information about branch labels associated with Git commits. Default: true.
	 */
	'include-git-labeling-info'?: boolean, 
	/**
	 * Include information about pending sessions for external uploads. Default: true.
	 */
	'include-pending-external-upload-sessions'?: boolean, 
	/**
	 * Include project data in the backup. May be present multiple times.
	 */
	'include-project'?: Array<string>
};

export type CreateComparisonBody = {
	/**
	 * The name of the externally uploaded snapshot. If provided, the remote-snapshot-id must not be provided.
	 */
	'external-snapshot-name'?: string, 
	/**
	 * Access token for the remote instance.
	 */
	'remote-access-token'?: string, 
	/**
	 * The ID of the remote snapshot. May be empty or an incomplete prefix, as long as exactly one remote snapshot is uniquely identified.
	 */
	'remote-snapshot-id'?: string, 
	/**
	 * URL of the remote instance
	 */
	'remote-url'?: string, 
	/**
	 * User name for the remote instance. May be empty, in this case locally logged in user is assumed.
	 */
	'remote-user'?: string
};

export type CreateDashboardFromTemplateQueryParams = {
	/**
	 * ID of the template to use as base for the new dashboard.
	 */
	'template': string, 
	/**
	 * ID of the project to use as datasource for the new dashboard.
	 */
	'project': string, 
	/**
	 * Name of the new dashboard.
	 */
	'dashboard-name': string, 
	/**
	 * Path within the project to use as datasource for the new dashboard.
	 */
	'path'?: string
};

export type CreateOrUpdateBaselineQueryParams = {
	/**
	 * Old baseline name, needed if case of baseline renaming
	 */
	'old-name'?: string
};

export type CreateProjectQueryParams = {
	/**
	 * Indicates whether to copy all data (ext. uploads, finding exclusions, etc.) from an existing project. The value is the project id.
	 */
	'copy-data-from-project'?: string, 
	/**
	 * Indicates whether to skip validation of the project. Can be used to force project creation despite validation errors.
	 */
	'skip-project-validation'?: boolean
};

export type CreateQualityReportQueryParams = {
	/**
	 * The id of the report to be copied
	 */
	'from-template'?: string
};

export type CreateRetrospectiveQueryParams = {
	/**
	 * The id of the retrospective to be copied/overwritten
	 */
	'from-template'?: string
};

export type CreateSessionQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter allows to pass a revision instead of a timestamp.
	 */
	'revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * A message that describes the external analysis, similar to a commit message.
	 */
	'message'?: string, 
	/**
	 * The name of the logical partition to store the results into. All existing data in this partition will be invalidated. A partition typically corresponds to one analysis run, i.e. if there are two independent builds/runs, they must use different partitions.The partition parameter is only required with an auto-create session.
	 */
	'partition'?: string, 
	/**
	 * Whether to move the upload timestamp to right after the last commit.
	 */
	'movetolastcommit'?: boolean
};

export type CreateSnapshotBody = {
	/**
	 * The branch name for which to create the instance comparison snapshot.
	 */
	'branch-name'?: string, 
	/**
	 * Whether a more detailed snapshot should be created (i.e. includes which findings are present). May slow down the snapshot creation and comparison computation depending on instance size.
	 */
	'detailed-snapshot'?: boolean, 
	/**
	 * The timestamp used to determine when to cut off comparison. Useful when one instance has an ongoing analysis, and the other does not.
	 */
	'end-timestamp'?: string, 
	/**
	 * Optional regex pattern to specify which projects should be excluded from the snapshot.
	 */
	'project-exclusion-pattern'?: string, 
	/**
	 * Optional regex pattern to specify which projects should be included in the snapshot.
	 */
	'project-inclusion-pattern'?: string, 
	/**
	 * Access token for the remote instance. May be empty, in this case access token of locally logged in user is assumed.
	 */
	'remote-access-token'?: string, 
	/**
	 * URL of the remote instance
	 */
	'remote-url'?: string, 
	/**
	 * User name for the remote instance. May be empty, in this case locally logged in user is assumed.
	 */
	'remote-user'?: string, 
	/**
	 * Whether to schedule a comparison with a remote instance that uses the same parameters.
	 */
	'schedule-remote-comparison'?: string
};

export type DeleteAllUnprocessedArchitectureCommitsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type DeleteExternalAccountsQueryParams = {
	/**
	 * Skip connector validation for connectors using the account.
	 */
	'skip-connector-validation'?: boolean
};

export type DeleteExternalStorageBackendQueryParams = {
	/**
	 * Force deletion of the external storage backend.
	 */
	'force-deletion'?: boolean
};

export type DeleteGlobalCriticalEventLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteGlobalInteractionLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteGlobalJavascriptErrorLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteGlobalServiceLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteGlobalWorkerLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteJobTestsQueryParams = {
	'deleteMode'?: EDeleteModeEntry
};

export type DeleteProjectCriticalEventLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteProjectInteractionLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteProjectJavascriptErrorLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteProjectServiceLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DeleteProjectWorkerLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type DownloadGlobalCriticalEventLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadGlobalInteractionLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadGlobalJavascriptErrorLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadGlobalServiceLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadGlobalWorkerLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadHeapDumpBody = {
	/**
	 * Whether dead (i.e., unreachable) objects are included in the heap dump.
	 */
	'include-dead-objects'?: boolean
};

export type DownloadProjectCriticalEventLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadProjectInteractionLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadProjectJavascriptErrorLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadProjectServiceLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadProjectWorkerLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean, 
	'maxChars'?: number
};

export type DownloadTestSelectionCsvQueryParams = {
	'timeBudgetMillis'?: number
};

export type EditProjectWithConfigurationQueryParams = {
	/**
	 * Parameter for controlling whether to perform a re-analyze of the project if required when changing the project configuration. Certain connector parameter  changes require the project to be re-analyzed for consistency reasons. This flag indicates that the user agreed for a re-analysis.
	 */
	'reanalyze-if-required'?: boolean, 
	/**
	 * Indicates whether to skip validation of the project. Can be used to force project creation despite validation errors.
	 */
	'skip-project-validation'?: boolean
};

export type EditServerOptionQueryParams = {
	/**
	 * If the option should be saved despite failing validation
	 */
	'save-if-validation-fails'?: boolean
};

export type ExecuteSchedulerCommandBody = {
	'command'?: ESchedulerCommandEntry
};

export type ExportExternalFindingsQueryParams = {
	/**
	 * The group to export, if specified
	 */
	'group-name'?: string
};

export type ExportImpactedTestsAsCsvQueryParams = {
	/**
	 * The baseline commit (exclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline revision (exclusive). If both revision and commit are given, the revision will be preferred.
	 */
	'baseline-revision'?: string, 
	/**
	 * The end revision (inclusive). If both revision and commit are given, the revision will be preferred.
	 */
	'end-revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * The name of the test prioritization strategy
	 */
	'prioritization-strategy'?: ETestPrioritizationStrategyEntry, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Append and prioritize non-impacted tests after impacted tests.
	 */
	'include-non-impacted'?: boolean, 
	/**
	 * Append and prioritize previously failed and skipped tests before impacted tests.
	 */
	'include-failed-and-skipped'?: boolean, 
	/**
	 * Append and prioritize tests for which there is no coverage yet.
	 */
	'include-added-tests'?: boolean, 
	/**
	 * The upper bound for the predicted test execution duration in milliseconds that the resulting test list should exhibit. All impacted tests that do not fit into this budget will be cut off.
	 */
	'max-exec-time'?: number, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string
};

export type FindInitialCommitQueryParams = {
	/**
	 * The path of the file
	 */
	'uniformPath'?: string
};

export type FindLogEntriesInRangeQueryParams = {
	/**
	 * Start timestamp to return repository log entries after.
	 */
	'start'?: number, 
	/**
	 * End timestamp to return repository log entries before.
	 */
	'end'?: number, 
	/**
	 * Whether or not commits for the timestamps from the start and/or end commit are included.
	 */
	'include-bounds'?: boolean, 
	/**
	 * The amount of repository logs returned when at least one of the commit parameters is omitted.
	 */
	'entry-count'?: number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon. Only log entries from commits merged into the given branch up to the given point will be considered when the start and end timestamps are evaluated.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Controls whether only repository log entries are returned where the current user was the committer.
	 */
	'privacy-aware'?: boolean, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>
};

export type FlagFindingsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Request operation to perform (e.g. add or remove flagging information).
	 */
	'operation': EFindingBlacklistOperationEntry, 
	/**
	 * The type of flagging (optional for an unflag operation, findings of both types will be unflagged)
	 */
	'type'?: EFindingBlacklistTypeEntry
};

export type GetAbsoluteDateQueryParams = {
	'relativeDate'?: string
};

export type GetAffectedFilesForCommitsQueryParams = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type GetAllDashboardsQueryParams = {
	/**
	 * If the dashboards should be retrieved for specific project only
	 */
	'project'?: string
};

export type GetAllExternalSnapshotsQueryParams = {
	/**
	 * Whether only the IDs of the snapshots created by the currently logged in user should be returned
	 */
	'only-current-user'?: boolean
};

export type GetAllGitTagsQueryParams = {
	/**
	 * Only return tags from the given repository
	 */
	'repository'?: string
};

export type GetAllMetricThresholdConfigurationNamesQueryParams = {
	/**
	 * Parameter whether to include default configurations.
	 */
	'include-default-configurations'?: boolean
};

export type GetAllMetricsForThresholdProfilesQueryParams = {
	/**
	 * Project for which to return the metrics for threshold profiles.
	 */
	'project'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetAllPartitionDetailsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetAllProjectsQueryParams = {
	/**
	 * Whether to include projects marked as deleted or not.
	 */
	'include-deleting'?: boolean, 
	/**
	 * Whether to include reanalyzing projects or not.
	 */
	'include-reanalyzing'?: boolean
};

export type GetAllSnapshotIdsQueryParams = {
	/**
	 * Whether only the IDs of the snapshots created by the currently logged in user should be returned
	 */
	'only-current-user'?: boolean
};

export type GetAnalysisEditHistoryVersionDiffQueryParams = {
	/**
	 * The version number of the newer version
	 */
	'newVersion': number, 
	/**
	 * The version number of the older version
	 */
	'oldVersion': number
};

export type GetAnalysisResultsByPathQueryParams = {
	'sessionKey'?: string
};

export type GetAnalysisResultsByPath1QueryParams = {
	'sessionKey'?: string
};

export type GetAnalysisStatusQueryParams = {
	'sessionKey'?: string
};

export type GetAnalysisStatus1QueryParams = {
	'sessionKey'?: string
};

export type GetAnalysisToolsQueryParams = {
	/**
	 * Languages to retrieve possible tools for
	 */
	'language': Array<ELanguageEntry>
};

export type GetAnalysisToolsForProjectQueryParams = {
	/**
	 * Languages to retrieve the project's tools for
	 */
	'language': Array<ELanguageEntry>
};

export type GetArchitecturePerformanceStatisticsQueryParams = {
	't'?: UnresolvedCommitDescriptor
};

export type GetBaselinesQueryParams = {
	'project'?: Array<string>
};

export type GetBasicRolesQueryParams = {
	/**
	 * Determines the basic permission scope for the instances. Represented by the enum values of EBasicPermissionScope.
	 */
	'permission-scope': EBasicPermissionScopeEntry
};

export type GetBenchmarkResultsQueryParams = {
	/**
	 * The non-anonymized projects considered for the benchmark.
	 */
	'projects'?: Array<string>, 
	/**
	 * The anonymized projects considered for the benchmark.
	 */
	'anonymized-projects'?: Array<string>, 
	/**
	 * The metric used for the benchmark.
	 */
	'metric-name'?: string
};

export type GetBranchForSpecItemQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetBranchesGetRequestQueryParams = {
	/**
	 * The offset from which to start loading branches.
	 */
	'start-offset'?: number, 
	/**
	 * The number of branches that should be returned.
	 */
	'limit'?: number, 
	/**
	 * Whether only currently live branches should be returned.
	 */
	'only-live-branches'?: boolean, 
	/**
	 * If this is set the service uses the regex filter to return branches matching the filter.
	 */
	'regex-filter'?: string, 
	/**
	 * If this is set the service checks whether the passed branches are contained in the currently live branches of the project and returns the one that are.
	 */
	'filter'?: Array<string>
};

export type GetBranchesPostRequestQueryParams = {
	/**
	 * The offset from which to start loading branches.
	 */
	'start-offset'?: number, 
	/**
	 * The number of branches that should be returned.
	 */
	'limit'?: number, 
	/**
	 * Whether only currently live branches should be returned.
	 */
	'only-live-branches'?: boolean, 
	/**
	 * If this is set the service uses the regex filter to return branches matching the filter.
	 */
	'regex-filter'?: string, 
	/**
	 * If this is set the service checks whether the passed branches are contained in the currently live branches of the project and returns the one that are.
	 */
	'filter'?: Array<string>
};

export type GetChangeTreemapQueryParams = {
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string
};

export type GetCodeComponentsTreemapWrapperQueryParams = {
	'uniform-path': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: number, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	/**
	 * Color (as 6-digit hex value without preceding #) for highlighted components.
	 */
	'highlight-color'?: string, 
	/**
	 * Pattern to color only matching components.
	 */
	'highlight-term'?: string, 
	/**
	 * Specifies whether top-level components should be colored or whether languages should be colored
	 */
	'is-show-components'?: boolean
};

export type GetCodeOutlineQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetCodeReferencesAndTestExecutionMappingQueryParams = {
	/**
	 * Spec item query
	 */
	'query': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetCodeSearchMatchResultAndTreemapQueryParams = {
	/**
	 * Uniform path to retrieve files for
	 */
	'uniform-path': string, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: number, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	'search-term': string, 
	'token-classes'?: Array<ETokenClassEntry>, 
	'is-color-gradation-active'?: boolean, 
	/**
	 * Result list size limit (or 0 for no limit)
	 */
	'preview-size'?: number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetCommitAlertsQueryParams = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type GetComparisonResultsForContributorQueryParams = {
	/**
	 * The contributor of the comparison.
	 */
	'contributor'?: string
};

export type GetCompleteExampleListQueryParams = {
	/**
	 * Whether the \"missingLocal\" or \"missingRemote\" value is requested.
	 */
	'missingLocal'?: boolean
};

export type GetConcurrencyPerformanceDetailsQueryParams = {
	/**
	 * The Analysis States to include, must value from EAnalysisState enum.
	 */
	'state'?: Array<EAnalysisStateEntry>
};

export type GetConfigurationTemplateQueryParams = {
	/**
	 * Languages to retrieve the configuration template for
	 */
	'language'?: Array<ELanguageEntry>, 
	/**
	 * Tools to retrieve the configuration template for
	 */
	'tool'?: Array<EAnalysisToolEntry>, 
	/**
	 * Analysis profile to obtain the default values for the configuration template from
	 */
	'profile'?: string
};

export type GetCopyrightLicensesTreemapQueryParams = {
	/**
	 * Uniform path to retrieve licenses for
	 */
	'uniform-path': string, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: number, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	/**
	 * The list of customer licenses that should be compared against. Comparison is case sensitive. Licenses are separated by commas.
	 */
	'customer-license'?: Array<string>, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetCoverageSourcesQueryParams = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor
};

export type GetDebugContentQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	'representation'?: ERepresentationEntry, 
	'pre-commit-id'?: string
};

export type GetDeclarationOfAccessibilityQueryParams = {
	/**
	 * Whether a reload should be forced.
	 */
	'force-reload'?: boolean
};

export type GetDeltaAffectedFilesQueryParams = {
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string
};

export type GetDependencyGraphQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetDiffsQueryParams = {
	/**
	 * The full path of the left element of the comparison. Notation: {project}/{uniformPath}#@#{commit}
	 */
	'left': string, 
	/**
	 * The full path of the right element of the comparison. Notation: {project}/{uniformPath}#@#{commit}
	 */
	'right': string, 
	/**
	 * An optional explicit region to focus on. This can be used, e.g., to highlight differences in a clone. The format of this region is 'leftStart-leftEnd:rightStart-rightEnd', e.g. '45-67:22-43'.
	 */
	'region'?: string, 
	/**
	 * A boolean parameter which decides whether or not the comparison should be made by using the normalized codes.
	 */
	'normalized'?: boolean
};

export type GetDotNetVersionsQueryParams = {
	'project'?: Array<string>
};

export type GetDumpCommitDescriptorQueryParams = {
	/**
	 * If the xdot parameter is true, the service returns commit descriptors as an xdot graph. Otherwise it returns a plain text.
	 */
	'xdot'?: boolean
};

export type GetDumpCommitTreeQueryParams = {
	/**
	 * If the xdot parameter is true, the service returns the commit trees as an xdot graph. Otherwise it returns a plain text.
	 */
	'xdot'?: boolean, 
	/**
	 * When provided will only dump the commit trees for the given repository, instead of all repository trees.
	 */
	'repository-id'?: string
};

export type GetDumpCommitTreeNodesQueryParams = {
	/**
	 * When provided will only dump the commit tree for the given repository, instead of all repository trees.
	 */
	'repository-id'?: string
};

export type GetEditedServiceProviderConfigurationQueryParams = {
	'name'?: string
};

export type GetExceptionsHierarchyTreeQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetExceptionsHierarchyTreeSVGQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Whether the DOT format is expected.
	 */
	'isdot'?: boolean
};

export type GetFileSummaryQueryParams = {
	/**
	 * The path to a folder in the local file system for which the summary is created.
	 */
	'path'?: string, 
	/**
	 * Included files of summary, given as ant pattern. Matching is case insensitive.
	 */
	'includes'?: Array<string>, 
	/**
	 * Excluded files of summary, given as ant pattern. Matching is case insensitive.
	 */
	'excludes'?: Array<string>
};

export type GetFilesWithIdenticalContentQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	'regex'?: string, 
	'ignoreWhitespaces'?: boolean
};

export type GetFilesWithIdenticalNamesQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	'regex'?: string
};

export type GetFindingChurnCountQueryParams = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type GetFindingDeltaQueryParams = {
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string, 
	/**
	 * The case-insensitive regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The finding flagging filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * Indicates whether the result should contain the actual delta findings.
	 */
	'numeric-delta-only'?: boolean, 
	/**
	 * Filter findings changed by commits of this user group. The group needs at least 3 members.
	 */
	'group'?: string
};

export type GetFindingSiblingsQueryParams = {
	't'?: UnresolvedCommitDescriptor
};

export type GetFindingsQueryParams = {
	/**
	 * The uniform path for which findings should be retrieved. This can be either a concrete file or a container.
	 */
	'uniform-path'?: string, 
	/**
	 * If this is true, the findings are adjusted to match a pretty printed version of the code. This may not be used together with recursive queries to directories.
	 */
	'pretty'?: boolean, 
	/**
	 * If this is true, the uniform path will be treated case-insensitive. This may be used if the file casing may be different to the one on the Teamscale server.
	 */
	'case-insensitive-path'?: boolean, 
	/**
	 * The case-insensitive regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The finding flagging filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string, 
	/**
	 * The finding property by which the result is sorted. If none is given the result is not sorted. One of group, uniformpath, message, or a finding property, or random
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * If this parameter is given, the findings returned will start from this index (0 based), i.e. the first start findings in the list (for current sorting) will be skipped.
	 */
	'start'?: number, 
	/**
	 * Limits the number of findings returned. If also the parameter 'all' is used, the limit is ignored. Providing no limit will use the default limit of 300.
	 */
	'max'?: number, 
	/**
	 * If this is true, the finding list is not truncated to 300 elements.
	 */
	'all'?: boolean
};

export type GetFindingsExportQueryParams = {
	/**
	 * The uniform path for which findings should be retrieved. This can be either a concrete file or a container. In the latter case the recursive parameter can be used to specify whether sub-trees should be considered.
	 */
	'uniform-path'?: string, 
	/**
	 * The case-insensitive regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The finding flagging filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string, 
	/**
	 * The finding property by which the result is sorted. If none is given the result is not sorted. One of group, uniformpath, message, or a finding property, or random
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry
};

export type GetFindingsSiblingsQueryParams = {
	/**
	 * The uniform path for which findings should be retrieved. This can be either a concrete file or a container.
	 */
	'uniform-path'?: string, 
	/**
	 * If this is true, the findings are adjusted to match a pretty printed version of the code. This may not be used together with recursive queries to directories.
	 */
	'pretty'?: boolean, 
	/**
	 * If this is true, the uniform path will be treated case-insensitive. This may be used if the file casing may be different to the one on the Teamscale server.
	 */
	'case-insensitive-path'?: boolean, 
	/**
	 * The case-insensitive regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The finding flagging filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string, 
	/**
	 * The finding property by which the result is sorted. If none is given the result is not sorted. One of group, uniformpath, message, or a finding property, or random
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * If this parameter is given, the findings returned will start from this index (0 based), i.e. the first start findings in the list (for current sorting) will be skipped.
	 */
	'start'?: number, 
	/**
	 * Limits the number of findings returned. If also the parameter 'all' is used, the limit is ignored. Providing no limit will use the default limit of 300.
	 */
	'max'?: number, 
	/**
	 * If this is true, the finding list is not truncated to 300 elements.
	 */
	'all'?: boolean
};

export type GetFindingsSummaryQueryParams = {
	'uniform-path'?: string, 
	/**
	 * The case-insensitive regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The finding flagging filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string, 
	/**
	 * Whether finding categories without findings will be included.
	 */
	'report-categories-without-findings'?: boolean
};

export type GetFindingsTreemapQueryParams = {
	'uniform-path'?: string, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	/**
	 * Optional: The main color for the treemap.
	 */
	'color'?: string, 
	/**
	 * The case-insensitive regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The finding flagging filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string
};

export type GetFindingsWithCountQueryParams = {
	/**
	 * The uniform path for which findings should be retrieved. This can be either a concrete file or a container.
	 */
	'uniform-path'?: string, 
	/**
	 * If this is true, the findings are adjusted to match a pretty printed version of the code. This may not be used together with recursive queries to directories.
	 */
	'pretty'?: boolean, 
	/**
	 * If this is true, the uniform path will be treated case-insensitive. This may be used if the file casing may be different to the one on the Teamscale server.
	 */
	'case-insensitive-path'?: boolean, 
	/**
	 * The case-insensitive regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The finding flagging filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, only findings for spec items matching the query are returned.
	 */
	'spec-item-query'?: string, 
	/**
	 * The baseline name or baseline timestamp, with regards to which the findings shall be filtered.
	 */
	'baseline'?: string, 
	/**
	 * If this parameter is given, general findings and such covering the specified qualified location are returned.
	 */
	'qualified-name'?: string, 
	/**
	 * If this is true, findings in changed code as included as well. Only used if a baseline is provided as well.
	 */
	'include-changed-code-findings'?: boolean, 
	/**
	 * If this is true, only findings in changed code are to be shown. Only used if a baseline is provided as well.
	 */
	'only-changed-code-findings'?: boolean, 
	/**
	 * A pattern to be matched against the rationale for which the findings was tolerated or marked as false positive.
	 */
	'blacklistRationale'?: string, 
	/**
	 * The finding property by which the result is sorted. If none is given the result is not sorted. One of group, uniformpath, message, or a finding property, or random
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * If this parameter is given, the findings returned will start from this index (0 based), i.e. the first start findings in the list (for current sorting) will be skipped.
	 */
	'start'?: number, 
	/**
	 * Limits the number of findings returned. If also the parameter 'all' is used, the limit is ignored. Providing no limit will use the default limit of 300.
	 */
	'max'?: number, 
	/**
	 * If this is true, the finding list is not truncated to 300 elements.
	 */
	'all'?: boolean
};

export type GetFlaggedFindingIdsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetFlaggedFindingInfoQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetFlaggedFindingsQueryParams = {
	/**
	 * Key of a precomputed (using the \"merge-requests/parent-info\" endpoint) merge-base info. Should be provided in case the finding delta is requested for a (possible) merge request. 
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Commit denoting the start of the flagged finding interval, or the source commit in case of a (possible) merge. Must be present when \"merge-base-cache-key\" is provided.
	 */
	'from'?: UnresolvedCommitDescriptor, 
	/**
	 * Commit denoting the end of the flagged finding interval, or the target commit in case of a (possible) merge.
	 */
	'to': UnresolvedCommitDescriptor, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean
};

export type GetFlaggedFindingsInfosQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean
};

export type GetGitHubAppInstallationRepositoriesQueryParams = {
	'appId'?: string
};

export type GetGitTagByNameQueryParams = {
	/**
	 * Only return tags from the given repository
	 */
	'repository'?: string
};

export type GetGlobalBranchesGetRequestQueryParams = {
	/**
	 * A list of project IDs. When not specified it defaults to \"all projects\"
	 */
	'projects'?: Array<string>, 
	/**
	 * The offset from which to start loading branches.
	 */
	'start-offset'?: number, 
	/**
	 * The number of branches that should be returned.
	 */
	'limit'?: number, 
	/**
	 * Whether only currently live branches should be returned.
	 */
	'only-live-branches'?: boolean, 
	/**
	 * If this is set the service uses the regex filter to return branches matching the filter.
	 */
	'regex-filter'?: string, 
	/**
	 * If this is set the service checks whether the passed branches are contained in the currently live branches of the project and returns the one that are.
	 */
	'filter'?: Array<string>
};

export type GetGlobalContainerInfoQueryParams = {
	/**
	 * The uniform path of the parent directory.
	 */
	'uniform-path'?: string
};

export type GetGlobalCriticalEventLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetGlobalFirstUIBranchGetRequestQueryParams = {
	/**
	 * A list of project IDs. When not specified it defaults to \"all projects\"
	 */
	'projects'?: Array<string>
};

export type GetGlobalInteractionLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetGlobalJavascriptErrorLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetGlobalRecentBranchesGetRequestQueryParams = {
	/**
	 * A list of project IDs. When not specified it defaults to \"all projects\"
	 */
	'projects'?: Array<string>
};

export type GetGlobalServiceLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetGlobalWorkerLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetGroupAutoCompletionSuggestionsQueryParams = {
	/**
	 * Maximum number of results to return.
	 */
	'limit'?: number, 
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type GetHealthStatusQueryParams = {
	/**
	 * List of system health checks to perform
	 */
	'check'?: Array<string>, 
	/**
	 * Determines if only critical health check entries should be returned
	 */
	'critical-only'?: boolean
};

export type GetImpactedTestsQueryParams = {
	/**
	 * The baseline commit (exclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline revision (exclusive). If both revision and commit are given, the revision will be preferred.
	 */
	'baseline-revision'?: string, 
	/**
	 * The end revision (inclusive). If both revision and commit are given, the revision will be preferred.
	 */
	'end-revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * The name of the test prioritization strategy
	 */
	'prioritization-strategy'?: ETestPrioritizationStrategyEntry, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Append and prioritize non-impacted tests after impacted tests.
	 */
	'include-non-impacted'?: boolean, 
	/**
	 * Append and prioritize previously failed and skipped tests before impacted tests.
	 */
	'include-failed-and-skipped'?: boolean, 
	/**
	 * Append and prioritize tests for which there is no coverage yet.
	 */
	'include-added-tests'?: boolean, 
	/**
	 * The upper bound for the predicted test execution duration in milliseconds that the resulting test list should exhibit. All impacted tests that do not fit into this budget will be cut off.
	 */
	'max-exec-time'?: number, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string
};

export type GetImpactedTestsFromAvailableTestsQueryParams = {
	/**
	 * The baseline commit (exclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline revision (exclusive). If both revision and commit are given, the revision will be preferred.
	 */
	'baseline-revision'?: string, 
	/**
	 * The end revision (inclusive). If both revision and commit are given, the revision will be preferred.
	 */
	'end-revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * The name of the test prioritization strategy
	 */
	'prioritization-strategy'?: ETestPrioritizationStrategyEntry, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Append and prioritize non-impacted tests after impacted tests.
	 */
	'include-non-impacted'?: boolean, 
	/**
	 * Append and prioritize previously failed and skipped tests before impacted tests.
	 */
	'include-failed-and-skipped'?: boolean, 
	/**
	 * Append and prioritize tests for which there is no coverage yet.
	 */
	'include-added-tests'?: boolean, 
	/**
	 * The upper bound for the predicted test execution duration in milliseconds that the resulting test list should exhibit. All impacted tests that do not fit into this budget will be cut off.
	 */
	'max-exec-time'?: number, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string
};

export type GetInstancePermissionsQueryParams = {
	/**
	 * Determines the basic permission scope for the instances. Represented by the enum values of EBasicPermissionScope.
	 */
	'permission-scope': EBasicPermissionScopeEntry
};

export type GetIssuesByRepositoryCommitsQueryParams = {
	'commit'?: Array<UnresolvedCommitDescriptor>
};

export type GetIssuesDetailsQueryParams = {
	/**
	 * IDs of the issues to retrieve. The format is the connector ID of the originating issue tracker/requirements management tool (as specified in the connector settings in the project configuration), followed by the separator '|', followed by the ID of the issue, as it is provided by the external issue tracker/requirements management tool. For example, an issue with the ID \"TS-123\" is imported using an issue tracker connector with the connector ID \"issues1\". The expected ID is then \"issues1|TS-123\".
	 */
	'issue-ids'?: Array<string>
};

export type GetKubernetesManifestQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetLanguageRulesQueryParams = {
	/**
	 * The language for which to get rules.
	 */
	'languages'?: Array<ELanguageEntry>, 
	/**
	 * The tools for which to get rules.
	 */
	'tools'?: Array<EAnalysisToolEntry>
};

export type GetLanguageRulesForAnalysisProfileQueryParams = {
	/**
	 * The language for which to get rules.
	 */
	'languages'?: Array<ELanguageEntry>, 
	/**
	 * The tools for which to get rules.
	 */
	'tools'?: Array<EAnalysisToolEntry>, 
	/**
	 * Whether to include rules that are disabled for the given project or analysis profile. Default: false.
	 */
	'includeDisabledRules'?: boolean, 
	/**
	 * Whether to include analysis groups that are disabled for the given project or analysis profile. Default: false.
	 */
	'includeDisabledGroups'?: boolean, 
	/**
	 * Whether the current analysis profile is on create mode. 
	 */
	'createMode'?: boolean
};

export type GetLanguageRulesForProjectQueryParams = {
	/**
	 * The language for which to get rules.
	 */
	'languages'?: Array<ELanguageEntry>, 
	/**
	 * The tools for which to get rules.
	 */
	'tools'?: Array<EAnalysisToolEntry>, 
	/**
	 * Whether to include rules that are disabled for the given project or analysis profile. Default: false.
	 */
	'includeDisabledRules'?: boolean, 
	/**
	 * Whether to include analysis groups that are disabled for the given project or analysis profile. Default: false.
	 */
	'includeDisabledGroups'?: boolean, 
	/**
	 * Whether the current analysis profile is on create mode. 
	 */
	'createMode'?: boolean
};

export type GetLastCommitOfTypeQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	'commit-type'?: ECommitTypeEntry
};

export type GetLicenseInfoQueryParams = {
	/**
	 * Reload license information
	 */
	'reload'?: boolean
};

export type GetLicensesQueryParams = {
	/**
	 * The list of customer licenses that should be compared against. Comparison is case sensitive. Licenses are separated by commas.
	 */
	'customer-license'?: Array<string>, 
	/**
	 * If given filters the results to this sub-path (including nested resources)
	 */
	'uniform-path'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetListOfActiveCommittersQueryParams = {
	/**
	 * Number of days
	 */
	'number-of-days'?: number
};

export type GetListOfActiveCommittersForProjectsQueryParams = {
	/**
	 * Number of days
	 */
	'number-of-days'?: number
};

export type GetMergeRequestAffectedFilesQueryParams = {
	/**
	 * The source commit descriptor.
	 */
	'source': UnresolvedCommitDescriptor, 
	/**
	 * The target commit descriptor.
	 */
	'target': UnresolvedCommitDescriptor, 
	/**
	 * Key of a precomputed merge-base info object.
	 */
	'merge-base-cache-key'?: string
};

export type GetMergeRequestDeltaQueryParams = {
	/**
	 * Maximum number of findings to return per category. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number
};

export type GetMergeRequestFindingChurnQueryParams = {
	/**
	 * The source commit descriptor.
	 */
	'source': UnresolvedCommitDescriptor, 
	/**
	 * The target commit descriptor.
	 */
	'target': UnresolvedCommitDescriptor, 
	/**
	 * Key of a precomputed merge-base info object.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * The case-insensitive regex filter. Filters findings by this regex. Considered Fields are Message and Location. Matches will be included in the result.
	 */
	'regex'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline'?: string, 
	/**
	 * The guideline filter. Filters findings showing only findings which are relevant for this guideline.
	 */
	'guideline-rules'?: Array<string>, 
	/**
	 * Whether regex excludes or includes findings.
	 */
	'exclude-regex'?: boolean, 
	/**
	 * The added to task filter. Filter findings that already have a task assigned.
	 */
	'added-to-task'?: boolean, 
	/**
	 * The finding category, group, and type filters. Every string must be either a single category, a combination category/group, or a type ID. If a category or group is given, all matching findings will be filtered out and not included in the result.
	 */
	'filter'?: Array<string>, 
	/**
	 * Whether to invert the category, group, type filters, i.e. including the elements given in the filters instead of excluding them.
	 */
	'invert'?: boolean, 
	/**
	 * The assessment filter. All mentioned assessment colors will be filtered out and not included in the result.
	 */
	'assessment-filters'?: Array<ETrafficLightColorEntry>, 
	/**
	 * The finding flagging filtering option.
	 */
	'blacklisted'?: EBlacklistingOptionEntry, 
	/**
	 * If this parameter is given, only findings whose uniform path matches one of the given Ant patterns are returned.
	 */
	'included-paths'?: Array<string>, 
	/**
	 * If this parameter is given, only findings whose uniform path does not match one of the given Ant patterns are returned.
	 */
	'excluded-paths'?: Array<string>, 
	/**
	 * If this is true, only spec item findings are to be shown.
	 */
	'only-spec-item-findings'?: boolean
};

export type GetMergeRequestRepositoryChurnQueryParams = {
	/**
	 * Source commit descriptor.
	 */
	'source': UnresolvedCommitDescriptor, 
	/**
	 * Target commit descriptor.
	 */
	'target': UnresolvedCommitDescriptor, 
	/**
	 * Key of a precomputed merge-base info object.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>
};

export type GetMetadataForFileQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetMethodHistoryQueryParams = {
	/**
	 * The character-based offset in file on which the method starts (at the specified time).
	 */
	'start-offset': number, 
	/**
	 * The character-based offset in file on which the method ends (at the specified time).
	 */
	'end-offset': number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The partitions from which coverage uploads should be retrieved. If not set, all partitions in the project are used.
	 */
	'partitions'?: Array<string>, 
	/**
	 * The cross-annotation projects to retrieve execution information from.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * The baseline until to compute detailed history.
	 */
	'baseline-timestamp'?: number, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>
};

export type GetMetricBenchmarkQueryParams = {
	/**
	 * The metric used for the benchmark.
	 */
	'metric-name': string, 
	/**
	 * The anonymized project ids considered for the benchmark.
	 */
	'anonymized-projects'?: Array<string>, 
	/**
	 * The non-anonymized project ids considered for the benchmark.
	 */
	'projects'?: Array<string>
};

export type GetMetricDirectorySchemasQueryParams = {
	/**
	 * List of uniform paths to retrieve metric directory schemas for
	 */
	'uniform-path'?: Array<string>
};

export type GetMetricThresholdConfigurationQueryParams = {
	/**
	 * Project name to load built-in configurations for
	 */
	'project-name'?: string, 
	/**
	 * Parameter indicating if configuration should be loaded together with default configurations.
	 */
	'load-with-base-configurations'?: boolean
};

export type GetMinimizedTestsQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end': UnresolvedCommitDescriptor, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Do the minimization only for the tests specified by the query.
	 */
	'query'?: string, 
	/**
	 * The maximum duration of the minimized test suite in milliseconds (only relevant for test suite minimization).
	 */
	'max-exec-time'?: number, 
	/**
	 * The regex that is used to determine the clustering of tests. The first capture group of the regex should match the subset of the test uniform path that represents the cluster ID. Tests with the same cluster ID are grouped and prioritized together. If left empty, each cluster contains exactly one test.
	 */
	'clustering-regex'?: string, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string
};

export type GetMultiProjectLatexTableQueryParams = {
	/**
	 * The language in which a table is created.
	 */
	'language': EAuditExportLanguageEntry, 
	/**
	 * The table types containing specific metrics.
	 */
	'table-type': Array<EAuditExportTableEntry>, 
	/**
	 * The project with corresponding application code.
	 */
	'application': string, 
	/**
	 * The project with corresponding test code.
	 */
	'test': string, 
	/**
	 * The project with test and application code combined.
	 */
	'all': string
};

export type GetOpenIdEndpointsQueryParams = {
	'issuer'?: string
};

export type GetPermissionLookupQueryParams = {
	/**
	 * Determines the basic permission scope for the instances. Represented by the enum values of EBasicPermissionScope.
	 */
	'permission-scope': EBasicPermissionScopeEntry
};

export type GetPreCommitContentQueryParams = {
	/**
	 * Uniform path to obtain the token element info for
	 */
	'uniform-path': string, 
	/**
	 * The commit id for which the pre-commit analysis has been triggered
	 */
	'preCommitId': string, 
	/**
	 * If the output should be pretty-printed
	 */
	'pretty'?: boolean
};

export type GetProjectCriticalEventLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetProjectInteractionLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetProjectJavascriptErrorLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetProjectServiceLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetProjectUserActivityTrendQueryParams = {
	/**
	 * List of all ids of the projects.
	 */
	'project-ids'?: Array<string>, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline': number, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\" If this is not given, only one trend entry will be returned, which is for the day on which the given baseline timestamp lies.
	 */
	'end'?: number, 
	/**
	 * Users in these groups are ignored in the user count. Group names can contain spaces.
	 */
	'exclude-group'?: Array<string>
};

export type GetProjectWorkerLogsQueryParams = {
	/**
	 * The minimum log level to retrieve log entries for. This includes all levels that are more severe than the given one.
	 */
	'minLogLevel'?: ELogLevelEntry, 
	/**
	 * The maximum number of log entries to return, or 0 to return all entries.
	 */
	'maxResults'?: number, 
	/**
	 * The timestamp of the earliest log entry to include. If greater than endTimestamp, sort order is inverted.
	 */
	'startTimestamp'?: number, 
	/**
	 * The timestamp of the latest log entry to include. If smaller than endTimestamp, sort order is inverted.
	 */
	'endTimestamp'?: number, 
	/**
	 * The timestamp of the oldest log entry that will be displayed. This is necessary to determine display of collapsed log entries.
	 */
	'oldestTimestamp'?: number, 
	/**
	 * Optional regular expression used for filtering log entries.
	 */
	'filterRegex'?: string, 
	/**
	 * Whether to include or exclude matches of the filterRegex.
	 */
	'includeMatches'?: boolean, 
	/**
	 * Whether to search detailed log messages (may be expensive)
	 */
	'searchDetailedLogs'?: boolean, 
	/**
	 * Whether to collapse repeated entries
	 */
	'collapseRepeatedEntries'?: boolean
};

export type GetRedundantLiteralTreemapQueryParams = {
	/**
	 * The sub path
	 */
	'uniform-path'?: string, 
	/**
	 * The minimum size of the literal
	 */
	'min-literal'?: number, 
	/**
	 * The minimum occurrences of the literal
	 */
	'min-occurrences'?: number, 
	/**
	 * Constraint metric to local scope.
	 */
	'local-scope-metric'?: boolean, 
	/**
	 * Regex to include literals.
	 */
	'include-regex'?: string, 
	/**
	 * Regex to exclude literals.
	 */
	'exclude-regex'?: string, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: number, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetRedundantLiteralsQueryQueryParams = {
	/**
	 * The sub path
	 */
	'uniform-path'?: string, 
	/**
	 * The minimum size of the literal
	 */
	'min-literal'?: number, 
	/**
	 * The minimum occurrences of the literal
	 */
	'min-occurrences'?: number, 
	/**
	 * Constraint metric to local scope.
	 */
	'local-scope-metric'?: boolean, 
	/**
	 * Regex to include literals.
	 */
	'include-regex'?: string, 
	/**
	 * Regex to exclude literals.
	 */
	'exclude-regex'?: string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetRepositoryChurnQueryParams = {
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>
};

export type GetRepositoryLogQueryParams = {
	/**
	 * Optional parameter that controls whether only repository log  entries are returned where the current user was the committer. This defaults to false.
	 */
	'privacy-aware'?: boolean, 
	'commit'?: Array<UnresolvedCommitDescriptor>, 
	'merge-request-id'?: string
};

export type GetRepositoryLogWithSplitAggregatesQueryParams = {
	/**
	 * Optional parameter that controls whether only repository log  entries are returned where the current user was the committer. This defaults to false.
	 */
	'privacy-aware'?: boolean, 
	'commit'?: Array<UnresolvedCommitDescriptor>, 
	'merge-request-id'?: string
};

export type GetRepositorySummaryQueryParams = {
	/**
	 * Optional parameter, may be used to specify the last point in time to consider in the given query.
	 */
	'end-timestamp'?: number, 
	/**
	 * Optional parameter, if set a simpler RepositorySummary is returned, which is much faster to calculate.
	 */
	'only-first-and-last'?: boolean, 
	/**
	 * Optional parameter, if set only RepositoryLogEntries that include at least one code commit are considered
	 */
	'code-commits-only'?: boolean
};

export type GetResolvedTasksQueryParams = {
	/**
	 * Specifies the field to sort tasks by.
	 */
	'sort-by'?: ETaskSortPropertyEntry, 
	/**
	 * Task IDs filter
	 */
	'ids'?: Array<number>, 
	/**
	 * Task status filter
	 */
	'status'?: Array<ETaskStatusEntry>, 
	/**
	 * Task tag filter
	 */
	'tag'?: Array<string>, 
	/**
	 * Task assignee filter
	 */
	'assignee'?: Array<string>, 
	/**
	 * Task author filter
	 */
	'author'?: Array<string>, 
	/**
	 * Specifies the sort order of requested tasks. One of ascending or descending
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * Limits the number of tasks returned to the given number. 0 means that all results should be returned.
	 */
	'max'?: number, 
	/**
	 * Specifies start index of requested tasks.The tasks returned will start from this index (0 based).
	 */
	'start'?: number, 
	/**
	 * Defines whether details (list of findings, discussion history) are retrieved. This can be used to reduce the amount of data transferred when retrieving all tasks.)
	 */
	'details'?: boolean, 
	/**
	 * Optional: The branch for which to determine the findings' removal status
	 */
	'branch'?: string
};

export type GetResourceHistoryQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The commit types to show or hide. If omitted, commits for all types are returned.
	 */
	'commit-types'?: Array<ECommitTypeEntry>, 
	/**
	 * The commit attribute to filter by.
	 */
	'commit-attribute'?: ECommitAttributeEntry, 
	/**
	 * Option whether to filter out commits, that belong to other branches.
	 */
	'exclude-other-branches'?: boolean, 
	/**
	 * User names to filter for.
	 */
	'users'?: Array<string>, 
	/**
	 * Group names to filter for.
	 */
	'groups'?: Array<string>, 
	/**
	 * Whether we want to filter for spec items or code.
	 */
	'specItemEntriesOnly'?: boolean, 
	/**
	 * Commit messages to filter for.
	 */
	'commit-messages'?: Array<string>, 
	/**
	 * This parameter limits the number of returned entries to the newest ones. If all parameters should be returned, either omit the parameter or use 0 instead.
	 */
	'number-of-entries'?: number
};

export type GetResourceTypeQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to retrieve the resource type for
	 */
	'uniform-path': string, 
	/**
	 * Determines if the resource type should be searched in the default branch head in case not found for the given commit.
	 */
	'check-default-branch'?: boolean
};

export type GetRisksQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	'test-gaps-only'?: boolean
};

export type GetRunningProfilersQueryParams = {
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number, 
	/**
	 * Name of the field to sort entries by
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry
};

export type GetSamlIdentityProviderMetadataQueryParams = {
	'idp-url'?: string
};

export type GetSelectedTestsQueryParams = {
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number
};

export type GetSingleProjectLatexTableQueryParams = {
	/**
	 * The language in which a table is created.
	 */
	'language': EAuditExportLanguageEntry, 
	/**
	 * The table types containing specific metrics.
	 */
	'table-type': Array<EAuditExportTableEntry>, 
	/**
	 * The uniform path for which metrics should be returned.
	 */
	'uniform-path': string
};

export type GetSnapshotQueryParams = {
	/**
	 * A flag whether or not the detailed comparison inputs should be included in the reply
	 */
	'reduced'?: boolean, 
	/**
	 * Whether the response should be a file download
	 */
	'asFileDownload'?: boolean
};

export type GetSpecItemChurnQueryParams = {
	/**
	 * Range start timestamp
	 */
	't1'?: UnresolvedCommitDescriptor, 
	/**
	 * Range end timestamp
	 */
	't2'?: UnresolvedCommitDescriptor, 
	/**
	 * Range duration in milliseconds
	 */
	'max-milliseconds'?: number, 
	/**
	 * Uniform path to retrieve data for
	 */
	'uniform-path'?: string
};

export type GetSpecItemDetailsQueryParams = {
	'spec-item-ids'?: Array<string>, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetSpecItemVerifiesQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetSpecItemsVerifiedByQueriedQueryParams = {
	/**
	 * Spec item query
	 */
	'query': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetSpecItemsVerifyingQueriedQueryParams = {
	/**
	 * Spec item query
	 */
	'query': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetStacktraceQueryParams = {
	/**
	 * This parameter must be set to the partition in which the test was uploaded.
	 */
	'partition': string, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetStoredServiceProviderConfigurationQueryParams = {
	'name'?: string
};

export type GetSummary2QueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Uniform path to obtain the token element info for
	 */
	'uniform-path': string
};

export type GetTaskQueryParams = {
	/**
	 * The branch for which to show the findings status
	 */
	'branch'?: string
};

export type GetTaskPreviousNextQueryParams = {
	/**
	 * Specifies the field to sort tasks by.
	 */
	'sort-by'?: ETaskSortPropertyEntry, 
	/**
	 * Task IDs filter
	 */
	'ids'?: Array<number>, 
	/**
	 * Task status filter
	 */
	'status'?: Array<ETaskStatusEntry>, 
	/**
	 * Task tag filter
	 */
	'tag'?: Array<string>, 
	/**
	 * Task assignee filter
	 */
	'assignee'?: Array<string>, 
	/**
	 * Task author filter
	 */
	'author'?: Array<string>, 
	/**
	 * Specifies the sort order of requested tasks. One of ascending or descending
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * Limits the number of tasks returned to the given number. 0 means that all results should be returned.
	 */
	'max'?: number, 
	/**
	 * Specifies start index of requested tasks.The tasks returned will start from this index (0 based).
	 */
	'start'?: number, 
	/**
	 * Defines whether details (list of findings, discussion history) are retrieved. This can be used to reduce the amount of data transferred when retrieving all tasks.)
	 */
	'details'?: boolean, 
	/**
	 * Optional: The branch for which to determine the findings' removal status
	 */
	'branch'?: string
};

export type GetTaskSummaryQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline from which tasks that don't have a task status of open are counted.
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * Specifies the field to sort tasks by.
	 */
	'sort-by'?: ETaskSortPropertyEntry, 
	/**
	 * Task IDs filter
	 */
	'ids'?: Array<number>, 
	/**
	 * Task status filter
	 */
	'status'?: Array<ETaskStatusEntry>, 
	/**
	 * Task tag filter
	 */
	'tag'?: Array<string>, 
	/**
	 * Task assignee filter
	 */
	'assignee'?: Array<string>, 
	/**
	 * Task author filter
	 */
	'author'?: Array<string>, 
	/**
	 * Specifies the sort order of requested tasks. One of ascending or descending
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * Limits the number of tasks returned to the given number. 0 means that all results should be returned.
	 */
	'max'?: number, 
	/**
	 * Specifies start index of requested tasks.The tasks returned will start from this index (0 based).
	 */
	'start'?: number, 
	/**
	 * Defines whether details (list of findings, discussion history) are retrieved. This can be used to reduce the amount of data transferred when retrieving all tasks.)
	 */
	'details'?: boolean, 
	/**
	 * Optional: The branch for which to determine the findings' removal status
	 */
	'branch'?: string
};

export type GetTaskToFindingMappingBody = {
	/**
	 * Finding IDs to determine tasks for
	 */
	'finding-id'?: Array<string>, 
	/**
	 * Tasks statuses to be filtered for
	 */
	'task-status'?: Array<ETaskStatusEntry>
};

export type GetTasksWithCountQueryParams = {
	/**
	 * Specifies the field to sort tasks by.
	 */
	'sort-by'?: ETaskSortPropertyEntry, 
	/**
	 * Task IDs filter
	 */
	'ids'?: Array<number>, 
	/**
	 * Task status filter
	 */
	'status'?: Array<ETaskStatusEntry>, 
	/**
	 * Task tag filter
	 */
	'tag'?: Array<string>, 
	/**
	 * Task assignee filter
	 */
	'assignee'?: Array<string>, 
	/**
	 * Task author filter
	 */
	'author'?: Array<string>, 
	/**
	 * Specifies the sort order of requested tasks. One of ascending or descending
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * Limits the number of tasks returned to the given number. 0 means that all results should be returned.
	 */
	'max'?: number, 
	/**
	 * Specifies start index of requested tasks.The tasks returned will start from this index (0 based).
	 */
	'start'?: number, 
	/**
	 * Defines whether details (list of findings, discussion history) are retrieved. This can be used to reduce the amount of data transferred when retrieving all tasks.)
	 */
	'details'?: boolean, 
	/**
	 * Optional: The branch for which to determine the findings' removal status
	 */
	'branch'?: string
};

export type GetTeamscaleUploadInvocationQueryParams = {
	/**
	 * The Teamscale user which should be used to perform the upload
	 */
	'user': string, 
	/**
	 * The partition in which to store the uploaded coverage in Teamscale
	 */
	'partition': string, 
	/**
	 * The format of the external upload data
	 */
	'format': EReportFormatEntry
};

export type GetTechnologyDependenciesQueryParams = {
	/**
	 * Uniform path to retrieve files for
	 */
	'uniform-path': string, 
	/**
	 * Package depth for the given project.
	 */
	'package-depth': number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTechnologyTreeMapQueryParams = {
	/**
	 * Uniform path to retrieve files for
	 */
	'uniform-path': string, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	/**
	 * The index for the metric used to determine the area of the displayed nodes.
	 */
	'area-metric'?: number, 
	/**
	 * The index for the metric used to determine the color of the displayed nodes.
	 */
	'color-metric'?: number, 
	'min-value-for-color'?: number, 
	'max-value-for-color'?: number, 
	'color'?: number, 
	/**
	 * Regular expressions the file paths have to match to be displayed.
	 */
	'included-files-regexes'?: Array<string>, 
	/**
	 * Regular expressions the file paths may not match to be displayed.
	 */
	'excluded-files-regexes'?: Array<string>, 
	/**
	 * The partitions to consider for metric retrieval
	 */
	'partition'?: Array<string>, 
	/**
	 * Whether all partitions should be included
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether to enable the color-blind mode for the Treemap
	 */
	'color-blind-mode'?: boolean, 
	'dependency'?: string, 
	'package-depth'?: number, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTestCoverageQueryParams = {
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider. If this parameter is omitted every partition will be taken.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If true, the coverage is adjusted to match a pretty printed version of the code.
	 */
	'pretty'?: boolean, 
	/**
	 * If true, the coverage will include method-accurate coverage.
	 */
	'include-method-coverage'?: boolean
};

export type GetTestCoverageOverlayQueryParams = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * Will auto-select the issue branch if set to true. Otherwise, if given, the branchName will be used. As fallback the default branch will be used.
	 */
	'auto-select-branch'?: boolean, 
	/**
	 * If this is given, all issues are shown on this branch. Otherwise, the branch is auto-determined for each issue separately.
	 */
	'branch-name'?: string, 
	/**
	 * Will include changes introduced by child issues.
	 */
	'include-child-issues'?: boolean, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean
};

export type GetTestCoveragePostBody = {
	/**
	 * If true, the coverage will include method-accurate coverage.
	 */
	'include-method-coverage'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider. If this parameter is omitted every partition will be taken.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If true, the coverage is adjusted to match a pretty printed version of the code.
	 */
	'pretty'?: boolean, 
	/**
	 *  This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \\\"HEAD\\\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \\\"p1\\\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \\\"master:1601637680000p1\\\"
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTestGapDataAsCsvQueryParams = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string, 
	/**
	 * Will auto-select the issue branch if set to true. Otherwise, if given, the branchName will be used. As fallback the default branch will be used.
	 */
	'auto-select-branch'?: boolean, 
	/**
	 * If this is given, all issues are shown on this branch. Otherwise, the branch is auto-determined for each issue separately.
	 */
	'branch-name'?: string, 
	/**
	 * Will include changes introduced by child issues.
	 */
	'include-child-issues'?: boolean, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean, 
	/**
	 * Parameter determining if only changed methods should be returned
	 */
	'exclude-unchanged-methods'?: boolean
};

export type GetTestGapTreeMapQueryParams = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * Will auto-select the issue branch if set to true. Otherwise, if given, the branchName will be used. As fallback the default branch will be used.
	 */
	'auto-select-branch'?: boolean, 
	/**
	 * If this is given, all issues are shown on this branch. Otherwise, the branch is auto-determined for each issue separately.
	 */
	'branch-name'?: string, 
	/**
	 * Will include changes introduced by child issues.
	 */
	'include-child-issues'?: boolean, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean, 
	/**
	 * The width for the treemap to layout.
	 */
	'width'?: number, 
	/**
	 * The height for the treemap to layout.
	 */
	'height'?: number, 
	/**
	 * Parameter determining if only changed methods should be returned
	 */
	'exclude-unchanged-methods'?: boolean
};

export type GetTgaPercentageQueryParams = {
	/**
	 * Will auto-select the issue branch if set to true. Otherwise, if given, the branchName will be used. As fallback the default branch will be used.
	 */
	'auto-select-branch'?: boolean, 
	/**
	 * If this is given, all issues are shown on this branch. Otherwise, the branch is auto-determined for each issue separately.
	 */
	'branch-name'?: string, 
	/**
	 * Will include changes introduced by child issues.
	 */
	'include-child-issues'?: boolean, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean
};

export type GetTgaTableQueryParams = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean
};

export type GetTgaTestCoveragePartitionsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type GetTgaTrendQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean
};

export type GetTriggerGraphDotQueryParams = {
	/**
	 * Whether read/written indexes are included as nodes in the graph.
	 */
	'include-indexes'?: boolean, 
	/**
	 * Names of nodes in the graph that should be used to start slicing. This can be trigger names or index names. To slice by index, use the index name, not the index-class name (e.g., check-phase-dependencies, not CheckPhaseResultDependencyIndex). All nodes that are not forward/backward reachable from these nodes are not displayed.Nodes are matched exact, so provide the exact names of triggers/indexes that you want to be included.If the list is empty, the complete graph is returned.
	 */
	'slice-nodes'?: Array<string>, 
	/**
	 * If true (forward slicing), only nodes influenced by the slice nodes are returned (i.e., nodes that are executed \"after\" the slice nodes). If false (backward slicing), we return only nodes that influence one of the slice nodes.
	 */
	'forward-slice'?: boolean
};

export type GetUserAutoCompletionSuggestionsQueryParams = {
	/**
	 * Maximum number of results to return.
	 */
	'limit'?: number, 
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type GetUserAvatarDataQueryParams = {
	/**
	 * Controls the least size of the image.
	 */
	'size'?: string
};

export type GetVerifiedBySpecItemsQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type ImportAbapLintConfigurationFileBody = {
	'configuration-files': Array<File>, 
	'enable-teamscale-defaults'?: boolean
};

export type ImportAnalysisProfileBody = {
	'analysis-profile': Array<File>
};

export type ImportAndReplaceDashboardsBody = {
	'dashboard': Array<File>
};

export type ImportBackupBody = {
	/**
	 * The backups to import.
	 */
	'backup'?: Array<File>, 
	/**
	 * Path to the backup
	 */
	'backup-path'?: string, 
	/**
	 * Remote user access token
	 */
	'remote-access-token'?: string, 
	/**
	 * Path to the instance
	 */
	'remote-url'?: string, 
	/**
	 * Remote user
	 */
	'remote-user'?: string, 
	/**
	 * Whether to enable shadow mode right after import.
	 */
	'shadow-mode'?: boolean, 
	/**
	 * Whether to skip importing global data
	 */
	'skip-global-data-import'?: boolean, 
	/**
	 * Whether to skip new projects and only import backup data into existing projects.
	 */
	'skip-new-projects'?: boolean, 
	/**
	 * Whether to skip the project validation on import
	 */
	'skip-project-validation'?: boolean
};

export type ImportDashboardBody = {
	'dashboard': Array<File>
};

export type ImportDashboardTemplateBody = {
	'dashboard-template': Array<File>
};

export type ImportExternalFindingsBody = {
	'external-findings'?: File
};

export type ImportExternalMetricsBody = {
	'metrics-descriptions-import-file'?: File
};

export type ImportGroupQueryParams = {
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type ImportSonarProfileBody = {
	'blocker-enablement'?: EFindingEnablementEntry, 
	'critical-enablement'?: EFindingEnablementEntry, 
	'enable-teamscale-defaults'?: boolean, 
	'info-enablement'?: EFindingEnablementEntry, 
	'major-enablement'?: EFindingEnablementEntry, 
	'minor-enablement'?: EFindingEnablementEntry, 
	'profile': File
};

export type ImportSwiftLintConfigurationBody = {
	'configuration': File, 
	'enable-teamscale-defaults'?: boolean
};

export type ImportUserQueryParams = {
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type ListAllParseLogEntriesQueryParams = {
	/**
	 * The logs will start at this given index.
	 */
	'start'?: number, 
	/**
	 * Limits the number of returned logs to the given number.
	 */
	'max'?: number
};

export type ListMergeRequestsQueryParams = {
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number, 
	/**
	 * Name of the field to sort entries by
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * The value to filter by in the merge request list. If the value is empty do not filter.
	 */
	'filter'?: string, 
	/**
	 * The status of the merge request to filter by.
	 */
	'status'?: EMergeRequestStatusEntry
};

export type ListQualityReportsQueryParams = {
	/**
	 * The project id
	 */
	'projectId'?: string
};

export type LoadFromNamedTestListQueryParams = {
	'testListName'?: string, 
	'replaceTests'?: boolean
};

export type MarkDashboardAsFavoriteQueryParams = {
	/**
	 * Whether to mark or unmark a dashboard as favorite
	 */
	'setFavorite': boolean
};

export type PerformTestQueryQueryParams = {
	/**
	 * Test query
	 */
	'query': string, 
	/**
	 * The index of the first entry to return starting at 0
	 */
	'start'?: number, 
	/**
	 * Maximum number of entries to return. If set to 0 all entries will be omitted and only relevant metadata is returned. If set to a negative value, all relevant entries are returned.
	 */
	'max'?: number, 
	/**
	 * Name of the field to sort entries by
	 */
	'sort-by'?: string, 
	/**
	 * The sort order
	 */
	'sort-order'?: ESortOrderEntry, 
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * If true only tests with testwise coverage will be returned
	 */
	'only-with-coverage'?: boolean
};

export type PostBasicRoleForInstanceQueryParams = {
	/**
	 * Determines the basic permission scope for the instances. Represented by the enum values of EBasicPermissionScope.
	 */
	'permission-scope': EBasicPermissionScopeEntry
};

export type PostBasicRoleGloballyQueryParams = {
	/**
	 * Determines the basic permission scope for the instances. Represented by the enum values of EBasicPermissionScope.
	 */
	'permission-scope': EBasicPermissionScopeEntry
};

export type PostResolveUserAliasesBody = {
	/**
	 * User aliases to resolve
	 */
	'alias'?: Array<string>
};

export type PostTgaPercentageQueryParams = {
	/**
	 * Will auto-select the issue branch if set to true. Otherwise, if given, the branchName will be used. As fallback the default branch will be used.
	 */
	'auto-select-branch'?: boolean, 
	/**
	 * If this is given, all issues are shown on this branch. Otherwise, the branch is auto-determined for each issue separately.
	 */
	'branch-name'?: string, 
	/**
	 * Will include changes introduced by child issues.
	 */
	'include-child-issues'?: boolean, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution of methods.
	 */
	'only-executed-methods'?: boolean, 
	/**
	 * If this parameter is given and is a valid issue ID, information about all methods changed in the context of this issue ID will be returned. In this case, the baseline parameter is ignored (the baseline is determined automatically for the issue instead). If the special value \"###no-connector###|[no-issue]\" is given, instead all methods that were changed in commits without an issue link between the baseline and end date are returned.
	 */
	'issue-id'?: string, 
	/**
	 * If this is true, we will compute TGA information based on the methods changed in the history of commit1 but not in the history of commit2. This simulates a merge from commit1 to commit2.
	 */
	'merge-request-mode'?: boolean, 
	/**
	 * Uniform path at which the request should focus on
	 */
	'uniform-path'?: string, 
	/**
	 * The test uniform path for which coverage should be shown in the treemap. This is an exclusive alternative to test-query i.e. if given, test-query is ignored.
	 */
	'test-uniform-path'?: string, 
	/**
	 * Test query that determines which coverage should be shown in the treemap. This is an exclusive alternative to test-uniform-path i.e. if test-uniform-path is given, this parameter is ignored.
	 */
	'test-query'?: string, 
	/**
	 * Optional key into the cache index for merge base calculation. If this is known it can be used to speed up calculation of the merge base.
	 */
	'merge-base-cache-key'?: string, 
	/**
	 * Optional merge request identifier to retrieve the merge base info. If this is provided it can be used to directly fetch the merge base info from the merge request delta index before trying to calculate the merge base. This is especially required when creating the TgaBranchMergeRequest for an already merged  merge request.
	 */
	'merge-request-identifier'?: string
};

export type PostTgaPercentageBody = {
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * Whether we want to view only the churn. This means coverage is ignored for the assessment and methods are only classified as unchanged, added or modified.
	 */
	'churn'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies further Teamscale projects from which to consider test coverage.
	 */
	'cross-annotation-project-patterns'?: Array<string>, 
	/**
	 * If this parameter is given, the service disregards code changes and only assesses execution/coverage of methods. The result will not contain information about test gaps, but only about which methods were executed.
	 */
	'execution-only'?: boolean, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>
};

export type PostUserAvatarDataBody = {
	/**
	 * User avatar image
	 */
	'avatar'?: object
};

export type PreviewRepositoryBranchesQueryParams = {
	/**
	 * The id of the project to which the connector configuration belongs
	 */
	'projectId'?: string
};

export type PreviewRepositoryCommitMessagesQueryParams = {
	/**
	 * The name of the branch to fetch the commits from
	 */
	'branchName'?: string, 
	/**
	 * The id of the project to which the connector configuration belongs
	 */
	'projectId'?: string
};

export type PreviewRepositoryFilesQueryParams = {
	/**
	 * The id of the project to which the connector configuration belongs
	 */
	'projectId'?: string
};

export type ProcessBuildWebhookEventQueryParams = {
	/**
	 * Git revision for which the build job is running.
	 */
	'revision': string, 
	/**
	 * Git repository URL for which the build job is running.
	 */
	'repository-url': string, 
	/**
	 * Git repository's platform. It must be one of [AZURE_DEVOPS_GIT, BITBUCKET, BITBUCKET_SERVER, GITEA, GITHUB, GITLAB, SCM_MANAGER]
	 */
	'repository-platform': ERepositoryConnectorEntry, 
	/**
	 * The URL the GitHub instance is reachable at. This parameter is required and used only if repository-platform is set to GITHUB.
	 */
	'github-server-url'?: string
};

export type PutAvatarDataQueryParams = {
	/**
	 * Controls the size of the crop that is being cut out of the avatar image.
	 */
	'avatarSize'?: number, 
	/**
	 * Controls the x offset of the crop that is being cut out of the avatar image.
	 */
	'avatarOffsetX'?: number, 
	/**
	 * Controls the y offset of the crop that is being cut out of the avatar image.
	 */
	'avatarOffsetY'?: number
};

export type PutJobTestsFromImpactedQueryParams = {
	/**
	 * The baseline commit (exclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'baseline'?: UnresolvedCommitDescriptor, 
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end'?: UnresolvedCommitDescriptor, 
	/**
	 * The baseline revision (exclusive). If both revision and commit are given, the revision will be preferred.
	 */
	'baseline-revision'?: string, 
	/**
	 * The end revision (inclusive). If both revision and commit are given, the revision will be preferred.
	 */
	'end-revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If this is true, all available test coverage partitions are considered.
	 */
	'all-partitions'?: boolean, 
	/**
	 * The name of the test prioritization strategy
	 */
	'prioritization-strategy'?: ETestPrioritizationStrategyEntry, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Append and prioritize non-impacted tests after impacted tests.
	 */
	'include-non-impacted'?: boolean, 
	/**
	 * Append and prioritize previously failed and skipped tests before impacted tests.
	 */
	'include-failed-and-skipped'?: boolean, 
	/**
	 * Append and prioritize tests for which there is no coverage yet.
	 */
	'include-added-tests'?: boolean, 
	/**
	 * The upper bound for the predicted test execution duration in milliseconds that the resulting test list should exhibit. All impacted tests that do not fit into this budget will be cut off.
	 */
	'max-exec-time'?: number, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string, 
	'cross-annotation-project-patterns'?: Array<string>, 
	'test-gaps-only'?: boolean
};

export type PutJobTestsFromQueryQueryParams = {
	'query'?: string, 
	/**
	 * If true only tests with testwise coverage will be considered
	 */
	'only-with-coverage'?: boolean
};

export type ReceiveAnalysisCompletedConfirmationQueryParams = {
	'sessionKey'?: string, 
	'projectId'?: string, 
	'commitDescriptor'?: UnresolvedCommitDescriptor
};

export type RedirectToCorrectHostQueryParams = {
	/**
	 * Id of the snapshot the comparison belongs to
	 */
	'snapshot-id'?: string, 
	/**
	 * Id of the comparison the example belongs to
	 */
	'comparison-id'?: string, 
	/**
	 * Project ID of the example
	 */
	'project'?: string, 
	/**
	 * ID of the example
	 */
	'example-id'?: string, 
	/**
	 * Type of the example
	 */
	'example-type'?: EInstanceComparisonTypeEntry, 
	/**
	 * Whether the example is on the remote instance
	 */
	'is-remote-example'?: boolean
};

export type RegenerateStoredServiceProviderCertificateQueryParams = {
	'name'?: string
};

export type RegisterProfilerQueryParams = {
	/**
	 * The ID of the profiler configuration to retrieve.
	 */
	'configuration-id': string
};

export type RemoveAccessKeysQueryParams = {
	'keys'?: Array<string>
};

export type RequestAnalysisAndOpenSessionQueryParams = {
	'projectId'?: string, 
	'commitDescriptor'?: UnresolvedCommitDescriptor, 
	'returnAddress'?: string
};

export type RequestAnalysisAndOpenSession1QueryParams = {
	'projectId'?: string, 
	'commitDescriptor'?: UnresolvedCommitDescriptor, 
	'returnAddress'?: string
};

export type ResolveFindingQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor
};

export type SetupJobQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end': UnresolvedCommitDescriptor, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Do the minimization only for the tests specified by the query.
	 */
	'query'?: string, 
	/**
	 * The maximum duration of the minimized test suite in milliseconds (only relevant for test suite minimization).
	 */
	'max-exec-time'?: number, 
	/**
	 * The regex that is used to determine the clustering of tests. The first capture group of the regex should match the subset of the test uniform path that represents the cluster ID. Tests with the same cluster ID are grouped and prioritized together. If left empty, each cluster contains exactly one test.
	 */
	'clustering-regex'?: string, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string
};

export type ShutdownBody = {
	/**
	 * The exit code of the process. By convention, a nonzero status code indicates abnormal termination.
	 */
	'exitCode'?: number
};

export type SynchronizeAllGroupsQueryParams = {
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type SynchronizeAllUsersQueryParams = {
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type SynchronizeSingleGroupQueryParams = {
	/**
	 * The name of the remote server.
	 */
	'server-name': string, 
	/**
	 * The type of the remote server (e.g. ldap or crowd).
	 */
	'server-type': EAuthenticationToolEntry
};

export type TriggerSnapshotBackupBody = {
	'target'?: string
};

export type UnmarkToleratedAndFalsePositiveFindingsBeforeCommitQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * Whether false positive findings should be unmarked as well. If not specified, only tolerated findings will be unmarked.
	 */
	'unmark-false-positives'?: boolean
};

export type UpdateAnalysisProfileQueryParams = {
	/**
	 * A comment similar to git commit messages associated with the changes made in the analysis profile
	 */
	'analysisProfileEditComment'?: string
};

export type UpdateExternalAccountsQueryParams = {
	/**
	 * Skip connector validation for connectors using the account.
	 */
	'skip-connector-validation'?: boolean
};

export type UpdateJobOptionsQueryParams = {
	/**
	 * The end commit (inclusive). This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided or \"HEAD\" to refer to the most recent commit. This can optionally be prefixed by the name of the branch, followed by a colon (by default, we use the default branch). You can append a \"p1\" to resolve to the immediate parent of the most recent commit at the given branch/timestamp. p2 would resolve to the parent of the immediate parent and so on. In merge commits, the parent resolution considers only the first parent. A parameter value that uses all features would be \"master:1601637680000p1\"
	 */
	'end': UnresolvedCommitDescriptor, 
	/**
	 * This parameter may be given multiple times. Specifies the test coverage partitions to consider.
	 */
	'partitions'?: Array<string>, 
	/**
	 * If set to true the request will fail with '412 PRECONDITION FAILED' if the exact given end commit has not been processed yet and therefore data may not be up-to-date.
	 */
	'ensure-processed'?: boolean, 
	/**
	 * Do the minimization only for the tests specified by the query.
	 */
	'query'?: string, 
	/**
	 * The maximum duration of the minimized test suite in milliseconds (only relevant for test suite minimization).
	 */
	'max-exec-time'?: number, 
	/**
	 * The regex that is used to determine the clustering of tests. The first capture group of the regex should match the subset of the test uniform path that represents the cluster ID. Tests with the same cluster ID are grouped and prioritized together. If left empty, each cluster contains exactly one test.
	 */
	'clustering-regex'?: string, 
	/**
	 * Consider coverage within this given uniform path only.
	 */
	'covering-path'?: string
};

export type UpdateTaskQueryParams = {
	'keep-findings'?: boolean
};

export type UploadArchitectureQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter allows to pass a revision instead of a timestamp.
	 */
	'revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * The commit message describing the changes made by the upload.
	 */
	'message'?: string, 
	/**
	 * The parameter that determines the upload format.
	 */
	'format'?: EArchitectureFormatEntry, 
	/**
	 * The parameter used for passing in a template architecture.
	 */
	'template'?: string, 
	/**
	 * If true, the architecture will be saved to all important branches and default branch in addition to the current branch
	 */
	'save-to-all-important-branches'?: boolean
};

export type UploadArchitectureAsJsonQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter allows to pass a revision instead of a timestamp.
	 */
	'revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * The commit message describing the changes made by the upload.
	 */
	'message'?: string, 
	/**
	 * If true, the architecture will be saved to all important branches and default branch in addition to the current branch
	 */
	'save-to-all-important-branches'?: boolean
};

export type UploadExternalSnapshotBody = {
	/**
	 * The snapshot file
	 */
	'snapshot'?: File, 
	/**
	 * Name of the external snapshot
	 */
	'snapshot-name'?: string
};

export type UploadReportQueryParams = {
	/**
	 * This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	't'?: UnresolvedCommitDescriptor, 
	/**
	 * This parameter allows to pass a revision instead of a timestamp.
	 */
	'revision'?: string, 
	/**
	 * When using the revision parameter, this parameter allows to pass a repository name which is used to identify the correct commit in situations where the same revision exists in multiple repositories.
	 */
	'repository'?: string, 
	/**
	 * A message that describes the external analysis, similar to a commit message.
	 */
	'message'?: string, 
	/**
	 * The name of the logical partition to store the results into. All existing data in this partition will be invalidated. A partition typically corresponds to one analysis run, i.e. if there are two independent builds/runs, they must use different partitions.The partition parameter is only required with an auto-create session.
	 */
	'partition'?: string, 
	/**
	 * Whether to move the upload timestamp to right after the last commit.
	 */
	'movetolastcommit'?: boolean, 
	/**
	 * The format of the uploaded report.
	 */
	'format': EReportFormatEntry, 
	/**
	 * The path prefix for the uploaded test artifacts. For coverage reports the covered paths are only matched against those files that have the specified prefix within the Teamscale project. This can be used if the same package structures and classes appear in multiple subfolders of a project. For test execution reports the prefix is prepended to the test execution names, which allows to make otherwise ambiguous test paths unique.
	 */
	'path-prefix'?: string
};

export type UploadReportBody = {
	/**
	 * The external analysis report(s) to upload to Teamscale. The reports are expected to be UTF-8 encoded, other encodings need to be specified.  Note: Files that are *not* encoded in UTF-8, or without a BOM, and are not specified as such, cannot be read reliably, which might lead to parsing errors. For more information on uploading external analysis reports, see https://docs.teamscale.com/howto/uploading-external-results.
	 */
	'report': Array<File>
};

export type UploadSlideImageBody = {
	'slide-image'?: object
};


/** Type definition of the QUERIES interface. */
export type Queries = {

	/**
	 * Add comment to task
	 *
	 * Adds a comment to the task specified by ID. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param task ID of the task to add comment to
	 */
	addCommentToTask(this: void, project: string, task: number, body: string): QueryOperation<void>

	/**
	 * Add metrics
	 *
	 * Adds a set of external metrics to the schema. This service is public API since Teamscale 5.8. The API requires the user to have Edit External Metrics Schema permissions.
	 */
	addExternalMetrics(this: void, body: Array<MetricSchemaChangeEntry>): QueryOperation<void>

	/**
	 * Add report slide
	 *
	 * Adds slide(s) to an existing quality report. The API requires the user to have Edit permissions on the Quality Report reportId.
     * @param reportId The ID of the report that is added.
	 */
	addQualityReportSlides(this: void, reportId: string, body: Record<string, ReportSlideData>): QueryOperation<void>

	/**
	 * Get autocompletion suggestions
	 *
	 * Gets autocompletion suggestions based on the input The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	autocompleteIssueId(this: void, project: string, queryParams: AutocompleteIssueIdQueryParams): QueryOperation<Array<string>>

	/**
	 * Autocomplete user group name
	 *
	 * null The API requires no permissions
	 */
	autocompleteUserGroup(this: void, queryParams: AutocompleteUserGroupQueryParams): QueryOperation<Array<string>>

	/**
	 * Get user autocompletion suggestions
	 *
	 * Gets autocompletion suggestions based on the input The API requires no permissions
	 */
	autocompleteUserName(this: void, queryParams: AutocompleteUserNameQueryParams): QueryOperation<Array<string>>

	/**
	 * Returns user activity statistics.
	 *
	 * Returns the number of users who have been active during different time durations. The API requires the user to have View System Status permissions.
	 */
	calculateActiveUsersStatistics(this: void): QueryOperation<Array<UserActivity>>

	/**
	 * Returns committer activity statistics.
	 *
	 * Returns the number of committers who have been contributing during different time durations. The API requires the user to have Access Administrative Services permissions.
	 */
	calculateCommitterStatistics(this: void): QueryOperation<Array<UserActivity>>

	/**
	 * Cancel the provided trigger
	 *
	 * Cancels the provided trigger. There are two steps of canceling a trigger. The first is via a flag, the trigger has to look up and may cancel gracefully. The second is via interrupt, where the trigger may be canceled at any operation, which may lead to inconsistent data. The API requires the user to have Access Administrative Services permissions.
     * @param workerId 
	 */
	cancelTrigger(this: void, workerId: string, body: CancelTriggerRequestBody): QueryOperation<void>

	/**
	 * Clear analysis results cached on this Execution Server for a given project
	 *
	 * The execution server caches analysis results and serves them if the same analysis request comes again. This DELETE request clears that cache of analysis results. The API requires the user to have Access Administrative Services permissions.
	 */
	clearResultsCache(this: void): QueryOperation<void>

	/**
	 * Commit session
	 *
	 * Commits and closes the given session. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
     * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	commitAnalysisResults(this: void, project: string, sessionId: string): QueryOperation<void>

	/**
	 * Get metric table
	 *
	 * Retrieves a list of metric table entries for the given uniform path and its children. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	computeMetricTable(this: void, project: string, queryParams: ComputeMetricTableQueryParams): QueryOperation<MetricTablePage>

	/**
	 * Create analysis profile
	 *
	 * Creates a new analysis profile. The API requires the user to have Create Analysis Profiles permissions.
	 */
	createAnalysisProfile(this: void, body: AnalysisProfile): QueryOperation<void>

	/**
	 * Export backup.
	 *
	 * Triggers the creation of a backup and returns its ID. This service is public API since Teamscale 6.1. The user needs to have the permission to export global data provided the backup contains any and the permission to backup project data for all projects contained in the backup.
	 */
	createBackup(this: void, body: CreateBackupBody): QueryOperation<string>

	/**
	 * Trigger comparison computation
	 *
	 * Triggers computation of the comparison between two snapshots and returns the ID of the new comparison. One of the snapshots is stored locally, the other fetched from a remote instance. The API requires the user to have Access Administrative Services permissions.
     * @param snapshotId The ID of the local snapshot.
	 */
	createComparison(this: void, snapshotId: string, body: CreateComparisonBody): QueryOperation<string>

	/**
	 * Create dashboard
	 *
	 * Creates a new dashboard. Every logged in user is allowed to create dashboards
	 */
	createDashboard(this: void, body: DashboardDescriptor): QueryOperation<string>

	/**
	 * Create dashboard from template
	 *
	 * Creates a dashboard from a template. Returns the ID of the created dashboard. Every logged in user is allowed to create dashboards
	 */
	createDashboardFromTemplate(this: void, queryParams: CreateDashboardFromTemplateQueryParams): QueryOperation<string>

	/**
	 * Create dashboard template
	 *
	 * Creates a new dashboard template The API requires the user to have Create Dashboard Templates permissions.
	 */
	createDashboardTemplate(this: void, body: DashboardTemplateDescriptor): QueryOperation<string>

	/**
	 * Create external accounts
	 *
	 * Creates new external accounts. The API requires the user to have Create External Accounts permissions.
	 */
	createExternalAccounts(this: void, body: ExternalCredentialsData): QueryOperation<void>

	/**
	 * Create external analysis group
	 *
	 * Creates a new external analysis group. This service is public API since Teamscale 6.0. The API requires the user to have Edit External Findings Schema permissions.
	 */
	createExternalAnalysisGroup(this: void, body: ExternalAnalysisGroup): QueryOperation<void>

	/**
	 * Create external finding description
	 *
	 * Create a new external finding description in the system. This service is public API since Teamscale 6.0. The API requires the user to have Edit External Findings Schema permissions.
	 */
	createExternalFindingDescription(this: void, body: ExternalFindingsDescription): QueryOperation<void>

	/**
	 * Create external storage backend
	 *
	 * Creates new external storage backend. The API requires the user to have Configure External Storage Backends permissions.
	 */
	createExternalStorageBackend(this: void, body: ExternalStorageBackendData): QueryOperation<void>

	/**
	 * Create metric threshold configuration
	 *
	 * Creates a new metric threshold configuration. The API requires the user to have Create Metric Threshold Configurations permissions.
	 */
	createMetricThresholdConfiguration(this: void, body: MetricThresholdConfiguration): QueryOperation<void>

	/**
	 * Generate Access Key
	 *
	 * Generates a new access key. The API requires the user to have Edit permissions on the user.
     * @param user 
	 */
	createNewRandomAccessKey(this: void, user: string): QueryOperation<string>

	/**
	 * Update baseline
	 *
	 * Creates a new or updates an existing baseline. This service is public API since Teamscale 5.2. The API requires the user to have Edit Baselines permissions on the project.
     * @param project The project ID.
     * @param baseline 
	 */
	createOrUpdateBaseline(this: void, project: string, baseline: string, queryParams: CreateOrUpdateBaselineQueryParams, body: BaselineInfo): QueryOperation<void>

	/**
	 * Create profiler configuration
	 *
	 * Creates a new profiler configuration. The API requires the user to have Create Profiler Configurations permissions.
	 */
	createProfilerConfiguration(this: void, body: ProfilerConfiguration): QueryOperation<void>

	/**
	 * Create project
	 *
	 * Creates project based on provided project configuration. This service is public API since Teamscale 7.1. The API requires the user to have Create Projects permissions.
	 */
	createProject(this: void, queryParams: CreateProjectQueryParams, body: ProjectConfiguration): QueryOperation<void>

	/**
	 * Create quality report
	 *
	 * Creates a new quality report either from scratch or by copying an existing report. The API requires the user to have Create Quality Reports permissions.
	 */
	createQualityReport(this: void, queryParams: CreateQualityReportQueryParams, body: QualityArtifactParameters): QueryOperation<string>

	/**
	 * Create quality retrospective
	 *
	 * Creates a new quality retrospective either from scratch or by copying an existing retrospective. The API requires the user to have View Project, Edit Retrospectives permissions on the project.
     * @param project The project ID.
	 */
	createRetrospective(this: void, project: string, queryParams: CreateRetrospectiveQueryParams, body: QualityArtifactParameters): QueryOperation<string>

	/**
	 * Get session ID
	 *
	 * Obtains a new session ID. Using session ID allows to perform external uploads in multiple calls that all belong to the same session. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
	 */
	createSession(this: void, project: string, queryParams: CreateSessionQueryParams): QueryOperation<string>

	/**
	 * Create snapshot
	 *
	 * Triggers creation of a snapshot of this instance and returns the ID of the created snapshot. Additionally, a remote snapshot with the same parameters can be created and a comparison between the two scheduled. The API requires the user to have Access Administrative Services permissions.
	 */
	createSnapshot(this: void, body: CreateSnapshotBody): QueryOperation<string>

	/**
	 * Create support request
	 *
	 * Creates the support request. The API requires the user to have Create Support Request permissions.
	 */
	createSupportRequest(this: void, body: SupportRequestData): QueryOperation<void>

	/**
	 * Create task
	 *
	 * Creates a new task. The API requires the user to have Edit Tasks permissions on the project.
     * @param project The project ID.
	 */
	createTask(this: void, project: string, body: Task): QueryOperation<Task>

	/**
	 * Create user group
	 *
	 * Creates new user group. The API requires the user to have Create Groups permissions.
	 */
	createUserGroup(this: void, body: UserGroup): QueryOperation<void>

	/**
	 * Delete all unprocessed architecture uploads
	 *
	 * Deletes all commits of the architecture identified by the provided architecture path. The API requires the user to have Edit Architectures permissions on the project.
     * @param project 
	 */
	deleteAllUnprocessedArchitectureCommits(this: void, project: string, queryParams: DeleteAllUnprocessedArchitectureCommitsQueryParams): QueryOperation<void>

	/**
	 * Delete analysis profile
	 *
	 * Deletes the analysis profile identified by the given name from the system The API requires the user to have Delete permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName Name of the analysis profile to delete
	 */
	deleteAnalysisProfile(this: void, analysisProfileName: string): QueryOperation<void>

	/**
	 * Delete session
	 *
	 * Deletes a session in case of abortion. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
     * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	deleteAnalysisResults(this: void, project: string, sessionId: string): QueryOperation<void>

	/**
	 * Delete baseline
	 *
	 * Deletes a baseline. This service is public API since Teamscale 5.2. The API requires the user to have Edit Baselines permissions on the project.
     * @param project The project ID.
     * @param baseline 
	 */
	deleteBaseline(this: void, project: string, baseline: string): QueryOperation<void>

	/**
	 * Delete comparison
	 *
	 * Permanently deletes the given comparison. The API requires the user to have Access Administrative Services permissions.
     * @param snapshotId The ID of the local snapshot the comparison belongs to.
     * @param comparisonId The ID of the comparison to delete.
	 */
	deleteComparison(this: void, snapshotId: string, comparisonId: string): QueryOperation<void>

	/**
	 * Remove dashboard
	 *
	 * Removes the dashboard descriptor identified by given ID. The API requires the user to have Edit permissions on the Dashboard dashboardId.
     * @param dashboardId ID of the dashboard to retrieve.
	 */
	deleteDashboard(this: void, dashboardId: string): QueryOperation<void>

	/**
	 * Delete dashboard template
	 *
	 * Deletes the dashboard template identified by the given ID. The API requires the user to have Edit permissions on the Dashboard Template templateId.
     * @param templateId ID of the template to delete
	 */
	deleteDashboardTemplate(this: void, templateId: string): QueryOperation<void>

	/**
	 * Delete event announcement
	 *
	 * Deletes the given announcement so it will not be displayed to anyone anymore. The API requires the user to have Access Administrative Services permissions.
     * @param eventId 
	 */
	deleteEventAnnouncement(this: void, eventId: string): QueryOperation<void>

	/**
	 * Delete external accounts
	 *
	 * Deletes the external accounts identified by the given name from the system The API requires the user to have Delete permissions on the External Account externalCredentialsName.
     * @param externalCredentialsName Name of the external accounts to delete
	 */
	deleteExternalAccounts(this: void, externalCredentialsName: string, queryParams: DeleteExternalAccountsQueryParams): QueryOperation<void>

	/**
	 * Remove external analysis group
	 *
	 * Removes the external analysis group identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
     * @param groupName Name of the external analysis group to remove
	 */
	deleteExternalAnalysisGroup(this: void, groupName: string): QueryOperation<void>

	/**
	 * Remove external finding description
	 *
	 * Removes the external finding description identified by the given name The API requires the user to have Edit External Findings Schema permissions.
     * @param externalFindingDescriptionName Name of the finding description to remove
	 */
	deleteExternalFindingDescription(this: void, externalFindingDescriptionName: string): QueryOperation<void>

	/**
	 * Delete metric
	 *
	 * Deletes an external metric. The API requires the user to have Edit External Metrics Schema permissions.
     * @param metricName Metric to delete
	 */
	deleteExternalMetric(this: void, metricName: string): QueryOperation<void>

	/**
	 * Delete external snapshots
	 *
	 * Deletes the external snapshot with the provided name The API requires the user to have Access Administrative Services permissions.
     * @param name The name of the external snapshot to be deleted
	 */
	deleteExternalSnapshot(this: void, name: string): QueryOperation<void>

	/**
	 * Delete external storage backends
	 *
	 * Deletes the external storage backends identified by the given name from the system The API requires the user to have Delete permissions on the External Storage Backend externalStorageBackendName.
     * @param externalStorageBackendName Name of the external storage backend to delete
	 */
	deleteExternalStorageBackend(this: void, externalStorageBackendName: string, queryParams: DeleteExternalStorageBackendQueryParams): QueryOperation<void>

	/**
	 * Delete critical event logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalCriticalEventLogs(this: void, queryParams: DeleteGlobalCriticalEventLogsQueryParams): QueryOperation<void>

	/**
	 * Delete interaction logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalInteractionLogs(this: void, queryParams: DeleteGlobalInteractionLogsQueryParams): QueryOperation<void>

	/**
	 * Delete JS error logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalJavascriptErrorLogs(this: void, queryParams: DeleteGlobalJavascriptErrorLogsQueryParams): QueryOperation<void>

	/**
	 * Delete global role
	 *
	 * Removes an existing global role. The API requires the user to have Edit Roles permissions.
     * @param name The name of the role to delete.
	 */
	deleteGlobalRole(this: void, name: string): QueryOperation<void>

	/**
	 * Delete service logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalServiceLogs(this: void, queryParams: DeleteGlobalServiceLogsQueryParams): QueryOperation<void>

	/**
	 * Delete worker logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalWorkerLogs(this: void, queryParams: DeleteGlobalWorkerLogsQueryParams): QueryOperation<void>

	/**
	 * Remove the given list of tests (identified by their current ranking) from the list of tests to minimize in the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	deleteJobTests(this: void, project: string, jobId: string, queryParams: DeleteJobTestsQueryParams, body: Array<number>): QueryOperation<number>

	/**
	 * Delete metric threshold configuration
	 *
	 * Deletes the metric threshold configuration identified by the given name from the system The API requires the user to have Delete permissions on the Metric Threshold Configuration metricThresholdConfigurationName.
     * @param metricThresholdConfigurationName Name of the metric threshold configuration to delete
	 */
	deleteMetricThresholdConfiguration(this: void, metricThresholdConfigurationName: string): QueryOperation<void>

	/**
	 * Remove profiler configuration
	 *
	 * Removes the profiler configuration identified by given ID. The API requires the user to have Delete permissions on the Profiler Configuration configurationId.
     * @param configurationId ID of the dashboard to retrieve.
	 */
	deleteProfilerConfiguration(this: void, configurationId: string): QueryOperation<void>

	/**
	 * Delete critical event logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectCriticalEventLogs(this: void, project: string, queryParams: DeleteProjectCriticalEventLogsQueryParams): QueryOperation<void>

	/**
	 * Delete interaction logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectInteractionLogs(this: void, project: string, queryParams: DeleteProjectInteractionLogsQueryParams): QueryOperation<void>

	/**
	 * Delete JS error logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectJavascriptErrorLogs(this: void, project: string, queryParams: DeleteProjectJavascriptErrorLogsQueryParams): QueryOperation<void>

	/**
	 * Delete option
	 *
	 * Delete the project option identified by the given name. The API requires the user to have Edit Project Options permissions on the project.
     * @param project The project ID.
     * @param optionId Name of the option to delete
	 */
	deleteProjectOption(this: void, project: string, optionId: string): QueryOperation<void>

	/**
	 * Delete project role
	 *
	 * Removes an existing project role. The API requires the user to have Edit Roles permissions.
     * @param name The name of the role to delete.
	 */
	deleteProjectRole(this: void, name: string): QueryOperation<void>

	/**
	 * Delete service logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectServiceLogs(this: void, project: string, queryParams: DeleteProjectServiceLogsQueryParams): QueryOperation<void>

	/**
	 * Delete worker logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectWorkerLogs(this: void, project: string, queryParams: DeleteProjectWorkerLogsQueryParams): QueryOperation<void>

	/**
	 * Delete report
	 *
	 * Deletes the report with the given id. The API requires the user to have Delete permissions on the Quality Report id.
     * @param id The report id
	 */
	deleteQualityReport(this: void, id: string): QueryOperation<void>

	/**
	 * Put quality retrospective
	 *
	 * Updates/Renames an existing quality retrospective. The API requires the user to have View Project, Edit Retrospectives permissions on the project.
     * @param project The project ID.
     * @param id The id of the retrospective that should be deleted.
	 */
	deleteRetrospective(this: void, project: string, id: string): QueryOperation<void>

	/**
	 * Delete option
	 *
	 * Deletes the specified server option The API requires the user to have Edit Server Options permissions.
     * @param optionId Name of the option to delete
	 */
	deleteServerOption(this: void, optionId: string): QueryOperation<void>

	/**
	 * Delete snapshot
	 *
	 * Permanently deletes the given snapshot and all associated comparisons. The API requires the user to have Access Administrative Services permissions.
     * @param id The ID of the snapshot to delete.
	 */
	deleteSnapshot(this: void, id: string): QueryOperation<void>

	/**
	 * Delete test list
	 *
	 * Deletes a test list descriptor from the system. The API requires the user to have Edit Test Queries permissions on the project.
     * @param project The project ID.
     * @param testListName Name of the test list to delete
	 */
	deleteTestListDescriptor(this: void, project: string, testListName: string): QueryOperation<void>

	/**
	 * Delete user group
	 *
	 * Deletes the user group identified by the given name from the system The API requires the user to have Delete permissions on the Group userGroupName.
     * @param userGroupName Name of the user group to delete
	 */
	deleteUserGroup(this: void, userGroupName: string): QueryOperation<void>

	/**
	 * Delete option
	 *
	 * Deletes the user option identified by the given name The API requires the user to have Edit permissions on the user.
     * @param user 
     * @param optionId 
	 */
	deleteUserOption(this: void, user: string, optionId: string): QueryOperation<void>

	/**
	 * Get analysis profile documentation
	 *
	 * Returns documentation of an analysis profile. The documentation is restricted to findings which are enabled in the profile and includes descriptions of the findings. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	downloadAnalysisProfileDocumentation(this: void, analysisProfileName: string): QueryOperation<void>

	/**
	 * Download backup
	 *
	 * Allows downloading a Teamscale backup from the temporary file store. This service is public API since Teamscale 6.1. The user needs to have the permission to backup global data provided it is contained in the backup and the permission to backup project data for all projects contained in the backup.
     * @param backupId The backup ID.
	 */
	downloadBackup(this: void, backupId: string): QueryOperation<File>

	/**
	 * Download critical event logs
	 *
	 * Returns a file download of all critical event logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalCriticalEventLogs(this: void, queryParams: DownloadGlobalCriticalEventLogsQueryParams): QueryOperation<void>

	/**
	 * Download interaction logs
	 *
	 * Returns a file download of all interaction logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalInteractionLogs(this: void, queryParams: DownloadGlobalInteractionLogsQueryParams): QueryOperation<void>

	/**
	 * Download JS error logs
	 *
	 * Returns a file download of the JavaScript error logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalJavascriptErrorLogs(this: void, queryParams: DownloadGlobalJavascriptErrorLogsQueryParams): QueryOperation<void>

	/**
	 * Download service logs
	 *
	 * Returns a file download of all service logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalServiceLogs(this: void, queryParams: DownloadGlobalServiceLogsQueryParams): QueryOperation<void>

	/**
	 * Download worker logs
	 *
	 * Returns a file download of all worker logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalWorkerLogs(this: void, queryParams: DownloadGlobalWorkerLogsQueryParams): QueryOperation<void>

	/**
	 * Download heap dump
	 *
	 * Downloads a heap dump in HProf format. The API requires a HotSpot-based JVM. The API requires the user to have Access Administrative Services permissions.
	 */
	downloadHeapDump(this: void, body: DownloadHeapDumpBody): QueryOperation<void>

	/**
	 * Download critical event logs
	 *
	 * Returns a file download of all critical event logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectCriticalEventLogs(this: void, project: string, queryParams: DownloadProjectCriticalEventLogsQueryParams): QueryOperation<void>

	/**
	 * Download interaction logs
	 *
	 * Returns a file download of all interaction logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectInteractionLogs(this: void, project: string, queryParams: DownloadProjectInteractionLogsQueryParams): QueryOperation<void>

	/**
	 * Download JS error logs
	 *
	 * Returns a file download of the JavaScript error logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectJavascriptErrorLogs(this: void, project: string, queryParams: DownloadProjectJavascriptErrorLogsQueryParams): QueryOperation<void>

	/**
	 * Download service logs
	 *
	 * Returns a file download of all service logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectServiceLogs(this: void, project: string, queryParams: DownloadProjectServiceLogsQueryParams): QueryOperation<void>

	/**
	 * Download worker logs
	 *
	 * Returns a file download of all worker logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectWorkerLogs(this: void, project: string, queryParams: DownloadProjectWorkerLogsQueryParams): QueryOperation<void>

	/**
	 * Download support request
	 *
	 * Downloads the support request previously created with POST. The API requires the user to have Create Support Request permissions.
	 */
	downloadSupportRequest(this: void): QueryOperation<File>

	/**
	 * Returns the (possibly ranked) list of tests that have been selected for this job as CSV.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	downloadTestSelectionCsv(this: void, project: string, jobId: string, queryParams: DownloadTestSelectionCsvQueryParams): QueryOperation<File>

	/**
	 * Update dashboard
	 *
	 * Updates an existing dashboard. The API requires the user to have Edit permissions on the Dashboard dashboardId.
     * @param dashboardId The ID of the dashboard to update.
	 */
	editDashboard(this: void, dashboardId: string, body: DashboardDescriptor): QueryOperation<void>

	/**
	 * Edit option
	 *
	 * Edits the project option identified by the given name. The API requires the user to have Edit Project Options permissions on the project.
     * @param project The project ID.
     * @param optionId Name of the option to edit
	 */
	editProjectOption(this: void, project: string, optionId: string, body: Array<string>): QueryOperation<void>

	/**
	 * Edit project
	 *
	 * Edits project based on provided project configuration. This service is public API since Teamscale 7.1. The API requires the user to have Edit Project permissions on the project.
     * @param project The project ID.
	 */
	editProjectWithConfiguration(this: void, project: string, queryParams: EditProjectWithConfigurationQueryParams, body: ProjectConfiguration): QueryOperation<boolean>

	/**
	 * Edit options
	 *
	 * Edits the specified server option The API requires the user to have Edit Server Options permissions.
     * @param optionId Name of the option to edit
	 */
	editServerOption(this: void, optionId: string, queryParams: EditServerOptionQueryParams, body: Array<string>): QueryOperation<void>

	/**
	 * Edit option
	 *
	 * Edits the user option identified by the given name The API requires the user to have Edit permissions on the user.
     * @param user Name of the user to edit the option for
     * @param optionId Name of the option to edit
	 */
	editUserOption(this: void, user: string, optionId: string, body: object): QueryOperation<void>

	/**
	 * Edit users
	 *
	 * Performs given user operation. This service is public API since Teamscale 5.5.1. Needs delete permission.
	 */
	editUsers(this: void, body: UserBatchOperation): QueryOperation<string>

	/**
	 * Execute scheduler command
	 *
	 * Allows to pass a command to the scheduler. The command is appended to the URL and should be one of the values from ESchedulerCommand. Note that using this service may harm the stability and data integrity of your instance. The API requires the user to have Access Administrative Services permissions.
	 */
	executeSchedulerCommand(this: void, body: ExecuteSchedulerCommandBody): QueryOperation<void>

	/**
	 * Exports the analysis profile
	 *
	 * Retrieves the analysis profile identified by given name. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	exportAnalysisProfile(this: void, analysisProfileName: string): QueryOperation<void>

	/**
	 * Get a dashboard file
	 *
	 * Performs an export of a dashboard. Returns the descriptor of the dashboard as a .tsdashboard file. The dashboard must be submitted within an XML Teamscale Version Container. The API requires the user to have View permissions on the Dashboard dashboardId.
     * @param dashboardId The ID of the dashboard
	 */
	exportDashboard(this: void, dashboardId: string): QueryOperation<TeamscaleVersionContainer>

	/**
	 * Get a dashboard template
	 *
	 * Performs an export of a dashboard template. Returns the template as a .tstemplate file. The API requires no permissions
     * @param templateId ID of the dashboard template
	 */
	exportDashboardTemplate(this: void, templateId: string): QueryOperation<TeamscaleVersionContainer>

	/**
	 * Export external findings
	 *
	 * Downloads external findings groups and descriptions as a zip archive The API requires the user to have Edit External Findings Schema permissions.
	 */
	exportExternalFindings(this: void, queryParams: ExportExternalFindingsQueryParams): QueryOperation<File>

	/**
	 * Downloads external metrics
	 *
	 * Downloads external metrics as a ZIP file. The API requires the user to have Edit External Metrics Schema permissions.
	 */
	exportExternalMetrics(this: void): QueryOperation<void>

	/**
	 * Export impacted tests
	 *
	 * Returns an ordered list of test clusters that are impacted by the given changes. The returned list of tests is a subset of all tests known to Teamscale in CSV format. When the tests themselves have changed, new tests were added or tests have been deleted, this service cannot take that into account. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	exportImpactedTestsAsCsv(this: void, project: string, queryParams: ExportImpactedTestsAsCsvQueryParams): QueryOperation<void>

	/**
	 * Check if external accounts exist
	 *
	 * Checks if the external accounts identified by the given name exist. No permissions needed, as the service should check whether the credentials already exists for every user to prohibit overwriting credentials not visible for the current user
     * @param externalCredentialsName Name of the external accounts to check
	 */
	externalAccountsExist(this: void, externalCredentialsName: string): QueryOperation<ExternalCredentialsData>

	/**
	 * Check if external storage backends exist
	 *
	 * Checks if the external storage backend identified by the given name exist. No permissions needed, as the service checks whether the backend already exists for every user to prohibit overwriting backends not visible for the current user
     * @param externalStorageBackendName Name of the external storage backend to check
	 */
	externalStorageBackendExists(this: void, externalStorageBackendName: string): QueryOperation<ExternalStorageBackendData>

	/**
	 * Get job queue
	 *
	 * Retrieves the entire job queue from all projects. The API requires the user to have View System Status permissions.
	 */
	extractJobQueue(this: void): QueryOperation<Array<JobDescriptor>>

	/**
	 * Returns the initial commit which added the file at the given path. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param unresolvedCommit This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	findInitialCommit(this: void, project: string, unresolvedCommit: UnresolvedCommitDescriptor, queryParams: FindInitialCommitQueryParams): QueryOperation<CommitDescriptor | null>

	/**
	 * Get repository log entries
	 *
	 * Retrieves a specific count of repository log entries after a start timestamp or before an end timestamp. If both start and end timestamp are missing, the newest log entries are returned. If both a start and end timestamp are provided all log entries between the two are returned. The resulting log entries include all log entries from merged commits into the current branch. The current branch and branch state at a point in time are determined by the commit parameter. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	findLogEntriesInRange(this: void, project: string, queryParams: FindLogEntriesInRangeQueryParams): QueryOperation<Array<ExtendedRepositoryLogEntry>>

	/**
	 * Flags/unflags findings
	 *
	 * Flags/unflags the given findings with the given flagging type. This service is public API since Teamscale 8.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	flagFindings(this: void, project: string, queryParams: FlagFindingsQueryParams, body: FindingBlacklistRequestBody): QueryOperation<void>

	/**
	 * Get Absolute Date
	 *
	 * Transforms a relative date string into a fixed date string The API requires no permissions
	 */
	getAbsoluteDate(this: void, queryParams: GetAbsoluteDateQueryParams): QueryOperation<string>

	/**
	 * List Access Keys Metadata
	 *
	 * List the metadata of all access keys of the user The API requires the user to have Edit permissions on the user.
     * @param user 
	 */
	getAccessKeysMetadata(this: void, user: string): QueryOperation<Array<AccessKeyDTO>>

	/**
	 * Returns active users (anonymized) for the given primary project IDs
	 *
	 * For the given primary project IDs, returns all users (anonymized) of the last 3 months that contribute to the license volume. If an empty list is provided, all current projects will be included. The results might differ from the GET query in case the primary project id of a project changed in the last three months or if a user has logged into Teamscale but has not interacted with any project. The API requires the user to have Access Administrative Services permissions.
	 */
	getActiveUsersForProjects(this: void, body: Array<string>): QueryOperation<Array<string>>

	/**
	 * Returns a list of sets of additionally covered methods. 
	 *
	 * The set on position i denotes that executing the test corresponding to position i covers additionally (relative to the methods covered by tests 0 to i-1) the methods described in the set. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	getAdditionallyCoveredMethods(this: void, project: string, jobId: string): QueryOperation<Array<Array<MethodLocation>>>

	/**
	 * Get affected files by commits
	 *
	 * Returns repository log file entries for commits, i.e. the association between files and repository log entries. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getAffectedFilesForCommits(this: void, project: string, queryParams: GetAffectedFilesForCommitsQueryParams): QueryOperation<Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Get aggregated trigger performance metrics
	 *
	 * Allows downloading the performance aggregate data report as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getAggregatedTriggerPerformanceMetrics(this: void): QueryOperation<void>

	/**
	 * Get all baselines
	 *
	 * Returns a list of all configured baselines for the project. This service is public API since Teamscale 5.2. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getAllBaselines(this: void, project: string): QueryOperation<Array<BaselineInfo>>

	/**
	 * Get all dashboard templates
	 *
	 * Retrieves all the dashboard templates available in the system. Any user may see dashboard templates.
	 */
	getAllDashboardTemplates(this: void): QueryOperation<Array<DashboardTemplateDescriptor>>

	/**
	 * Get all dashboard descriptors
	 *
	 * Retrieves all the dashboard descriptors available in the system No permissions needed, as the service will only return the dashboard descriptors visible to current user.
	 */
	getAllDashboards(this: void, queryParams: GetAllDashboardsQueryParams): QueryOperation<Array<UserResolvedDashboardDescriptor>>

	/**
	 * Get all external accounts
	 *
	 * Retrieves all available external accounts in the system. No permissions needed, as the service will only return credentials visible to current user.
	 */
	getAllExternalAccounts(this: void): QueryOperation<Array<ExternalCredentialsData>>

	/**
	 * Get external analysis groups
	 *
	 * Retrieves all the available analysis groups in the system. The API requires the user to have Edit External Findings Schema permissions.
	 */
	getAllExternalAnalysisGroups(this: void): QueryOperation<Array<ExternalAnalysisGroup>>

	/**
	 * Get external snapshots
	 *
	 * Returns the list of all available externally uploaded snapshots The API requires the user to have Access Administrative Services permissions.
	 */
	getAllExternalSnapshots(this: void, queryParams: GetAllExternalSnapshotsQueryParams): QueryOperation<Array<ExternalInstanceComparisonSnapshotDto>>

	/**
	 * Get all external storage backends
	 *
	 * Retrieves all available external storage backends in the system. No permissions needed, as the service will only return backends visible to current user.
	 */
	getAllExternalStorageBackends(this: void): QueryOperation<Array<ExternalStorageBackendData>>

	/**
	 * Get external finding descriptions
	 *
	 * Retrieves all external finding descriptions available in the system The API requires the user to have Edit External Findings Schema permissions.
	 */
	getAllFindingsDescriptions(this: void): QueryOperation<Array<ExternalFindingsDescription>>

	/**
	 * Get all git tags
	 *
	 * Collects all git tags from all git connections in the given project. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getAllGitTags(this: void, project: string, queryParams: GetAllGitTagsQueryParams): QueryOperation<Array<GitTag>>

	/**
	 * Get all metric threshold configuration names
	 *
	 * Retrieves the names of all available metric threshold configurations in the system. No permissions needed, as the service will only return the names of metric threshold configurations visible to the current user.
	 */
	getAllMetricThresholdConfigurationNames(this: void, queryParams: GetAllMetricThresholdConfigurationNamesQueryParams): QueryOperation<Array<string>>

	/**
	 * Get all metric threshold configurations
	 *
	 * Retrieves all available metric threshold configurations in the system. No permissions needed, as the service will only return metric threshold configurations visible to the current user.
	 */
	getAllMetricThresholdConfigurations(this: void): QueryOperation<Array<MetricThresholdConfiguration>>

	/**
	 * Get all metrics for metric threshold configuration names
	 *
	 * Retrieves a list, the entries being the metric threshold configuration names and lists of the metrics included in the profile with that name. No permissions needed, as the service will only return the metrics and their profiles visible to the current user.
	 */
	getAllMetricsForThresholdProfiles(this: void, queryParams: GetAllMetricsForThresholdProfilesQueryParams): QueryOperation<Array<MetricsForThresholdProfile>>

	/**
	 * Get parse log overview
	 *
	 * Returns overview information about the parse log entries in all visible projects The API requires the user to have View System Status permissions.
	 */
	getAllParseLogOverviewEntries(this: void): QueryOperation<Array<ParseLogOverviewEntry>>

	/**
	 * Get details (name, last updated) for all test execution partitions
	 *
	 * Returns all available test execution partitions and their last updated timestamp; of the project that have been uploaded and processed up to the given commit. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getAllPartitionDetails(this: void, project: string, queryParams: GetAllPartitionDetailsQueryParams): QueryOperation<Array<PartitionInfo>>

	/**
	 * Get all profiler configurations
	 *
	 * Retrieves all the defined profiler configurations No permissions needed, as the service will only return the profiler configurations visible to current user.
	 */
	getAllProfilerConfigurations(this: void): QueryOperation<Array<ProfilerConfiguration>>

	/**
	 * Get projects
	 *
	 * Returns a list of all projects. This service is public API since Teamscale 7.1. Only projects visible to the user are returned in the get all queries.
	 */
	getAllProjects(this: void, queryParams: GetAllProjectsQueryParams): QueryOperation<Array<ProjectInfo>>

	/**
	 * Get snapshot IDs
	 *
	 * Returns the list of all available snapshot IDs.  The API requires the user to have Access Administrative Services permissions.
	 */
	getAllSnapshotIds(this: void, queryParams: GetAllSnapshotIdsQueryParams): QueryOperation<Array<string>>

	/**
	 * Get all role assignments for all groups and users (and assigned groups).
	 *
	 * Returns a list of all subject role assignments. The API requires the user to have Assign Global Roles permissions.
	 */
	getAllSubjectRoleAssignments(this: void): QueryOperation<Array<Array<SubjectRoleAssignments>>>

	/**
	 * Dumps the stack traces for all currently running threads as HTML. The API requires the user to have Access Administrative Services permissions.
	 */
	getAllThreadsDumpAsHtml(this: void): QueryOperation<string>

	/**
	 * Get all user group names
	 *
	 * Retrieves the names of all available user groups in the system. No permissions needed, as the service will only return group names visible to current user.
	 */
	getAllUserGroupNames(this: void): QueryOperation<Array<string>>

	/**
	 * Get all user groups
	 *
	 * Retrieves all available user groups in the system. No permissions needed, as the service will only return groups visible to current user.
	 */
	getAllUserGroups(this: void): QueryOperation<Array<UserGroup>>

	/**
	 * Get all users
	 *
	 * Returns a list of all users. This service is public API since Teamscale 5.2. Only users visible to the user are returned in the get all queries.
	 */
	getAllUsers(this: void): QueryOperation<Array<UserWithActivity>>

	/**
	 * Analysis profile version difference
	 *
	 * Calculates and return the difference between an older edit version and a newer version of an analysis profile. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisEditHistoryVersionDiff(this: void, analysisProfileName: string, queryParams: GetAnalysisEditHistoryVersionDiffQueryParams): QueryOperation<AnalysisProfileHistoryVersionComparison>

	/**
	 * Get analysis profile
	 *
	 * Retrieves the analysis profile identified by given name. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisProfile(this: void, analysisProfileName: string): QueryOperation<AnalysisProfile>

	/**
	 * Analysis Profile Edit History
	 *
	 * Returns the different versions of an analysis profile containing a version corresponding to each time the analysis profile was edited. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisProfileEditVersionHistory(this: void, analysisProfileName: string): QueryOperation<Array<AnalysisProfileEditVersion>>

	/**
	 * Analysis Profile Edit History Creation Times
	 *
	 * Returns a list of timestamps, each corresponding to the creation of a new profile edit history entry. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisProfileEditVersionHistoryTimestamps(this: void, analysisProfileName: string): QueryOperation<Array<number>>

	/**
	 * Get analysis profile metadata
	 *
	 * Retrieves the analysis profile identified by given name and returns its description, languages and tools. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisProfileMetadata(this: void, analysisProfileName: string): QueryOperation<AnalysisProfileMetadata>

	/**
	 * Get all analysis profile names
	 *
	 * Retrieves the names of all analysis profiles visible to the current user. No permissions needed, as the service will only return names of analysis profiles visible to current user.
	 */
	getAnalysisProfileNames(this: void): QueryOperation<Array<string>>

	/**
	 * Get analysis profile usage
	 *
	 * Returns a list of AnalysisProfileUsageInfoWithProjects indicating the list of referenced projects for each analysis profile No permissions needed, as the service will only return names of analysis profiles visible to current user.
	 */
	getAnalysisProfileUsageInfo(this: void): QueryOperation<Array<AnalysisProfileUsageInfoWithProjects>>

	/**
	 * Get analysis profile with referencing projects
	 *
	 * Returns an analysis profile annotated with the list of projects using the profile The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName 
	 */
	getAnalysisProfileWithProjects(this: void, analysisProfileName: string): QueryOperation<AnalysisProfileWithProjects>

	/**
	 * Get ClangTidy analysis results
	 *
	 * Gets the ClangTidy analysis results. Results may be incomplete if the session is not in the completed status. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisResultsByPath(this: void, queryParams: GetAnalysisResultsByPathQueryParams): QueryOperation<Array<ClangTidyResultsTransport>>

	/**
	 * Get SonarLint analysis results
	 *
	 * Gets the SonarLint analysis results. Results may be incomplete if the session is not in the completed status. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisResultsByPath1(this: void, queryParams: GetAnalysisResultsByPath1QueryParams): QueryOperation<Record<string, Array<SonarLintFindingTransport>>>

	/**
	 * Get timings of analysis state changes
	 *
	 * Allows downloading timing of analysis state changes as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getAnalysisStateChangeTiming(this: void): QueryOperation<void>

	/**
	 * Get the status of a ClangTidy Analysis session
	 *
	 * Gets the status of the given session. May return the 'unknown' status, if no session was found for the given key. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisStatus(this: void, queryParams: GetAnalysisStatusQueryParams): QueryOperation<EClangTidyOutsourcedAnalysisStatusEntry>

	/**
	 * Get the status of a SonarLint Analysis session
	 *
	 * Gets the status of the given session. May return the 'unknown' status, if no session was found for the given key. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisStatus1(this: void, queryParams: GetAnalysisStatus1QueryParams): QueryOperation<ESonarLintIndependentAnalysisStatusEntry>

	/**
	 * Get analysis tools
	 *
	 * Retrieves the available tools based on the languages provided. Teamscale itself is not returned as a tool. No specific permissions required to retrieve the available tools
	 */
	getAnalysisTools(this: void, queryParams: GetAnalysisToolsQueryParams): QueryOperation<Array<EAnalysisToolEntry>>

	/**
	 * Get analysis tools
	 *
	 * Returns the available analysis tools for a specific project. Teamscale itself is not returned as a tool. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getAnalysisToolsForProject(this: void, project: string, queryParams: GetAnalysisToolsForProjectQueryParams): QueryOperation<Array<EAnalysisToolEntry>>

	/**
	 * Profile architecture pattern performance
	 *
	 * Measures the performance of every include/exclude pattern of the provided architecture, by matching it against all available types. Reports the 10 slowest patterns and their runtime. Can be used to detect slow patterns in the architecture analysis.  The API requires the user to have Edit Architectures permissions on the project.
     * @param project The project ID.
     * @param architecture 
	 */
	getArchitecturePerformanceStatistics(this: void, project: string, architecture: string, queryParams: GetArchitecturePerformanceStatisticsQueryParams): QueryOperation<string>

	/**
	 * Get associated comparison IDs
	 *
	 * Returns the IDs of the associated comparisons of a single snapshot identified by the given ID. The API requires the user to have Access Administrative Services permissions.
     * @param id The ID of the snapshot.
	 */
	getAssociatedComparisonIds(this: void, id: string): QueryOperation<Array<string>>

	/**
	 * Get authenticator
	 *
	 * Retrieves authenticator information The API requires the user to have Create Users permissions.
	 */
	getAuthenticatorInformation(this: void): QueryOperation<AuthenticatorMappingReply>

	/**
	 * Get authenticator
	 *
	 * Retrieves authenticator information The API requires the user to have Edit permissions on the User username.
     * @param username User to retrieve authentication info for
	 */
	getAuthenticatorInformationForUser(this: void, username: string): QueryOperation<AuthenticatorMappingReply>

	/**
	 * Get authentication servers
	 *
	 * List configured authentication servers. User must have the permission to edit or create groups or users to view authentication servers.
	 */
	getAvailableServers(this: void): QueryOperation<Record<string, Array<string>>>

	/**
	 * Get user avatar data
	 *
	 * Returns avatar data for the user in the first path parameter. The API requires the user to have View permissions on the user.
     * @param user 
	 */
	getAvatarData(this: void, user: string): QueryOperation<AvatarData>

	/**
	 * Get the backup export status
	 *
	 * Get the current backup export status This service is public API since Teamscale 6.1. The user needs to be able to configure projects. In addition the user needs to have the permission to backup global data provided the backup contains any global data and the permission to backup project data for all projects contained in the backup.
     * @param backupId The backup ID.
	 */
	getBackupExportStatus(this: void, backupId: string): QueryOperation<BackupExportStatus>

	/**
	 * Get the backup import status
	 *
	 * Get the current backup import status This service is public API since Teamscale 6.1. The user needs to be able to configure projects. In addition the user needs to have the permission to backup global data provided the backup contains any global data and the permission to backup project data for all projects contained in the backup.
     * @param backupId The backup ID.
	 */
	getBackupImportStatus(this: void, backupId: string): QueryOperation<BackupImportStatus>

	/**
	 * Get available sample projects
	 *
	 * Returns a list of sample projects that can be used as starting point. The API requires the user to have Backup Global Data permissions.
	 */
	getBackupsForImportWizard(this: void): QueryOperation<Array<Backup>>

	/**
	 * Get baseline
	 *
	 * Returns details on a configured baseline. This service is public API since Teamscale 5.2. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param baseline 
	 */
	getBaseline(this: void, project: string, baseline: string): QueryOperation<BaselineInfo>

	/**
	 * Get all baselines
	 *
	 * Returns a list of all configured baselines for the given projects or for all visible projects if no project ID is given. No permissions needed, as the service checks if all listed projects are visible to the user.
	 */
	getBaselines(this: void, queryParams: GetBaselinesQueryParams): QueryOperation<Record<string, Array<BaselineInfo>>>

	/**
	 * Get basic roles assigned to an instance
	 *
	 * Returns all basic roles with assignments. Requires the basic permission to \"Assign Roles\"
     * @param instance The instance&#39;s id
	 */
	getBasicRoles(this: void, instance: string, queryParams: GetBasicRolesQueryParams): QueryOperation<RolesWithAssignments>

	/**
	 * Get benchmark projects
	 *
	 * Returns the projects with benchmark analysis profiles User must have audit support included in the license.
	 */
	getBenchmarkProjects(this: void): QueryOperation<Array<ProjectDescription>>

	/**
	 * Get a (partly anonymized) benchmark results for a specific metric
	 *
	 * Returns a CSV file with all benchmark results. Only projects visible to the user are returned.
	 */
	getBenchmarkResults(this: void, queryParams: GetBenchmarkResultsQueryParams): QueryOperation<void>

	/**
	 * Get spec item branch
	 *
	 * Get the branch where the spec item with the provided id is analysed on The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id ID of the spec item to fetch the branch for
	 */
	getBranchForSpecItem(this: void, project: string, id: string, queryParams: GetBranchForSpecItemQueryParams): QueryOperation<string>

	/**
	 * Get branches
	 *
	 * Returns the specified range of sorted branches (or all if no range specified), including/excluding deleted/anonymous branches or the sorted filtered branches based on the defined branchesFilter/regexFilter as BranchesInfo. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getBranchesGetRequest(this: void, project: string, queryParams: GetBranchesGetRequestQueryParams): QueryOperation<BranchesInfo>

	/**
	 * Get branches
	 *
	 * Returns the specified range of sorted branches (or all if no range specified), including/excluding deleted/anonymous branches or the sorted filtered branches based on the defined branchesFilter/regexFilter as BranchesInfo. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getBranchesPostRequest(this: void, project: string, queryParams: GetBranchesPostRequestQueryParams): QueryOperation<BranchesInfo>

	/**
	 * Get change treemap
	 *
	 * Retrieves a treemap of change frequencies. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getChangeTreemap(this: void, project: string, queryParams: GetChangeTreemapQueryParams): QueryOperation<TreeMapNode>

	/**
	 * Get a treemap that colors components or languages
	 *
	 * Either top-level components or the used languages can be colored. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCodeComponentsTreemapWrapper(this: void, project: string, queryParams: GetCodeComponentsTreemapWrapperQueryParams): QueryOperation<FilteredTreeMapWrapper>

	/**
	 * Get code outline
	 *
	 * Returns a code outline for a file, which summarizes the abstract syntax tree of the code The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getCodeOutline(this: void, project: string, uniformPath: string, queryParams: GetCodeOutlineQueryParams): QueryOperation<Array<OutlineElement>>

	/**
	 * Get a mapping from spec items to code references and test executions
	 *
	 * Returns a spec item code and test result mapping for all spec items matching the given query. If the query is empty, all known spec items are considered. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCodeReferencesAndTestExecutionMapping(this: void, project: string, queryParams: GetCodeReferencesAndTestExecutionMappingQueryParams): QueryOperation<SpecItemReferenceMapping>

	/**
	 * List the code search match results and returns a treemap.
	 *
	 * Lists all code search matches result including path and line numbers for a given search term and a max result size. Additionally, return a treemap. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCodeSearchMatchResultAndTreemap(this: void, project: string, queryParams: GetCodeSearchMatchResultAndTreemapQueryParams): QueryOperation<CodeSearchResultsWrapper>

	/**
	 * Get commit alerts
	 *
	 * Provides access to the alerts for the given commits. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCommitAlerts(this: void, project: string, queryParams: GetCommitAlertsQueryParams): QueryOperation<Array<CommitAlerts | null>>

	/**
	 * Get task commits
	 *
	 * Returns the timestamps of commits associated with a given task. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id ID of the requested task
	 */
	getCommitsForTask(this: void, project: string, id: number): QueryOperation<Array<CommitDescriptor>>

	/**
	 * Get comparison summary
	 *
	 * Returns the global comparison data, the projects and the contributors for the projects for a single comparison identified by the given ID. The API requires the user to have Access Administrative Services permissions.
     * @param snapshotId The ID of the local snapshot the comparison belongs to.
     * @param comparisonId The ID of the snapshot to get. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getComparison(this: void, snapshotId: string, comparisonId: string): QueryOperation<InstanceComparisonComputationMetadata>

	/**
	 * Get project comparison results
	 *
	 * Returns the comparison data for the specified comparison, project and contributor. The comparison can be identified by the given ID. The API requires the user to have Access Administrative Services permissions.
     * @param snapshotId The ID of the local snapshot the comparison belongs to.
     * @param comparisonId The ID of the comparison to get.
     * @param project The name of the project.
	 */
	getComparisonResultsForContributor(this: void, snapshotId: string, comparisonId: string, project: string, queryParams: GetComparisonResultsForContributorQueryParams): QueryOperation<InstanceComparisonResultBase>

	/**
	 * Get complete example list
	 *
	 * Get the full list of missing local or remote detail values The API requires the user to have Access Administrative Services permissions.
     * @param snapshotId The ID of the local snapshot the comparison belongs to.
     * @param comparisonId The ID of the comparison to get.
     * @param project The name of the project.
     * @param contributor The contributor of the comparison.
     * @param diffEntryName The name of the difference entry.
	 */
	getCompleteExampleList(this: void, snapshotId: string, comparisonId: string, project: string, contributor: string, diffEntryName: string, queryParams: GetCompleteExampleListQueryParams): QueryOperation<Array<DetailedInstanceComparisonValue>>

	/**
	 * Get a detailed concurrency analysis
	 *
	 * Allows downloading the detailed trigger performance analysis. The feature toggle -Dcom.teamscale.log_performance_details=true must be enabled. The API requires the user to have Access Administrative Services permissions.
	 */
	getConcurrencyPerformanceDetails(this: void, queryParams: GetConcurrencyPerformanceDetailsQueryParams): QueryOperation<string>

	/**
	 * Get configuration languages
	 *
	 * Returns the languages available for analysis, sorted by their readable name. This is available to every authenticated user.
	 */
	getConfigLanguages(this: void): QueryOperation<Array<Language>>

	/**
	 * Get configuration languages
	 *
	 * Returns the languages available for analysis for a specific project, sorted by their readable name. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getConfigLanguagesForProject(this: void, project: string): QueryOperation<Array<Language>>

	/**
	 * Get configuration template
	 *
	 * Retrieves the configuration template for given languages. No specific permissions required to retrieve a configuration template.
	 */
	getConfigurationTemplate(this: void, queryParams: GetConfigurationTemplateQueryParams): QueryOperation<ConfigurationTemplate>

	/**
	 * Get connector descriptors
	 *
	 * Retrieves connector descriptors available in the system. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getConnectorDescriptors(this: void): QueryOperation<Array<ConnectorDescriptorTransport>>

	/**
	 * Get copyright license treemap
	 *
	 * Builds a treemap of the copyright licenses in a system. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCopyrightLicensesTreemap(this: void, project: string, queryParams: GetCopyrightLicensesTreemapQueryParams): QueryOperation<FilteredTreeMapWrapper>

	/**
	 * Get coverage sources
	 *
	 * Retrieves information about coverage sources (partitions and cross-annotation projects),  respecting the given path. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getCoverageSources(this: void, project: string, queryParams: GetCoverageSourcesQueryParams): QueryOperation<Array<CoverageSourceInfo>>

	/**
	 * Get scheduler filtering status
	 *
	 * Returns the current filtering status of the scheduler. The API requires no permissions
	 */
	getCurrentSchedulingFilter(this: void): QueryOperation<ProjectSchedulingFilter>

	/**
	 * Get dashboard
	 *
	 * Retrieves the dashboard descriptor identified by given ID. The API requires the user to have View permissions on the Dashboard dashboardId.
     * @param dashboardId ID of the dashboard to retrieve.
	 */
	getDashboard(this: void, dashboardId: string): QueryOperation<UserResolvedDashboardDescriptor>

	/**
	 * Get dashboard template
	 *
	 * Retrieves the dashboard template identified by the given ID. Any user may see dashboard templates.
     * @param templateId ID of the template to retrieve
	 */
	getDashboardTemplateDescriptor(this: void, templateId: string): QueryOperation<DashboardTemplateDescriptor>

	/**
	 * Get token element internals
	 *
	 * Returns a string representation of a token element, supporting various representations. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getDebugContent(this: void, project: string, uniformPath: string, queryParams: GetDebugContentQueryParams): QueryOperation<string>

	/**
	 * Get the declaration of accessibility
	 *
	 * Returns the declaration of accessibility as found in the configuration directory (file name: 'declaration_of_accessibility.md'). To avoid unnecessary IO lookups, the declaration (or non-existent declaration) is locally cached until a reload is forced. The API requires no login
	 */
	getDeclarationOfAccessibility(this: void, queryParams: GetDeclarationOfAccessibilityQueryParams): QueryOperation<string | null>

	/**
	 * null The API requires no login
	 */
	getDeclarationOfAccessibilityContext(this: void): QueryOperation<DeclarationOfAccessibilityContext>

	/**
	 * Get default patterns
	 *
	 * Returns the default include/exclude patterns for connectors as a map from analysis profile name to the corresponding default patterns based on the languages configured in the respective analysis profile The API requires no permissions
	 */
	getDefaultPatterns(this: void): QueryOperation<Record<string, IncludeExcludePatterns>>

	/**
	 * Get token element churn
	 *
	 * Provides a token element churn (i.e., the list of changed elements) for a given uniform path and time range. This service is public API since Teamscale 5.9.10. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getDeltaAffectedFiles(this: void, project: string, queryParams: GetDeltaAffectedFilesQueryParams): QueryOperation<Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Get the entire dependency graph of a system
	 *
	 * Extracts the dependency graph of a project in the \"Trivial Graph Format\" The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getDependencyGraph(this: void, project: string, uniformPath: string, queryParams: GetDependencyGraphQueryParams): QueryOperation<string>

	/**
	 * Get diffs for two files
	 *
	 * Compares two elements with each other. The elements are given via a full path including project and uniform path and (optionally) the commit separated by '#@#' Requires read permissions on the projects containing the files to be compared
	 */
	getDiffs(this: void, queryParams: GetDiffsQueryParams): QueryOperation<Array<DiffDescription>>

	/**
	 * Get .NET versions
	 *
	 * Returns a list of all versions known by uploaded PDBs for the given projects or for all visible projects if no project ID is given. No permissions needed, as the service checks if all listed projects are visible to the user.
	 */
	getDotNetVersions(this: void, queryParams: GetDotNetVersionsQueryParams): QueryOperation<Record<string, Array<DotNetVersionInfo>>>

	/**
	 * Get commit descriptors
	 *
	 * Returns commit descriptors for a project. The API requires the user to have Access Administrative Services permissions.
     * @param project The project ID.
	 */
	getDumpCommitDescriptor(this: void, project: string, queryParams: GetDumpCommitDescriptorQueryParams): QueryOperation<string>

	/**
	 * Get commit trees
	 *
	 * Returns the commit trees for a project. The API requires the user to have Access Administrative Services permissions.
     * @param project The project ID.
	 */
	getDumpCommitTree(this: void, project: string, queryParams: GetDumpCommitTreeQueryParams): QueryOperation<string>

	/**
	 * Get commit trees
	 *
	 * Returns the commit tree data for a project. It excludes the nodes from the detailed commit tree. Use the debug/commit-tree service to get the full detailed information. The API requires the user to have Access Administrative Services permissions.
     * @param project The project ID.
	 */
	getDumpCommitTreeNodes(this: void, project: string, queryParams: GetDumpCommitTreeNodesQueryParams): QueryOperation<Array<CommitTreeNodeData>>

	/**
	 * Returns the configuration of the service provider that is passed to this service.
	 *
	 * This is meant to simplify the configuration of the identity provider. The API requires the user to have Access Administrative Services permissions.
	 */
	getEditedServiceProviderConfiguration(this: void, queryParams: GetEditedServiceProviderConfigurationQueryParams, body: SamlAuthenticationOption): QueryOperation<SamlServiceProviderConfiguration>

	/**
	 * Get all events
	 *
	 * Returns the list of all available event announcements. The API requires no permissions
	 */
	getEventAnnouncements(this: void): QueryOperation<Array<EventAnnouncement>>

	/**
	 * Get exception hierarchies
	 *
	 * Retrieves hierarchies of custom exceptions represented as objects. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getExceptionsHierarchyTree(this: void, project: string, queryParams: GetExceptionsHierarchyTreeQueryParams): QueryOperation<Array<Array<ExceptionsTree>>>

	/**
	 * Get hierarchies of custom exceptions
	 *
	 * Retrieves hierarchies of custom exceptions represented graphically. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getExceptionsHierarchyTreeSVG(this: void, project: string, queryParams: GetExceptionsHierarchyTreeSVGQueryParams): QueryOperation<Array<string>>

	/**
	 * Get external file content
	 *
	 * Retrieves the file content of a file from disk with local pre-processing. The API requires the user to have Access Administrative Services permissions. User must have audit support included in the license.
     * @param path The path to retrieve files for
	 */
	getExternalFileWithPreprocessing(this: void, path: string): QueryOperation<FormattedTokenElementInfo>

	/**
	 * Get file summary
	 *
	 * Retrieves the file summary showing the different file types in a given folder on the local file system along with the corresponding number of lines of code. User must have audit support included in the license.
	 */
	getFileSummary(this: void, queryParams: GetFileSummaryQueryParams): QueryOperation<PairListStringFileSummaryInfoRecord>

	/**
	 * Get files with an identical content
	 *
	 * Retrieves files. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getFilesWithIdenticalContent(this: void, project: string, uniformPath: string, queryParams: GetFilesWithIdenticalContentQueryParams): QueryOperation<Array<FileGroup> | null>

	/**
	 * Get files with an identical names
	 *
	 * Retrieves files. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getFilesWithIdenticalNames(this: void, project: string, uniformPath: string, queryParams: GetFilesWithIdenticalNamesQueryParams): QueryOperation<Array<FileGroup> | null>

	/**
	 * This service provides access to the finding churn count. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingChurnCount(this: void, project: string, queryParams: GetFindingChurnCountQueryParams): QueryOperation<Array<FindingChurnCount>>

	/**
	 * Get finding delta
	 *
	 * Retrieves the finding delta (i.e., the list of newly added and deleted findings) for the given uniform path and time range. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingDelta(this: void, project: string, queryParams: GetFindingDeltaQueryParams): QueryOperation<FindingDelta>

	/**
	 * Get sibling findings
	 *
	 * Returns all sibling findings of the given finding. Only searches for siblings in the same findings partition. The finding with the given finding id is not included in the list. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id 
	 */
	getFindingSiblings(this: void, project: string, id: string, queryParams: GetFindingSiblingsQueryParams): QueryOperation<Array<TrackedFinding>>

	/**
	 * Get finding descriptions
	 *
	 * Returns user readable finding names and descriptions for given finding type IDs This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingTypeDescriptions(this: void, project: string, body: Array<string>): QueryOperation<Array<FindingTypeDescription>>

	/**
	 * Get findings
	 *
	 * Gets a list of all findings. Findings can be filtered by path prefix. For recursive queries, these are all findings found in the sub-tree. Filter parameters allow to only retrieve findings in a specific category and/or group. There is an upper limit for the number of returned findings. This service is public API since Teamscale 5.6. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindings(this: void, project: string, queryParams: GetFindingsQueryParams): QueryOperation<Array<TrackedFinding>>

	/**
	 * Export findings
	 *
	 * Exports findings for the element with the provided uniform path in the given format. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param format The export format used.
	 */
	getFindingsExport(this: void, project: string, format: EFindingsExportFormatEntry, queryParams: GetFindingsExportQueryParams): QueryOperation<void>

	/**
	 * Get finding siblings
	 *
	 * Gets next and prev finding respecting filter and sort options.  The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param finding 
	 */
	getFindingsSiblings(this: void, project: string, finding: string, queryParams: GetFindingsSiblingsQueryParams): QueryOperation<PreviousNextSiblings>

	/**
	 * Get summaries for all findings
	 *
	 * Gets the list of finding summaries for an element or a resource sub-tree. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingsSummary(this: void, project: string, queryParams: GetFindingsSummaryQueryParams): QueryOperation<FindingsSummaryInfo>

	/**
	 * Get findings treemap
	 *
	 * Produces a treemap of finding frequencies. The intensity of the rectangle colors determine the finding frequency in the corresponding file. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingsTreemap(this: void, project: string, queryParams: GetFindingsTreemapQueryParams): QueryOperation<FindingsTreemapWrapper>

	/**
	 * Get findings with false positive counts
	 *
	 * Gets a list of all findings with additional information on . For recursive queries, these are all findings found in the sub-tree. Filter parameters allow to only retrieve findings in a specific category and/or group. There is an upper limit for the number of returned findings. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFindingsWithCount(this: void, project: string, queryParams: GetFindingsWithCountQueryParams): QueryOperation<ExtendedFindingsWithCount>

	/**
	 * Get the first branch name to be displayed in the UI.
	 *
	 * Get the first branch name to be displayed in the UI. This is either the preselected UI branch or the default branch. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getFirstUIBranchNameGetRequest(this: void, project: string): QueryOperation<string>

	/**
	 * Get identifiers of flagged findings
	 *
	 * Returns identifiers of all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFlaggedFindingIds(this: void, project: string, queryParams: GetFlaggedFindingIdsQueryParams): QueryOperation<Array<string>>

	/**
	 * Gets flagging information for a finding.
	 *
	 * Returns the flagging information for the finding with given identifier. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param finding Id of the finding the information is requested for.
	 */
	getFlaggedFindingInfo(this: void, project: string, finding: string, queryParams: GetFlaggedFindingInfoQueryParams): QueryOperation<UserResolvedFindingBlacklistInfo | null>

	/**
	 * Get flagged findings
	 *
	 * Returns all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFlaggedFindings(this: void, project: string, queryParams: GetFlaggedFindingsQueryParams): QueryOperation<Array<FindingBlacklistInfo>>

	/**
	 * Get flagging information for findings.
	 *
	 * Returns flagging information for the given findings. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getFlaggedFindingsInfos(this: void, project: string, queryParams: GetFlaggedFindingsInfosQueryParams, body: Array<string>): QueryOperation<Array<FindingBlacklistInfo>>

	/**
	 * Get connector ids of all Git-based connectors
	 *
	 * Returns all connector identifiers in a project that use Git. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getGitConnectorIds(this: void, project: string): QueryOperation<Array<string>>

	/**
	 * Get Repositories the GitHub App has Access to
	 *
	 * Returns list of repositories of the installed GitHub App. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getGitHubAppInstallationRepositories(this: void, queryParams: GetGitHubAppInstallationRepositoriesQueryParams): QueryOperation<Array<string>>

	/**
	 * Get GitHub App Infos
	 *
	 * Returns information about the installed GitHub Apps the current user has access to. User must be provisioned from e.g. GitHub.
	 */
	getGitHubAppInstallations(this: void): QueryOperation<AppInstallationInfo>

	/**
	 * Collect URLs of all installed GitHub Apps.
	 *
	 * Returns URLs by setup GitHub Apps. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getGitHubAppUrls(this: void): QueryOperation<Array<string>>

	/**
	 * Gets a git tag by its ref name
	 *
	 * Returns the git tag with the given ref name or null if it could not be found. The API requires the user to have View Project permissions on the project.
     * @param project 
     * @param tagName 
	 */
	getGitTagByName(this: void, project: string, tagName: string, queryParams: GetGitTagByNameQueryParams): QueryOperation<GitTag | null>

	/**
	 * Get global analysis progress
	 *
	 * Retrieves the global analysis progress (for all projects). User must either have the permission to view system status or the permission to access administrative services
	 */
	getGlobalAnalysisProgress(this: void): QueryOperation<Record<string, Array<AnalysisProgressDescriptor>>>

	/**
	 * Get multi project branches
	 *
	 * Gets the branches for multiple projects as BranchesInfo The API requires no permissions
	 */
	getGlobalBranchesGetRequest(this: void, queryParams: GetGlobalBranchesGetRequestQueryParams): QueryOperation<BranchesInfo>

	/**
	 * Get all project's directories
	 *
	 * Returns global container info by merging container info from all projects. The API requires the user to have Create Metric Threshold Configurations permissions.
	 */
	getGlobalContainerInfo(this: void, queryParams: GetGlobalContainerInfoQueryParams): QueryOperation<ContainerInfo>

	/**
	 * Get critical event detail log
	 *
	 * Returns the detailed critical event log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalCriticalEventLog(this: void, timestamp: number): QueryOperation<DetailedServiceLog>

	/**
	 * Get critical event log frequencies
	 *
	 * Returns the frequencies of the various critical event log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 */
	getGlobalCriticalEventLogFrequencies(this: void): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all critical event logs
	 *
	 * Returns a list of all critical event short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalCriticalEventLogs(this: void, queryParams: GetGlobalCriticalEventLogsQueryParams): QueryOperation<ShortLogResponseShortLogEntryBase>

	/**
	 * Get multi project first UI branch.
	 *
	 * Gets the branch to be displayed first in the UI for one of the specified projects. The API requires no permissions
	 */
	getGlobalFirstUIBranchGetRequest(this: void, queryParams: GetGlobalFirstUIBranchGetRequestQueryParams): QueryOperation<string | null>

	/**
	 * Get quality indicator and analysis group names
	 *
	 * Retrieves the available quality indicator and analysis group names from all configured projects. Every authenticated user should see the available quality indicator and analysis group names.
	 */
	getGlobalIndicatorsAndGroups(this: void): QueryOperation<IndicatorsAndGroups>

	/**
	 * Get interaction detail log
	 *
	 * Returns the detailed interaction log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalInteractionLog(this: void, timestamp: number): QueryOperation<DetailedWorkerLog>

	/**
	 * Get interaction log frequencies
	 *
	 * Returns the frequencies of the various interaction log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 */
	getGlobalInteractionLogFrequencies(this: void): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all interaction logs
	 *
	 * Returns a list of all interaction short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalInteractionLogs(this: void, queryParams: GetGlobalInteractionLogsQueryParams): QueryOperation<ShortLogResponseShortInteractionLog>

	/**
	 * Get JS error detail log
	 *
	 * Returns the detailed JavaScript error log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalJavascriptErrorLog(this: void, timestamp: number): QueryOperation<DetailedJavaScriptErrorLog>

	/**
	 * Get JS error frequencies
	 *
	 * Returns the number of JavaScript error logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalJavascriptErrorLogFrequencies(this: void): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all JS error logs
	 *
	 * Returns a list of all JavaScript error short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalJavascriptErrorLogs(this: void, queryParams: GetGlobalJavascriptErrorLogsQueryParams): QueryOperation<ShortLogResponseShortJavaScriptErrorLog>

	/**
	 * Get multi project recent branches
	 *
	 * Gets the recent branches for multiple projects. The API requires no permissions
	 */
	getGlobalRecentBranchesGetRequest(this: void, queryParams: GetGlobalRecentBranchesGetRequestQueryParams): QueryOperation<Array<string>>

	/**
	 * Get service detail log
	 *
	 * Returns the detailed service log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalServiceLog(this: void, timestamp: number): QueryOperation<DetailedServiceLog>

	/**
	 * Get service log frequencies
	 *
	 * Returns the frequencies of the various service log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 */
	getGlobalServiceLogFrequencies(this: void): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all service logs
	 *
	 * Returns a list of all service short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalServiceLogs(this: void, queryParams: GetGlobalServiceLogsQueryParams): QueryOperation<ShortLogResponseShortServiceLog>

	/**
	 * Get worker detail log
	 *
	 * Returns the detailed worker log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalWorkerLog(this: void, timestamp: number): QueryOperation<DetailedWorkerLog>

	/**
	 * Get worker log frequencies
	 *
	 * Returns the frequencies of the various worker log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 */
	getGlobalWorkerLogFrequencies(this: void): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all worker logs
	 *
	 * Returns a list of all worker short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalWorkerLogs(this: void, queryParams: GetGlobalWorkerLogsQueryParams): QueryOperation<ShortLogResponseShortWorkerLog>

	/**
	 * Get auto-completion suggestions
	 *
	 * Returns a list of suggested group names for auto-completion. The API requires the user to have Create Users permissions.
     * @param input The current input that should be auto-completed.
	 */
	getGroupAutoCompletionSuggestions(this: void, input: string, queryParams: GetGroupAutoCompletionSuggestionsQueryParams): QueryOperation<Array<string>>

	/**
	 * Get system health status
	 *
	 * Retrieves a log of system health status information. This service is public API since Teamscale 5.5. No login required to perform monitoring.
	 */
	getHealthStatus(this: void, queryParams: GetHealthStatusQueryParams): QueryOperation<string>

	/**
	 * Retrieve heap dump form
	 *
	 * Provides a UI to download heap dump The API requires the user to have Access Administrative Services permissions.
	 */
	getHeapDumpFormPage(this: void): QueryOperation<string>

	/**
	 * Get impacted tests
	 *
	 * Returns an ordered list of test clusters that are impacted by the given changes. The returned flat list of tests is a subset of all tests known to Teamscale. When the tests themselves have changed, new tests were added or tests have been deleted, this service cannot take that into account. Please use the PUT endpoint instead to provide a list of available tests. In particular, this endpoint may return tests that no longer exist. Callers must handle this case. This service is public API since Teamscale 9.4. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getImpactedTests(this: void, project: string, queryParams: GetImpactedTestsQueryParams): QueryOperation<Array<PrioritizableTest>>

	/**
	 * Get impacted tests
	 *
	 * Returns an ordered list of test clusters that are impacted by the given changes. The returned list of test clusters is a subset of the tests given in the request body. Clusters of selected and prioritized tests are formed based on the clustering information given in the request body. The tests from the request body are furthermore used to determine if any tests have changed or new tests were added. These are always included in the returned list of impacted tests. This service is public API since Teamscale 9.4. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getImpactedTestsFromAvailableTests(this: void, project: string, queryParams: GetImpactedTestsFromAvailableTestsQueryParams, body: Array<TestWithClusterId>): QueryOperation<Array<PrioritizableTestCluster>>

	/**
	 * Get the names of the important branches
	 *
	 * Get the names of the important branches The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getImportantBranches(this: void, project: string): QueryOperation<Array<string>>

	/**
	 * Get permissions for an instance
	 *
	 * Returns a set of basic permissions for a single instance identified by the instance ID. The API requires no permissions
     * @param instanceId ID of the instance to get the permissions for
	 */
	getInstancePermissions(this: void, instanceId: string, queryParams: GetInstancePermissionsQueryParams): QueryOperation<Array<EBasicPermissionEntry>>

	/**
	 * Get files affected by issue
	 *
	 * Retrieves the files affected by the commits to a given issue. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param issueId ID of the issue to retrieve affected files for. The format is the connector ID of the originating issue tracker/requirements management tool (as specified in the connector settings in the project configuration), followed by the separator &#39;|&#39;, followed by the ID of the issue, as it is provided by the external issue tracker/requirements management tool. For example, an issue with the ID \&quot;TS-123\&quot; is imported using an issue tracker connector with the connector ID \&quot;issues1\&quot;. The expected ID is then \&quot;issues1|TS-123\&quot;.
	 */
	getIssueAffectedFiles(this: void, project: string, issueId: string): QueryOperation<Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Retrieve commit issues
	 *
	 * Returns the issues associated with the given commits. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getIssuesByRepositoryCommits(this: void, project: string, queryParams: GetIssuesByRepositoryCommitsQueryParams): QueryOperation<Array<Array<TeamscaleIssueStatus> | null>>

	/**
	 * Get issues details
	 *
	 * Retrieves issue details by their IDs. Unknown issue IDs will cause 'null' entries in the result The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getIssuesDetails(this: void, project: string, queryParams: GetIssuesDetailsQueryParams): QueryOperation<Array<UserResolvedTeamscaleIssue | null>>

	/**
	 * Get contained package names
	 *
	 * All packages that should be profiled (subpackages will be profiled as well). The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getJavaProfilerJvmPackageNames(this: void, project: string): QueryOperation<Array<string>>

	/**
	 * Returns the current computation status of a test minimization job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	getJob(this: void, project: string, jobId: string): QueryOperation<TestMinimizationJobRun>

	/**
	 * Returns the arguments of the test minimization job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	getJobOptions(this: void, project: string, jobId: string): QueryOperation<TestMinimizationRequestOptions>

	/**
	 * Get job queue size
	 *
	 * Retrieves size of the entire job queue from all projects. The API requires the user to have View System Status permissions.
	 */
	getJobQueueSize(this: void): QueryOperation<JobQueueCountWithDelayedJobs>

	/**
	 * Returns the list of test minimization jobs of the current user for the project.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getJobs(this: void, project: string): QueryOperation<Array<TestMinimizationJobRun>>

	/**
	 * Get full Kubernetes manifest
	 *
	 * Returns the content of the full Kubernetes manifest which is built from the given Helm chart or Kustomization. Returns null in case no full manifest is available for the given uniform path, e.g. a non-root Kustomization. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getKubernetesManifest(this: void, project: string, uniformPath: string, queryParams: GetKubernetesManifestQueryParams): QueryOperation<FormattedTokenElementInfo>

	/**
	 * Get rules
	 *
	 * Gets rules for a given language available in the current Teamscale version. No permission needed since every user can explore rules with the rule browser.
	 */
	getLanguageRules(this: void, queryParams: GetLanguageRulesQueryParams): QueryOperation<RulesContainer>

	/**
	 * Get enabled rules
	 *
	 * Gets enabled rules for the given languages and tools for a specific analysis profile. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
     * @param analysisProfileName The analysis profile for which to get rules.
	 */
	getLanguageRulesForAnalysisProfile(this: void, analysisProfileName: string, queryParams: GetLanguageRulesForAnalysisProfileQueryParams): QueryOperation<RulesContainer>

	/**
	 * Get enabled rules
	 *
	 * Gets enabled rules for the given languages for a specific project. The API requires the user to have View Project permissions on the project.
     * @param project The project for which to get rules.
	 */
	getLanguageRulesForProject(this: void, project: string, queryParams: GetLanguageRulesForProjectQueryParams): QueryOperation<RulesContainer>

	/**
	 * Return last change log entry with the given commit type
	 *
	 * Returns the log entry for the last change with the given commit type The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getLastCommitOfType(this: void, project: string, queryParams: GetLastCommitOfTypeQueryParams): QueryOperation<RepositoryLogEntryAggregate | null>

	/**
	 * Get the late external upload information
	 *
	 * Retrieves the timestamp of the next late processing of external uploads, the age threshold for external uploads being considered late and the maximum upload age for external uploads. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
	 */
	getLateExternalUploadProcessingInfo(this: void, project: string): QueryOperation<LateExternalUploadProcessingInfo>

	/**
	 * Returns information about the license.
	 *
	 * Returns license information. If the reload parameter is set this reloads the license and logs any errors into the service log. The API requires the user to have Access Administrative Services permissions.
	 */
	getLicenseInfo(this: void, queryParams: GetLicenseInfoQueryParams): QueryOperation<LicenseInfo | null>

	/**
	 * Get licenses
	 *
	 * Service to retrieve all licenses sorted by criticality The API requires no permissions
     * @param project The project ID.
	 */
	getLicenses(this: void, project: string, queryParams: GetLicensesQueryParams): QueryOperation<Array<CopyrightLicenseInfo>>

	/**
	 * Get link roles
	 *
	 * Returns the link roles for a specific connector. The API requires the user to have View permissions on the External Account accountName.
     * @param accountName The ID of the external account the user needs access to.
	 */
	getLinkRoles(this: void, accountName: string, body: ConnectorConfiguration): QueryOperation<GetLinkRolesResponse>

	/**
	 * Returns active committers across all projects
	 *
	 * Returns all committers of the last n days (across all projects) that contribute to the license volume The API requires the user to have Access Administrative Services permissions.
	 */
	getListOfActiveCommitters(this: void, queryParams: GetListOfActiveCommittersQueryParams): QueryOperation<Array<CommitterDisplayName>>

	/**
	 * Returns active committers for the given primary project IDs
	 *
	 * For the given list of primary project IDs, returns all committers of the last n days that contribute to the license volume. If an empty list is provided, all projects will be included (which is equivalent to performing a GET query). The API requires the user to have Access Administrative Services permissions.
	 */
	getListOfActiveCommittersForProjects(this: void, queryParams: GetListOfActiveCommittersForProjectsQueryParams, body: Array<string>): QueryOperation<Array<CommitterDisplayName>>

	/**
	 * Provides context for the login screen
	 *
	 * null The API requires no login
	 */
	getLoginContext(this: void): QueryOperation<LoginContext>

	/**
	 * Get a single merge request
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param idWithRepository 
	 */
	getMergeRequest(this: void, project: string, idWithRepository: string): QueryOperation<ExtendedMergeRequest | null>

	/**
	 * Get affected files for merge request
	 *
	 * Provides the list of changed files for a given merge request. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMergeRequestAffectedFiles(this: void, project: string, queryParams: GetMergeRequestAffectedFilesQueryParams): QueryOperation<Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Get a merge request analysis delta
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param idWithRepository 
	 */
	getMergeRequestDelta(this: void, project: string, idWithRepository: string, queryParams: GetMergeRequestDeltaQueryParams): QueryOperation<MergeRequestDelta | null>

	/**
	 * Get merge request finding churn
	 *
	 * Creates a findings delta (newly added or deleted findings and unchanged findings in modified code) for a given proposed merge. Findings in modified code will only be reported if the modification happened since the last common commit with the target branch. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMergeRequestFindingChurn(this: void, project: string, queryParams: GetMergeRequestFindingChurnQueryParams): QueryOperation<FindingDelta>

	/**
	 * Get merge request repository churn
	 *
	 * Retrieves the merge request repository churn. The API requires the user to have View Project permissions on the project.
     * @param project ID of the project which analyzes the merge request.
	 */
	getMergeRequestRepositoryChurn(this: void, project: string, queryParams: GetMergeRequestRepositoryChurnQueryParams): QueryOperation<Array<ExtendedRepositoryLogEntry>>

	/**
	 * Retrieves metadata for an ABAP file stored in Teamscale, previously retrieved from some SAP system.
	 *
	 * Retrieves metadata for an ABAP file stored in Teamscale, previously retrieved from some SAP System The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param filePath Path to the file in Teamscale.
	 */
	getMetadataForFile(this: void, project: string, filePath: string, queryParams: GetMetadataForFileQueryParams): QueryOperation<AbapFileMetadata>

	/**
	 * Get method history
	 *
	 * Returns the method history entries including changes, renamings or movements and points in time where method has been tested. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath Uniform path to retrieve the metric history for.
	 */
	getMethodHistory(this: void, project: string, uniformPath: string, queryParams: GetMethodHistoryQueryParams): QueryOperation<MethodHistoryEntriesWrapper>

	/**
	 * Get benchmark results
	 *
	 * Returns (partly anonymized) benchmark results for a specific metric. User must have audit support included in the license.
	 */
	getMetricBenchmark(this: void, queryParams: GetMetricBenchmarkQueryParams): QueryOperation<Array<BenchmarkResult>>

	/**
	 * Get metric schema
	 *
	 * Returns the metric schema for a path within a project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPathType The path for which the metric schema should be fetched
	 */
	getMetricDirectorySchemaForPath(this: void, project: string, uniformPathType: ETypeEntry): QueryOperation<MetricDirectorySchema>

	/**
	 * Get global metric schema
	 *
	 * Retrieves all available metric schemas in the same order as the provided paths. Global metric directory schema is available to each authenticated user.
	 */
	getMetricDirectorySchemas(this: void, queryParams: GetMetricDirectorySchemasQueryParams): QueryOperation<Array<MetricDirectorySchema>>

	/**
	 * Get metrics
	 *
	 * Retrieves the available external metrics. The API requires no permissions
	 */
	getMetricSchemaEntries(this: void): QueryOperation<Array<MetricSchemaChangeEntry>>

	/**
	 * Get metric threshold configuration
	 *
	 * Retrieves the metric threshold configuration identified by given name. Built-in metric threshold configurations require no access permissions. For the rest, view permission for the respective configuration is required.
     * @param metricThresholdConfigurationName 
	 */
	getMetricThresholdConfiguration(this: void, metricThresholdConfigurationName: string, queryParams: GetMetricThresholdConfigurationQueryParams): QueryOperation<MetricThresholdConfiguration>

	/**
	 * Get minimized Test Set
	 *
	 * Returns a list of test clusters that have been determined to be the best tests to run in the amount of time that was specified in the request. The clusters are formed according tothe clustering-regex that can be specified in the request options. This service is public API since Teamscale 7.8. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getMinimizedTests(this: void, project: string, queryParams: GetMinimizedTestsQueryParams): QueryOperation<TestMinimizationResult>

	/**
	 * Returns a preview of the data we would send
	 *
	 * Returns a the preview of the data we would send to our monitoring back-end. The API requires the user to have Access Administrative Services permissions.
	 */
	getMonitoringPreview(this: void): QueryOperation<string>

	/**
	 * Export Latex metric table
	 *
	 * Service for exporting Latex tables containing metrics for a given project as well as the corresponding test and application subprojects. User must have audit support included in the license.
	 */
	getMultiProjectLatexTable(this: void, queryParams: GetMultiProjectLatexTableQueryParams): QueryOperation<string>

	/**
	 * Number of Active User
	 *
	 * Calculates the number of active users in the past 90 days. This is either the number of active Teamscale users or the number of committers, depending on which value is larger.  The API requires no permissions
	 */
	getNumberOfActiveUsers(this: void): QueryOperation<number>

	/**
	 * Retrieves OpenID endpoints
	 *
	 * Tries to retrieve all Teamscale-relevant endpoints from the issuer URL The API requires the user to have Access Administrative Services permissions.
	 */
	getOpenIdEndpoints(this: void, queryParams: GetOpenIdEndpointsQueryParams): QueryOperation<OpenIdEndpointInfo>

	/**
	 * Get partitions
	 *
	 * Returns the list of external upload partitions in this project. The API requires the user to have Perform External Uploads, View Project permissions on the project.
     * @param project The project ID.
	 */
	getPartitions(this: void, project: string): QueryOperation<Array<ExternalAnalysisPartitionInfo>>

	/**
	 * Get permission lookup
	 *
	 * Returns a permission lookup for the whole permission scope The API requires no permissions
	 */
	getPermissionLookup(this: void, queryParams: GetPermissionLookupQueryParams): QueryOperation<PermissionLookupEBasicPermission>

	/**
	 * Perspective context
	 *
	 * Provides basic information about the logged in user, permissions, available projects and the Teamscale instance. The API requires no permissions
	 */
	getPerspectiveContext(this: void): QueryOperation<PerspectiveContext>

	/**
	 * Get the content for a pre-commit element
	 *
	 * Retrieves the formatted content (i.e., incl. style information) for an element that is part of a pre-commit branch. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getPreCommitContent(this: void, project: string, queryParams: GetPreCommitContentQueryParams): QueryOperation<FormattedTokenElementInfo>

	/**
	 * Get profiler configuration
	 *
	 * Retrieves the profiler configuration identified by given ID. The API requires the user to have View permissions on the Profiler Configuration configurationId.
     * @param configurationId ID of the profiler configuration to retrieve.
	 */
	getProfilerConfiguration(this: void, configurationId: string): QueryOperation<ProfilerConfiguration>

	/**
	 * Get project analysis progress
	 *
	 * Retrieves the project specific analysis progress. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getProjectAnalysisProgress(this: void, project: string): QueryOperation<Array<AnalysisProgressDescriptor>>

	/**
	 * Get project configuration
	 *
	 * Returns the configuration used for creation of the project in the path parameter. This service is public API since Teamscale 7.1. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getProjectConfiguration(this: void, project: string): QueryOperation<ProjectConfiguration>

	/**
	 * Get critical event detail log
	 *
	 * Returns the detailed critical event log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectCriticalEventLog(this: void, project: string, timestamp: number): QueryOperation<DetailedServiceLog>

	/**
	 * Get critical event log frequencies
	 *
	 * Returns the frequencies of the various critical event log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectCriticalEventLogFrequencies(this: void, project: string): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all critical event logs
	 *
	 * Returns a list of all critical event short logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectCriticalEventLogs(this: void, project: string, queryParams: GetProjectCriticalEventLogsQueryParams): QueryOperation<ShortLogResponseShortLogEntryBase>

	/**
	 * Get interaction detail log
	 *
	 * Returns the detailed interaction log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectInteractionLog(this: void, project: string, timestamp: number): QueryOperation<DetailedWorkerLog>

	/**
	 * Get interaction log frequencies
	 *
	 * Returns the frequencies of the various interaction log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectInteractionLogFrequencies(this: void, project: string): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all interaction logs
	 *
	 * Returns a list of all interaction short logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectInteractionLogs(this: void, project: string, queryParams: GetProjectInteractionLogsQueryParams): QueryOperation<ShortLogResponseShortInteractionLog>

	/**
	 * Get JS error detail log
	 *
	 * Returns the detailed JavaScript error log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectJavascriptErrorLog(this: void, project: string, timestamp: number): QueryOperation<DetailedJavaScriptErrorLog>

	/**
	 * Get JS error frequencies
	 *
	 * Returns the number of JavaScript error logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectJavascriptErrorLogFrequencies(this: void, project: string): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all JS error logs
	 *
	 * Returns a list of all JavaScript error short logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectJavascriptErrorLogs(this: void, project: string, queryParams: GetProjectJavascriptErrorLogsQueryParams): QueryOperation<ShortLogResponseShortJavaScriptErrorLog>

	/**
	 * Get project notification rules
	 *
	 * Retrieves the currently set project notification rules The API requires the user to have Edit Global Notification Settings permissions.
	 */
	getProjectNotificationRules(this: void): QueryOperation<ProjectNotificationRules | null>

	/**
	 * Get options
	 *
	 * Retrieves all available project options. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getProjectOptions(this: void, project: string): QueryOperation<Record<string, object>>

	/**
	 * Get service detail log
	 *
	 * Returns the detailed service log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectServiceLog(this: void, project: string, timestamp: number): QueryOperation<DetailedServiceLog>

	/**
	 * Get service log frequencies
	 *
	 * Returns the frequencies of the various service log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectServiceLogFrequencies(this: void, project: string): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all service logs
	 *
	 * Returns a list of all service short logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectServiceLogs(this: void, project: string, queryParams: GetProjectServiceLogsQueryParams): QueryOperation<ShortLogResponseShortServiceLog>

	/**
	 * Get unique users activity trend
	 *
	 * Returns a list of lists, where each list contains a pair of the date timestamp and the number of unique users on that date. Requires VIEW permissions on all projects given via the project parameter
	 */
	getProjectUserActivityTrend(this: void, queryParams: GetProjectUserActivityTrendQueryParams): QueryOperation<Array<Array<number>>>

	/**
	 * Get worker detail log
	 *
	 * Returns the detailed worker log entry for the given timestamp. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
     * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectWorkerLog(this: void, project: string, timestamp: number): QueryOperation<DetailedWorkerLog>

	/**
	 * Get worker log frequencies
	 *
	 * Returns the frequencies of the various worker log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectWorkerLogFrequencies(this: void, project: string): QueryOperation<Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all worker logs
	 *
	 * Returns a list of all worker short logs. The API requires the user to have View System Status permissions.
     * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectWorkerLogs(this: void, project: string, queryParams: GetProjectWorkerLogsQueryParams): QueryOperation<ShortLogResponseShortWorkerLog>

	/**
	 * Get simple read/write performance statistics
	 *
	 * Returns a textual summary of read/write performance statistics. The API requires the user to have Access Administrative Services permissions.
	 */
	getReadWriteStatistics(this: void): QueryOperation<string>

	/**
	 * Get recent branches
	 *
	 * Returns the recently visited branches. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRecentBranches(this: void, project: string): QueryOperation<Array<string>>

	/**
	 * Get redundant literal treemap
	 *
	 * Retrieves a treemap of all redundant literals. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRedundantLiteralTreemap(this: void, project: string, queryParams: GetRedundantLiteralTreemapQueryParams): QueryOperation<FilteredTreeMapWrapper>

	/**
	 * Get redundant literals
	 *
	 * Retrieves all redundant literals in the project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRedundantLiteralsQuery(this: void, project: string, queryParams: GetRedundantLiteralsQueryQueryParams): QueryOperation<Array<RedundantLiteralInfo>>

	/**
	 * Get report
	 *
	 * Returns the report. The API requires the user to have View permissions on the Quality Report id.
     * @param id The report id
	 */
	getReport(this: void, id: string): QueryOperation<QualityReport>

	/**
	 * Get report slide render data
	 *
	 * Returns the data necessary to render the slide in the frontend. The API requires the user to have View permissions on the Quality Report reportId.
     * @param reportId The ID of the report.
     * @param slideId The ID of the slide.
	 */
	getReportSlideRawRenderData(this: void, reportId: string, slideId: string): QueryOperation<object | null>

	/**
	 * Get repository churn
	 *
	 * Retrieves the repository churn for a time range. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRepositoryChurn(this: void, project: string, queryParams: GetRepositoryChurnQueryParams): QueryOperation<Array<ExtendedRepositoryLogEntry>>

	/**
	 * Get all repository log entries
	 *
	 * Returns repository log entries for given commits.A commit is identified as branch1@timestamp1All timestamps are interpreted as milliseconds since 1970. If no commits are provided, all log entries are returned. Commits are separated by comma. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRepositoryLog(this: void, project: string, queryParams: GetRepositoryLogQueryParams): QueryOperation<Array<ExtendedRepositoryLogEntry>>

	/**
	 * Get all repository log entries, with split aggregates
	 *
	 * Returns repository log entries for given commits. Aggregated entries are split into their constituents.A commit is identified as branch1@timestamp1All timestamps are interpreted as milliseconds since 1970. If no commits are provided, all log entries are returned. Commits are separated by comma. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRepositoryLogWithSplitAggregates(this: void, project: string, queryParams: GetRepositoryLogWithSplitAggregatesQueryParams): QueryOperation<Array<ExtendedRepositoryLogEntry>>

	/**
	 * Get the repository summary
	 *
	 * Provides summary information about the commit activity in a project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRepositorySummary(this: void, project: string, queryParams: GetRepositorySummaryQueryParams): QueryOperation<RepositorySummary>

	/**
	 * Get resolved tasks
	 *
	 * Returns all available tasks with resolved properties. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getResolvedTasks(this: void, project: string, queryParams: GetResolvedTasksQueryParams): QueryOperation<Array<ResolvedTask>>

	/**
	 * Get resource history
	 *
	 * Returns repository log file entries for a specific path, i.e. the association between files and repository log entries. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getResourceHistory(this: void, project: string, uniformPath: string, queryParams: GetResourceHistoryQueryParams): QueryOperation<Array<RepositoryLogFileHistoryEntry>>

	/**
	 * Get resource type
	 *
	 * Retrieves the type of a resource represented by a uniform path The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getResourceType(this: void, project: string, queryParams: GetResourceTypeQueryParams): QueryOperation<EResourceTypeEntry>

	/**
	 * Get retrospective
	 *
	 * Returns the retrospective. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id The retrospective id
	 */
	getRetrospective(this: void, project: string, id: string): QueryOperation<Retrospective>

	/**
	 * Get quality retrospectives
	 *
	 * Returns all retrospectives stored for the given project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRetrospectivesForProject(this: void, project: string): QueryOperation<Array<Retrospective>>

	/**
	 * Get review baseline
	 *
	 * Allows to retrieve the review baseline for a project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getReviewBaseline(this: void, project: string): QueryOperation<number | null>

	/**
	 * Get risk methods
	 *
	 * Returns all methods that are considered a risk and should therefore be covered with tests. For now this is all methods that have been added or changed in the given time range. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getRisks(this: void, project: string, queryParams: GetRisksQueryParams): QueryOperation<RiskMethods>

	/**
	 * Get role schema
	 *
	 * Returns the RoleSchema holding all existing global and project roles. The API requires no permissions
	 */
	getRoleSchema(this: void): QueryOperation<RoleSchemaData>

	/**
	 * Get running profiler
	 *
	 * Retrieves the running profiler The user needs to have VIEW permissions on the configuration this profiler is using.
     * @param profilerId 
	 */
	getRunningProfiler(this: void, profilerId: string): QueryOperation<RunningProfilerInfo>

	/**
	 * Get running profilers
	 *
	 * Retrieves the running profilers available in the system No permissions needed, as the service will only return the profiler infos that use a profiler configuration that is visible to the current user.
	 */
	getRunningProfilers(this: void, queryParams: GetRunningProfilersQueryParams): QueryOperation<RunningProfilersResult>

	/**
	 * Returns the SAML metadata from the given URL.
	 *
	 * This is meant to simplify SAML configuration in Teamscale. The API requires the user to have Access Administrative Services permissions.
	 */
	getSamlIdentityProviderMetadata(this: void, queryParams: GetSamlIdentityProviderMetadataQueryParams): QueryOperation<string>

	/**
	 * Open scheduler control page
	 *
	 * Provides a UI to send a command to the scheduler control. The API requires the user to have Access Administrative Services permissions.
	 */
	getSchedulerControlPage(this: void): QueryOperation<string>

	/**
	 * Returns the currently selected list of test (possibly Pareto ranked).
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	getSelectedTests(this: void, project: string, jobId: string, queryParams: GetSelectedTestsQueryParams): QueryOperation<Array<SelectedTest>>

	/**
	 * Get schema
	 *
	 * Retrieves server option schema. The API requires the user to have Edit Server Options permissions.
	 */
	getServerOptionSchema(this: void): QueryOperation<Array<OptionDescriptor>>

	/**
	 * Service-Load information
	 *
	 * Returns information about service load. The API requires the user to have View System Status permissions.
	 */
	getServiceLoad(this: void): QueryOperation<Array<LoadProfile>>

	/**
	 * Export Latex metric table
	 *
	 * Service for exporting Latex tables containing metrics for the project. User must have audit support included in the license.
     * @param project The project ID.
	 */
	getSingleProjectLatexTable(this: void, project: string, queryParams: GetSingleProjectLatexTableQueryParams): QueryOperation<string>

	/**
	 * Get option
	 *
	 * Retrieves the project option identified by the given name. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param optionId Name of the option to retrieve.
	 */
	getSingleProjectOption(this: void, project: string, optionId: string): QueryOperation<object>

	/**
	 * Get option
	 *
	 * Retrieves the server option identified by the given name. EDIT_SERVER_OPTIONS permission needed for most options, but some options (PUBLIC_READ_OPTION_NAMES) can be read without permission
     * @param optionId Name of the option to retrieve.
	 */
	getSingleServerOption(this: void, optionId: string): QueryOperation<object>

	/**
	 * Returns an image that has been stored previously using this service
	 *
	 * null The API requires the user to have View permissions on the Quality Report reportId.
     * @param reportId The ID of the quality report the user needs access to.
     * @param id The unique image id that was returned by the POST call to this service.
	 */
	getSlideImage(this: void, reportId: string, id: string): QueryOperation<void>

	/**
	 * Get a snapshot
	 *
	 * Returns a single snapshot identified by the given ID or ID prefix. The ID (prefix) must not be empty, but can be as short as one character, as long as it unambiguously identifies the snapshot to be returned. The API requires the user to have Access Administrative Services permissions.
     * @param id The ID of the snapshot to get. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getSnapshot(this: void, id: string, queryParams: GetSnapshotQueryParams): QueryOperation<InstanceComparisonSnapshotDto>

	/**
	 * Get a snapshot status
	 *
	 * Returns the status (IN_PROGRESS, SUCCESS or FAILURE) of a single status identified by the given ID or ID prefix. The ID (prefix) must not be empty, but can be as short as one character, as long as it unambiguously identifies a status. Only snapshots created by the logged-in user are considered for this operation. The API requires the user to have Access Administrative Services permissions.
     * @param id The ID of the snapshot. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getSnapshotStatus(this: void, id: string): QueryOperation<EInstanceComparisonStatusEntry>

	/**
	 * Gets the spec item churn
	 *
	 * Provides a spec item churn (i.e., the list of changed spec items) for a given time range. This service is public API since Teamscale 8.5. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSpecItemChurn(this: void, project: string, queryParams: GetSpecItemChurnQueryParams): QueryOperation<Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Get spec item details
	 *
	 * Retrieves spec item details by their IDs. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSpecItemDetails(this: void, project: string, queryParams: GetSpecItemDetailsQueryParams): QueryOperation<Array<UserResolvedSpecItem | null>>

	/**
	 * Get the spec items that the given one is verifying
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id ID of the verifying spec item
	 */
	getSpecItemVerifies(this: void, project: string, id: string, queryParams: GetSpecItemVerifiesQueryParams): QueryOperation<Array<SpecItemReferenceElement>>

	/**
	 * Get the spec items that are verified by the queried items 
	 *
	 * Retrieves all 'verifies' references from the queried spec items to the verified ones. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSpecItemsVerifiedByQueried(this: void, project: string, queryParams: GetSpecItemsVerifiedByQueriedQueryParams): QueryOperation<Array<SpecItemRelationReference>>

	/**
	 * Get the spec items that verify the queried items 
	 *
	 * Retrieves all 'verified-by' references from the queried spec items to the verifying ones. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getSpecItemsVerifyingQueried(this: void, project: string, queryParams: GetSpecItemsVerifyingQueriedQueryParams): QueryOperation<Array<SpecItemRelationReference>>

	/**
	 * Get stacktrace of failed test execution
	 *
	 * Retrieves the stacktrace of the failed test execution at a given uniform path. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param test Uniform path to retrieve the stacktrace for
	 */
	getStacktrace(this: void, project: string, test: string, queryParams: GetStacktraceQueryParams): QueryOperation<Stacktrace | null>

	/**
	 * Get state change timestamps
	 *
	 * Allows getting the timestamps for the different analysis states. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getStateChangeTimestamps(this: void, project: string): QueryOperation<Record<string, number>>

	/**
	 * Get aggregated store performance metrics
	 *
	 * Allows downloading the store performance aggregate data as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getStorePerformanceDetails(this: void): QueryOperation<void>

	/**
	 * Returns the configuration of the service provider that is stored at the named option.
	 *
	 * This is meant to simplify the configuration of the identity provider. The API requires no login
	 */
	getStoredServiceProviderConfiguration(this: void, queryParams: GetStoredServiceProviderConfigurationQueryParams): QueryOperation<File>

	/**
	 * Get stored test lists
	 *
	 * Retrieves the list of available test list descriptors. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getStoredTestLists(this: void, project: string): QueryOperation<Array<TestListDescriptor>>

	/**
	 * Get stored test lists names
	 *
	 * Retrieves the list of available test list descriptors names. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getStoredTestListsNames(this: void, project: string): QueryOperation<Array<string>>

	/**
	 * Get the complete backup summary
	 *
	 * Get the summary of the 10 most recent backups. This service is public API since Teamscale 6.1. The API requires the user to have Backup Global Data permissions.
	 */
	getSummary1(this: void): QueryOperation<Array<BackupJobSummary>>

	/**
	 * Get an AI generated summary for an element
	 *
	 * Retrieves the AI summary for an element. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param engine The interal name of the configured AI engine to use.
	 */
	getSummary2(this: void, project: string, engine: string, queryParams: GetSummary2QueryParams): QueryOperation<string>

	/**
	 * Get system information.
	 *
	 * Returns system information. The API requires the user to have View System Status permissions.
	 */
	getSystemInformation(this: void): QueryOperation<Array<SystemProcessInfo>>

	/**
	 * Get task
	 *
	 * Returns the respective task with detailed findings. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id ID of the requested task
	 */
	getTask(this: void, project: string, id: number, queryParams: GetTaskQueryParams): QueryOperation<TaskWithDetailedFindings>

	/**
	 * Get task siblings
	 *
	 * Retrieves the task siblings (previous and next tasks) for the task specified by ID, allowing user filters. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param task ID of the task to retrieve the sibling tasks for.
	 */
	getTaskPreviousNext(this: void, project: string, task: number, queryParams: GetTaskPreviousNextQueryParams): QueryOperation<PreviousNextSiblings>

	/**
	 * Get task summary
	 *
	 * Retrieves the counts of tasks in each possible task state. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTaskSummary(this: void, project: string, queryParams: GetTaskSummaryQueryParams): QueryOperation<Record<string, number>>

	/**
	 * Get task tags
	 *
	 * Allows to retrieve all used task tags. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTaskTags(this: void, project: string): QueryOperation<Array<string>>

	/**
	 * Get finding to task mapping
	 *
	 * Returns the IDs of all tasks containing the given findings. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTaskToFindingMapping(this: void, project: string, body: GetTaskToFindingMappingBody): QueryOperation<Array<GetTaskToFindingMappingDefaultResponseInner>>

	/**
	 * Get tasks with count
	 *
	 * Returns all available tasks with count. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTasksWithCount(this: void, project: string, queryParams: GetTasksWithCountQueryParams): QueryOperation<TasksWithCount>

	/**
	 * Shutdown Teamscale
	 *
	 * Provides an UI to issue a Teamscale shutdown request. The API requires the user to have Access Administrative Services permissions.
	 */
	getTeamscaleShutdownPage(this: void): QueryOperation<string>

	/**
	 * Get a valid teamscale-upload invocation.
	 *
	 * Allows the user to get a valid teamscale-upload invocation that the Teamscale Upload wizard UI created. The API requires the user to have View Project permissions on the project.
     * @param project The project ID to which the external coverage report should be uploaded
	 */
	getTeamscaleUploadInvocation(this: void, project: string, queryParams: GetTeamscaleUploadInvocationQueryParams): QueryOperation<string>

	/**
	 * Input for the Teamscale Upload wizard.
	 *
	 * Returns the input needed to render the Teamscale Upload wizard in the UI. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	getTeamscaleUploadWizardInput(this: void, project: string): QueryOperation<TeamscaleUploadWizardInput>

	/**
	 * List all third party dependencies
	 *
	 * Lists all third party dependencies for the project with given package depth, along with the percentage of affected files for each dependency. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTechnologyDependencies(this: void, project: string, queryParams: GetTechnologyDependenciesQueryParams): QueryOperation<Array<DependencyEntry>>

	/**
	 * Get treemap with the third party dependencies
	 *
	 * Builds a treemap with the third party dependencies from the technology scan. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTechnologyTreeMap(this: void, project: string, queryParams: GetTechnologyTreeMapQueryParams): QueryOperation<FilteredTreeMapWrapper>

	/**
	 * Returns test coverage
	 *
	 * Returns the line coverage data for an element. This service is public API since Teamscale 5.3. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getTestCoverage(this: void, project: string, uniformPath: string, queryParams: GetTestCoverageQueryParams): QueryOperation<LineCoverageInfo | null>

	/**
	 * Get test coverage overlay
	 *
	 * Retrieves the methods that are expected to be covered by the impacted tests if they are rerun. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTestCoverageOverlay(this: void, project: string, queryParams: GetTestCoverageOverlayQueryParams): QueryOperation<TestCoverageOverlayData>

	/**
	 * Returns test coverage
	 *
	 * Returns the line coverage data for an element. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param uniformPath 
	 */
	getTestCoveragePost(this: void, project: string, uniformPath: string, body: GetTestCoveragePostBody): QueryOperation<LineCoverageInfo | null>

	/**
	 * Download test gap data
	 *
	 * Downloads test gap information as a CSV file The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTestGapDataAsCsv(this: void, project: string, queryParams: GetTestGapDataAsCsvQueryParams): QueryOperation<File>

	/**
	 * Get test gap treemap
	 *
	 * Retrieves the test get treemap. For each method in the project, the treemap shows whether it was tested after its last change within the reference time frame. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTestGapTreeMap(this: void, project: string, queryParams: GetTestGapTreeMapQueryParams): QueryOperation<TestGapTreeMapWrapper>

	/**
	 * Get test gap percentage
	 *
	 * Calculates the TGA percentage for the given uniform path or the given issue ID. This service is public API since Teamscale 5.9.6. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTgaPercentage(this: void, project: string, queryParams: GetTgaPercentageQueryParams): QueryOperation<number>

	/**
	 * Get metric table entries
	 *
	 * Retrieves various test gap metrics (e.g., test gap state, number of test gaps, execution state and churn) and returns them in tabular form. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTgaTable(this: void, project: string, queryParams: GetTgaTableQueryParams): QueryOperation<Array<TgaTableEntry>>

	/**
	 * Get test coverage partitions
	 *
	 * Returns the available test coverage partitions of the project. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTgaTestCoveragePartitions(this: void, project: string, queryParams: GetTgaTestCoveragePartitionsQueryParams): QueryOperation<Array<string>>

	/**
	 * Get test gap trend
	 *
	 * Calculates test gap trends. Does not support issue requests. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTgaTrend(this: void, project: string, queryParams: GetTgaTrendQueryParams): QueryOperation<Array<MetricTrendEntry>>

	/**
	 * Get custom fields definitions
	 *
	 * Returns the list of all available custom fields from the given connector User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getTrackerCustomFields(this: void, body: ConnectorConfiguration): QueryOperation<ExternalToolIssueCustomFieldResult>

	/**
	 * Get trigger graph as JSON
	 *
	 * Returns a plain text GraphViz DOT description of the trigger graph The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	getTriggerGraphDot(this: void, project: string, queryParams: GetTriggerGraphDotQueryParams): QueryOperation<Array<TriggerGraphNode>>

	/**
	 * Get detailed trigger performance metrics
	 *
	 * Allows downloading the detailed trigger performance data as CSV. The feature toggle -Dcom.teamscale.log_performance_details=true must be enabled. The API requires the user to have Access Administrative Services permissions.
	 */
	getTriggerPerformanceDetails(this: void): QueryOperation<void>

	/**
	 * Get unused ID
	 *
	 * Returns a valid event ID that is unused so far. The API requires no permissions
	 */
	getUnusedEventId(this: void): QueryOperation<string>

	/**
	 * Preview of usage data
	 *
	 * Returns a preview of the data we collect if you opt-in to provide that data. The API requires the user to have Access Administrative Services permissions.
	 */
	getUsageDataPreview(this: void, body: UsageDataReportingOption): QueryOperation<UsageDataPreview>

	/**
	 * Get auto-completion suggestions
	 *
	 * Returns a list of suggested user names for auto-completion. The API requires the user to have Create Users permissions.
     * @param input The current input that should be auto-completed.
	 */
	getUserAutoCompletionSuggestions(this: void, input: string, queryParams: GetUserAutoCompletionSuggestionsQueryParams): QueryOperation<Array<string>>

	/**
	 * Get user avatar image
	 *
	 * Returns the image file that serves as the avatar for the user in the first path parameter. The API requires no permissions
     * @param user 
	 */
	getUserAvatarData(this: void, user: string, queryParams: GetUserAvatarDataQueryParams): QueryOperation<void>

	/**
	 * Get a user group
	 *
	 * Get the user group identified by the given name. The API requires the user to have View permissions on the Group userGroupName.
     * @param userGroupName Name of the requested user group.
	 */
	getUserGroup(this: void, userGroupName: string): QueryOperation<UserGroup>

	/**
	 * Get the spec items that are verifying the given one (the given spec item is verified by...)
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param id ID of the spec item to fetch
	 */
	getVerifiedBySpecItems(this: void, project: string, id: string, queryParams: GetVerifiedBySpecItemsQueryParams): QueryOperation<Array<SpecItemReferenceElement>>

	/**
	 * Returns all accessible projects along with their metric schema
	 *
	 * null The permissions will be checked at runtime (this service works cross-project)
	 */
	getWidgetContext(this: void): QueryOperation<WidgetContext>

	/**
	 * Get worker status
	 *
	 * Retrieves the worker status info. The API requires the user to have View System Status permissions.
	 */
	getWorkerStatus(this: void): QueryOperation<Array<WorkerGroupStatus>>

	/**
	 * Sufficient Ram
	 *
	 * Checks whether Teamscale has enough RAM to operate, given the amount of workers. The API requires no permissions
	 */
	hasSufficientRam(this: void): QueryOperation<SufficientRamResponse>

	/**
	 * Hide event announcement
	 *
	 * Hides the event announcement with the given ID for the logged-in user. The event will no longer be advertised to the user. The API requires no permissions
	 */
	hideEventAnnouncement(this: void, body: string): QueryOperation<void>

	/**
	 * Upload AbapLint configuration files
	 *
	 * Uploads AbapLint configuration files which are then converted to Teamscale analysis profiles. Returns a textual summary of the import process The API requires the user to have Create Analysis Profiles permissions.
	 */
	importAbapLintConfigurationFile(this: void, body: ImportAbapLintConfigurationFileBody): QueryOperation<Array<ABAPLintConfigurationFileImportSummary>>

	/**
	 * Import analysis profile
	 *
	 * Imports an analysis profile. Requires Create Analysis Profiles or the permission to edit analysis profiles when a profile with the same name already exists.
	 */
	importAnalysisProfile(this: void, body: ImportAnalysisProfileBody): QueryOperation<void>

	/**
	 * Update a dashboard from a dashboard export
	 *
	 * Performs an import of a dashboard. Adds the uploaded descriptor to the list of dashboards/templates. The descriptor must be packaged within an JSON Teamscale Version Container. In case the dashboard with the UUID already exists the existing one will be overridden. This service is public API since Teamscale 8.9.8. Every logged in user is allowed to create dashboards. For dashboards that already exist and should therefore be overwritten the user needs to have EDIT permissions on the dashboard.
	 */
	importAndReplaceDashboards(this: void, body: ImportAndReplaceDashboardsBody): QueryOperation<Array<string>>

	/**
	 * Import backup.
	 *
	 * Triggers the import of a backup and returns the job ID. This service is public API since Teamscale 6.1. The API requires the user to have Backup Global Data permissions.
	 */
	importBackup(this: void, body: ImportBackupBody): QueryOperation<string>

	/**
	 * Create a dashboard from a dashboard export
	 *
	 * Performs an import of a dashboard. Adds the uploaded descriptor to the list of dashboards/templates. The descriptor must be packaged within an JSON Teamscale Version Container. This service is public API since Teamscale 8.7. Every logged in user is allowed to create dashboards
	 */
	importDashboard(this: void, body: ImportDashboardBody): QueryOperation<Array<string>>

	/**
	 * Create a dashboard template
	 *
	 * Performs an import of a dashboard template. Adds the uploaded descriptor to the list of templates. The descriptor must be packaged within an JSON Teamscale Version Container. The API requires the user to have Create Dashboard Templates permissions.
	 */
	importDashboardTemplate(this: void, body: ImportDashboardTemplateBody): QueryOperation<Array<string>>

	/**
	 * Import external findings
	 *
	 * Uploads external findings groups and/or descriptions put from a zip archive The API requires the user to have Edit External Findings Schema permissions.
	 */
	importExternalFindings(this: void, body: ImportExternalFindingsBody): QueryOperation<void>

	/**
	 * Imports external metrics
	 *
	 * Imports external metrics as a ZIP file. The API requires the user to have Edit External Metrics Schema permissions.
	 */
	importExternalMetrics(this: void, body: ImportExternalMetricsBody): QueryOperation<void>

	/**
	 * Import group
	 *
	 * Imports all users of one group from a remote server. Returns the list of imported user names. The API requires the user to have Create Users, Create Groups permissions.
     * @param groupName The name of the group that should be imported.
	 */
	importGroup(this: void, groupName: string, queryParams: ImportGroupQueryParams): QueryOperation<Array<string>>

	/**
	 * Upload Sonar profile
	 *
	 * Uploads Sonar Quality Profiles which are then converted to Teamscale analysis profiles. Returns a textual summary of the import process The API requires the user to have Create Analysis Profiles permissions.
	 */
	importSonarProfile(this: void, body: ImportSonarProfileBody): QueryOperation<SonarQualityProfileImportSummary>

	/**
	 * Upload SwiftLint Configuration
	 *
	 * Uploads SwiftLint Configurations which are then converted to Teamscale analysis profiles. Returns a textual summary of the import process The API requires the user to have Create Analysis Profiles permissions.
	 */
	importSwiftLintConfiguration(this: void, body: ImportSwiftLintConfigurationBody): QueryOperation<SwiftLintConfigurationImportSummary>

	/**
	 * Import user
	 *
	 * Imports one user from a remote server. Returns the name of the imported user. The API requires the user to have Create Users permissions.
     * @param userName The name of the user that should be imported.
	 */
	importUser(this: void, userName: string, queryParams: ImportUserQueryParams): QueryOperation<string>

	/**
	 * Availability of merge requests
	 *
	 * Returns true if merge requests are configured for the project The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	isMergeRequestsEnabled(this: void, project: string): QueryOperation<boolean>

	/**
	 * Reports if snapshot backups are supported
	 *
	 * Returns if snapshot backups are supported by the storage system. The API requires the user to have Access Administrative Services permissions.
	 */
	isSnapshotBackupSupported(this: void): QueryOperation<boolean>

	/**
	 * Availability of spec-item analysis
	 *
	 * Determines whether spec-item analysis is enabled for the project The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	isSpecItemAnalysisEnabled(this: void, project: string): QueryOperation<boolean>

	/**
	 * List parse log of project
	 *
	 * Lists all parse log entries for the project. The API requires the user to have View System Status permissions.
     * @param project The project ID.
	 */
	listAllParseLogEntries(this: void, project: string, queryParams: ListAllParseLogEntriesQueryParams): QueryOperation<Array<ParseLogEntry>>

	/**
	 * List of merge requests, total number and existing status.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	listMergeRequests(this: void, project: string, queryParams: ListMergeRequestsQueryParams): QueryOperation<ExtendedMergeRequestsInfo>

	/**
	 * Get quality reports
	 *
	 * Returns all stored reports details, without slide contents. No permissions needed, as the service will only return reports visible to current user.
	 */
	listQualityReports(this: void, queryParams: ListQualityReportsQueryParams): QueryOperation<Array<UserResolvedQualityReport>>

	/**
	 * Load the list of tests of this job from a named test list.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	loadFromNamedTestList(this: void, project: string, jobId: string, queryParams: LoadFromNamedTestListQueryParams): QueryOperation<number>

	/**
	 * Mark/Unmark a dashboard as user favorite
	 *
	 * Performs an operation on the dashboard favorite list of the current user, whether to add/remove a dashboard to/from the list. The API requires no permissions
     * @param dashboardId Contains the name of the dashboard
	 */
	markDashboardAsFavorite(this: void, dashboardId: string, queryParams: MarkDashboardAsFavoriteQueryParams): QueryOperation<void>

	/**
	 * Performs the login of a user.
	 *
	 * Authenticates the user based on given credentials and returns a session cookie in case of success. The API requires no login
	 */
	performLogin(this: void, body: Credentials): QueryOperation<void>

	/**
	 * Perform test query
	 *
	 * Retrieves the size and list of tests for the query. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	performTestQuery(this: void, project: string, queryParams: PerformTestQueryQueryParams): QueryOperation<TestQueryResult>

	/**
	 * Apply basic role change for instance
	 *
	 * Applies the instance's role changes sent to the sever. Requires the basic permission to \"Assign Roles\"
     * @param instance The instance&#39;s id
	 */
	postBasicRoleForInstance(this: void, instance: string, queryParams: PostBasicRoleForInstanceQueryParams, body: RoleChange): QueryOperation<void>

	/**
	 * Apply basic role change globally
	 *
	 * Applies the role changes sent to the sever. The API requires the user to have Assign Global Roles permissions.
	 */
	postBasicRoleGlobally(this: void, queryParams: PostBasicRoleGloballyQueryParams, body: RoleChange): QueryOperation<void>

	/**
	 * Create global role
	 *
	 * Creates a new global role. The API requires the user to have Edit Roles permissions.
	 */
	postGlobalRole(this: void, body: GlobalRole): QueryOperation<void>

	/**
	 * Apply role change for global role assignments
	 *
	 * Updates the role assignments with the given changes. The API requires the user to have Assign Global Roles permissions.
	 */
	postGlobalRoleWithAssignments(this: void, body: RoleChange): QueryOperation<void>

	/**
	 * Sets the list of tests to minimize in the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	postJobTests(this: void, project: string, jobId: string, body: Array<string>): QueryOperation<number>

	/**
	 * Create project role
	 *
	 * Creates a new project role. The API requires the user to have Edit Roles permissions.
	 */
	postProjectRole(this: void, body: ProjectRole): QueryOperation<void>

	/**
	 * Resolve username or alias
	 *
	 * Retrieves the usernames for the supplied aliases, usernames, full names or e-mail addresses. For users which cannot be resolved, the supplied value is returned back. This API requires VIEW permission for every resolved user. If the permission is missing, the supplied value is returned back.
	 */
	postResolveUserAliases(this: void, body: PostResolveUserAliasesBody): QueryOperation<Array<string>>

	/**
	 * Apply role change for project role assignments globally
	 *
	 * Processes the post request and updates the role assignments according to the {@link RoleChange} globally. The API requires the user to have Assign Global Roles permissions.
	 */
	postRoleChangeGlobally(this: void, body: RoleChange): QueryOperation<void>

	/**
	 * Get test gap percentage
	 *
	 * Calculates the TGA percentage for the given uniform path or the given issue ID. This service is public API since Teamscale 5.9.6. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	postTgaPercentage(this: void, project: string, queryParams: PostTgaPercentageQueryParams, body: PostTgaPercentageBody): QueryOperation<number>

	/**
	 * Posts user avatar image
	 *
	 * Uploads the image file given in the multipart called 'avatar' as the avatar of the user in the first path parameter. Users can change their own avatar only but admin users can change someone else's avatar
     * @param user 
	 */
	postUserAvatarData(this: void, user: string, body: PostUserAvatarDataBody): QueryOperation<void>

	/**
	 * Retrieves branches from VCSs
	 *
	 * Returns set of all branches for a given VCS connector The API requires no permissions
	 */
	previewRepositoryBranches(this: void, queryParams: PreviewRepositoryBranchesQueryParams, body: ConnectorConfiguration): QueryOperation<Array<string>>

	/**
	 * Retrieves commit messages from VCSs
	 *
	 * Returns latest commit messages for a given branch for a given VCS connector The API requires no permissions
	 */
	previewRepositoryCommitMessages(this: void, queryParams: PreviewRepositoryCommitMessagesQueryParams, body: ConnectorConfiguration): QueryOperation<Array<string>>

	/**
	 * Retrieves files from VCSs
	 *
	 * Returns list of all files for a given VCS connector existing on the default branch at HEAD The API requires no permissions
	 */
	previewRepositoryFiles(this: void, queryParams: PreviewRepositoryFilesQueryParams, body: ConnectorConfiguration): QueryOperation<Array<string>>

	/**
	 * Handles the incoming requests from code collaboration platforms signaling an update in the status of build jobs.
	 *
	 * Handles the incoming requests from code collaboration platforms signaling the start or completion of a build job.The service identifies the merge request corresponding to the provided revision and triggers the merge request update process. The API requires no login
     * @param project The project ID.
	 */
	processBuildWebhookEvent(this: void, project: string, queryParams: ProcessBuildWebhookEventQueryParams): QueryOperation<void>

	/**
	 * Handles incoming requests from Gitea.
	 *
	 * Handles the incoming requests from Gitea and processes the request body depending on the event. The events which are handled by the service are 'push' and 'pull_request'. The API requires no login
	 */
	processWebhook(this: void, body: Array<string>): QueryOperation<void>

	/**
	 * Puts new crop dimensions for user avatar
	 *
	 * Tries to set new crop dimensions for the avatar of the user in the first path parameter. The API requires the user to have Edit permissions on the user.
     * @param user 
	 */
	putAvatarData(this: void, user: string, queryParams: PutAvatarDataQueryParams): QueryOperation<AvatarData>

	/**
	 * Update global role
	 *
	 * Updates/renames an existing global role. Or creates it as a new role if the parameter for the old global role name is null or an empty string. The API requires the user to have Edit Roles permissions.
     * @param name The name of the role to update/rename.
	 */
	putGlobalRole(this: void, name: string, body: GlobalRole): QueryOperation<void>

	/**
	 * Append the list of impacted tests as tests to minimize to the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	putJobTestsFromImpacted(this: void, project: string, jobId: string, queryParams: PutJobTestsFromImpactedQueryParams): QueryOperation<number>

	/**
	 * Append the list of tests to minimize to the job based on a query.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	putJobTestsFromQuery(this: void, project: string, jobId: string, queryParams: PutJobTestsFromQueryQueryParams): QueryOperation<number>

	/**
	 * Update project role
	 *
	 * Updates/renames an existing project role. Or creates it as a new role if the parameter for the old global role name is null or an empty string. The API requires the user to have Edit Roles permissions.
     * @param name The name of the role to update/rename.
	 */
	putProjectRole(this: void, name: string, body: ProjectRole): QueryOperation<void>

	/**
	 * Receive an \"analysis completed\" notification from the clang-tidy execution server
	 *
	 * Receiver for the \"analysis completed\" notification from execution server to Teamscale server. After the notification, this Teamscale server will query the analysis results from the execution server.  The API requires the user to have Access Administrative Services permissions.
	 */
	receiveAnalysisCompletedConfirmation(this: void, queryParams: ReceiveAnalysisCompletedConfirmationQueryParams): QueryOperation<void>

	/**
	 * Profiler heartbeat
	 *
	 * Updates the profiler infos and sets the profiler to still alive. This service is public API since Teamscale 9.4. The user needs to have VIEW permissions on the configuration this profiler is using.
     * @param profilerId The ID of the profiler to update.
	 */
	receiveHeartbeat(this: void, profilerId: string, body: ProfilerInfo): QueryOperation<void>

	/**
	 * Redirect to the example hosting host
	 *
	 * Redirects to /example on the instance, which hosts the actual example. The API requires no permissions
	 */
	redirectToCorrectHost(this: void, queryParams: RedirectToCorrectHostQueryParams): QueryOperation<void>

	/**
	 * Generate Service Provider certificate
	 *
	 * Generates a new certificate for the service provider stored at the named option. The API requires the user to have Access Administrative Services permissions.
	 */
	regenerateStoredServiceProviderCertificate(this: void, queryParams: RegenerateStoredServiceProviderCertificateQueryParams): QueryOperation<boolean>

	/**
	 * Registers a profiler instance
	 *
	 * Registers a profiler to Teamscale and returns the profiler configuration it should be started with. This service is public API since Teamscale 9.4. The API requires the user to have View permissions on the Profiler Configuration .
	 */
	registerProfiler(this: void, queryParams: RegisterProfilerQueryParams, body: ProcessInformation): QueryOperation<ProfilerRegistration>

	/**
	 * Revoke Access Keys
	 *
	 * Revokes the given access key of the user. The API requires the user to have Edit permissions on the user.
     * @param user 
	 */
	removeAccessKeys(this: void, user: string, queryParams: RemoveAccessKeysQueryParams): QueryOperation<void>

	/**
	 * Delete report slides
	 *
	 * Deletes the given slides from an existing report. The API requires the user to have Edit permissions on the Quality Report reportId.
     * @param reportId The id of the report that is edited.
	 */
	removeQualityReportSlides(this: void, reportId: string, body: Array<string>): QueryOperation<void>

	/**
	 * Report JavaScript error
	 *
	 * Writes the given JavaScript error that occurred in the Web UI into the Teamscale's JavaScript error log. The API requires no permissions
	 */
	reportJavascriptError(this: void, body: JavaScriptError): QueryOperation<void>

	/**
	 * Request a ClangTidy analysis
	 *
	 * Opens and schedules a new ClangTidy analysis session, returning the session key. The API requires the user to have Create Projects permissions.
	 */
	requestAnalysisAndOpenSession(this: void, queryParams: RequestAnalysisAndOpenSessionQueryParams, body: ClangTidyOutsourcedAnalysisRequestParameters): QueryOperation<string>

	/**
	 * Request a SonarLint analysis
	 *
	 * Opens and schedules a new SonarLint analysis session, returning the session key. The API requires the user to have Create Projects permissions.
	 */
	requestAnalysisAndOpenSession1(this: void, queryParams: RequestAnalysisAndOpenSession1QueryParams, body: SonarLintIndependentAnalysisRequestParameters): QueryOperation<string>

	/**
	 * Get an AI generated finding resolution suggestion
	 *
	 * Retrieves the AI generated resolution for a finding. The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param engine The name of the configured AI engine to use.
     * @param id The id of the finding to find a resolution for.
	 */
	resolveFinding(this: void, project: string, engine: string, id: string, queryParams: ResolveFindingQueryParams): QueryOperation<FindingResolutionResult>

	/**
	 * Get the target commit of a git tag
	 *
	 * Resolves the given git tag to the tagged commit. The API requires the user to have View Project permissions on the project.
     * @param project 
	 */
	resolveTag(this: void, project: string, body: GitTag): QueryOperation<CommitDescriptor | null>

	/**
	 * Re-vote on all open merge requests
	 *
	 * Re-votes on all open merge requests in the project. This can be used to manually trigger voting or commenting. The API requires the user to have Access Administrative Services permissions.
     * @param project The project ID.
	 */
	revoteOnAllOpenMergeRequests(this: void, project: string): QueryOperation<void>

	/**
	 * Re-vote on a specific open merge request
	 *
	 * Re-votes on a specific open merge request in the project. This can be used to manually trigger voting or commenting. The API requires the user to have Access Administrative Services permissions.
     * @param project The project ID.
     * @param idWithRepository 
	 */
	revoteOnMergeRequest(this: void, project: string, idWithRepository: string): QueryOperation<void>

	/**
	 * Run database compaction
	 *
	 * Allows running a full compaction on the database. WARNING: This is likely a very expensive operation. The API requires the user to have Access Administrative Services permissions.
	 */
	runDatabaseCompaction(this: void): QueryOperation<void>

	/**
	 * Saves the list of tests created along this job as a named test list.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	saveAsNamedTestList(this: void, project: string, jobId: string, body: string): QueryOperation<number>

	/**
	 * Save a given test list
	 *
	 * Creates a test list descriptor in the system. The API requires the user to have Edit Test Queries permissions on the project.
     * @param project The project ID.
	 */
	saveTestListDescriptor(this: void, project: string, body: TestListDescriptor): QueryOperation<void>

	/**
	 * Set project notification rules
	 *
	 * Edits the currently set project notification rules The API requires the user to have Edit Global Notification Settings permissions.
	 */
	setProjectNotificationRules(this: void, body: ProjectNotificationRules): QueryOperation<void>

	/**
	 * Set scheduler filtering status
	 *
	 * Sets the filtering status of the scheduler The API requires the user to have Access Administrative Services permissions.
	 */
	setSchedulingMode(this: void, body: string): QueryOperation<void>

	/**
	 * Request the computation of a minimized test set.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
	 */
	setupJob(this: void, project: string, queryParams: SetupJobQueryParams): QueryOperation<string>

	/**
	 * Default Admin Enabled
	 *
	 * Checks whether Teamscale has the default admin user enabled when it shouldn't. The API requires no permissions
	 */
	showDefaultAdminWarning(this: void): QueryOperation<boolean>

	/**
	 * Default Backup Settings
	 *
	 * Checks whether Teamscale has default backup settings enabled, which were not yet acknowledged by an admin user.  The API requires no permissions
	 */
	showDefaultBackupSettingsWarning(this: void): QueryOperation<boolean>

	/**
	 * In Memory Database
	 *
	 * Checks whether Teamscale operates on an in memory database when it shouldn't. The API requires no permissions
	 */
	showInMemoryWarning(this: void): QueryOperation<boolean>

	/**
	 * Shadow Mode
	 *
	 * Checks whether Teamscale has the shadow mode enabled. The API requires no permissions
	 */
	showShadowModeWarning(this: void): QueryOperation<boolean>

	/**
	 * Shutdown Teamscale
	 *
	 * Allows to shut down a running Teamscale instance The API requires the user to have Access Administrative Services permissions.
	 */
	shutdown(this: void, body: ShutdownBody): QueryOperation<void>

	/**
	 * Starts the given test minimization job.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	startJob(this: void, project: string, jobId: string): QueryOperation<void>

	/**
	 * Stops and deletes the given test minimization job and its results.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	stopAndDeleteJob(this: void, project: string, jobId: string): QueryOperation<void>

	/**
	 * Store event announcement
	 *
	 * Stores the given event announcement. The API requires the user to have Access Administrative Services permissions.
     * @param eventId 
	 */
	storeEventAnnouncement(this: void, eventId: string, body: EventAnnouncement): QueryOperation<void>

	/**
	 * Synchronize groups
	 *
	 * Synchronizes all groups with a remote server. Will also automatically update all users in the groups. In case groups are deleted on the server, the process will remove all users (and additional remote server information) from them, but will not delete them, in order to preserve access rights. Returns a list containing the names of the synchronized groups. The API requires the user to have Create Users, Create Groups permissions.
	 */
	synchronizeAllGroups(this: void, queryParams: SynchronizeAllGroupsQueryParams): QueryOperation<Array<string>>

	/**
	 * Synchronize users
	 *
	 * Synchronizes all users with a remote server. Returns the names of updated users. The API requires the user to have Create Users permissions.
	 */
	synchronizeAllUsers(this: void, queryParams: SynchronizeAllUsersQueryParams): QueryOperation<Array<string>>

	/**
	 * Synchronize group
	 *
	 * Synchronizes the given group with a remote server. Will also automatically update all users in the group. In case the group is deleted on the server, the process will remove all users (and additional remote server information) from it, but will not delete it, in order to preserve access rights. The API requires the user to have Create Users, Create Groups permissions.
     * @param groupName The name of the group that should be synchronized.
	 */
	synchronizeSingleGroup(this: void, groupName: string, queryParams: SynchronizeSingleGroupQueryParams): QueryOperation<void>

	/**
	 * Starts a snapshot backup.
	 *
	 * Schedules a snapshot backup to the given target URI. The API requires the user to have Access Administrative Services permissions.
	 */
	triggerSnapshotBackup(this: void, body: TriggerSnapshotBackupBody): QueryOperation<void>

	/**
	 * Unmark tolerated/false positive findings before given commit.
	 *
	 * Unmark all tolerated/false positive findings that were marked before the given commit. Also returns the ids of the unmarked findings. The API requires the user to have View Project, Flag Red Findings, Flag Yellow Findings permissions on the project.
     * @param project The project ID.
	 */
	unmarkToleratedAndFalsePositiveFindingsBeforeCommit(this: void, project: string, queryParams: UnmarkToleratedAndFalsePositiveFindingsBeforeCommitQueryParams): QueryOperation<Array<string>>

	/**
	 * Unregister profiler
	 *
	 * Removes the profiler identified by given ID. This service is public API since Teamscale 9.4. The user needs to have VIEW permissions on the configuration this profiler is using.
     * @param profilerId The ID of the profiler to unregister.
	 */
	unregisterProfiler(this: void, profilerId: string): QueryOperation<void>

	/**
	 * Update analysis profile
	 *
	 * Updates the analysis profile identified by the given name with the value in the request body. Requires the edit permission on the respective analysis profile.
	 */
	updateAnalysisProfile(this: void, queryParams: UpdateAnalysisProfileQueryParams, body: AnalysisProfile): QueryOperation<void>

	/**
	 * Update dashboard template
	 *
	 * Updates the given dashboard template The API requires the user to have Edit permissions on the Dashboard Template templateId.
     * @param templateId ID of the template to update
	 */
	updateDashboardTemplate(this: void, templateId: string, body: DashboardTemplateDescriptor): QueryOperation<void>

	/**
	 * Update external accounts
	 *
	 * Updates the external accounts identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the External Account externalCredentialsName.
     * @param externalCredentialsName Name of the old external accounts.
	 */
	updateExternalAccounts(this: void, externalCredentialsName: string, queryParams: UpdateExternalAccountsQueryParams, body: ExternalCredentialsData): QueryOperation<void>

	/**
	 * Update external analysis group
	 *
	 * Updates the external analysis group identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
     * @param groupName Name of the external analysis group to remove
	 */
	updateExternalAnalysisGroup(this: void, groupName: string, body: ExternalAnalysisGroup): QueryOperation<void>

	/**
	 * Update external finding description
	 *
	 * Update the external finding description identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
     * @param externalFindingDescriptionTypeId ID of the finding description to update
	 */
	updateExternalFindingDescription(this: void, externalFindingDescriptionTypeId: string, body: ExternalFindingsDescription): QueryOperation<void>

	/**
	 * Update metrics
	 *
	 * Updates a set of external metrics The API requires the user to have Edit External Metrics Schema permissions.
	 */
	updateExternalMetrics(this: void, body: Array<MetricSchemaChangeEntry>): QueryOperation<void>

	/**
	 * Update external storage backends
	 *
	 * Updates the external storage backend identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the External Storage Backend externalStorageBackendName.
     * @param externalStorageBackendName Name of the external storage backend which should be updated.
	 */
	updateExternalStorageBackend(this: void, externalStorageBackendName: string, body: ExternalStorageBackendData): QueryOperation<void>

	/**
	 * Updates the minimization options for the given job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
     * @param project The project ID.
     * @param jobId 
	 */
	updateJobOptions(this: void, project: string, jobId: string, queryParams: UpdateJobOptionsQueryParams): QueryOperation<void>

	/**
	 * Update metric threshold configuration
	 *
	 * Updates the metric threshold configuration identified by the given name with the value in the request body. Requires the edit permission for the respective metric threshold configuration.
	 */
	updateMetricThresholdConfiguration(this: void, body: MetricThresholdConfiguration): QueryOperation<void>

	/**
	 * Update password
	 *
	 * Updates the user password The API requires the user to have Edit permissions on the User username.
     * @param username The ID of the user the user needs access to.
	 */
	updatePassword(this: void, username: string, body: PasswordChangeRequest): QueryOperation<void>

	/**
	 * Update profiler configuration
	 *
	 * Updates an existing profiler configuration. The API requires the user to have Edit permissions on the Profiler Configuration configurationId.
     * @param configurationId The ID of the dashboard to update.
	 */
	updateProfilerConfiguration(this: void, configurationId: string, body: ProfilerConfiguration): QueryOperation<void>

	/**
	 * Updates quality report
	 *
	 * Updates a quality report's metadata such as title and global settings like default project and threshold profile. The API requires the user to have Edit permissions on the Quality Report id.
     * @param id The ID of the report that gets an update.
	 */
	updateQualityReport(this: void, id: string, body: QualityArtifactParameters): QueryOperation<void>

	/**
	 * Update report slide
	 *
	 * Updates an existing slide in a quality report. The API requires the user to have Edit permissions on the Quality Report id.
     * @param id The ID of the report that is updated.
	 */
	updateQualityReportSlides(this: void, id: string, body: Array<ReportSlideData>): QueryOperation<void>

	/**
	 * Update retrospective
	 *
	 * Updates an existing retrospective. The API requires the user to have View Project, Edit Retrospectives permissions on the project.
     * @param project The project ID.
     * @param id The id of the retrospective that gets an update.
	 */
	updateRetrospective(this: void, project: string, id: string, body: QualityArtifactParameters): QueryOperation<void>

	/**
	 * Update retrospective
	 *
	 * Updates an existing retrospective. The API requires the user to have View Project, Edit Retrospectives permissions on the project.
     * @param project The project ID.
     * @param id The id of the retrospective that gets an update.
	 */
	updateRetrospectiveNotes(this: void, project: string, id: string, body: string): QueryOperation<void>

	/**
	 * Update task
	 *
	 * Updates the task with the given ID. The API requires the user to have Edit Tasks permissions on the project.
     * @param project The project ID.
     * @param id Task id
	 */
	updateTask(this: void, project: string, id: number, queryParams: UpdateTaskQueryParams, body: Task): QueryOperation<Task>

	/**
	 * Update task resolution
	 *
	 * Updates the resolution of the task with the given ID. The API requires the user to have Update Task Resolution permissions on the project.
     * @param project The project ID.
     * @param id Task id
	 */
	updateTaskResolution(this: void, project: string, id: number, body: string): QueryOperation<Task>

	/**
	 * Update task status
	 *
	 * Updates the status of the task with the given ID. The API requires the user to have Update Task Status permissions on the project.
     * @param project The project ID.
     * @param id Task id
	 */
	updateTaskStatus(this: void, project: string, id: number, body: string): QueryOperation<Task>

	/**
	 * Update user group
	 *
	 * Updates the user group identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the Group oldUserGroupName.
     * @param oldUserGroupName Old name of the user group to change
	 */
	updateUserGroup(this: void, oldUserGroupName: string, body: UserGroup): QueryOperation<void>

	/**
	 * Upload architecture
	 *
	 * Imports an architecture file. This service is public API since Teamscale 5.9. The API requires the user to have Edit Architectures permissions on the project.
     * @param project The project ID.
	 */
	uploadArchitecture(this: void, project: string, queryParams: UploadArchitectureQueryParams, body: Record<string, File>): QueryOperation<void>

	/**
	 * Upload architecture
	 *
	 * Saves an architecture definition. The API requires the user to have Edit Architectures permissions on the project.
     * @param project The project ID.
	 */
	uploadArchitectureAsJson(this: void, project: string, queryParams: UploadArchitectureAsJsonQueryParams, body: ArchitectureInfoWithUniformPath): QueryOperation<void>

	/**
	 * Upload snapshot
	 *
	 * Uploads an external snapshot for comparison The API requires the user to have Access Administrative Services permissions.
	 */
	uploadExternalSnapshot(this: void, body: UploadExternalSnapshotBody): QueryOperation<void>

	/**
	 * Upload external report(s)
	 *
	 * Adds external reports to the session. For performance reasons, it is recommended to batch calls to this service, i.e. not commit all files using single calls. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
     * @param project The project ID.
     * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	uploadReport(this: void, project: string, sessionId: string, queryParams: UploadReportQueryParams, body: UploadReportBody): QueryOperation<void>

	/**
	 * Stores the passed image and returns a unique key to reference it in future scenarios
	 *
	 * null The API requires the user to have Edit permissions on the Quality Report reportId.
     * @param reportId The ID of the quality report the user needs access to.
	 */
	uploadSlideImage(this: void, reportId: string, body: UploadSlideImageBody): QueryOperation<string>

	/**
	 * Validates a branching configuration
	 *
	 * Validates a given branching configuration. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	validateBranchingConfiguration(this: void, body: ProjectBranchingConfiguration): QueryOperation<string | null>

	/**
	 * Verifies that there is a PowerShellScriptAnalyzer installed on the server, has the required  version, and can be called by Teamscale.
	 *
	 * Verifies the PowerShellScriptAnalyzer installation on the server. The API requires the user to have Create Analysis Profiles permissions.
	 */
	verifyPowershellScriptAnalyzerSetup(this: void): QueryOperation<string>

}

/** Type definition of the MUTATIONS interface. */
export type Mutations = {

	/**
	 * Add comment to task
	 *
	 * Adds a comment to the task specified by ID. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param task ID of the task to add comment to
	 */
	addCommentToTask: MutationOperation<{ pathParams: { project: string, task: number }, body: string }, void>

	/**
	 * Add metrics
	 *
	 * Adds a set of external metrics to the schema. This service is public API since Teamscale 5.8. The API requires the user to have Edit External Metrics Schema permissions.
	 */
	addExternalMetrics: MutationOperation<{ body: Array<MetricSchemaChangeEntry> }, void>

	/**
	 * Add report slide
	 *
	 * Adds slide(s) to an existing quality report. The API requires the user to have Edit permissions on the Quality Report reportId.
	 * @param reportId The ID of the report that is added.
	 */
	addQualityReportSlides: MutationOperation<{ pathParams: { reportId: string }, body: Record<string, ReportSlideData> }, void>

	/**
	 * Get autocompletion suggestions
	 *
	 * Gets autocompletion suggestions based on the input The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	autocompleteIssueId: MutationOperation<{ pathParams: { project: string }, queryParams: AutocompleteIssueIdQueryParams }, Array<string>>

	/**
	 * Autocomplete user group name
	 *
	 * null The API requires no permissions
	 */
	autocompleteUserGroup: MutationOperation<{ queryParams: AutocompleteUserGroupQueryParams }, Array<string>>

	/**
	 * Get user autocompletion suggestions
	 *
	 * Gets autocompletion suggestions based on the input The API requires no permissions
	 */
	autocompleteUserName: MutationOperation<{ queryParams: AutocompleteUserNameQueryParams }, Array<string>>

	/**
	 * Returns user activity statistics.
	 *
	 * Returns the number of users who have been active during different time durations. The API requires the user to have View System Status permissions.
	 */
	calculateActiveUsersStatistics: MutationOperation<void, Array<UserActivity>>

	/**
	 * Returns committer activity statistics.
	 *
	 * Returns the number of committers who have been contributing during different time durations. The API requires the user to have Access Administrative Services permissions.
	 */
	calculateCommitterStatistics: MutationOperation<void, Array<UserActivity>>

	/**
	 * Cancel the provided trigger
	 *
	 * Cancels the provided trigger. There are two steps of canceling a trigger. The first is via a flag, the trigger has to look up and may cancel gracefully. The second is via interrupt, where the trigger may be canceled at any operation, which may lead to inconsistent data. The API requires the user to have Access Administrative Services permissions.
	 * @param workerId 
	 */
	cancelTrigger: MutationOperation<{ pathParams: { workerId: string }, body: CancelTriggerRequestBody }, void>

	/**
	 * Clear analysis results cached on this Execution Server for a given project
	 *
	 * The execution server caches analysis results and serves them if the same analysis request comes again. This DELETE request clears that cache of analysis results. The API requires the user to have Access Administrative Services permissions.
	 */
	clearResultsCache: MutationOperation<void, void>

	/**
	 * Commit session
	 *
	 * Commits and closes the given session. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	commitAnalysisResults: MutationOperation<{ pathParams: { project: string, sessionId: string } }, void>

	/**
	 * Get metric table
	 *
	 * Retrieves a list of metric table entries for the given uniform path and its children. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	computeMetricTable: MutationOperation<{ pathParams: { project: string }, queryParams: ComputeMetricTableQueryParams }, MetricTablePage>

	/**
	 * Create analysis profile
	 *
	 * Creates a new analysis profile. The API requires the user to have Create Analysis Profiles permissions.
	 */
	createAnalysisProfile: MutationOperation<{ body: AnalysisProfile }, void>

	/**
	 * Export backup.
	 *
	 * Triggers the creation of a backup and returns its ID. This service is public API since Teamscale 6.1. The user needs to have the permission to export global data provided the backup contains any and the permission to backup project data for all projects contained in the backup.
	 */
	createBackup: MutationOperation<{ body: CreateBackupBody }, string>

	/**
	 * Trigger comparison computation
	 *
	 * Triggers computation of the comparison between two snapshots and returns the ID of the new comparison. One of the snapshots is stored locally, the other fetched from a remote instance. The API requires the user to have Access Administrative Services permissions.
	 * @param snapshotId The ID of the local snapshot.
	 */
	createComparison: MutationOperation<{ pathParams: { snapshotId: string }, body: CreateComparisonBody }, string>

	/**
	 * Create dashboard
	 *
	 * Creates a new dashboard. Every logged in user is allowed to create dashboards
	 */
	createDashboard: MutationOperation<{ body: DashboardDescriptor }, string>

	/**
	 * Create dashboard from template
	 *
	 * Creates a dashboard from a template. Returns the ID of the created dashboard. Every logged in user is allowed to create dashboards
	 */
	createDashboardFromTemplate: MutationOperation<{ queryParams: CreateDashboardFromTemplateQueryParams }, string>

	/**
	 * Create dashboard template
	 *
	 * Creates a new dashboard template The API requires the user to have Create Dashboard Templates permissions.
	 */
	createDashboardTemplate: MutationOperation<{ body: DashboardTemplateDescriptor }, string>

	/**
	 * Create external accounts
	 *
	 * Creates new external accounts. The API requires the user to have Create External Accounts permissions.
	 */
	createExternalAccounts: MutationOperation<{ body: ExternalCredentialsData }, void>

	/**
	 * Create external analysis group
	 *
	 * Creates a new external analysis group. This service is public API since Teamscale 6.0. The API requires the user to have Edit External Findings Schema permissions.
	 */
	createExternalAnalysisGroup: MutationOperation<{ body: ExternalAnalysisGroup }, void>

	/**
	 * Create external finding description
	 *
	 * Create a new external finding description in the system. This service is public API since Teamscale 6.0. The API requires the user to have Edit External Findings Schema permissions.
	 */
	createExternalFindingDescription: MutationOperation<{ body: ExternalFindingsDescription }, void>

	/**
	 * Create external storage backend
	 *
	 * Creates new external storage backend. The API requires the user to have Configure External Storage Backends permissions.
	 */
	createExternalStorageBackend: MutationOperation<{ body: ExternalStorageBackendData }, void>

	/**
	 * Create metric threshold configuration
	 *
	 * Creates a new metric threshold configuration. The API requires the user to have Create Metric Threshold Configurations permissions.
	 */
	createMetricThresholdConfiguration: MutationOperation<{ body: MetricThresholdConfiguration }, void>

	/**
	 * Generate Access Key
	 *
	 * Generates a new access key. The API requires the user to have Edit permissions on the user.
	 * @param user 
	 */
	createNewRandomAccessKey: MutationOperation<{ pathParams: { user: string } }, string>

	/**
	 * Update baseline
	 *
	 * Creates a new or updates an existing baseline. This service is public API since Teamscale 5.2. The API requires the user to have Edit Baselines permissions on the project.
	 * @param project The project ID.
	 * @param baseline 
	 */
	createOrUpdateBaseline: MutationOperation<{ pathParams: { project: string, baseline: string }, queryParams: CreateOrUpdateBaselineQueryParams, body: BaselineInfo }, void>

	/**
	 * Create profiler configuration
	 *
	 * Creates a new profiler configuration. The API requires the user to have Create Profiler Configurations permissions.
	 */
	createProfilerConfiguration: MutationOperation<{ body: ProfilerConfiguration }, void>

	/**
	 * Create project
	 *
	 * Creates project based on provided project configuration. This service is public API since Teamscale 7.1. The API requires the user to have Create Projects permissions.
	 */
	createProject: MutationOperation<{ queryParams: CreateProjectQueryParams, body: ProjectConfiguration }, void>

	/**
	 * Create quality report
	 *
	 * Creates a new quality report either from scratch or by copying an existing report. The API requires the user to have Create Quality Reports permissions.
	 */
	createQualityReport: MutationOperation<{ queryParams: CreateQualityReportQueryParams, body: QualityArtifactParameters }, string>

	/**
	 * Create quality retrospective
	 *
	 * Creates a new quality retrospective either from scratch or by copying an existing retrospective. The API requires the user to have View Project, Edit Retrospectives permissions on the project.
	 * @param project The project ID.
	 */
	createRetrospective: MutationOperation<{ pathParams: { project: string }, queryParams: CreateRetrospectiveQueryParams, body: QualityArtifactParameters }, string>

	/**
	 * Get session ID
	 *
	 * Obtains a new session ID. Using session ID allows to perform external uploads in multiple calls that all belong to the same session. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 */
	createSession: MutationOperation<{ pathParams: { project: string }, queryParams: CreateSessionQueryParams }, string>

	/**
	 * Create snapshot
	 *
	 * Triggers creation of a snapshot of this instance and returns the ID of the created snapshot. Additionally, a remote snapshot with the same parameters can be created and a comparison between the two scheduled. The API requires the user to have Access Administrative Services permissions.
	 */
	createSnapshot: MutationOperation<{ body: CreateSnapshotBody }, string>

	/**
	 * Create support request
	 *
	 * Creates the support request. The API requires the user to have Create Support Request permissions.
	 */
	createSupportRequest: MutationOperation<{ body: SupportRequestData }, void>

	/**
	 * Create task
	 *
	 * Creates a new task. The API requires the user to have Edit Tasks permissions on the project.
	 * @param project The project ID.
	 */
	createTask: MutationOperation<{ pathParams: { project: string }, body: Task }, Task>

	/**
	 * Create user group
	 *
	 * Creates new user group. The API requires the user to have Create Groups permissions.
	 */
	createUserGroup: MutationOperation<{ body: UserGroup }, void>

	/**
	 * Delete all unprocessed architecture uploads
	 *
	 * Deletes all commits of the architecture identified by the provided architecture path. The API requires the user to have Edit Architectures permissions on the project.
	 * @param project 
	 */
	deleteAllUnprocessedArchitectureCommits: MutationOperation<{ pathParams: { project: string }, queryParams: DeleteAllUnprocessedArchitectureCommitsQueryParams }, void>

	/**
	 * Delete analysis profile
	 *
	 * Deletes the analysis profile identified by the given name from the system The API requires the user to have Delete permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName Name of the analysis profile to delete
	 */
	deleteAnalysisProfile: MutationOperation<{ pathParams: { analysisProfileName: string } }, void>

	/**
	 * Delete session
	 *
	 * Deletes a session in case of abortion. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	deleteAnalysisResults: MutationOperation<{ pathParams: { project: string, sessionId: string } }, void>

	/**
	 * Delete baseline
	 *
	 * Deletes a baseline. This service is public API since Teamscale 5.2. The API requires the user to have Edit Baselines permissions on the project.
	 * @param project The project ID.
	 * @param baseline 
	 */
	deleteBaseline: MutationOperation<{ pathParams: { project: string, baseline: string } }, void>

	/**
	 * Delete comparison
	 *
	 * Permanently deletes the given comparison. The API requires the user to have Access Administrative Services permissions.
	 * @param snapshotId The ID of the local snapshot the comparison belongs to.
	 * @param comparisonId The ID of the comparison to delete.
	 */
	deleteComparison: MutationOperation<{ pathParams: { snapshotId: string, comparisonId: string } }, void>

	/**
	 * Remove dashboard
	 *
	 * Removes the dashboard descriptor identified by given ID. The API requires the user to have Edit permissions on the Dashboard dashboardId.
	 * @param dashboardId ID of the dashboard to retrieve.
	 */
	deleteDashboard: MutationOperation<{ pathParams: { dashboardId: string } }, void>

	/**
	 * Delete dashboard template
	 *
	 * Deletes the dashboard template identified by the given ID. The API requires the user to have Edit permissions on the Dashboard Template templateId.
	 * @param templateId ID of the template to delete
	 */
	deleteDashboardTemplate: MutationOperation<{ pathParams: { templateId: string } }, void>

	/**
	 * Delete event announcement
	 *
	 * Deletes the given announcement so it will not be displayed to anyone anymore. The API requires the user to have Access Administrative Services permissions.
	 * @param eventId 
	 */
	deleteEventAnnouncement: MutationOperation<{ pathParams: { eventId: string } }, void>

	/**
	 * Delete external accounts
	 *
	 * Deletes the external accounts identified by the given name from the system The API requires the user to have Delete permissions on the External Account externalCredentialsName.
	 * @param externalCredentialsName Name of the external accounts to delete
	 */
	deleteExternalAccounts: MutationOperation<{ pathParams: { externalCredentialsName: string }, queryParams: DeleteExternalAccountsQueryParams }, void>

	/**
	 * Remove external analysis group
	 *
	 * Removes the external analysis group identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
	 * @param groupName Name of the external analysis group to remove
	 */
	deleteExternalAnalysisGroup: MutationOperation<{ pathParams: { groupName: string } }, void>

	/**
	 * Remove external finding description
	 *
	 * Removes the external finding description identified by the given name The API requires the user to have Edit External Findings Schema permissions.
	 * @param externalFindingDescriptionName Name of the finding description to remove
	 */
	deleteExternalFindingDescription: MutationOperation<{ pathParams: { externalFindingDescriptionName: string } }, void>

	/**
	 * Delete metric
	 *
	 * Deletes an external metric. The API requires the user to have Edit External Metrics Schema permissions.
	 * @param metricName Metric to delete
	 */
	deleteExternalMetric: MutationOperation<{ pathParams: { metricName: string } }, void>

	/**
	 * Delete external snapshots
	 *
	 * Deletes the external snapshot with the provided name The API requires the user to have Access Administrative Services permissions.
	 * @param name The name of the external snapshot to be deleted
	 */
	deleteExternalSnapshot: MutationOperation<{ pathParams: { name: string } }, void>

	/**
	 * Delete external storage backends
	 *
	 * Deletes the external storage backends identified by the given name from the system The API requires the user to have Delete permissions on the External Storage Backend externalStorageBackendName.
	 * @param externalStorageBackendName Name of the external storage backend to delete
	 */
	deleteExternalStorageBackend: MutationOperation<{ pathParams: { externalStorageBackendName: string }, queryParams: DeleteExternalStorageBackendQueryParams }, void>

	/**
	 * Delete critical event logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalCriticalEventLogs: MutationOperation<{ queryParams: DeleteGlobalCriticalEventLogsQueryParams }, void>

	/**
	 * Delete interaction logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalInteractionLogs: MutationOperation<{ queryParams: DeleteGlobalInteractionLogsQueryParams }, void>

	/**
	 * Delete JS error logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalJavascriptErrorLogs: MutationOperation<{ queryParams: DeleteGlobalJavascriptErrorLogsQueryParams }, void>

	/**
	 * Delete global role
	 *
	 * Removes an existing global role. The API requires the user to have Edit Roles permissions.
	 * @param name The name of the role to delete.
	 */
	deleteGlobalRole: MutationOperation<{ pathParams: { name: string } }, void>

	/**
	 * Delete service logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalServiceLogs: MutationOperation<{ queryParams: DeleteGlobalServiceLogsQueryParams }, void>

	/**
	 * Delete worker logs
	 *
	 * Deletes all log entries that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 */
	deleteGlobalWorkerLogs: MutationOperation<{ queryParams: DeleteGlobalWorkerLogsQueryParams }, void>

	/**
	 * Remove the given list of tests (identified by their current ranking) from the list of tests to minimize in the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	deleteJobTests: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: DeleteJobTestsQueryParams, body: Array<number> }, number>

	/**
	 * Delete metric threshold configuration
	 *
	 * Deletes the metric threshold configuration identified by the given name from the system The API requires the user to have Delete permissions on the Metric Threshold Configuration metricThresholdConfigurationName.
	 * @param metricThresholdConfigurationName Name of the metric threshold configuration to delete
	 */
	deleteMetricThresholdConfiguration: MutationOperation<{ pathParams: { metricThresholdConfigurationName: string } }, void>

	/**
	 * Remove profiler configuration
	 *
	 * Removes the profiler configuration identified by given ID. The API requires the user to have Delete permissions on the Profiler Configuration configurationId.
	 * @param configurationId ID of the dashboard to retrieve.
	 */
	deleteProfilerConfiguration: MutationOperation<{ pathParams: { configurationId: string } }, void>

	/**
	 * Delete critical event logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectCriticalEventLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DeleteProjectCriticalEventLogsQueryParams }, void>

	/**
	 * Delete interaction logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectInteractionLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DeleteProjectInteractionLogsQueryParams }, void>

	/**
	 * Delete JS error logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectJavascriptErrorLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DeleteProjectJavascriptErrorLogsQueryParams }, void>

	/**
	 * Delete option
	 *
	 * Delete the project option identified by the given name. The API requires the user to have Edit Project Options permissions on the project.
	 * @param project The project ID.
	 * @param optionId Name of the option to delete
	 */
	deleteProjectOption: MutationOperation<{ pathParams: { project: string, optionId: string } }, void>

	/**
	 * Delete project role
	 *
	 * Removes an existing project role. The API requires the user to have Edit Roles permissions.
	 * @param name The name of the role to delete.
	 */
	deleteProjectRole: MutationOperation<{ pathParams: { name: string } }, void>

	/**
	 * Delete service logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectServiceLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DeleteProjectServiceLogsQueryParams }, void>

	/**
	 * Delete worker logs
	 *
	 * Deletes all log entries in the specified project that match the applied filters. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	deleteProjectWorkerLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DeleteProjectWorkerLogsQueryParams }, void>

	/**
	 * Delete report
	 *
	 * Deletes the report with the given id. The API requires the user to have Delete permissions on the Quality Report id.
	 * @param id The report id
	 */
	deleteQualityReport: MutationOperation<{ pathParams: { id: string } }, void>

	/**
	 * Put quality retrospective
	 *
	 * Updates/Renames an existing quality retrospective. The API requires the user to have View Project, Edit Retrospectives permissions on the project.
	 * @param project The project ID.
	 * @param id The id of the retrospective that should be deleted.
	 */
	deleteRetrospective: MutationOperation<{ pathParams: { project: string, id: string } }, void>

	/**
	 * Delete option
	 *
	 * Deletes the specified server option The API requires the user to have Edit Server Options permissions.
	 * @param optionId Name of the option to delete
	 */
	deleteServerOption: MutationOperation<{ pathParams: { optionId: string } }, void>

	/**
	 * Delete snapshot
	 *
	 * Permanently deletes the given snapshot and all associated comparisons. The API requires the user to have Access Administrative Services permissions.
	 * @param id The ID of the snapshot to delete.
	 */
	deleteSnapshot: MutationOperation<{ pathParams: { id: string } }, void>

	/**
	 * Delete test list
	 *
	 * Deletes a test list descriptor from the system. The API requires the user to have Edit Test Queries permissions on the project.
	 * @param project The project ID.
	 * @param testListName Name of the test list to delete
	 */
	deleteTestListDescriptor: MutationOperation<{ pathParams: { project: string, testListName: string } }, void>

	/**
	 * Delete user group
	 *
	 * Deletes the user group identified by the given name from the system The API requires the user to have Delete permissions on the Group userGroupName.
	 * @param userGroupName Name of the user group to delete
	 */
	deleteUserGroup: MutationOperation<{ pathParams: { userGroupName: string } }, void>

	/**
	 * Delete option
	 *
	 * Deletes the user option identified by the given name The API requires the user to have Edit permissions on the user.
	 * @param user 
	 * @param optionId 
	 */
	deleteUserOption: MutationOperation<{ pathParams: { user: string, optionId: string } }, void>

	/**
	 * Get analysis profile documentation
	 *
	 * Returns documentation of an analysis profile. The documentation is restricted to findings which are enabled in the profile and includes descriptions of the findings. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	downloadAnalysisProfileDocumentation: MutationOperation<{ pathParams: { analysisProfileName: string } }, void>

	/**
	 * Download backup
	 *
	 * Allows downloading a Teamscale backup from the temporary file store. This service is public API since Teamscale 6.1. The user needs to have the permission to backup global data provided it is contained in the backup and the permission to backup project data for all projects contained in the backup.
	 * @param backupId The backup ID.
	 */
	downloadBackup: MutationOperation<{ pathParams: { backupId: string } }, File>

	/**
	 * Download critical event logs
	 *
	 * Returns a file download of all critical event logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalCriticalEventLogs: MutationOperation<{ queryParams: DownloadGlobalCriticalEventLogsQueryParams }, void>

	/**
	 * Download interaction logs
	 *
	 * Returns a file download of all interaction logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalInteractionLogs: MutationOperation<{ queryParams: DownloadGlobalInteractionLogsQueryParams }, void>

	/**
	 * Download JS error logs
	 *
	 * Returns a file download of the JavaScript error logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalJavascriptErrorLogs: MutationOperation<{ queryParams: DownloadGlobalJavascriptErrorLogsQueryParams }, void>

	/**
	 * Download service logs
	 *
	 * Returns a file download of all service logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalServiceLogs: MutationOperation<{ queryParams: DownloadGlobalServiceLogsQueryParams }, void>

	/**
	 * Download worker logs
	 *
	 * Returns a file download of all worker logs. The API requires the user to have View System Status permissions.
	 */
	downloadGlobalWorkerLogs: MutationOperation<{ queryParams: DownloadGlobalWorkerLogsQueryParams }, void>

	/**
	 * Download heap dump
	 *
	 * Downloads a heap dump in HProf format. The API requires a HotSpot-based JVM. The API requires the user to have Access Administrative Services permissions.
	 */
	downloadHeapDump: MutationOperation<{ body: DownloadHeapDumpBody }, void>

	/**
	 * Download critical event logs
	 *
	 * Returns a file download of all critical event logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectCriticalEventLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DownloadProjectCriticalEventLogsQueryParams }, void>

	/**
	 * Download interaction logs
	 *
	 * Returns a file download of all interaction logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectInteractionLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DownloadProjectInteractionLogsQueryParams }, void>

	/**
	 * Download JS error logs
	 *
	 * Returns a file download of the JavaScript error logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectJavascriptErrorLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DownloadProjectJavascriptErrorLogsQueryParams }, void>

	/**
	 * Download service logs
	 *
	 * Returns a file download of all service logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectServiceLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DownloadProjectServiceLogsQueryParams }, void>

	/**
	 * Download worker logs
	 *
	 * Returns a file download of all worker logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	downloadProjectWorkerLogs: MutationOperation<{ pathParams: { project: string }, queryParams: DownloadProjectWorkerLogsQueryParams }, void>

	/**
	 * Download support request
	 *
	 * Downloads the support request previously created with POST. The API requires the user to have Create Support Request permissions.
	 */
	downloadSupportRequest: MutationOperation<void, File>

	/**
	 * Returns the (possibly ranked) list of tests that have been selected for this job as CSV.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	downloadTestSelectionCsv: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: DownloadTestSelectionCsvQueryParams }, File>

	/**
	 * Update dashboard
	 *
	 * Updates an existing dashboard. The API requires the user to have Edit permissions on the Dashboard dashboardId.
	 * @param dashboardId The ID of the dashboard to update.
	 */
	editDashboard: MutationOperation<{ pathParams: { dashboardId: string }, body: DashboardDescriptor }, void>

	/**
	 * Edit option
	 *
	 * Edits the project option identified by the given name. The API requires the user to have Edit Project Options permissions on the project.
	 * @param project The project ID.
	 * @param optionId Name of the option to edit
	 */
	editProjectOption: MutationOperation<{ pathParams: { project: string, optionId: string }, body: Array<string> }, void>

	/**
	 * Edit project
	 *
	 * Edits project based on provided project configuration. This service is public API since Teamscale 7.1. The API requires the user to have Edit Project permissions on the project.
	 * @param project The project ID.
	 */
	editProjectWithConfiguration: MutationOperation<{ pathParams: { project: string }, queryParams: EditProjectWithConfigurationQueryParams, body: ProjectConfiguration }, boolean>

	/**
	 * Edit options
	 *
	 * Edits the specified server option The API requires the user to have Edit Server Options permissions.
	 * @param optionId Name of the option to edit
	 */
	editServerOption: MutationOperation<{ pathParams: { optionId: string }, queryParams: EditServerOptionQueryParams, body: Array<string> }, void>

	/**
	 * Edit option
	 *
	 * Edits the user option identified by the given name The API requires the user to have Edit permissions on the user.
	 * @param user Name of the user to edit the option for
	 * @param optionId Name of the option to edit
	 */
	editUserOption: MutationOperation<{ pathParams: { user: string, optionId: string }, body: object }, void>

	/**
	 * Edit users
	 *
	 * Performs given user operation. This service is public API since Teamscale 5.5.1. Needs delete permission.
	 */
	editUsers: MutationOperation<{ body: UserBatchOperation }, string>

	/**
	 * Execute scheduler command
	 *
	 * Allows to pass a command to the scheduler. The command is appended to the URL and should be one of the values from ESchedulerCommand. Note that using this service may harm the stability and data integrity of your instance. The API requires the user to have Access Administrative Services permissions.
	 */
	executeSchedulerCommand: MutationOperation<{ body: ExecuteSchedulerCommandBody }, void>

	/**
	 * Exports the analysis profile
	 *
	 * Retrieves the analysis profile identified by given name. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	exportAnalysisProfile: MutationOperation<{ pathParams: { analysisProfileName: string } }, void>

	/**
	 * Get a dashboard file
	 *
	 * Performs an export of a dashboard. Returns the descriptor of the dashboard as a .tsdashboard file. The dashboard must be submitted within an XML Teamscale Version Container. The API requires the user to have View permissions on the Dashboard dashboardId.
	 * @param dashboardId The ID of the dashboard
	 */
	exportDashboard: MutationOperation<{ pathParams: { dashboardId: string } }, TeamscaleVersionContainer>

	/**
	 * Get a dashboard template
	 *
	 * Performs an export of a dashboard template. Returns the template as a .tstemplate file. The API requires no permissions
	 * @param templateId ID of the dashboard template
	 */
	exportDashboardTemplate: MutationOperation<{ pathParams: { templateId: string } }, TeamscaleVersionContainer>

	/**
	 * Export external findings
	 *
	 * Downloads external findings groups and descriptions as a zip archive The API requires the user to have Edit External Findings Schema permissions.
	 */
	exportExternalFindings: MutationOperation<{ queryParams: ExportExternalFindingsQueryParams }, File>

	/**
	 * Downloads external metrics
	 *
	 * Downloads external metrics as a ZIP file. The API requires the user to have Edit External Metrics Schema permissions.
	 */
	exportExternalMetrics: MutationOperation<void, void>

	/**
	 * Export impacted tests
	 *
	 * Returns an ordered list of test clusters that are impacted by the given changes. The returned list of tests is a subset of all tests known to Teamscale in CSV format. When the tests themselves have changed, new tests were added or tests have been deleted, this service cannot take that into account. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	exportImpactedTestsAsCsv: MutationOperation<{ pathParams: { project: string }, queryParams: ExportImpactedTestsAsCsvQueryParams }, void>

	/**
	 * Check if external accounts exist
	 *
	 * Checks if the external accounts identified by the given name exist. No permissions needed, as the service should check whether the credentials already exists for every user to prohibit overwriting credentials not visible for the current user
	 * @param externalCredentialsName Name of the external accounts to check
	 */
	externalAccountsExist: MutationOperation<{ pathParams: { externalCredentialsName: string } }, ExternalCredentialsData>

	/**
	 * Check if external storage backends exist
	 *
	 * Checks if the external storage backend identified by the given name exist. No permissions needed, as the service checks whether the backend already exists for every user to prohibit overwriting backends not visible for the current user
	 * @param externalStorageBackendName Name of the external storage backend to check
	 */
	externalStorageBackendExists: MutationOperation<{ pathParams: { externalStorageBackendName: string } }, ExternalStorageBackendData>

	/**
	 * Get job queue
	 *
	 * Retrieves the entire job queue from all projects. The API requires the user to have View System Status permissions.
	 */
	extractJobQueue: MutationOperation<void, Array<JobDescriptor>>

	/**
	 * Returns the initial commit which added the file at the given path. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param unresolvedCommit This parameter can be used to pass a timestamp giving the time (in milliseconds since 1970) for which the data should be provided. This can optionally be prefixed by the name of the branch, followed by a colon.
	 */
	findInitialCommit: MutationOperation<{ pathParams: { project: string, unresolvedCommit: UnresolvedCommitDescriptor }, queryParams: FindInitialCommitQueryParams }, CommitDescriptor | null>

	/**
	 * Get repository log entries
	 *
	 * Retrieves a specific count of repository log entries after a start timestamp or before an end timestamp. If both start and end timestamp are missing, the newest log entries are returned. If both a start and end timestamp are provided all log entries between the two are returned. The resulting log entries include all log entries from merged commits into the current branch. The current branch and branch state at a point in time are determined by the commit parameter. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	findLogEntriesInRange: MutationOperation<{ pathParams: { project: string }, queryParams: FindLogEntriesInRangeQueryParams }, Array<ExtendedRepositoryLogEntry>>

	/**
	 * Flags/unflags findings
	 *
	 * Flags/unflags the given findings with the given flagging type. This service is public API since Teamscale 8.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	flagFindings: MutationOperation<{ pathParams: { project: string }, queryParams: FlagFindingsQueryParams, body: FindingBlacklistRequestBody }, void>

	/**
	 * Get Absolute Date
	 *
	 * Transforms a relative date string into a fixed date string The API requires no permissions
	 */
	getAbsoluteDate: MutationOperation<{ queryParams: GetAbsoluteDateQueryParams }, string>

	/**
	 * List Access Keys Metadata
	 *
	 * List the metadata of all access keys of the user The API requires the user to have Edit permissions on the user.
	 * @param user 
	 */
	getAccessKeysMetadata: MutationOperation<{ pathParams: { user: string } }, Array<AccessKeyDTO>>

	/**
	 * Returns active users (anonymized) for the given primary project IDs
	 *
	 * For the given primary project IDs, returns all users (anonymized) of the last 3 months that contribute to the license volume. If an empty list is provided, all current projects will be included. The results might differ from the GET query in case the primary project id of a project changed in the last three months or if a user has logged into Teamscale but has not interacted with any project. The API requires the user to have Access Administrative Services permissions.
	 */
	getActiveUsersForProjects: MutationOperation<{ body: Array<string> }, Array<string>>

	/**
	 * Returns a list of sets of additionally covered methods. 
	 *
	 * The set on position i denotes that executing the test corresponding to position i covers additionally (relative to the methods covered by tests 0 to i-1) the methods described in the set. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	getAdditionallyCoveredMethods: MutationOperation<{ pathParams: { project: string, jobId: string } }, Array<Array<MethodLocation>>>

	/**
	 * Get affected files by commits
	 *
	 * Returns repository log file entries for commits, i.e. the association between files and repository log entries. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getAffectedFilesForCommits: MutationOperation<{ pathParams: { project: string }, queryParams: GetAffectedFilesForCommitsQueryParams }, Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Get aggregated trigger performance metrics
	 *
	 * Allows downloading the performance aggregate data report as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getAggregatedTriggerPerformanceMetrics: MutationOperation<void, void>

	/**
	 * Get all baselines
	 *
	 * Returns a list of all configured baselines for the project. This service is public API since Teamscale 5.2. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getAllBaselines: MutationOperation<{ pathParams: { project: string } }, Array<BaselineInfo>>

	/**
	 * Get all dashboard templates
	 *
	 * Retrieves all the dashboard templates available in the system. Any user may see dashboard templates.
	 */
	getAllDashboardTemplates: MutationOperation<void, Array<DashboardTemplateDescriptor>>

	/**
	 * Get all dashboard descriptors
	 *
	 * Retrieves all the dashboard descriptors available in the system No permissions needed, as the service will only return the dashboard descriptors visible to current user.
	 */
	getAllDashboards: MutationOperation<{ queryParams: GetAllDashboardsQueryParams }, Array<UserResolvedDashboardDescriptor>>

	/**
	 * Get all external accounts
	 *
	 * Retrieves all available external accounts in the system. No permissions needed, as the service will only return credentials visible to current user.
	 */
	getAllExternalAccounts: MutationOperation<void, Array<ExternalCredentialsData>>

	/**
	 * Get external analysis groups
	 *
	 * Retrieves all the available analysis groups in the system. The API requires the user to have Edit External Findings Schema permissions.
	 */
	getAllExternalAnalysisGroups: MutationOperation<void, Array<ExternalAnalysisGroup>>

	/**
	 * Get external snapshots
	 *
	 * Returns the list of all available externally uploaded snapshots The API requires the user to have Access Administrative Services permissions.
	 */
	getAllExternalSnapshots: MutationOperation<{ queryParams: GetAllExternalSnapshotsQueryParams }, Array<ExternalInstanceComparisonSnapshotDto>>

	/**
	 * Get all external storage backends
	 *
	 * Retrieves all available external storage backends in the system. No permissions needed, as the service will only return backends visible to current user.
	 */
	getAllExternalStorageBackends: MutationOperation<void, Array<ExternalStorageBackendData>>

	/**
	 * Get external finding descriptions
	 *
	 * Retrieves all external finding descriptions available in the system The API requires the user to have Edit External Findings Schema permissions.
	 */
	getAllFindingsDescriptions: MutationOperation<void, Array<ExternalFindingsDescription>>

	/**
	 * Get all git tags
	 *
	 * Collects all git tags from all git connections in the given project. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getAllGitTags: MutationOperation<{ pathParams: { project: string }, queryParams: GetAllGitTagsQueryParams }, Array<GitTag>>

	/**
	 * Get all metric threshold configuration names
	 *
	 * Retrieves the names of all available metric threshold configurations in the system. No permissions needed, as the service will only return the names of metric threshold configurations visible to the current user.
	 */
	getAllMetricThresholdConfigurationNames: MutationOperation<{ queryParams: GetAllMetricThresholdConfigurationNamesQueryParams }, Array<string>>

	/**
	 * Get all metric threshold configurations
	 *
	 * Retrieves all available metric threshold configurations in the system. No permissions needed, as the service will only return metric threshold configurations visible to the current user.
	 */
	getAllMetricThresholdConfigurations: MutationOperation<void, Array<MetricThresholdConfiguration>>

	/**
	 * Get all metrics for metric threshold configuration names
	 *
	 * Retrieves a list, the entries being the metric threshold configuration names and lists of the metrics included in the profile with that name. No permissions needed, as the service will only return the metrics and their profiles visible to the current user.
	 */
	getAllMetricsForThresholdProfiles: MutationOperation<{ queryParams: GetAllMetricsForThresholdProfilesQueryParams }, Array<MetricsForThresholdProfile>>

	/**
	 * Get parse log overview
	 *
	 * Returns overview information about the parse log entries in all visible projects The API requires the user to have View System Status permissions.
	 */
	getAllParseLogOverviewEntries: MutationOperation<void, Array<ParseLogOverviewEntry>>

	/**
	 * Get details (name, last updated) for all test execution partitions
	 *
	 * Returns all available test execution partitions and their last updated timestamp; of the project that have been uploaded and processed up to the given commit. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getAllPartitionDetails: MutationOperation<{ pathParams: { project: string }, queryParams: GetAllPartitionDetailsQueryParams }, Array<PartitionInfo>>

	/**
	 * Get all profiler configurations
	 *
	 * Retrieves all the defined profiler configurations No permissions needed, as the service will only return the profiler configurations visible to current user.
	 */
	getAllProfilerConfigurations: MutationOperation<void, Array<ProfilerConfiguration>>

	/**
	 * Get projects
	 *
	 * Returns a list of all projects. This service is public API since Teamscale 7.1. Only projects visible to the user are returned in the get all queries.
	 */
	getAllProjects: MutationOperation<{ queryParams: GetAllProjectsQueryParams }, Array<ProjectInfo>>

	/**
	 * Get snapshot IDs
	 *
	 * Returns the list of all available snapshot IDs.  The API requires the user to have Access Administrative Services permissions.
	 */
	getAllSnapshotIds: MutationOperation<{ queryParams: GetAllSnapshotIdsQueryParams }, Array<string>>

	/**
	 * Get all role assignments for all groups and users (and assigned groups).
	 *
	 * Returns a list of all subject role assignments. The API requires the user to have Assign Global Roles permissions.
	 */
	getAllSubjectRoleAssignments: MutationOperation<void, Array<Array<SubjectRoleAssignments>>>

	/**
	 * Dumps the stack traces for all currently running threads as HTML. The API requires the user to have Access Administrative Services permissions.
	 */
	getAllThreadsDumpAsHtml: MutationOperation<void, string>

	/**
	 * Get all user group names
	 *
	 * Retrieves the names of all available user groups in the system. No permissions needed, as the service will only return group names visible to current user.
	 */
	getAllUserGroupNames: MutationOperation<void, Array<string>>

	/**
	 * Get all user groups
	 *
	 * Retrieves all available user groups in the system. No permissions needed, as the service will only return groups visible to current user.
	 */
	getAllUserGroups: MutationOperation<void, Array<UserGroup>>

	/**
	 * Get all users
	 *
	 * Returns a list of all users. This service is public API since Teamscale 5.2. Only users visible to the user are returned in the get all queries.
	 */
	getAllUsers: MutationOperation<void, Array<UserWithActivity>>

	/**
	 * Analysis profile version difference
	 *
	 * Calculates and return the difference between an older edit version and a newer version of an analysis profile. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisEditHistoryVersionDiff: MutationOperation<{ pathParams: { analysisProfileName: string }, queryParams: GetAnalysisEditHistoryVersionDiffQueryParams }, AnalysisProfileHistoryVersionComparison>

	/**
	 * Get analysis profile
	 *
	 * Retrieves the analysis profile identified by given name. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisProfile: MutationOperation<{ pathParams: { analysisProfileName: string } }, AnalysisProfile>

	/**
	 * Analysis Profile Edit History
	 *
	 * Returns the different versions of an analysis profile containing a version corresponding to each time the analysis profile was edited. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisProfileEditVersionHistory: MutationOperation<{ pathParams: { analysisProfileName: string } }, Array<AnalysisProfileEditVersion>>

	/**
	 * Analysis Profile Edit History Creation Times
	 *
	 * Returns a list of timestamps, each corresponding to the creation of a new profile edit history entry. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisProfileEditVersionHistoryTimestamps: MutationOperation<{ pathParams: { analysisProfileName: string } }, Array<number>>

	/**
	 * Get analysis profile metadata
	 *
	 * Retrieves the analysis profile identified by given name and returns its description, languages and tools. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisProfileMetadata: MutationOperation<{ pathParams: { analysisProfileName: string } }, AnalysisProfileMetadata>

	/**
	 * Get all analysis profile names
	 *
	 * Retrieves the names of all analysis profiles visible to the current user. No permissions needed, as the service will only return names of analysis profiles visible to current user.
	 */
	getAnalysisProfileNames: MutationOperation<void, Array<string>>

	/**
	 * Get analysis profile usage
	 *
	 * Returns a list of AnalysisProfileUsageInfoWithProjects indicating the list of referenced projects for each analysis profile No permissions needed, as the service will only return names of analysis profiles visible to current user.
	 */
	getAnalysisProfileUsageInfo: MutationOperation<void, Array<AnalysisProfileUsageInfoWithProjects>>

	/**
	 * Get analysis profile with referencing projects
	 *
	 * Returns an analysis profile annotated with the list of projects using the profile The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName 
	 */
	getAnalysisProfileWithProjects: MutationOperation<{ pathParams: { analysisProfileName: string } }, AnalysisProfileWithProjects>

	/**
	 * Get ClangTidy analysis results
	 *
	 * Gets the ClangTidy analysis results. Results may be incomplete if the session is not in the completed status. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisResultsByPath: MutationOperation<{ queryParams: GetAnalysisResultsByPathQueryParams }, Array<ClangTidyResultsTransport>>

	/**
	 * Get SonarLint analysis results
	 *
	 * Gets the SonarLint analysis results. Results may be incomplete if the session is not in the completed status. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisResultsByPath1: MutationOperation<{ queryParams: GetAnalysisResultsByPath1QueryParams }, Record<string, Array<SonarLintFindingTransport>>>

	/**
	 * Get timings of analysis state changes
	 *
	 * Allows downloading timing of analysis state changes as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getAnalysisStateChangeTiming: MutationOperation<void, void>

	/**
	 * Get the status of a ClangTidy Analysis session
	 *
	 * Gets the status of the given session. May return the 'unknown' status, if no session was found for the given key. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisStatus: MutationOperation<{ queryParams: GetAnalysisStatusQueryParams }, EClangTidyOutsourcedAnalysisStatusEntry>

	/**
	 * Get the status of a SonarLint Analysis session
	 *
	 * Gets the status of the given session. May return the 'unknown' status, if no session was found for the given key. The API requires the user to have Create Projects permissions.
	 */
	getAnalysisStatus1: MutationOperation<{ queryParams: GetAnalysisStatus1QueryParams }, ESonarLintIndependentAnalysisStatusEntry>

	/**
	 * Get analysis tools
	 *
	 * Retrieves the available tools based on the languages provided. Teamscale itself is not returned as a tool. No specific permissions required to retrieve the available tools
	 */
	getAnalysisTools: MutationOperation<{ queryParams: GetAnalysisToolsQueryParams }, Array<EAnalysisToolEntry>>

	/**
	 * Get analysis tools
	 *
	 * Returns the available analysis tools for a specific project. Teamscale itself is not returned as a tool. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getAnalysisToolsForProject: MutationOperation<{ pathParams: { project: string }, queryParams: GetAnalysisToolsForProjectQueryParams }, Array<EAnalysisToolEntry>>

	/**
	 * Profile architecture pattern performance
	 *
	 * Measures the performance of every include/exclude pattern of the provided architecture, by matching it against all available types. Reports the 10 slowest patterns and their runtime. Can be used to detect slow patterns in the architecture analysis.  The API requires the user to have Edit Architectures permissions on the project.
	 * @param project The project ID.
	 * @param architecture 
	 */
	getArchitecturePerformanceStatistics: MutationOperation<{ pathParams: { project: string, architecture: string }, queryParams: GetArchitecturePerformanceStatisticsQueryParams }, string>

	/**
	 * Get associated comparison IDs
	 *
	 * Returns the IDs of the associated comparisons of a single snapshot identified by the given ID. The API requires the user to have Access Administrative Services permissions.
	 * @param id The ID of the snapshot.
	 */
	getAssociatedComparisonIds: MutationOperation<{ pathParams: { id: string } }, Array<string>>

	/**
	 * Get authenticator
	 *
	 * Retrieves authenticator information The API requires the user to have Create Users permissions.
	 */
	getAuthenticatorInformation: MutationOperation<void, AuthenticatorMappingReply>

	/**
	 * Get authenticator
	 *
	 * Retrieves authenticator information The API requires the user to have Edit permissions on the User username.
	 * @param username User to retrieve authentication info for
	 */
	getAuthenticatorInformationForUser: MutationOperation<{ pathParams: { username: string } }, AuthenticatorMappingReply>

	/**
	 * Get authentication servers
	 *
	 * List configured authentication servers. User must have the permission to edit or create groups or users to view authentication servers.
	 */
	getAvailableServers: MutationOperation<void, Record<string, Array<string>>>

	/**
	 * Get user avatar data
	 *
	 * Returns avatar data for the user in the first path parameter. The API requires the user to have View permissions on the user.
	 * @param user 
	 */
	getAvatarData: MutationOperation<{ pathParams: { user: string } }, AvatarData>

	/**
	 * Get the backup export status
	 *
	 * Get the current backup export status This service is public API since Teamscale 6.1. The user needs to be able to configure projects. In addition the user needs to have the permission to backup global data provided the backup contains any global data and the permission to backup project data for all projects contained in the backup.
	 * @param backupId The backup ID.
	 */
	getBackupExportStatus: MutationOperation<{ pathParams: { backupId: string } }, BackupExportStatus>

	/**
	 * Get the backup import status
	 *
	 * Get the current backup import status This service is public API since Teamscale 6.1. The user needs to be able to configure projects. In addition the user needs to have the permission to backup global data provided the backup contains any global data and the permission to backup project data for all projects contained in the backup.
	 * @param backupId The backup ID.
	 */
	getBackupImportStatus: MutationOperation<{ pathParams: { backupId: string } }, BackupImportStatus>

	/**
	 * Get available sample projects
	 *
	 * Returns a list of sample projects that can be used as starting point. The API requires the user to have Backup Global Data permissions.
	 */
	getBackupsForImportWizard: MutationOperation<void, Array<Backup>>

	/**
	 * Get baseline
	 *
	 * Returns details on a configured baseline. This service is public API since Teamscale 5.2. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param baseline 
	 */
	getBaseline: MutationOperation<{ pathParams: { project: string, baseline: string } }, BaselineInfo>

	/**
	 * Get all baselines
	 *
	 * Returns a list of all configured baselines for the given projects or for all visible projects if no project ID is given. No permissions needed, as the service checks if all listed projects are visible to the user.
	 */
	getBaselines: MutationOperation<{ queryParams: GetBaselinesQueryParams }, Record<string, Array<BaselineInfo>>>

	/**
	 * Get basic roles assigned to an instance
	 *
	 * Returns all basic roles with assignments. Requires the basic permission to \"Assign Roles\"
	 * @param instance The instance&#39;s id
	 */
	getBasicRoles: MutationOperation<{ pathParams: { instance: string }, queryParams: GetBasicRolesQueryParams }, RolesWithAssignments>

	/**
	 * Get benchmark projects
	 *
	 * Returns the projects with benchmark analysis profiles User must have audit support included in the license.
	 */
	getBenchmarkProjects: MutationOperation<void, Array<ProjectDescription>>

	/**
	 * Get a (partly anonymized) benchmark results for a specific metric
	 *
	 * Returns a CSV file with all benchmark results. Only projects visible to the user are returned.
	 */
	getBenchmarkResults: MutationOperation<{ queryParams: GetBenchmarkResultsQueryParams }, void>

	/**
	 * Get spec item branch
	 *
	 * Get the branch where the spec item with the provided id is analysed on The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id ID of the spec item to fetch the branch for
	 */
	getBranchForSpecItem: MutationOperation<{ pathParams: { project: string, id: string }, queryParams: GetBranchForSpecItemQueryParams }, string>

	/**
	 * Get branches
	 *
	 * Returns the specified range of sorted branches (or all if no range specified), including/excluding deleted/anonymous branches or the sorted filtered branches based on the defined branchesFilter/regexFilter as BranchesInfo. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getBranchesGetRequest: MutationOperation<{ pathParams: { project: string }, queryParams: GetBranchesGetRequestQueryParams }, BranchesInfo>

	/**
	 * Get branches
	 *
	 * Returns the specified range of sorted branches (or all if no range specified), including/excluding deleted/anonymous branches or the sorted filtered branches based on the defined branchesFilter/regexFilter as BranchesInfo. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getBranchesPostRequest: MutationOperation<{ pathParams: { project: string }, queryParams: GetBranchesPostRequestQueryParams }, BranchesInfo>

	/**
	 * Get change treemap
	 *
	 * Retrieves a treemap of change frequencies. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getChangeTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetChangeTreemapQueryParams }, TreeMapNode>

	/**
	 * Get a treemap that colors components or languages
	 *
	 * Either top-level components or the used languages can be colored. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCodeComponentsTreemapWrapper: MutationOperation<{ pathParams: { project: string }, queryParams: GetCodeComponentsTreemapWrapperQueryParams }, FilteredTreeMapWrapper>

	/**
	 * Get code outline
	 *
	 * Returns a code outline for a file, which summarizes the abstract syntax tree of the code The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getCodeOutline: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetCodeOutlineQueryParams }, Array<OutlineElement>>

	/**
	 * Get a mapping from spec items to code references and test executions
	 *
	 * Returns a spec item code and test result mapping for all spec items matching the given query. If the query is empty, all known spec items are considered. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCodeReferencesAndTestExecutionMapping: MutationOperation<{ pathParams: { project: string }, queryParams: GetCodeReferencesAndTestExecutionMappingQueryParams }, SpecItemReferenceMapping>

	/**
	 * List the code search match results and returns a treemap.
	 *
	 * Lists all code search matches result including path and line numbers for a given search term and a max result size. Additionally, return a treemap. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCodeSearchMatchResultAndTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetCodeSearchMatchResultAndTreemapQueryParams }, CodeSearchResultsWrapper>

	/**
	 * Get commit alerts
	 *
	 * Provides access to the alerts for the given commits. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCommitAlerts: MutationOperation<{ pathParams: { project: string }, queryParams: GetCommitAlertsQueryParams }, Array<CommitAlerts | null>>

	/**
	 * Get task commits
	 *
	 * Returns the timestamps of commits associated with a given task. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id ID of the requested task
	 */
	getCommitsForTask: MutationOperation<{ pathParams: { project: string, id: number } }, Array<CommitDescriptor>>

	/**
	 * Get comparison summary
	 *
	 * Returns the global comparison data, the projects and the contributors for the projects for a single comparison identified by the given ID. The API requires the user to have Access Administrative Services permissions.
	 * @param snapshotId The ID of the local snapshot the comparison belongs to.
	 * @param comparisonId The ID of the snapshot to get. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getComparison: MutationOperation<{ pathParams: { snapshotId: string, comparisonId: string } }, InstanceComparisonComputationMetadata>

	/**
	 * Get project comparison results
	 *
	 * Returns the comparison data for the specified comparison, project and contributor. The comparison can be identified by the given ID. The API requires the user to have Access Administrative Services permissions.
	 * @param snapshotId The ID of the local snapshot the comparison belongs to.
	 * @param comparisonId The ID of the comparison to get.
	 * @param project The name of the project.
	 */
	getComparisonResultsForContributor: MutationOperation<{ pathParams: { snapshotId: string, comparisonId: string, project: string }, queryParams: GetComparisonResultsForContributorQueryParams }, InstanceComparisonResultBase>

	/**
	 * Get complete example list
	 *
	 * Get the full list of missing local or remote detail values The API requires the user to have Access Administrative Services permissions.
	 * @param snapshotId The ID of the local snapshot the comparison belongs to.
	 * @param comparisonId The ID of the comparison to get.
	 * @param project The name of the project.
	 * @param contributor The contributor of the comparison.
	 * @param diffEntryName The name of the difference entry.
	 */
	getCompleteExampleList: MutationOperation<{ pathParams: { snapshotId: string, comparisonId: string, project: string, contributor: string, diffEntryName: string }, queryParams: GetCompleteExampleListQueryParams }, Array<DetailedInstanceComparisonValue>>

	/**
	 * Get a detailed concurrency analysis
	 *
	 * Allows downloading the detailed trigger performance analysis. The feature toggle -Dcom.teamscale.log_performance_details=true must be enabled. The API requires the user to have Access Administrative Services permissions.
	 */
	getConcurrencyPerformanceDetails: MutationOperation<{ queryParams: GetConcurrencyPerformanceDetailsQueryParams }, string>

	/**
	 * Get configuration languages
	 *
	 * Returns the languages available for analysis, sorted by their readable name. This is available to every authenticated user.
	 */
	getConfigLanguages: MutationOperation<void, Array<Language>>

	/**
	 * Get configuration languages
	 *
	 * Returns the languages available for analysis for a specific project, sorted by their readable name. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getConfigLanguagesForProject: MutationOperation<{ pathParams: { project: string } }, Array<Language>>

	/**
	 * Get configuration template
	 *
	 * Retrieves the configuration template for given languages. No specific permissions required to retrieve a configuration template.
	 */
	getConfigurationTemplate: MutationOperation<{ queryParams: GetConfigurationTemplateQueryParams }, ConfigurationTemplate>

	/**
	 * Get connector descriptors
	 *
	 * Retrieves connector descriptors available in the system. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getConnectorDescriptors: MutationOperation<void, Array<ConnectorDescriptorTransport>>

	/**
	 * Get copyright license treemap
	 *
	 * Builds a treemap of the copyright licenses in a system. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCopyrightLicensesTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetCopyrightLicensesTreemapQueryParams }, FilteredTreeMapWrapper>

	/**
	 * Get coverage sources
	 *
	 * Retrieves information about coverage sources (partitions and cross-annotation projects),  respecting the given path. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getCoverageSources: MutationOperation<{ pathParams: { project: string }, queryParams: GetCoverageSourcesQueryParams }, Array<CoverageSourceInfo>>

	/**
	 * Get scheduler filtering status
	 *
	 * Returns the current filtering status of the scheduler. The API requires no permissions
	 */
	getCurrentSchedulingFilter: MutationOperation<void, ProjectSchedulingFilter>

	/**
	 * Get dashboard
	 *
	 * Retrieves the dashboard descriptor identified by given ID. The API requires the user to have View permissions on the Dashboard dashboardId.
	 * @param dashboardId ID of the dashboard to retrieve.
	 */
	getDashboard: MutationOperation<{ pathParams: { dashboardId: string } }, UserResolvedDashboardDescriptor>

	/**
	 * Get dashboard template
	 *
	 * Retrieves the dashboard template identified by the given ID. Any user may see dashboard templates.
	 * @param templateId ID of the template to retrieve
	 */
	getDashboardTemplateDescriptor: MutationOperation<{ pathParams: { templateId: string } }, DashboardTemplateDescriptor>

	/**
	 * Get token element internals
	 *
	 * Returns a string representation of a token element, supporting various representations. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getDebugContent: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetDebugContentQueryParams }, string>

	/**
	 * Get the declaration of accessibility
	 *
	 * Returns the declaration of accessibility as found in the configuration directory (file name: 'declaration_of_accessibility.md'). To avoid unnecessary IO lookups, the declaration (or non-existent declaration) is locally cached until a reload is forced. The API requires no login
	 */
	getDeclarationOfAccessibility: MutationOperation<{ queryParams: GetDeclarationOfAccessibilityQueryParams }, string | null>

	/**
	 * null The API requires no login
	 */
	getDeclarationOfAccessibilityContext: MutationOperation<void, DeclarationOfAccessibilityContext>

	/**
	 * Get default patterns
	 *
	 * Returns the default include/exclude patterns for connectors as a map from analysis profile name to the corresponding default patterns based on the languages configured in the respective analysis profile The API requires no permissions
	 */
	getDefaultPatterns: MutationOperation<void, Record<string, IncludeExcludePatterns>>

	/**
	 * Get token element churn
	 *
	 * Provides a token element churn (i.e., the list of changed elements) for a given uniform path and time range. This service is public API since Teamscale 5.9.10. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getDeltaAffectedFiles: MutationOperation<{ pathParams: { project: string }, queryParams: GetDeltaAffectedFilesQueryParams }, Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Get the entire dependency graph of a system
	 *
	 * Extracts the dependency graph of a project in the \"Trivial Graph Format\" The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getDependencyGraph: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetDependencyGraphQueryParams }, string>

	/**
	 * Get diffs for two files
	 *
	 * Compares two elements with each other. The elements are given via a full path including project and uniform path and (optionally) the commit separated by '#@#' Requires read permissions on the projects containing the files to be compared
	 */
	getDiffs: MutationOperation<{ queryParams: GetDiffsQueryParams }, Array<DiffDescription>>

	/**
	 * Get .NET versions
	 *
	 * Returns a list of all versions known by uploaded PDBs for the given projects or for all visible projects if no project ID is given. No permissions needed, as the service checks if all listed projects are visible to the user.
	 */
	getDotNetVersions: MutationOperation<{ queryParams: GetDotNetVersionsQueryParams }, Record<string, Array<DotNetVersionInfo>>>

	/**
	 * Get commit descriptors
	 *
	 * Returns commit descriptors for a project. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project ID.
	 */
	getDumpCommitDescriptor: MutationOperation<{ pathParams: { project: string }, queryParams: GetDumpCommitDescriptorQueryParams }, string>

	/**
	 * Get commit trees
	 *
	 * Returns the commit trees for a project. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project ID.
	 */
	getDumpCommitTree: MutationOperation<{ pathParams: { project: string }, queryParams: GetDumpCommitTreeQueryParams }, string>

	/**
	 * Get commit trees
	 *
	 * Returns the commit tree data for a project. It excludes the nodes from the detailed commit tree. Use the debug/commit-tree service to get the full detailed information. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project ID.
	 */
	getDumpCommitTreeNodes: MutationOperation<{ pathParams: { project: string }, queryParams: GetDumpCommitTreeNodesQueryParams }, Array<CommitTreeNodeData>>

	/**
	 * Returns the configuration of the service provider that is passed to this service.
	 *
	 * This is meant to simplify the configuration of the identity provider. The API requires the user to have Access Administrative Services permissions.
	 */
	getEditedServiceProviderConfiguration: MutationOperation<{ queryParams: GetEditedServiceProviderConfigurationQueryParams, body: SamlAuthenticationOption }, SamlServiceProviderConfiguration>

	/**
	 * Get all events
	 *
	 * Returns the list of all available event announcements. The API requires no permissions
	 */
	getEventAnnouncements: MutationOperation<void, Array<EventAnnouncement>>

	/**
	 * Get exception hierarchies
	 *
	 * Retrieves hierarchies of custom exceptions represented as objects. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getExceptionsHierarchyTree: MutationOperation<{ pathParams: { project: string }, queryParams: GetExceptionsHierarchyTreeQueryParams }, Array<Array<ExceptionsTree>>>

	/**
	 * Get hierarchies of custom exceptions
	 *
	 * Retrieves hierarchies of custom exceptions represented graphically. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getExceptionsHierarchyTreeSVG: MutationOperation<{ pathParams: { project: string }, queryParams: GetExceptionsHierarchyTreeSVGQueryParams }, Array<string>>

	/**
	 * Get external file content
	 *
	 * Retrieves the file content of a file from disk with local pre-processing. The API requires the user to have Access Administrative Services permissions. User must have audit support included in the license.
	 * @param path The path to retrieve files for
	 */
	getExternalFileWithPreprocessing: MutationOperation<{ pathParams: { path: string } }, FormattedTokenElementInfo>

	/**
	 * Get file summary
	 *
	 * Retrieves the file summary showing the different file types in a given folder on the local file system along with the corresponding number of lines of code. User must have audit support included in the license.
	 */
	getFileSummary: MutationOperation<{ queryParams: GetFileSummaryQueryParams }, PairListStringFileSummaryInfoRecord>

	/**
	 * Get files with an identical content
	 *
	 * Retrieves files. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getFilesWithIdenticalContent: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetFilesWithIdenticalContentQueryParams }, Array<FileGroup> | null>

	/**
	 * Get files with an identical names
	 *
	 * Retrieves files. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getFilesWithIdenticalNames: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetFilesWithIdenticalNamesQueryParams }, Array<FileGroup> | null>

	/**
	 * This service provides access to the finding churn count. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingChurnCount: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingChurnCountQueryParams }, Array<FindingChurnCount>>

	/**
	 * Get finding delta
	 *
	 * Retrieves the finding delta (i.e., the list of newly added and deleted findings) for the given uniform path and time range. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingDelta: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingDeltaQueryParams }, FindingDelta>

	/**
	 * Get sibling findings
	 *
	 * Returns all sibling findings of the given finding. Only searches for siblings in the same findings partition. The finding with the given finding id is not included in the list. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id 
	 */
	getFindingSiblings: MutationOperation<{ pathParams: { project: string, id: string }, queryParams: GetFindingSiblingsQueryParams }, Array<TrackedFinding>>

	/**
	 * Get finding descriptions
	 *
	 * Returns user readable finding names and descriptions for given finding type IDs This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingTypeDescriptions: MutationOperation<{ pathParams: { project: string }, body: Array<string> }, Array<FindingTypeDescription>>

	/**
	 * Get findings
	 *
	 * Gets a list of all findings. Findings can be filtered by path prefix. For recursive queries, these are all findings found in the sub-tree. Filter parameters allow to only retrieve findings in a specific category and/or group. There is an upper limit for the number of returned findings. This service is public API since Teamscale 5.6. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindings: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingsQueryParams }, Array<TrackedFinding>>

	/**
	 * Export findings
	 *
	 * Exports findings for the element with the provided uniform path in the given format. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param format The export format used.
	 */
	getFindingsExport: MutationOperation<{ pathParams: { project: string, format: EFindingsExportFormatEntry }, queryParams: GetFindingsExportQueryParams }, void>

	/**
	 * Get finding siblings
	 *
	 * Gets next and prev finding respecting filter and sort options.  The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param finding 
	 */
	getFindingsSiblings: MutationOperation<{ pathParams: { project: string, finding: string }, queryParams: GetFindingsSiblingsQueryParams }, PreviousNextSiblings>

	/**
	 * Get summaries for all findings
	 *
	 * Gets the list of finding summaries for an element or a resource sub-tree. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingsSummary: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingsSummaryQueryParams }, FindingsSummaryInfo>

	/**
	 * Get findings treemap
	 *
	 * Produces a treemap of finding frequencies. The intensity of the rectangle colors determine the finding frequency in the corresponding file. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingsTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingsTreemapQueryParams }, FindingsTreemapWrapper>

	/**
	 * Get findings with false positive counts
	 *
	 * Gets a list of all findings with additional information on . For recursive queries, these are all findings found in the sub-tree. Filter parameters allow to only retrieve findings in a specific category and/or group. There is an upper limit for the number of returned findings. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFindingsWithCount: MutationOperation<{ pathParams: { project: string }, queryParams: GetFindingsWithCountQueryParams }, ExtendedFindingsWithCount>

	/**
	 * Get the first branch name to be displayed in the UI.
	 *
	 * Get the first branch name to be displayed in the UI. This is either the preselected UI branch or the default branch. This service is public API since Teamscale 6.5. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getFirstUIBranchNameGetRequest: MutationOperation<{ pathParams: { project: string } }, string>

	/**
	 * Get identifiers of flagged findings
	 *
	 * Returns identifiers of all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFlaggedFindingIds: MutationOperation<{ pathParams: { project: string }, queryParams: GetFlaggedFindingIdsQueryParams }, Array<string>>

	/**
	 * Gets flagging information for a finding.
	 *
	 * Returns the flagging information for the finding with given identifier. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param finding Id of the finding the information is requested for.
	 */
	getFlaggedFindingInfo: MutationOperation<{ pathParams: { project: string, finding: string }, queryParams: GetFlaggedFindingInfoQueryParams }, UserResolvedFindingBlacklistInfo | null>

	/**
	 * Get flagged findings
	 *
	 * Returns all flagged findings for a given project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFlaggedFindings: MutationOperation<{ pathParams: { project: string }, queryParams: GetFlaggedFindingsQueryParams }, Array<FindingBlacklistInfo>>

	/**
	 * Get flagging information for findings.
	 *
	 * Returns flagging information for the given findings. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getFlaggedFindingsInfos: MutationOperation<{ pathParams: { project: string }, queryParams: GetFlaggedFindingsInfosQueryParams, body: Array<string> }, Array<FindingBlacklistInfo>>

	/**
	 * Get connector ids of all Git-based connectors
	 *
	 * Returns all connector identifiers in a project that use Git. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getGitConnectorIds: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Get Repositories the GitHub App has Access to
	 *
	 * Returns list of repositories of the installed GitHub App. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getGitHubAppInstallationRepositories: MutationOperation<{ queryParams: GetGitHubAppInstallationRepositoriesQueryParams }, Array<string>>

	/**
	 * Get GitHub App Infos
	 *
	 * Returns information about the installed GitHub Apps the current user has access to. User must be provisioned from e.g. GitHub.
	 */
	getGitHubAppInstallations: MutationOperation<void, AppInstallationInfo>

	/**
	 * Collect URLs of all installed GitHub Apps.
	 *
	 * Returns URLs by setup GitHub Apps. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getGitHubAppUrls: MutationOperation<void, Array<string>>

	/**
	 * Gets a git tag by its ref name
	 *
	 * Returns the git tag with the given ref name or null if it could not be found. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 * @param tagName 
	 */
	getGitTagByName: MutationOperation<{ pathParams: { project: string, tagName: string }, queryParams: GetGitTagByNameQueryParams }, GitTag | null>

	/**
	 * Get global analysis progress
	 *
	 * Retrieves the global analysis progress (for all projects). User must either have the permission to view system status or the permission to access administrative services
	 */
	getGlobalAnalysisProgress: MutationOperation<void, Record<string, Array<AnalysisProgressDescriptor>>>

	/**
	 * Get multi project branches
	 *
	 * Gets the branches for multiple projects as BranchesInfo The API requires no permissions
	 */
	getGlobalBranchesGetRequest: MutationOperation<{ queryParams: GetGlobalBranchesGetRequestQueryParams }, BranchesInfo>

	/**
	 * Get all project's directories
	 *
	 * Returns global container info by merging container info from all projects. The API requires the user to have Create Metric Threshold Configurations permissions.
	 */
	getGlobalContainerInfo: MutationOperation<{ queryParams: GetGlobalContainerInfoQueryParams }, ContainerInfo>

	/**
	 * Get critical event detail log
	 *
	 * Returns the detailed critical event log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalCriticalEventLog: MutationOperation<{ pathParams: { timestamp: number } }, DetailedServiceLog>

	/**
	 * Get critical event log frequencies
	 *
	 * Returns the frequencies of the various critical event log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 */
	getGlobalCriticalEventLogFrequencies: MutationOperation<void, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all critical event logs
	 *
	 * Returns a list of all critical event short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalCriticalEventLogs: MutationOperation<{ queryParams: GetGlobalCriticalEventLogsQueryParams }, ShortLogResponseShortLogEntryBase>

	/**
	 * Get multi project first UI branch.
	 *
	 * Gets the branch to be displayed first in the UI for one of the specified projects. The API requires no permissions
	 */
	getGlobalFirstUIBranchGetRequest: MutationOperation<{ queryParams: GetGlobalFirstUIBranchGetRequestQueryParams }, string | null>

	/**
	 * Get quality indicator and analysis group names
	 *
	 * Retrieves the available quality indicator and analysis group names from all configured projects. Every authenticated user should see the available quality indicator and analysis group names.
	 */
	getGlobalIndicatorsAndGroups: MutationOperation<void, IndicatorsAndGroups>

	/**
	 * Get interaction detail log
	 *
	 * Returns the detailed interaction log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalInteractionLog: MutationOperation<{ pathParams: { timestamp: number } }, DetailedWorkerLog>

	/**
	 * Get interaction log frequencies
	 *
	 * Returns the frequencies of the various interaction log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 */
	getGlobalInteractionLogFrequencies: MutationOperation<void, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all interaction logs
	 *
	 * Returns a list of all interaction short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalInteractionLogs: MutationOperation<{ queryParams: GetGlobalInteractionLogsQueryParams }, ShortLogResponseShortInteractionLog>

	/**
	 * Get JS error detail log
	 *
	 * Returns the detailed JavaScript error log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalJavascriptErrorLog: MutationOperation<{ pathParams: { timestamp: number } }, DetailedJavaScriptErrorLog>

	/**
	 * Get JS error frequencies
	 *
	 * Returns the number of JavaScript error logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalJavascriptErrorLogFrequencies: MutationOperation<void, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all JS error logs
	 *
	 * Returns a list of all JavaScript error short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalJavascriptErrorLogs: MutationOperation<{ queryParams: GetGlobalJavascriptErrorLogsQueryParams }, ShortLogResponseShortJavaScriptErrorLog>

	/**
	 * Get multi project recent branches
	 *
	 * Gets the recent branches for multiple projects. The API requires no permissions
	 */
	getGlobalRecentBranchesGetRequest: MutationOperation<{ queryParams: GetGlobalRecentBranchesGetRequestQueryParams }, Array<string>>

	/**
	 * Get service detail log
	 *
	 * Returns the detailed service log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalServiceLog: MutationOperation<{ pathParams: { timestamp: number } }, DetailedServiceLog>

	/**
	 * Get service log frequencies
	 *
	 * Returns the frequencies of the various service log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 */
	getGlobalServiceLogFrequencies: MutationOperation<void, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all service logs
	 *
	 * Returns a list of all service short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalServiceLogs: MutationOperation<{ queryParams: GetGlobalServiceLogsQueryParams }, ShortLogResponseShortServiceLog>

	/**
	 * Get worker detail log
	 *
	 * Returns the detailed worker log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getGlobalWorkerLog: MutationOperation<{ pathParams: { timestamp: number } }, DetailedWorkerLog>

	/**
	 * Get worker log frequencies
	 *
	 * Returns the frequencies of the various worker log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 */
	getGlobalWorkerLogFrequencies: MutationOperation<void, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all worker logs
	 *
	 * Returns a list of all worker short logs. The API requires the user to have View System Status permissions.
	 */
	getGlobalWorkerLogs: MutationOperation<{ queryParams: GetGlobalWorkerLogsQueryParams }, ShortLogResponseShortWorkerLog>

	/**
	 * Get auto-completion suggestions
	 *
	 * Returns a list of suggested group names for auto-completion. The API requires the user to have Create Users permissions.
	 * @param input The current input that should be auto-completed.
	 */
	getGroupAutoCompletionSuggestions: MutationOperation<{ pathParams: { input: string }, queryParams: GetGroupAutoCompletionSuggestionsQueryParams }, Array<string>>

	/**
	 * Get system health status
	 *
	 * Retrieves a log of system health status information. This service is public API since Teamscale 5.5. No login required to perform monitoring.
	 */
	getHealthStatus: MutationOperation<{ queryParams: GetHealthStatusQueryParams }, string>

	/**
	 * Retrieve heap dump form
	 *
	 * Provides a UI to download heap dump The API requires the user to have Access Administrative Services permissions.
	 */
	getHeapDumpFormPage: MutationOperation<void, string>

	/**
	 * Get impacted tests
	 *
	 * Returns an ordered list of test clusters that are impacted by the given changes. The returned flat list of tests is a subset of all tests known to Teamscale. When the tests themselves have changed, new tests were added or tests have been deleted, this service cannot take that into account. Please use the PUT endpoint instead to provide a list of available tests. In particular, this endpoint may return tests that no longer exist. Callers must handle this case. This service is public API since Teamscale 9.4. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getImpactedTests: MutationOperation<{ pathParams: { project: string }, queryParams: GetImpactedTestsQueryParams }, Array<PrioritizableTest>>

	/**
	 * Get impacted tests
	 *
	 * Returns an ordered list of test clusters that are impacted by the given changes. The returned list of test clusters is a subset of the tests given in the request body. Clusters of selected and prioritized tests are formed based on the clustering information given in the request body. The tests from the request body are furthermore used to determine if any tests have changed or new tests were added. These are always included in the returned list of impacted tests. This service is public API since Teamscale 9.4. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getImpactedTestsFromAvailableTests: MutationOperation<{ pathParams: { project: string }, queryParams: GetImpactedTestsFromAvailableTestsQueryParams, body: Array<TestWithClusterId> }, Array<PrioritizableTestCluster>>

	/**
	 * Get the names of the important branches
	 *
	 * Get the names of the important branches The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getImportantBranches: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Get permissions for an instance
	 *
	 * Returns a set of basic permissions for a single instance identified by the instance ID. The API requires no permissions
	 * @param instanceId ID of the instance to get the permissions for
	 */
	getInstancePermissions: MutationOperation<{ pathParams: { instanceId: string }, queryParams: GetInstancePermissionsQueryParams }, Array<EBasicPermissionEntry>>

	/**
	 * Get files affected by issue
	 *
	 * Retrieves the files affected by the commits to a given issue. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param issueId ID of the issue to retrieve affected files for. The format is the connector ID of the originating issue tracker/requirements management tool (as specified in the connector settings in the project configuration), followed by the separator &#39;|&#39;, followed by the ID of the issue, as it is provided by the external issue tracker/requirements management tool. For example, an issue with the ID \&quot;TS-123\&quot; is imported using an issue tracker connector with the connector ID \&quot;issues1\&quot;. The expected ID is then \&quot;issues1|TS-123\&quot;.
	 */
	getIssueAffectedFiles: MutationOperation<{ pathParams: { project: string, issueId: string } }, Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Retrieve commit issues
	 *
	 * Returns the issues associated with the given commits. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getIssuesByRepositoryCommits: MutationOperation<{ pathParams: { project: string }, queryParams: GetIssuesByRepositoryCommitsQueryParams }, Array<Array<TeamscaleIssueStatus> | null>>

	/**
	 * Get issues details
	 *
	 * Retrieves issue details by their IDs. Unknown issue IDs will cause 'null' entries in the result The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getIssuesDetails: MutationOperation<{ pathParams: { project: string }, queryParams: GetIssuesDetailsQueryParams }, Array<UserResolvedTeamscaleIssue | null>>

	/**
	 * Get contained package names
	 *
	 * All packages that should be profiled (subpackages will be profiled as well). The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getJavaProfilerJvmPackageNames: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Returns the current computation status of a test minimization job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	getJob: MutationOperation<{ pathParams: { project: string, jobId: string } }, TestMinimizationJobRun>

	/**
	 * Returns the arguments of the test minimization job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	getJobOptions: MutationOperation<{ pathParams: { project: string, jobId: string } }, TestMinimizationRequestOptions>

	/**
	 * Get job queue size
	 *
	 * Retrieves size of the entire job queue from all projects. The API requires the user to have View System Status permissions.
	 */
	getJobQueueSize: MutationOperation<void, JobQueueCountWithDelayedJobs>

	/**
	 * Returns the list of test minimization jobs of the current user for the project.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getJobs: MutationOperation<{ pathParams: { project: string } }, Array<TestMinimizationJobRun>>

	/**
	 * Get full Kubernetes manifest
	 *
	 * Returns the content of the full Kubernetes manifest which is built from the given Helm chart or Kustomization. Returns null in case no full manifest is available for the given uniform path, e.g. a non-root Kustomization. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getKubernetesManifest: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetKubernetesManifestQueryParams }, FormattedTokenElementInfo>

	/**
	 * Get rules
	 *
	 * Gets rules for a given language available in the current Teamscale version. No permission needed since every user can explore rules with the rule browser.
	 */
	getLanguageRules: MutationOperation<{ queryParams: GetLanguageRulesQueryParams }, RulesContainer>

	/**
	 * Get enabled rules
	 *
	 * Gets enabled rules for the given languages and tools for a specific analysis profile. The API requires the user to have View permissions on the Analysis Profile analysisProfileName.
	 * @param analysisProfileName The analysis profile for which to get rules.
	 */
	getLanguageRulesForAnalysisProfile: MutationOperation<{ pathParams: { analysisProfileName: string }, queryParams: GetLanguageRulesForAnalysisProfileQueryParams }, RulesContainer>

	/**
	 * Get enabled rules
	 *
	 * Gets enabled rules for the given languages for a specific project. The API requires the user to have View Project permissions on the project.
	 * @param project The project for which to get rules.
	 */
	getLanguageRulesForProject: MutationOperation<{ pathParams: { project: string }, queryParams: GetLanguageRulesForProjectQueryParams }, RulesContainer>

	/**
	 * Return last change log entry with the given commit type
	 *
	 * Returns the log entry for the last change with the given commit type The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getLastCommitOfType: MutationOperation<{ pathParams: { project: string }, queryParams: GetLastCommitOfTypeQueryParams }, RepositoryLogEntryAggregate | null>

	/**
	 * Get the late external upload information
	 *
	 * Retrieves the timestamp of the next late processing of external uploads, the age threshold for external uploads being considered late and the maximum upload age for external uploads. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 */
	getLateExternalUploadProcessingInfo: MutationOperation<{ pathParams: { project: string } }, LateExternalUploadProcessingInfo>

	/**
	 * Returns information about the license.
	 *
	 * Returns license information. If the reload parameter is set this reloads the license and logs any errors into the service log. The API requires the user to have Access Administrative Services permissions.
	 */
	getLicenseInfo: MutationOperation<{ queryParams: GetLicenseInfoQueryParams }, LicenseInfo | null>

	/**
	 * Get licenses
	 *
	 * Service to retrieve all licenses sorted by criticality The API requires no permissions
	 * @param project The project ID.
	 */
	getLicenses: MutationOperation<{ pathParams: { project: string }, queryParams: GetLicensesQueryParams }, Array<CopyrightLicenseInfo>>

	/**
	 * Get link roles
	 *
	 * Returns the link roles for a specific connector. The API requires the user to have View permissions on the External Account accountName.
	 * @param accountName The ID of the external account the user needs access to.
	 */
	getLinkRoles: MutationOperation<{ pathParams: { accountName: string }, body: ConnectorConfiguration }, GetLinkRolesResponse>

	/**
	 * Returns active committers across all projects
	 *
	 * Returns all committers of the last n days (across all projects) that contribute to the license volume The API requires the user to have Access Administrative Services permissions.
	 */
	getListOfActiveCommitters: MutationOperation<{ queryParams: GetListOfActiveCommittersQueryParams }, Array<CommitterDisplayName>>

	/**
	 * Returns active committers for the given primary project IDs
	 *
	 * For the given list of primary project IDs, returns all committers of the last n days that contribute to the license volume. If an empty list is provided, all projects will be included (which is equivalent to performing a GET query). The API requires the user to have Access Administrative Services permissions.
	 */
	getListOfActiveCommittersForProjects: MutationOperation<{ queryParams: GetListOfActiveCommittersForProjectsQueryParams, body: Array<string> }, Array<CommitterDisplayName>>

	/**
	 * Provides context for the login screen
	 *
	 * null The API requires no login
	 */
	getLoginContext: MutationOperation<void, LoginContext>

	/**
	 * Get a single merge request
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param idWithRepository 
	 */
	getMergeRequest: MutationOperation<{ pathParams: { project: string, idWithRepository: string } }, ExtendedMergeRequest | null>

	/**
	 * Get affected files for merge request
	 *
	 * Provides the list of changed files for a given merge request. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMergeRequestAffectedFiles: MutationOperation<{ pathParams: { project: string }, queryParams: GetMergeRequestAffectedFilesQueryParams }, Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Get a merge request analysis delta
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param idWithRepository 
	 */
	getMergeRequestDelta: MutationOperation<{ pathParams: { project: string, idWithRepository: string }, queryParams: GetMergeRequestDeltaQueryParams }, MergeRequestDelta | null>

	/**
	 * Get merge request finding churn
	 *
	 * Creates a findings delta (newly added or deleted findings and unchanged findings in modified code) for a given proposed merge. Findings in modified code will only be reported if the modification happened since the last common commit with the target branch. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMergeRequestFindingChurn: MutationOperation<{ pathParams: { project: string }, queryParams: GetMergeRequestFindingChurnQueryParams }, FindingDelta>

	/**
	 * Get merge request repository churn
	 *
	 * Retrieves the merge request repository churn. The API requires the user to have View Project permissions on the project.
	 * @param project ID of the project which analyzes the merge request.
	 */
	getMergeRequestRepositoryChurn: MutationOperation<{ pathParams: { project: string }, queryParams: GetMergeRequestRepositoryChurnQueryParams }, Array<ExtendedRepositoryLogEntry>>

	/**
	 * Retrieves metadata for an ABAP file stored in Teamscale, previously retrieved from some SAP system.
	 *
	 * Retrieves metadata for an ABAP file stored in Teamscale, previously retrieved from some SAP System The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param filePath Path to the file in Teamscale.
	 */
	getMetadataForFile: MutationOperation<{ pathParams: { project: string, filePath: string }, queryParams: GetMetadataForFileQueryParams }, AbapFileMetadata>

	/**
	 * Get method history
	 *
	 * Returns the method history entries including changes, renamings or movements and points in time where method has been tested. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath Uniform path to retrieve the metric history for.
	 */
	getMethodHistory: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetMethodHistoryQueryParams }, MethodHistoryEntriesWrapper>

	/**
	 * Get benchmark results
	 *
	 * Returns (partly anonymized) benchmark results for a specific metric. User must have audit support included in the license.
	 */
	getMetricBenchmark: MutationOperation<{ queryParams: GetMetricBenchmarkQueryParams }, Array<BenchmarkResult>>

	/**
	 * Get metric schema
	 *
	 * Returns the metric schema for a path within a project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPathType The path for which the metric schema should be fetched
	 */
	getMetricDirectorySchemaForPath: MutationOperation<{ pathParams: { project: string, uniformPathType: ETypeEntry } }, MetricDirectorySchema>

	/**
	 * Get global metric schema
	 *
	 * Retrieves all available metric schemas in the same order as the provided paths. Global metric directory schema is available to each authenticated user.
	 */
	getMetricDirectorySchemas: MutationOperation<{ queryParams: GetMetricDirectorySchemasQueryParams }, Array<MetricDirectorySchema>>

	/**
	 * Get metrics
	 *
	 * Retrieves the available external metrics. The API requires no permissions
	 */
	getMetricSchemaEntries: MutationOperation<void, Array<MetricSchemaChangeEntry>>

	/**
	 * Get metric threshold configuration
	 *
	 * Retrieves the metric threshold configuration identified by given name. Built-in metric threshold configurations require no access permissions. For the rest, view permission for the respective configuration is required.
	 * @param metricThresholdConfigurationName 
	 */
	getMetricThresholdConfiguration: MutationOperation<{ pathParams: { metricThresholdConfigurationName: string }, queryParams: GetMetricThresholdConfigurationQueryParams }, MetricThresholdConfiguration>

	/**
	 * Get minimized Test Set
	 *
	 * Returns a list of test clusters that have been determined to be the best tests to run in the amount of time that was specified in the request. The clusters are formed according tothe clustering-regex that can be specified in the request options. This service is public API since Teamscale 7.8. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getMinimizedTests: MutationOperation<{ pathParams: { project: string }, queryParams: GetMinimizedTestsQueryParams }, TestMinimizationResult>

	/**
	 * Returns a preview of the data we would send
	 *
	 * Returns a the preview of the data we would send to our monitoring back-end. The API requires the user to have Access Administrative Services permissions.
	 */
	getMonitoringPreview: MutationOperation<void, string>

	/**
	 * Export Latex metric table
	 *
	 * Service for exporting Latex tables containing metrics for a given project as well as the corresponding test and application subprojects. User must have audit support included in the license.
	 */
	getMultiProjectLatexTable: MutationOperation<{ queryParams: GetMultiProjectLatexTableQueryParams }, string>

	/**
	 * Number of Active User
	 *
	 * Calculates the number of active users in the past 90 days. This is either the number of active Teamscale users or the number of committers, depending on which value is larger.  The API requires no permissions
	 */
	getNumberOfActiveUsers: MutationOperation<void, number>

	/**
	 * Retrieves OpenID endpoints
	 *
	 * Tries to retrieve all Teamscale-relevant endpoints from the issuer URL The API requires the user to have Access Administrative Services permissions.
	 */
	getOpenIdEndpoints: MutationOperation<{ queryParams: GetOpenIdEndpointsQueryParams }, OpenIdEndpointInfo>

	/**
	 * Get partitions
	 *
	 * Returns the list of external upload partitions in this project. The API requires the user to have Perform External Uploads, View Project permissions on the project.
	 * @param project The project ID.
	 */
	getPartitions: MutationOperation<{ pathParams: { project: string } }, Array<ExternalAnalysisPartitionInfo>>

	/**
	 * Get permission lookup
	 *
	 * Returns a permission lookup for the whole permission scope The API requires no permissions
	 */
	getPermissionLookup: MutationOperation<{ queryParams: GetPermissionLookupQueryParams }, PermissionLookupEBasicPermission>

	/**
	 * Perspective context
	 *
	 * Provides basic information about the logged in user, permissions, available projects and the Teamscale instance. The API requires no permissions
	 */
	getPerspectiveContext: MutationOperation<void, PerspectiveContext>

	/**
	 * Get the content for a pre-commit element
	 *
	 * Retrieves the formatted content (i.e., incl. style information) for an element that is part of a pre-commit branch. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getPreCommitContent: MutationOperation<{ pathParams: { project: string }, queryParams: GetPreCommitContentQueryParams }, FormattedTokenElementInfo>

	/**
	 * Get profiler configuration
	 *
	 * Retrieves the profiler configuration identified by given ID. The API requires the user to have View permissions on the Profiler Configuration configurationId.
	 * @param configurationId ID of the profiler configuration to retrieve.
	 */
	getProfilerConfiguration: MutationOperation<{ pathParams: { configurationId: string } }, ProfilerConfiguration>

	/**
	 * Get project analysis progress
	 *
	 * Retrieves the project specific analysis progress. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getProjectAnalysisProgress: MutationOperation<{ pathParams: { project: string } }, Array<AnalysisProgressDescriptor>>

	/**
	 * Get project configuration
	 *
	 * Returns the configuration used for creation of the project in the path parameter. This service is public API since Teamscale 7.1. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getProjectConfiguration: MutationOperation<{ pathParams: { project: string } }, ProjectConfiguration>

	/**
	 * Get critical event detail log
	 *
	 * Returns the detailed critical event log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectCriticalEventLog: MutationOperation<{ pathParams: { project: string, timestamp: number } }, DetailedServiceLog>

	/**
	 * Get critical event log frequencies
	 *
	 * Returns the frequencies of the various critical event log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectCriticalEventLogFrequencies: MutationOperation<{ pathParams: { project: string } }, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all critical event logs
	 *
	 * Returns a list of all critical event short logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectCriticalEventLogs: MutationOperation<{ pathParams: { project: string }, queryParams: GetProjectCriticalEventLogsQueryParams }, ShortLogResponseShortLogEntryBase>

	/**
	 * Get interaction detail log
	 *
	 * Returns the detailed interaction log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectInteractionLog: MutationOperation<{ pathParams: { project: string, timestamp: number } }, DetailedWorkerLog>

	/**
	 * Get interaction log frequencies
	 *
	 * Returns the frequencies of the various interaction log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectInteractionLogFrequencies: MutationOperation<{ pathParams: { project: string } }, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all interaction logs
	 *
	 * Returns a list of all interaction short logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectInteractionLogs: MutationOperation<{ pathParams: { project: string }, queryParams: GetProjectInteractionLogsQueryParams }, ShortLogResponseShortInteractionLog>

	/**
	 * Get JS error detail log
	 *
	 * Returns the detailed JavaScript error log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectJavascriptErrorLog: MutationOperation<{ pathParams: { project: string, timestamp: number } }, DetailedJavaScriptErrorLog>

	/**
	 * Get JS error frequencies
	 *
	 * Returns the number of JavaScript error logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectJavascriptErrorLogFrequencies: MutationOperation<{ pathParams: { project: string } }, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all JS error logs
	 *
	 * Returns a list of all JavaScript error short logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectJavascriptErrorLogs: MutationOperation<{ pathParams: { project: string }, queryParams: GetProjectJavascriptErrorLogsQueryParams }, ShortLogResponseShortJavaScriptErrorLog>

	/**
	 * Get project notification rules
	 *
	 * Retrieves the currently set project notification rules The API requires the user to have Edit Global Notification Settings permissions.
	 */
	getProjectNotificationRules: MutationOperation<void, ProjectNotificationRules | null>

	/**
	 * Get options
	 *
	 * Retrieves all available project options. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getProjectOptions: MutationOperation<{ pathParams: { project: string } }, Record<string, object>>

	/**
	 * Get service detail log
	 *
	 * Returns the detailed service log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectServiceLog: MutationOperation<{ pathParams: { project: string, timestamp: number } }, DetailedServiceLog>

	/**
	 * Get service log frequencies
	 *
	 * Returns the frequencies of the various service log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectServiceLogFrequencies: MutationOperation<{ pathParams: { project: string } }, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all service logs
	 *
	 * Returns a list of all service short logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectServiceLogs: MutationOperation<{ pathParams: { project: string }, queryParams: GetProjectServiceLogsQueryParams }, ShortLogResponseShortServiceLog>

	/**
	 * Get unique users activity trend
	 *
	 * Returns a list of lists, where each list contains a pair of the date timestamp and the number of unique users on that date. Requires VIEW permissions on all projects given via the project parameter
	 */
	getProjectUserActivityTrend: MutationOperation<{ queryParams: GetProjectUserActivityTrendQueryParams }, Array<Array<number>>>

	/**
	 * Get worker detail log
	 *
	 * Returns the detailed worker log entry for the given timestamp. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 * @param timestamp The timestamp for which to retrieve the detailed log entry
	 */
	getProjectWorkerLog: MutationOperation<{ pathParams: { project: string, timestamp: number } }, DetailedWorkerLog>

	/**
	 * Get worker log frequencies
	 *
	 * Returns the frequencies of the various worker log types (i.e. INFO/WARN/ERROR). The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectWorkerLogFrequencies: MutationOperation<{ pathParams: { project: string } }, Array<ProjectLogLevelFrequencies>>

	/**
	 * Get all worker logs
	 *
	 * Returns a list of all worker short logs. The API requires the user to have View System Status permissions.
	 * @param project The project where the logs originate (\&quot;##maintenance##\&quot; for the maintenance project).
	 */
	getProjectWorkerLogs: MutationOperation<{ pathParams: { project: string }, queryParams: GetProjectWorkerLogsQueryParams }, ShortLogResponseShortWorkerLog>

	/**
	 * Get simple read/write performance statistics
	 *
	 * Returns a textual summary of read/write performance statistics. The API requires the user to have Access Administrative Services permissions.
	 */
	getReadWriteStatistics: MutationOperation<void, string>

	/**
	 * Get recent branches
	 *
	 * Returns the recently visited branches. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRecentBranches: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Get redundant literal treemap
	 *
	 * Retrieves a treemap of all redundant literals. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRedundantLiteralTreemap: MutationOperation<{ pathParams: { project: string }, queryParams: GetRedundantLiteralTreemapQueryParams }, FilteredTreeMapWrapper>

	/**
	 * Get redundant literals
	 *
	 * Retrieves all redundant literals in the project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRedundantLiteralsQuery: MutationOperation<{ pathParams: { project: string }, queryParams: GetRedundantLiteralsQueryQueryParams }, Array<RedundantLiteralInfo>>

	/**
	 * Get report
	 *
	 * Returns the report. The API requires the user to have View permissions on the Quality Report id.
	 * @param id The report id
	 */
	getReport: MutationOperation<{ pathParams: { id: string } }, QualityReport>

	/**
	 * Get report slide render data
	 *
	 * Returns the data necessary to render the slide in the frontend. The API requires the user to have View permissions on the Quality Report reportId.
	 * @param reportId The ID of the report.
	 * @param slideId The ID of the slide.
	 */
	getReportSlideRawRenderData: MutationOperation<{ pathParams: { reportId: string, slideId: string } }, object | null>

	/**
	 * Get repository churn
	 *
	 * Retrieves the repository churn for a time range. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRepositoryChurn: MutationOperation<{ pathParams: { project: string }, queryParams: GetRepositoryChurnQueryParams }, Array<ExtendedRepositoryLogEntry>>

	/**
	 * Get all repository log entries
	 *
	 * Returns repository log entries for given commits.A commit is identified as branch1@timestamp1All timestamps are interpreted as milliseconds since 1970. If no commits are provided, all log entries are returned. Commits are separated by comma. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRepositoryLog: MutationOperation<{ pathParams: { project: string }, queryParams: GetRepositoryLogQueryParams }, Array<ExtendedRepositoryLogEntry>>

	/**
	 * Get all repository log entries, with split aggregates
	 *
	 * Returns repository log entries for given commits. Aggregated entries are split into their constituents.A commit is identified as branch1@timestamp1All timestamps are interpreted as milliseconds since 1970. If no commits are provided, all log entries are returned. Commits are separated by comma. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRepositoryLogWithSplitAggregates: MutationOperation<{ pathParams: { project: string }, queryParams: GetRepositoryLogWithSplitAggregatesQueryParams }, Array<ExtendedRepositoryLogEntry>>

	/**
	 * Get the repository summary
	 *
	 * Provides summary information about the commit activity in a project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRepositorySummary: MutationOperation<{ pathParams: { project: string }, queryParams: GetRepositorySummaryQueryParams }, RepositorySummary>

	/**
	 * Get resolved tasks
	 *
	 * Returns all available tasks with resolved properties. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getResolvedTasks: MutationOperation<{ pathParams: { project: string }, queryParams: GetResolvedTasksQueryParams }, Array<ResolvedTask>>

	/**
	 * Get resource history
	 *
	 * Returns repository log file entries for a specific path, i.e. the association between files and repository log entries. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getResourceHistory: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetResourceHistoryQueryParams }, Array<RepositoryLogFileHistoryEntry>>

	/**
	 * Get resource type
	 *
	 * Retrieves the type of a resource represented by a uniform path The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getResourceType: MutationOperation<{ pathParams: { project: string }, queryParams: GetResourceTypeQueryParams }, EResourceTypeEntry>

	/**
	 * Get retrospective
	 *
	 * Returns the retrospective. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id The retrospective id
	 */
	getRetrospective: MutationOperation<{ pathParams: { project: string, id: string } }, Retrospective>

	/**
	 * Get quality retrospectives
	 *
	 * Returns all retrospectives stored for the given project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRetrospectivesForProject: MutationOperation<{ pathParams: { project: string } }, Array<Retrospective>>

	/**
	 * Get review baseline
	 *
	 * Allows to retrieve the review baseline for a project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getReviewBaseline: MutationOperation<{ pathParams: { project: string } }, number | null>

	/**
	 * Get risk methods
	 *
	 * Returns all methods that are considered a risk and should therefore be covered with tests. For now this is all methods that have been added or changed in the given time range. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getRisks: MutationOperation<{ pathParams: { project: string }, queryParams: GetRisksQueryParams }, RiskMethods>

	/**
	 * Get role schema
	 *
	 * Returns the RoleSchema holding all existing global and project roles. The API requires no permissions
	 */
	getRoleSchema: MutationOperation<void, RoleSchemaData>

	/**
	 * Get running profiler
	 *
	 * Retrieves the running profiler The user needs to have VIEW permissions on the configuration this profiler is using.
	 * @param profilerId 
	 */
	getRunningProfiler: MutationOperation<{ pathParams: { profilerId: string } }, RunningProfilerInfo>

	/**
	 * Get running profilers
	 *
	 * Retrieves the running profilers available in the system No permissions needed, as the service will only return the profiler infos that use a profiler configuration that is visible to the current user.
	 */
	getRunningProfilers: MutationOperation<{ queryParams: GetRunningProfilersQueryParams }, RunningProfilersResult>

	/**
	 * Returns the SAML metadata from the given URL.
	 *
	 * This is meant to simplify SAML configuration in Teamscale. The API requires the user to have Access Administrative Services permissions.
	 */
	getSamlIdentityProviderMetadata: MutationOperation<{ queryParams: GetSamlIdentityProviderMetadataQueryParams }, string>

	/**
	 * Open scheduler control page
	 *
	 * Provides a UI to send a command to the scheduler control. The API requires the user to have Access Administrative Services permissions.
	 */
	getSchedulerControlPage: MutationOperation<void, string>

	/**
	 * Returns the currently selected list of test (possibly Pareto ranked).
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	getSelectedTests: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: GetSelectedTestsQueryParams }, Array<SelectedTest>>

	/**
	 * Get schema
	 *
	 * Retrieves server option schema. The API requires the user to have Edit Server Options permissions.
	 */
	getServerOptionSchema: MutationOperation<void, Array<OptionDescriptor>>

	/**
	 * Service-Load information
	 *
	 * Returns information about service load. The API requires the user to have View System Status permissions.
	 */
	getServiceLoad: MutationOperation<void, Array<LoadProfile>>

	/**
	 * Export Latex metric table
	 *
	 * Service for exporting Latex tables containing metrics for the project. User must have audit support included in the license.
	 * @param project The project ID.
	 */
	getSingleProjectLatexTable: MutationOperation<{ pathParams: { project: string }, queryParams: GetSingleProjectLatexTableQueryParams }, string>

	/**
	 * Get option
	 *
	 * Retrieves the project option identified by the given name. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param optionId Name of the option to retrieve.
	 */
	getSingleProjectOption: MutationOperation<{ pathParams: { project: string, optionId: string } }, object>

	/**
	 * Get option
	 *
	 * Retrieves the server option identified by the given name. EDIT_SERVER_OPTIONS permission needed for most options, but some options (PUBLIC_READ_OPTION_NAMES) can be read without permission
	 * @param optionId Name of the option to retrieve.
	 */
	getSingleServerOption: MutationOperation<{ pathParams: { optionId: string } }, object>

	/**
	 * Returns an image that has been stored previously using this service
	 *
	 * null The API requires the user to have View permissions on the Quality Report reportId.
	 * @param reportId The ID of the quality report the user needs access to.
	 * @param id The unique image id that was returned by the POST call to this service.
	 */
	getSlideImage: MutationOperation<{ pathParams: { reportId: string, id: string } }, void>

	/**
	 * Get a snapshot
	 *
	 * Returns a single snapshot identified by the given ID or ID prefix. The ID (prefix) must not be empty, but can be as short as one character, as long as it unambiguously identifies the snapshot to be returned. The API requires the user to have Access Administrative Services permissions.
	 * @param id The ID of the snapshot to get. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getSnapshot: MutationOperation<{ pathParams: { id: string }, queryParams: GetSnapshotQueryParams }, InstanceComparisonSnapshotDto>

	/**
	 * Get a snapshot status
	 *
	 * Returns the status (IN_PROGRESS, SUCCESS or FAILURE) of a single status identified by the given ID or ID prefix. The ID (prefix) must not be empty, but can be as short as one character, as long as it unambiguously identifies a status. Only snapshots created by the logged-in user are considered for this operation. The API requires the user to have Access Administrative Services permissions.
	 * @param id The ID of the snapshot. May be an incomplete prefix, as long as it is unambiguous.
	 */
	getSnapshotStatus: MutationOperation<{ pathParams: { id: string } }, EInstanceComparisonStatusEntry>

	/**
	 * Gets the spec item churn
	 *
	 * Provides a spec item churn (i.e., the list of changed spec items) for a given time range. This service is public API since Teamscale 8.5. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSpecItemChurn: MutationOperation<{ pathParams: { project: string }, queryParams: GetSpecItemChurnQueryParams }, Array<TokenElementChurnWithOriginInfo>>

	/**
	 * Get spec item details
	 *
	 * Retrieves spec item details by their IDs. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSpecItemDetails: MutationOperation<{ pathParams: { project: string }, queryParams: GetSpecItemDetailsQueryParams }, Array<UserResolvedSpecItem | null>>

	/**
	 * Get the spec items that the given one is verifying
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id ID of the verifying spec item
	 */
	getSpecItemVerifies: MutationOperation<{ pathParams: { project: string, id: string }, queryParams: GetSpecItemVerifiesQueryParams }, Array<SpecItemReferenceElement>>

	/**
	 * Get the spec items that are verified by the queried items 
	 *
	 * Retrieves all 'verifies' references from the queried spec items to the verified ones. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSpecItemsVerifiedByQueried: MutationOperation<{ pathParams: { project: string }, queryParams: GetSpecItemsVerifiedByQueriedQueryParams }, Array<SpecItemRelationReference>>

	/**
	 * Get the spec items that verify the queried items 
	 *
	 * Retrieves all 'verified-by' references from the queried spec items to the verifying ones. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getSpecItemsVerifyingQueried: MutationOperation<{ pathParams: { project: string }, queryParams: GetSpecItemsVerifyingQueriedQueryParams }, Array<SpecItemRelationReference>>

	/**
	 * Get stacktrace of failed test execution
	 *
	 * Retrieves the stacktrace of the failed test execution at a given uniform path. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param test Uniform path to retrieve the stacktrace for
	 */
	getStacktrace: MutationOperation<{ pathParams: { project: string, test: string }, queryParams: GetStacktraceQueryParams }, Stacktrace | null>

	/**
	 * Get state change timestamps
	 *
	 * Allows getting the timestamps for the different analysis states. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getStateChangeTimestamps: MutationOperation<{ pathParams: { project: string } }, Record<string, number>>

	/**
	 * Get aggregated store performance metrics
	 *
	 * Allows downloading the store performance aggregate data as CSV. The API requires the user to have Access Administrative Services permissions.
	 */
	getStorePerformanceDetails: MutationOperation<void, void>

	/**
	 * Returns the configuration of the service provider that is stored at the named option.
	 *
	 * This is meant to simplify the configuration of the identity provider. The API requires no login
	 */
	getStoredServiceProviderConfiguration: MutationOperation<{ queryParams: GetStoredServiceProviderConfigurationQueryParams }, File>

	/**
	 * Get stored test lists
	 *
	 * Retrieves the list of available test list descriptors. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getStoredTestLists: MutationOperation<{ pathParams: { project: string } }, Array<TestListDescriptor>>

	/**
	 * Get stored test lists names
	 *
	 * Retrieves the list of available test list descriptors names. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getStoredTestListsNames: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Get the complete backup summary
	 *
	 * Get the summary of the 10 most recent backups. This service is public API since Teamscale 6.1. The API requires the user to have Backup Global Data permissions.
	 */
	getSummary1: MutationOperation<void, Array<BackupJobSummary>>

	/**
	 * Get an AI generated summary for an element
	 *
	 * Retrieves the AI summary for an element. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param engine The interal name of the configured AI engine to use.
	 */
	getSummary2: MutationOperation<{ pathParams: { project: string, engine: string }, queryParams: GetSummary2QueryParams }, string>

	/**
	 * Get system information.
	 *
	 * Returns system information. The API requires the user to have View System Status permissions.
	 */
	getSystemInformation: MutationOperation<void, Array<SystemProcessInfo>>

	/**
	 * Get task
	 *
	 * Returns the respective task with detailed findings. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id ID of the requested task
	 */
	getTask: MutationOperation<{ pathParams: { project: string, id: number }, queryParams: GetTaskQueryParams }, TaskWithDetailedFindings>

	/**
	 * Get task siblings
	 *
	 * Retrieves the task siblings (previous and next tasks) for the task specified by ID, allowing user filters. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param task ID of the task to retrieve the sibling tasks for.
	 */
	getTaskPreviousNext: MutationOperation<{ pathParams: { project: string, task: number }, queryParams: GetTaskPreviousNextQueryParams }, PreviousNextSiblings>

	/**
	 * Get task summary
	 *
	 * Retrieves the counts of tasks in each possible task state. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTaskSummary: MutationOperation<{ pathParams: { project: string }, queryParams: GetTaskSummaryQueryParams }, Record<string, number>>

	/**
	 * Get task tags
	 *
	 * Allows to retrieve all used task tags. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTaskTags: MutationOperation<{ pathParams: { project: string } }, Array<string>>

	/**
	 * Get finding to task mapping
	 *
	 * Returns the IDs of all tasks containing the given findings. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTaskToFindingMapping: MutationOperation<{ pathParams: { project: string }, body: GetTaskToFindingMappingBody }, Array<GetTaskToFindingMappingDefaultResponseInner>>

	/**
	 * Get tasks with count
	 *
	 * Returns all available tasks with count. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTasksWithCount: MutationOperation<{ pathParams: { project: string }, queryParams: GetTasksWithCountQueryParams }, TasksWithCount>

	/**
	 * Shutdown Teamscale
	 *
	 * Provides an UI to issue a Teamscale shutdown request. The API requires the user to have Access Administrative Services permissions.
	 */
	getTeamscaleShutdownPage: MutationOperation<void, string>

	/**
	 * Get a valid teamscale-upload invocation.
	 *
	 * Allows the user to get a valid teamscale-upload invocation that the Teamscale Upload wizard UI created. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID to which the external coverage report should be uploaded
	 */
	getTeamscaleUploadInvocation: MutationOperation<{ pathParams: { project: string }, queryParams: GetTeamscaleUploadInvocationQueryParams }, string>

	/**
	 * Input for the Teamscale Upload wizard.
	 *
	 * Returns the input needed to render the Teamscale Upload wizard in the UI. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	getTeamscaleUploadWizardInput: MutationOperation<{ pathParams: { project: string } }, TeamscaleUploadWizardInput>

	/**
	 * List all third party dependencies
	 *
	 * Lists all third party dependencies for the project with given package depth, along with the percentage of affected files for each dependency. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTechnologyDependencies: MutationOperation<{ pathParams: { project: string }, queryParams: GetTechnologyDependenciesQueryParams }, Array<DependencyEntry>>

	/**
	 * Get treemap with the third party dependencies
	 *
	 * Builds a treemap with the third party dependencies from the technology scan. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTechnologyTreeMap: MutationOperation<{ pathParams: { project: string }, queryParams: GetTechnologyTreeMapQueryParams }, FilteredTreeMapWrapper>

	/**
	 * Returns test coverage
	 *
	 * Returns the line coverage data for an element. This service is public API since Teamscale 5.3. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getTestCoverage: MutationOperation<{ pathParams: { project: string, uniformPath: string }, queryParams: GetTestCoverageQueryParams }, LineCoverageInfo | null>

	/**
	 * Get test coverage overlay
	 *
	 * Retrieves the methods that are expected to be covered by the impacted tests if they are rerun. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTestCoverageOverlay: MutationOperation<{ pathParams: { project: string }, queryParams: GetTestCoverageOverlayQueryParams }, TestCoverageOverlayData>

	/**
	 * Returns test coverage
	 *
	 * Returns the line coverage data for an element. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param uniformPath 
	 */
	getTestCoveragePost: MutationOperation<{ pathParams: { project: string, uniformPath: string }, body: GetTestCoveragePostBody }, LineCoverageInfo | null>

	/**
	 * Download test gap data
	 *
	 * Downloads test gap information as a CSV file The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTestGapDataAsCsv: MutationOperation<{ pathParams: { project: string }, queryParams: GetTestGapDataAsCsvQueryParams }, File>

	/**
	 * Get test gap treemap
	 *
	 * Retrieves the test get treemap. For each method in the project, the treemap shows whether it was tested after its last change within the reference time frame. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTestGapTreeMap: MutationOperation<{ pathParams: { project: string }, queryParams: GetTestGapTreeMapQueryParams }, TestGapTreeMapWrapper>

	/**
	 * Get test gap percentage
	 *
	 * Calculates the TGA percentage for the given uniform path or the given issue ID. This service is public API since Teamscale 5.9.6. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTgaPercentage: MutationOperation<{ pathParams: { project: string }, queryParams: GetTgaPercentageQueryParams }, number>

	/**
	 * Get metric table entries
	 *
	 * Retrieves various test gap metrics (e.g., test gap state, number of test gaps, execution state and churn) and returns them in tabular form. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTgaTable: MutationOperation<{ pathParams: { project: string }, queryParams: GetTgaTableQueryParams }, Array<TgaTableEntry>>

	/**
	 * Get test coverage partitions
	 *
	 * Returns the available test coverage partitions of the project. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTgaTestCoveragePartitions: MutationOperation<{ pathParams: { project: string }, queryParams: GetTgaTestCoveragePartitionsQueryParams }, Array<string>>

	/**
	 * Get test gap trend
	 *
	 * Calculates test gap trends. Does not support issue requests. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTgaTrend: MutationOperation<{ pathParams: { project: string }, queryParams: GetTgaTrendQueryParams }, Array<MetricTrendEntry>>

	/**
	 * Get custom fields definitions
	 *
	 * Returns the list of all available custom fields from the given connector User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	getTrackerCustomFields: MutationOperation<{ body: ConnectorConfiguration }, ExternalToolIssueCustomFieldResult>

	/**
	 * Get trigger graph as JSON
	 *
	 * Returns a plain text GraphViz DOT description of the trigger graph The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	getTriggerGraphDot: MutationOperation<{ pathParams: { project: string }, queryParams: GetTriggerGraphDotQueryParams }, Array<TriggerGraphNode>>

	/**
	 * Get detailed trigger performance metrics
	 *
	 * Allows downloading the detailed trigger performance data as CSV. The feature toggle -Dcom.teamscale.log_performance_details=true must be enabled. The API requires the user to have Access Administrative Services permissions.
	 */
	getTriggerPerformanceDetails: MutationOperation<void, void>

	/**
	 * Get unused ID
	 *
	 * Returns a valid event ID that is unused so far. The API requires no permissions
	 */
	getUnusedEventId: MutationOperation<void, string>

	/**
	 * Preview of usage data
	 *
	 * Returns a preview of the data we collect if you opt-in to provide that data. The API requires the user to have Access Administrative Services permissions.
	 */
	getUsageDataPreview: MutationOperation<{ body: UsageDataReportingOption }, UsageDataPreview>

	/**
	 * Get auto-completion suggestions
	 *
	 * Returns a list of suggested user names for auto-completion. The API requires the user to have Create Users permissions.
	 * @param input The current input that should be auto-completed.
	 */
	getUserAutoCompletionSuggestions: MutationOperation<{ pathParams: { input: string }, queryParams: GetUserAutoCompletionSuggestionsQueryParams }, Array<string>>

	/**
	 * Get user avatar image
	 *
	 * Returns the image file that serves as the avatar for the user in the first path parameter. The API requires no permissions
	 * @param user 
	 */
	getUserAvatarData: MutationOperation<{ pathParams: { user: string }, queryParams: GetUserAvatarDataQueryParams }, void>

	/**
	 * Get a user group
	 *
	 * Get the user group identified by the given name. The API requires the user to have View permissions on the Group userGroupName.
	 * @param userGroupName Name of the requested user group.
	 */
	getUserGroup: MutationOperation<{ pathParams: { userGroupName: string } }, UserGroup>

	/**
	 * Get the spec items that are verifying the given one (the given spec item is verified by...)
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param id ID of the spec item to fetch
	 */
	getVerifiedBySpecItems: MutationOperation<{ pathParams: { project: string, id: string }, queryParams: GetVerifiedBySpecItemsQueryParams }, Array<SpecItemReferenceElement>>

	/**
	 * Returns all accessible projects along with their metric schema
	 *
	 * null The permissions will be checked at runtime (this service works cross-project)
	 */
	getWidgetContext: MutationOperation<void, WidgetContext>

	/**
	 * Get worker status
	 *
	 * Retrieves the worker status info. The API requires the user to have View System Status permissions.
	 */
	getWorkerStatus: MutationOperation<void, Array<WorkerGroupStatus>>

	/**
	 * Sufficient Ram
	 *
	 * Checks whether Teamscale has enough RAM to operate, given the amount of workers. The API requires no permissions
	 */
	hasSufficientRam: MutationOperation<void, SufficientRamResponse>

	/**
	 * Hide event announcement
	 *
	 * Hides the event announcement with the given ID for the logged-in user. The event will no longer be advertised to the user. The API requires no permissions
	 */
	hideEventAnnouncement: MutationOperation<{ body: string }, void>

	/**
	 * Upload AbapLint configuration files
	 *
	 * Uploads AbapLint configuration files which are then converted to Teamscale analysis profiles. Returns a textual summary of the import process The API requires the user to have Create Analysis Profiles permissions.
	 */
	importAbapLintConfigurationFile: MutationOperation<{ body: ImportAbapLintConfigurationFileBody }, Array<ABAPLintConfigurationFileImportSummary>>

	/**
	 * Import analysis profile
	 *
	 * Imports an analysis profile. Requires Create Analysis Profiles or the permission to edit analysis profiles when a profile with the same name already exists.
	 */
	importAnalysisProfile: MutationOperation<{ body: ImportAnalysisProfileBody }, void>

	/**
	 * Update a dashboard from a dashboard export
	 *
	 * Performs an import of a dashboard. Adds the uploaded descriptor to the list of dashboards/templates. The descriptor must be packaged within an JSON Teamscale Version Container. In case the dashboard with the UUID already exists the existing one will be overridden. This service is public API since Teamscale 8.9.8. Every logged in user is allowed to create dashboards. For dashboards that already exist and should therefore be overwritten the user needs to have EDIT permissions on the dashboard.
	 */
	importAndReplaceDashboards: MutationOperation<{ body: ImportAndReplaceDashboardsBody }, Array<string>>

	/**
	 * Import backup.
	 *
	 * Triggers the import of a backup and returns the job ID. This service is public API since Teamscale 6.1. The API requires the user to have Backup Global Data permissions.
	 */
	importBackup: MutationOperation<{ body: ImportBackupBody }, string>

	/**
	 * Create a dashboard from a dashboard export
	 *
	 * Performs an import of a dashboard. Adds the uploaded descriptor to the list of dashboards/templates. The descriptor must be packaged within an JSON Teamscale Version Container. This service is public API since Teamscale 8.7. Every logged in user is allowed to create dashboards
	 */
	importDashboard: MutationOperation<{ body: ImportDashboardBody }, Array<string>>

	/**
	 * Create a dashboard template
	 *
	 * Performs an import of a dashboard template. Adds the uploaded descriptor to the list of templates. The descriptor must be packaged within an JSON Teamscale Version Container. The API requires the user to have Create Dashboard Templates permissions.
	 */
	importDashboardTemplate: MutationOperation<{ body: ImportDashboardTemplateBody }, Array<string>>

	/**
	 * Import external findings
	 *
	 * Uploads external findings groups and/or descriptions put from a zip archive The API requires the user to have Edit External Findings Schema permissions.
	 */
	importExternalFindings: MutationOperation<{ body: ImportExternalFindingsBody }, void>

	/**
	 * Imports external metrics
	 *
	 * Imports external metrics as a ZIP file. The API requires the user to have Edit External Metrics Schema permissions.
	 */
	importExternalMetrics: MutationOperation<{ body: ImportExternalMetricsBody }, void>

	/**
	 * Import group
	 *
	 * Imports all users of one group from a remote server. Returns the list of imported user names. The API requires the user to have Create Users, Create Groups permissions.
	 * @param groupName The name of the group that should be imported.
	 */
	importGroup: MutationOperation<{ pathParams: { groupName: string }, queryParams: ImportGroupQueryParams }, Array<string>>

	/**
	 * Upload Sonar profile
	 *
	 * Uploads Sonar Quality Profiles which are then converted to Teamscale analysis profiles. Returns a textual summary of the import process The API requires the user to have Create Analysis Profiles permissions.
	 */
	importSonarProfile: MutationOperation<{ body: ImportSonarProfileBody }, SonarQualityProfileImportSummary>

	/**
	 * Upload SwiftLint Configuration
	 *
	 * Uploads SwiftLint Configurations which are then converted to Teamscale analysis profiles. Returns a textual summary of the import process The API requires the user to have Create Analysis Profiles permissions.
	 */
	importSwiftLintConfiguration: MutationOperation<{ body: ImportSwiftLintConfigurationBody }, SwiftLintConfigurationImportSummary>

	/**
	 * Import user
	 *
	 * Imports one user from a remote server. Returns the name of the imported user. The API requires the user to have Create Users permissions.
	 * @param userName The name of the user that should be imported.
	 */
	importUser: MutationOperation<{ pathParams: { userName: string }, queryParams: ImportUserQueryParams }, string>

	/**
	 * Availability of merge requests
	 *
	 * Returns true if merge requests are configured for the project The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	isMergeRequestsEnabled: MutationOperation<{ pathParams: { project: string } }, boolean>

	/**
	 * Reports if snapshot backups are supported
	 *
	 * Returns if snapshot backups are supported by the storage system. The API requires the user to have Access Administrative Services permissions.
	 */
	isSnapshotBackupSupported: MutationOperation<void, boolean>

	/**
	 * Availability of spec-item analysis
	 *
	 * Determines whether spec-item analysis is enabled for the project The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	isSpecItemAnalysisEnabled: MutationOperation<{ pathParams: { project: string } }, boolean>

	/**
	 * List parse log of project
	 *
	 * Lists all parse log entries for the project. The API requires the user to have View System Status permissions.
	 * @param project The project ID.
	 */
	listAllParseLogEntries: MutationOperation<{ pathParams: { project: string }, queryParams: ListAllParseLogEntriesQueryParams }, Array<ParseLogEntry>>

	/**
	 * List of merge requests, total number and existing status.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	listMergeRequests: MutationOperation<{ pathParams: { project: string }, queryParams: ListMergeRequestsQueryParams }, ExtendedMergeRequestsInfo>

	/**
	 * Get quality reports
	 *
	 * Returns all stored reports details, without slide contents. No permissions needed, as the service will only return reports visible to current user.
	 */
	listQualityReports: MutationOperation<{ queryParams: ListQualityReportsQueryParams }, Array<UserResolvedQualityReport>>

	/**
	 * Load the list of tests of this job from a named test list.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	loadFromNamedTestList: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: LoadFromNamedTestListQueryParams }, number>

	/**
	 * Mark/Unmark a dashboard as user favorite
	 *
	 * Performs an operation on the dashboard favorite list of the current user, whether to add/remove a dashboard to/from the list. The API requires no permissions
	 * @param dashboardId Contains the name of the dashboard
	 */
	markDashboardAsFavorite: MutationOperation<{ pathParams: { dashboardId: string }, queryParams: MarkDashboardAsFavoriteQueryParams }, void>

	/**
	 * Performs the login of a user.
	 *
	 * Authenticates the user based on given credentials and returns a session cookie in case of success. The API requires no login
	 */
	performLogin: MutationOperation<{ body: Credentials }, void>

	/**
	 * Perform test query
	 *
	 * Retrieves the size and list of tests for the query. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	performTestQuery: MutationOperation<{ pathParams: { project: string }, queryParams: PerformTestQueryQueryParams }, TestQueryResult>

	/**
	 * Apply basic role change for instance
	 *
	 * Applies the instance's role changes sent to the sever. Requires the basic permission to \"Assign Roles\"
	 * @param instance The instance&#39;s id
	 */
	postBasicRoleForInstance: MutationOperation<{ pathParams: { instance: string }, queryParams: PostBasicRoleForInstanceQueryParams, body: RoleChange }, void>

	/**
	 * Apply basic role change globally
	 *
	 * Applies the role changes sent to the sever. The API requires the user to have Assign Global Roles permissions.
	 */
	postBasicRoleGlobally: MutationOperation<{ queryParams: PostBasicRoleGloballyQueryParams, body: RoleChange }, void>

	/**
	 * Create global role
	 *
	 * Creates a new global role. The API requires the user to have Edit Roles permissions.
	 */
	postGlobalRole: MutationOperation<{ body: GlobalRole }, void>

	/**
	 * Apply role change for global role assignments
	 *
	 * Updates the role assignments with the given changes. The API requires the user to have Assign Global Roles permissions.
	 */
	postGlobalRoleWithAssignments: MutationOperation<{ body: RoleChange }, void>

	/**
	 * Sets the list of tests to minimize in the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	postJobTests: MutationOperation<{ pathParams: { project: string, jobId: string }, body: Array<string> }, number>

	/**
	 * Create project role
	 *
	 * Creates a new project role. The API requires the user to have Edit Roles permissions.
	 */
	postProjectRole: MutationOperation<{ body: ProjectRole }, void>

	/**
	 * Resolve username or alias
	 *
	 * Retrieves the usernames for the supplied aliases, usernames, full names or e-mail addresses. For users which cannot be resolved, the supplied value is returned back. This API requires VIEW permission for every resolved user. If the permission is missing, the supplied value is returned back.
	 */
	postResolveUserAliases: MutationOperation<{ body: PostResolveUserAliasesBody }, Array<string>>

	/**
	 * Apply role change for project role assignments globally
	 *
	 * Processes the post request and updates the role assignments according to the {@link RoleChange} globally. The API requires the user to have Assign Global Roles permissions.
	 */
	postRoleChangeGlobally: MutationOperation<{ body: RoleChange }, void>

	/**
	 * Get test gap percentage
	 *
	 * Calculates the TGA percentage for the given uniform path or the given issue ID. This service is public API since Teamscale 5.9.6. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	postTgaPercentage: MutationOperation<{ pathParams: { project: string }, queryParams: PostTgaPercentageQueryParams, body: PostTgaPercentageBody }, number>

	/**
	 * Posts user avatar image
	 *
	 * Uploads the image file given in the multipart called 'avatar' as the avatar of the user in the first path parameter. Users can change their own avatar only but admin users can change someone else's avatar
	 * @param user 
	 */
	postUserAvatarData: MutationOperation<{ pathParams: { user: string }, body: PostUserAvatarDataBody }, void>

	/**
	 * Retrieves branches from VCSs
	 *
	 * Returns set of all branches for a given VCS connector The API requires no permissions
	 */
	previewRepositoryBranches: MutationOperation<{ queryParams: PreviewRepositoryBranchesQueryParams, body: ConnectorConfiguration }, Array<string>>

	/**
	 * Retrieves commit messages from VCSs
	 *
	 * Returns latest commit messages for a given branch for a given VCS connector The API requires no permissions
	 */
	previewRepositoryCommitMessages: MutationOperation<{ queryParams: PreviewRepositoryCommitMessagesQueryParams, body: ConnectorConfiguration }, Array<string>>

	/**
	 * Retrieves files from VCSs
	 *
	 * Returns list of all files for a given VCS connector existing on the default branch at HEAD The API requires no permissions
	 */
	previewRepositoryFiles: MutationOperation<{ queryParams: PreviewRepositoryFilesQueryParams, body: ConnectorConfiguration }, Array<string>>

	/**
	 * Handles the incoming requests from code collaboration platforms signaling an update in the status of build jobs.
	 *
	 * Handles the incoming requests from code collaboration platforms signaling the start or completion of a build job.The service identifies the merge request corresponding to the provided revision and triggers the merge request update process. The API requires no login
	 * @param project The project ID.
	 */
	processBuildWebhookEvent: MutationOperation<{ pathParams: { project: string }, queryParams: ProcessBuildWebhookEventQueryParams }, void>

	/**
	 * Handles incoming requests from Gitea.
	 *
	 * Handles the incoming requests from Gitea and processes the request body depending on the event. The events which are handled by the service are 'push' and 'pull_request'. The API requires no login
	 */
	processWebhook: MutationOperation<{ body: Array<string> }, void>

	/**
	 * Puts new crop dimensions for user avatar
	 *
	 * Tries to set new crop dimensions for the avatar of the user in the first path parameter. The API requires the user to have Edit permissions on the user.
	 * @param user 
	 */
	putAvatarData: MutationOperation<{ pathParams: { user: string }, queryParams: PutAvatarDataQueryParams }, AvatarData>

	/**
	 * Update global role
	 *
	 * Updates/renames an existing global role. Or creates it as a new role if the parameter for the old global role name is null or an empty string. The API requires the user to have Edit Roles permissions.
	 * @param name The name of the role to update/rename.
	 */
	putGlobalRole: MutationOperation<{ pathParams: { name: string }, body: GlobalRole }, void>

	/**
	 * Append the list of impacted tests as tests to minimize to the job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	putJobTestsFromImpacted: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: PutJobTestsFromImpactedQueryParams }, number>

	/**
	 * Append the list of tests to minimize to the job based on a query.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	putJobTestsFromQuery: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: PutJobTestsFromQueryQueryParams }, number>

	/**
	 * Update project role
	 *
	 * Updates/renames an existing project role. Or creates it as a new role if the parameter for the old global role name is null or an empty string. The API requires the user to have Edit Roles permissions.
	 * @param name The name of the role to update/rename.
	 */
	putProjectRole: MutationOperation<{ pathParams: { name: string }, body: ProjectRole }, void>

	/**
	 * Receive an \"analysis completed\" notification from the clang-tidy execution server
	 *
	 * Receiver for the \"analysis completed\" notification from execution server to Teamscale server. After the notification, this Teamscale server will query the analysis results from the execution server.  The API requires the user to have Access Administrative Services permissions.
	 */
	receiveAnalysisCompletedConfirmation: MutationOperation<{ queryParams: ReceiveAnalysisCompletedConfirmationQueryParams }, void>

	/**
	 * Profiler heartbeat
	 *
	 * Updates the profiler infos and sets the profiler to still alive. This service is public API since Teamscale 9.4. The user needs to have VIEW permissions on the configuration this profiler is using.
	 * @param profilerId The ID of the profiler to update.
	 */
	receiveHeartbeat: MutationOperation<{ pathParams: { profilerId: string }, body: ProfilerInfo }, void>

	/**
	 * Redirect to the example hosting host
	 *
	 * Redirects to /example on the instance, which hosts the actual example. The API requires no permissions
	 */
	redirectToCorrectHost: MutationOperation<{ queryParams: RedirectToCorrectHostQueryParams }, void>

	/**
	 * Generate Service Provider certificate
	 *
	 * Generates a new certificate for the service provider stored at the named option. The API requires the user to have Access Administrative Services permissions.
	 */
	regenerateStoredServiceProviderCertificate: MutationOperation<{ queryParams: RegenerateStoredServiceProviderCertificateQueryParams }, boolean>

	/**
	 * Registers a profiler instance
	 *
	 * Registers a profiler to Teamscale and returns the profiler configuration it should be started with. This service is public API since Teamscale 9.4. The API requires the user to have View permissions on the Profiler Configuration .
	 */
	registerProfiler: MutationOperation<{ queryParams: RegisterProfilerQueryParams, body: ProcessInformation }, ProfilerRegistration>

	/**
	 * Revoke Access Keys
	 *
	 * Revokes the given access key of the user. The API requires the user to have Edit permissions on the user.
	 * @param user 
	 */
	removeAccessKeys: MutationOperation<{ pathParams: { user: string }, queryParams: RemoveAccessKeysQueryParams }, void>

	/**
	 * Delete report slides
	 *
	 * Deletes the given slides from an existing report. The API requires the user to have Edit permissions on the Quality Report reportId.
	 * @param reportId The id of the report that is edited.
	 */
	removeQualityReportSlides: MutationOperation<{ pathParams: { reportId: string }, body: Array<string> }, void>

	/**
	 * Report JavaScript error
	 *
	 * Writes the given JavaScript error that occurred in the Web UI into the Teamscale's JavaScript error log. The API requires no permissions
	 */
	reportJavascriptError: MutationOperation<{ body: JavaScriptError }, void>

	/**
	 * Request a ClangTidy analysis
	 *
	 * Opens and schedules a new ClangTidy analysis session, returning the session key. The API requires the user to have Create Projects permissions.
	 */
	requestAnalysisAndOpenSession: MutationOperation<{ queryParams: RequestAnalysisAndOpenSessionQueryParams, body: ClangTidyOutsourcedAnalysisRequestParameters }, string>

	/**
	 * Request a SonarLint analysis
	 *
	 * Opens and schedules a new SonarLint analysis session, returning the session key. The API requires the user to have Create Projects permissions.
	 */
	requestAnalysisAndOpenSession1: MutationOperation<{ queryParams: RequestAnalysisAndOpenSession1QueryParams, body: SonarLintIndependentAnalysisRequestParameters }, string>

	/**
	 * Get an AI generated finding resolution suggestion
	 *
	 * Retrieves the AI generated resolution for a finding. The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param engine The name of the configured AI engine to use.
	 * @param id The id of the finding to find a resolution for.
	 */
	resolveFinding: MutationOperation<{ pathParams: { project: string, engine: string, id: string }, queryParams: ResolveFindingQueryParams }, FindingResolutionResult>

	/**
	 * Get the target commit of a git tag
	 *
	 * Resolves the given git tag to the tagged commit. The API requires the user to have View Project permissions on the project.
	 * @param project 
	 */
	resolveTag: MutationOperation<{ pathParams: { project: string }, body: GitTag }, CommitDescriptor | null>

	/**
	 * Re-vote on all open merge requests
	 *
	 * Re-votes on all open merge requests in the project. This can be used to manually trigger voting or commenting. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project ID.
	 */
	revoteOnAllOpenMergeRequests: MutationOperation<{ pathParams: { project: string } }, void>

	/**
	 * Re-vote on a specific open merge request
	 *
	 * Re-votes on a specific open merge request in the project. This can be used to manually trigger voting or commenting. The API requires the user to have Access Administrative Services permissions.
	 * @param project The project ID.
	 * @param idWithRepository 
	 */
	revoteOnMergeRequest: MutationOperation<{ pathParams: { project: string, idWithRepository: string } }, void>

	/**
	 * Run database compaction
	 *
	 * Allows running a full compaction on the database. WARNING: This is likely a very expensive operation. The API requires the user to have Access Administrative Services permissions.
	 */
	runDatabaseCompaction: MutationOperation<void, void>

	/**
	 * Saves the list of tests created along this job as a named test list.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	saveAsNamedTestList: MutationOperation<{ pathParams: { project: string, jobId: string }, body: string }, number>

	/**
	 * Save a given test list
	 *
	 * Creates a test list descriptor in the system. The API requires the user to have Edit Test Queries permissions on the project.
	 * @param project The project ID.
	 */
	saveTestListDescriptor: MutationOperation<{ pathParams: { project: string }, body: TestListDescriptor }, void>

	/**
	 * Set project notification rules
	 *
	 * Edits the currently set project notification rules The API requires the user to have Edit Global Notification Settings permissions.
	 */
	setProjectNotificationRules: MutationOperation<{ body: ProjectNotificationRules }, void>

	/**
	 * Set scheduler filtering status
	 *
	 * Sets the filtering status of the scheduler The API requires the user to have Access Administrative Services permissions.
	 */
	setSchedulingMode: MutationOperation<{ body: string }, void>

	/**
	 * Request the computation of a minimized test set.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 */
	setupJob: MutationOperation<{ pathParams: { project: string }, queryParams: SetupJobQueryParams }, string>

	/**
	 * Default Admin Enabled
	 *
	 * Checks whether Teamscale has the default admin user enabled when it shouldn't. The API requires no permissions
	 */
	showDefaultAdminWarning: MutationOperation<void, boolean>

	/**
	 * Default Backup Settings
	 *
	 * Checks whether Teamscale has default backup settings enabled, which were not yet acknowledged by an admin user.  The API requires no permissions
	 */
	showDefaultBackupSettingsWarning: MutationOperation<void, boolean>

	/**
	 * In Memory Database
	 *
	 * Checks whether Teamscale operates on an in memory database when it shouldn't. The API requires no permissions
	 */
	showInMemoryWarning: MutationOperation<void, boolean>

	/**
	 * Shadow Mode
	 *
	 * Checks whether Teamscale has the shadow mode enabled. The API requires no permissions
	 */
	showShadowModeWarning: MutationOperation<void, boolean>

	/**
	 * Shutdown Teamscale
	 *
	 * Allows to shut down a running Teamscale instance The API requires the user to have Access Administrative Services permissions.
	 */
	shutdown: MutationOperation<{ body: ShutdownBody }, void>

	/**
	 * Starts the given test minimization job.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	startJob: MutationOperation<{ pathParams: { project: string, jobId: string } }, void>

	/**
	 * Stops and deletes the given test minimization job and its results.
	 *
	 * null The API requires the user to have Perform Test Ranking permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	stopAndDeleteJob: MutationOperation<{ pathParams: { project: string, jobId: string } }, void>

	/**
	 * Store event announcement
	 *
	 * Stores the given event announcement. The API requires the user to have Access Administrative Services permissions.
	 * @param eventId 
	 */
	storeEventAnnouncement: MutationOperation<{ pathParams: { eventId: string }, body: EventAnnouncement }, void>

	/**
	 * Synchronize groups
	 *
	 * Synchronizes all groups with a remote server. Will also automatically update all users in the groups. In case groups are deleted on the server, the process will remove all users (and additional remote server information) from them, but will not delete them, in order to preserve access rights. Returns a list containing the names of the synchronized groups. The API requires the user to have Create Users, Create Groups permissions.
	 */
	synchronizeAllGroups: MutationOperation<{ queryParams: SynchronizeAllGroupsQueryParams }, Array<string>>

	/**
	 * Synchronize users
	 *
	 * Synchronizes all users with a remote server. Returns the names of updated users. The API requires the user to have Create Users permissions.
	 */
	synchronizeAllUsers: MutationOperation<{ queryParams: SynchronizeAllUsersQueryParams }, Array<string>>

	/**
	 * Synchronize group
	 *
	 * Synchronizes the given group with a remote server. Will also automatically update all users in the group. In case the group is deleted on the server, the process will remove all users (and additional remote server information) from it, but will not delete it, in order to preserve access rights. The API requires the user to have Create Users, Create Groups permissions.
	 * @param groupName The name of the group that should be synchronized.
	 */
	synchronizeSingleGroup: MutationOperation<{ pathParams: { groupName: string }, queryParams: SynchronizeSingleGroupQueryParams }, void>

	/**
	 * Starts a snapshot backup.
	 *
	 * Schedules a snapshot backup to the given target URI. The API requires the user to have Access Administrative Services permissions.
	 */
	triggerSnapshotBackup: MutationOperation<{ body: TriggerSnapshotBackupBody }, void>

	/**
	 * Unmark tolerated/false positive findings before given commit.
	 *
	 * Unmark all tolerated/false positive findings that were marked before the given commit. Also returns the ids of the unmarked findings. The API requires the user to have View Project, Flag Red Findings, Flag Yellow Findings permissions on the project.
	 * @param project The project ID.
	 */
	unmarkToleratedAndFalsePositiveFindingsBeforeCommit: MutationOperation<{ pathParams: { project: string }, queryParams: UnmarkToleratedAndFalsePositiveFindingsBeforeCommitQueryParams }, Array<string>>

	/**
	 * Unregister profiler
	 *
	 * Removes the profiler identified by given ID. This service is public API since Teamscale 9.4. The user needs to have VIEW permissions on the configuration this profiler is using.
	 * @param profilerId The ID of the profiler to unregister.
	 */
	unregisterProfiler: MutationOperation<{ pathParams: { profilerId: string } }, void>

	/**
	 * Update analysis profile
	 *
	 * Updates the analysis profile identified by the given name with the value in the request body. Requires the edit permission on the respective analysis profile.
	 */
	updateAnalysisProfile: MutationOperation<{ queryParams: UpdateAnalysisProfileQueryParams, body: AnalysisProfile }, void>

	/**
	 * Update dashboard template
	 *
	 * Updates the given dashboard template The API requires the user to have Edit permissions on the Dashboard Template templateId.
	 * @param templateId ID of the template to update
	 */
	updateDashboardTemplate: MutationOperation<{ pathParams: { templateId: string }, body: DashboardTemplateDescriptor }, void>

	/**
	 * Update external accounts
	 *
	 * Updates the external accounts identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the External Account externalCredentialsName.
	 * @param externalCredentialsName Name of the old external accounts.
	 */
	updateExternalAccounts: MutationOperation<{ pathParams: { externalCredentialsName: string }, queryParams: UpdateExternalAccountsQueryParams, body: ExternalCredentialsData }, void>

	/**
	 * Update external analysis group
	 *
	 * Updates the external analysis group identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
	 * @param groupName Name of the external analysis group to remove
	 */
	updateExternalAnalysisGroup: MutationOperation<{ pathParams: { groupName: string }, body: ExternalAnalysisGroup }, void>

	/**
	 * Update external finding description
	 *
	 * Update the external finding description identified by the given name. The API requires the user to have Edit External Findings Schema permissions.
	 * @param externalFindingDescriptionTypeId ID of the finding description to update
	 */
	updateExternalFindingDescription: MutationOperation<{ pathParams: { externalFindingDescriptionTypeId: string }, body: ExternalFindingsDescription }, void>

	/**
	 * Update metrics
	 *
	 * Updates a set of external metrics The API requires the user to have Edit External Metrics Schema permissions.
	 */
	updateExternalMetrics: MutationOperation<{ body: Array<MetricSchemaChangeEntry> }, void>

	/**
	 * Update external storage backends
	 *
	 * Updates the external storage backend identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the External Storage Backend externalStorageBackendName.
	 * @param externalStorageBackendName Name of the external storage backend which should be updated.
	 */
	updateExternalStorageBackend: MutationOperation<{ pathParams: { externalStorageBackendName: string }, body: ExternalStorageBackendData }, void>

	/**
	 * Updates the minimization options for the given job.
	 *
	 * null The API requires the user to have View Project permissions on the project.
	 * @param project The project ID.
	 * @param jobId 
	 */
	updateJobOptions: MutationOperation<{ pathParams: { project: string, jobId: string }, queryParams: UpdateJobOptionsQueryParams }, void>

	/**
	 * Update metric threshold configuration
	 *
	 * Updates the metric threshold configuration identified by the given name with the value in the request body. Requires the edit permission for the respective metric threshold configuration.
	 */
	updateMetricThresholdConfiguration: MutationOperation<{ body: MetricThresholdConfiguration }, void>

	/**
	 * Update password
	 *
	 * Updates the user password The API requires the user to have Edit permissions on the User username.
	 * @param username The ID of the user the user needs access to.
	 */
	updatePassword: MutationOperation<{ pathParams: { username: string }, body: PasswordChangeRequest }, void>

	/**
	 * Update profiler configuration
	 *
	 * Updates an existing profiler configuration. The API requires the user to have Edit permissions on the Profiler Configuration configurationId.
	 * @param configurationId The ID of the dashboard to update.
	 */
	updateProfilerConfiguration: MutationOperation<{ pathParams: { configurationId: string }, body: ProfilerConfiguration }, void>

	/**
	 * Updates quality report
	 *
	 * Updates a quality report's metadata such as title and global settings like default project and threshold profile. The API requires the user to have Edit permissions on the Quality Report id.
	 * @param id The ID of the report that gets an update.
	 */
	updateQualityReport: MutationOperation<{ pathParams: { id: string }, body: QualityArtifactParameters }, void>

	/**
	 * Update report slide
	 *
	 * Updates an existing slide in a quality report. The API requires the user to have Edit permissions on the Quality Report id.
	 * @param id The ID of the report that is updated.
	 */
	updateQualityReportSlides: MutationOperation<{ pathParams: { id: string }, body: Array<ReportSlideData> }, void>

	/**
	 * Update retrospective
	 *
	 * Updates an existing retrospective. The API requires the user to have View Project, Edit Retrospectives permissions on the project.
	 * @param project The project ID.
	 * @param id The id of the retrospective that gets an update.
	 */
	updateRetrospective: MutationOperation<{ pathParams: { project: string, id: string }, body: QualityArtifactParameters }, void>

	/**
	 * Update retrospective
	 *
	 * Updates an existing retrospective. The API requires the user to have View Project, Edit Retrospectives permissions on the project.
	 * @param project The project ID.
	 * @param id The id of the retrospective that gets an update.
	 */
	updateRetrospectiveNotes: MutationOperation<{ pathParams: { project: string, id: string }, body: string }, void>

	/**
	 * Update task
	 *
	 * Updates the task with the given ID. The API requires the user to have Edit Tasks permissions on the project.
	 * @param project The project ID.
	 * @param id Task id
	 */
	updateTask: MutationOperation<{ pathParams: { project: string, id: number }, queryParams: UpdateTaskQueryParams, body: Task }, Task>

	/**
	 * Update task resolution
	 *
	 * Updates the resolution of the task with the given ID. The API requires the user to have Update Task Resolution permissions on the project.
	 * @param project The project ID.
	 * @param id Task id
	 */
	updateTaskResolution: MutationOperation<{ pathParams: { project: string, id: number }, body: string }, Task>

	/**
	 * Update task status
	 *
	 * Updates the status of the task with the given ID. The API requires the user to have Update Task Status permissions on the project.
	 * @param project The project ID.
	 * @param id Task id
	 */
	updateTaskStatus: MutationOperation<{ pathParams: { project: string, id: number }, body: string }, Task>

	/**
	 * Update user group
	 *
	 * Updates the user group identified by the given name with the value in the request body. The API requires the user to have Edit permissions on the Group oldUserGroupName.
	 * @param oldUserGroupName Old name of the user group to change
	 */
	updateUserGroup: MutationOperation<{ pathParams: { oldUserGroupName: string }, body: UserGroup }, void>

	/**
	 * Upload architecture
	 *
	 * Imports an architecture file. This service is public API since Teamscale 5.9. The API requires the user to have Edit Architectures permissions on the project.
	 * @param project The project ID.
	 */
	uploadArchitecture: MutationOperation<{ pathParams: { project: string }, queryParams: UploadArchitectureQueryParams, body: Record<string, File> }, void>

	/**
	 * Upload architecture
	 *
	 * Saves an architecture definition. The API requires the user to have Edit Architectures permissions on the project.
	 * @param project The project ID.
	 */
	uploadArchitectureAsJson: MutationOperation<{ pathParams: { project: string }, queryParams: UploadArchitectureAsJsonQueryParams, body: ArchitectureInfoWithUniformPath }, void>

	/**
	 * Upload snapshot
	 *
	 * Uploads an external snapshot for comparison The API requires the user to have Access Administrative Services permissions.
	 */
	uploadExternalSnapshot: MutationOperation<{ body: UploadExternalSnapshotBody }, void>

	/**
	 * Upload external report(s)
	 *
	 * Adds external reports to the session. For performance reasons, it is recommended to batch calls to this service, i.e. not commit all files using single calls. This service is public API since Teamscale 5.9. The API requires the user to have Perform External Uploads permissions on the project.
	 * @param project The project ID.
	 * @param sessionId If session ID is provided, the results will be appended to the given session instead of creating a new session. Use \&quot;auto-create\&quot; in place of session ID to create a new session, perform upload and commit session in one step.
	 */
	uploadReport: MutationOperation<{ pathParams: { project: string, sessionId: string }, queryParams: UploadReportQueryParams, body: UploadReportBody }, void>

	/**
	 * Stores the passed image and returns a unique key to reference it in future scenarios
	 *
	 * null The API requires the user to have Edit permissions on the Quality Report reportId.
	 * @param reportId The ID of the quality report the user needs access to.
	 */
	uploadSlideImage: MutationOperation<{ pathParams: { reportId: string }, body: UploadSlideImageBody }, string>

	/**
	 * Validates a branching configuration
	 *
	 * Validates a given branching configuration. User must either have the global permission to create projects, or the permission to view any existing project.
	 */
	validateBranchingConfiguration: MutationOperation<{ body: ProjectBranchingConfiguration }, string | null>

	/**
	 * Verifies that there is a PowerShellScriptAnalyzer installed on the server, has the required  version, and can be called by Teamscale.
	 *
	 * Verifies the PowerShellScriptAnalyzer installation on the server. The API requires the user to have Create Analysis Profiles permissions.
	 */
	verifyPowershellScriptAnalyzerSetup: MutationOperation<void, string>

}

/** Maps operation IDs to the actual path, method and expected Content-Type. */
export const urlMapping = { 
	addCommentToTask: {
		method: 'POST',
		path: '/api/projects/{project}/tasks/{task}/comments',
		contentType: 'application/json'
	},
	addExternalMetrics: {
		method: 'POST',
		path: '/api/external-metrics',
		contentType: 'application/json'
	},
	addQualityReportSlides: {
		method: 'POST',
		path: '/api/quality-reports/{reportId}/add-slides',
		contentType: 'application/json'
	},
	autocompleteIssueId: {
		method: 'GET',
		path: '/api/projects/{project}/issues/find'
	},
	autocompleteUserGroup: {
		method: 'GET',
		path: '/api/user-groups/find'
	},
	autocompleteUserName: {
		method: 'GET',
		path: '/api/users/find'
	},
	calculateActiveUsersStatistics: {
		method: 'GET',
		path: '/api/user-activity-history'
	},
	calculateCommitterStatistics: {
		method: 'GET',
		path: '/api/committer-activity-history'
	},
	cancelTrigger: {
		method: 'PUT',
		path: '/api/execution-status/workers/{workerId}/cancel',
		contentType: 'application/json'
	},
	clearResultsCache: {
		method: 'DELETE',
		path: '/api/analysis/clangtidy-execution/clearResultsCache'
	},
	commitAnalysisResults: {
		method: 'POST',
		path: '/api/projects/{project}/external-analysis/session/{sessionId}'
	},
	computeMetricTable: {
		method: 'GET',
		path: '/api/projects/{project}/metrics/table'
	},
	createAnalysisProfile: {
		method: 'POST',
		path: '/api/analysis-profiles',
		contentType: 'application/json'
	},
	createBackup: {
		method: 'POST',
		path: '/api/backups/export',
		contentType: 'application/x-www-form-urlencoded'
	},
	createComparison: {
		method: 'POST',
		path: '/api/instance-comparison/snapshots/{snapshotId}/comparisons',
		contentType: 'application/x-www-form-urlencoded'
	},
	createDashboard: {
		method: 'POST',
		path: '/api/dashboards',
		contentType: 'application/json'
	},
	createDashboardFromTemplate: {
		method: 'POST',
		path: '/api/dashboards/from-template'
	},
	createDashboardTemplate: {
		method: 'POST',
		path: '/api/dashboard-templates',
		contentType: 'application/json'
	},
	createExternalAccounts: {
		method: 'POST',
		path: '/api/external-accounts',
		contentType: 'application/json'
	},
	createExternalAnalysisGroup: {
		method: 'POST',
		path: '/api/external-findings/groups',
		contentType: 'application/json'
	},
	createExternalFindingDescription: {
		method: 'POST',
		path: '/api/external-findings/descriptions',
		contentType: 'application/json'
	},
	createExternalStorageBackend: {
		method: 'POST',
		path: '/api/external-storage-backends',
		contentType: 'application/json'
	},
	createMetricThresholdConfiguration: {
		method: 'POST',
		path: '/api/metric-threshold-configurations',
		contentType: 'application/json'
	},
	createNewRandomAccessKey: {
		method: 'POST',
		path: '/api/users/{user}/access-key'
	},
	createOrUpdateBaseline: {
		method: 'PUT',
		path: '/api/projects/{project}/baselines/{baseline}',
		contentType: 'application/json'
	},
	createProfilerConfiguration: {
		method: 'POST',
		path: '/api/profiler-configurations',
		contentType: 'application/json'
	},
	createProject: {
		method: 'POST',
		path: '/api/projects',
		contentType: 'application/json'
	},
	createQualityReport: {
		method: 'POST',
		path: '/api/quality-reports',
		contentType: 'application/json'
	},
	createRetrospective: {
		method: 'POST',
		path: '/api/projects/{project}/retrospectives',
		contentType: 'application/json'
	},
	createSession: {
		method: 'POST',
		path: '/api/projects/{project}/external-analysis/session'
	},
	createSnapshot: {
		method: 'POST',
		path: '/api/instance-comparison/snapshots',
		contentType: 'application/x-www-form-urlencoded'
	},
	createSupportRequest: {
		method: 'POST',
		path: '/api/support-request',
		contentType: 'application/json'
	},
	createTask: {
		method: 'POST',
		path: '/api/projects/{project}/tasks',
		contentType: 'application/json'
	},
	createUserGroup: {
		method: 'POST',
		path: '/api/user-groups',
		contentType: 'application/json'
	},
	deleteAllUnprocessedArchitectureCommits: {
		method: 'DELETE',
		path: '/api/projects/{project}/architectures/all-unprocessed-uploads'
	},
	deleteAnalysisProfile: {
		method: 'DELETE',
		path: '/api/analysis-profiles/{analysisProfileName}'
	},
	deleteAnalysisResults: {
		method: 'DELETE',
		path: '/api/projects/{project}/external-analysis/session/{sessionId}'
	},
	deleteBaseline: {
		method: 'DELETE',
		path: '/api/projects/{project}/baselines/{baseline}'
	},
	deleteComparison: {
		method: 'DELETE',
		path: '/api/instance-comparison/snapshots/{snapshotId}/comparisons/{comparisonId}'
	},
	deleteDashboard: {
		method: 'DELETE',
		path: '/api/dashboards/{dashboardId}'
	},
	deleteDashboardTemplate: {
		method: 'DELETE',
		path: '/api/dashboard-templates/{templateId}'
	},
	deleteEventAnnouncement: {
		method: 'DELETE',
		path: '/api/event-announcement/delete/{eventId}'
	},
	deleteExternalAccounts: {
		method: 'DELETE',
		path: '/api/external-accounts/{externalCredentialsName}'
	},
	deleteExternalAnalysisGroup: {
		method: 'DELETE',
		path: '/api/external-findings/groups/{groupName}'
	},
	deleteExternalFindingDescription: {
		method: 'DELETE',
		path: '/api/external-findings/descriptions/{externalFindingDescriptionName}'
	},
	deleteExternalMetric: {
		method: 'DELETE',
		path: '/api/external-metrics/{metricName}'
	},
	deleteExternalSnapshot: {
		method: 'DELETE',
		path: '/api/instance-comparison/snapshots/external/{name}'
	},
	deleteExternalStorageBackend: {
		method: 'DELETE',
		path: '/api/external-storage-backends/{externalStorageBackendName}'
	},
	deleteGlobalCriticalEventLogs: {
		method: 'DELETE',
		path: '/api/logs/critical-events'
	},
	deleteGlobalInteractionLogs: {
		method: 'DELETE',
		path: '/api/logs/interaction'
	},
	deleteGlobalJavascriptErrorLogs: {
		method: 'DELETE',
		path: '/api/logs/javascript-errors'
	},
	deleteGlobalRole: {
		method: 'DELETE',
		path: '/api/roles/global-roles/{name}'
	},
	deleteGlobalServiceLogs: {
		method: 'DELETE',
		path: '/api/logs/service'
	},
	deleteGlobalWorkerLogs: {
		method: 'DELETE',
		path: '/api/logs/worker'
	},
	deleteJobTests: {
		method: 'DELETE',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests',
		contentType: 'application/json'
	},
	deleteMetricThresholdConfiguration: {
		method: 'DELETE',
		path: '/api/metric-threshold-configurations/{metricThresholdConfigurationName}'
	},
	deleteProfilerConfiguration: {
		method: 'DELETE',
		path: '/api/profiler-configurations/{configurationId}'
	},
	deleteProjectCriticalEventLogs: {
		method: 'DELETE',
		path: '/api/projects/{project}/logs/critical-events'
	},
	deleteProjectInteractionLogs: {
		method: 'DELETE',
		path: '/api/projects/{project}/logs/interaction'
	},
	deleteProjectJavascriptErrorLogs: {
		method: 'DELETE',
		path: '/api/projects/{project}/logs/javascript-errors'
	},
	deleteProjectOption: {
		method: 'DELETE',
		path: '/api/projects/{project}/options/{optionId}'
	},
	deleteProjectRole: {
		method: 'DELETE',
		path: '/api/roles/project-roles/{name}'
	},
	deleteProjectServiceLogs: {
		method: 'DELETE',
		path: '/api/projects/{project}/logs/service'
	},
	deleteProjectWorkerLogs: {
		method: 'DELETE',
		path: '/api/projects/{project}/logs/worker'
	},
	deleteQualityReport: {
		method: 'DELETE',
		path: '/api/quality-reports/{id}'
	},
	deleteRetrospective: {
		method: 'DELETE',
		path: '/api/projects/{project}/retrospectives/{id}'
	},
	deleteServerOption: {
		method: 'DELETE',
		path: '/api/options/server/{optionId}'
	},
	deleteSnapshot: {
		method: 'DELETE',
		path: '/api/instance-comparison/snapshots/{id}'
	},
	deleteTestListDescriptor: {
		method: 'DELETE',
		path: '/api/projects/{project}/test-lists/{testListName}'
	},
	deleteUserGroup: {
		method: 'DELETE',
		path: '/api/user-groups/{userGroupName}'
	},
	deleteUserOption: {
		method: 'DELETE',
		path: '/api/users/{user}/options/{optionId}'
	},
	downloadAnalysisProfileDocumentation: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}/documentation'
	},
	downloadBackup: {
		method: 'GET',
		path: '/api/backups/export/{backupId}/download',
		acceptType: 'application/zip'
	},
	downloadGlobalCriticalEventLogs: {
		method: 'GET',
		path: '/api/logs/critical-events/download',
		acceptType: 'text/plain'
	},
	downloadGlobalInteractionLogs: {
		method: 'GET',
		path: '/api/logs/interaction/download',
		acceptType: 'text/plain'
	},
	downloadGlobalJavascriptErrorLogs: {
		method: 'GET',
		path: '/api/logs/javascript-errors/download',
		acceptType: 'text/plain'
	},
	downloadGlobalServiceLogs: {
		method: 'GET',
		path: '/api/logs/service/download',
		acceptType: 'text/plain'
	},
	downloadGlobalWorkerLogs: {
		method: 'GET',
		path: '/api/logs/worker/download',
		acceptType: 'text/plain'
	},
	downloadHeapDump: {
		method: 'POST',
		path: '/api/debug/heap-dump',
		contentType: 'application/x-www-form-urlencoded'
	},
	downloadProjectCriticalEventLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/critical-events/download',
		acceptType: 'text/plain'
	},
	downloadProjectInteractionLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/interaction/download',
		acceptType: 'text/plain'
	},
	downloadProjectJavascriptErrorLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/javascript-errors/download',
		acceptType: 'text/plain'
	},
	downloadProjectServiceLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/service/download',
		acceptType: 'text/plain'
	},
	downloadProjectWorkerLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/worker/download',
		acceptType: 'text/plain'
	},
	downloadSupportRequest: {
		method: 'GET',
		path: '/api/support-request',
		acceptType: 'application/zip'
	},
	downloadTestSelectionCsv: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests/csv',
		acceptType: 'text/csv'
	},
	editDashboard: {
		method: 'PUT',
		path: '/api/dashboards/{dashboardId}',
		contentType: 'application/json'
	},
	editProjectOption: {
		method: 'PUT',
		path: '/api/projects/{project}/options/{optionId}',
		contentType: 'application/json'
	},
	editProjectWithConfiguration: {
		method: 'PUT',
		path: '/api/projects/{project}/configuration',
		contentType: 'application/json'
	},
	editServerOption: {
		method: 'PUT',
		path: '/api/options/server/{optionId}',
		contentType: 'application/json'
	},
	editUserOption: {
		method: 'PUT',
		path: '/api/users/{user}/options/{optionId}',
		contentType: 'application/json'
	},
	editUsers: {
		method: 'POST',
		path: '/api/users',
		contentType: 'application/json'
	},
	executeSchedulerCommand: {
		method: 'POST',
		path: '/api/scheduler',
		contentType: 'application/x-www-form-urlencoded'
	},
	exportAnalysisProfile: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}/export'
	},
	exportDashboard: {
		method: 'GET',
		path: '/api/dashboards/{dashboardId}/export'
	},
	exportDashboardTemplate: {
		method: 'GET',
		path: '/api/dashboard-templates/{templateId}/export'
	},
	exportExternalFindings: {
		method: 'GET',
		path: '/api/external-findings/export',
		acceptType: 'application/zip'
	},
	exportExternalMetrics: {
		method: 'GET',
		path: '/api/external-metrics/export'
	},
	exportImpactedTestsAsCsv: {
		method: 'GET',
		path: '/api/projects/{project}/impacted-tests/csv'
	},
	externalAccountsExist: {
		method: 'GET',
		path: '/api/external-accounts/{externalCredentialsName}'
	},
	externalStorageBackendExists: {
		method: 'GET',
		path: '/api/external-storage-backends/{externalStorageBackendName}'
	},
	extractJobQueue: {
		method: 'GET',
		path: '/api/execution-status/queue'
	},
	findInitialCommit: {
		method: 'GET',
		path: '/api/projects/{project}/initial-commit-finder/{unresolvedCommit}'
	},
	findLogEntriesInRange: {
		method: 'GET',
		path: '/api/projects/{project}/repository-log-range'
	},
	flagFindings: {
		method: 'PUT',
		path: '/api/projects/{project}/findings/flagged',
		contentType: 'application/json'
	},
	getAbsoluteDate: {
		method: 'GET',
		path: '/api/relative-date-resolution'
	},
	getAccessKeysMetadata: {
		method: 'GET',
		path: '/api/users/{user}/access-key'
	},
	getActiveUsersForProjects: {
		method: 'POST',
		path: '/api/user-activity-history',
		contentType: 'application/json'
	},
	getAdditionallyCoveredMethods: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests/additional-coverage'
	},
	getAffectedFilesForCommits: {
		method: 'GET',
		path: '/api/projects/{project}/commits/affected-files'
	},
	getAggregatedTriggerPerformanceMetrics: {
		method: 'GET',
		path: '/api/debug/performance/trigger/aggregate.csv'
	},
	getAllBaselines: {
		method: 'GET',
		path: '/api/projects/{project}/baselines'
	},
	getAllDashboardTemplates: {
		method: 'GET',
		path: '/api/dashboard-templates'
	},
	getAllDashboards: {
		method: 'GET',
		path: '/api/dashboards'
	},
	getAllExternalAccounts: {
		method: 'GET',
		path: '/api/external-accounts'
	},
	getAllExternalAnalysisGroups: {
		method: 'GET',
		path: '/api/external-findings/groups'
	},
	getAllExternalSnapshots: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/external'
	},
	getAllExternalStorageBackends: {
		method: 'GET',
		path: '/api/external-storage-backends'
	},
	getAllFindingsDescriptions: {
		method: 'GET',
		path: '/api/external-findings/descriptions'
	},
	getAllGitTags: {
		method: 'GET',
		path: '/api/projects/{project}/git-tags'
	},
	getAllMetricThresholdConfigurationNames: {
		method: 'GET',
		path: '/api/metric-threshold-configurations/names'
	},
	getAllMetricThresholdConfigurations: {
		method: 'GET',
		path: '/api/metric-threshold-configurations'
	},
	getAllMetricsForThresholdProfiles: {
		method: 'GET',
		path: '/api/metric-threshold-configurations/metrics'
	},
	getAllParseLogOverviewEntries: {
		method: 'GET',
		path: '/api/system/parse-log/overview'
	},
	getAllPartitionDetails: {
		method: 'GET',
		path: '/api/projects/{project}/test-executions/partitions/details'
	},
	getAllProfilerConfigurations: {
		method: 'GET',
		path: '/api/profiler-configurations'
	},
	getAllProjects: {
		method: 'GET',
		path: '/api/projects'
	},
	getAllSnapshotIds: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/ids'
	},
	getAllSubjectRoleAssignments: {
		method: 'GET',
		path: '/api/subject-role-assignments'
	},
	getAllThreadsDumpAsHtml: {
		method: 'GET',
		path: '/api/debug/thread-dump',
		acceptType: 'text/html'
	},
	getAllUserGroupNames: {
		method: 'GET',
		path: '/api/user-groups/names'
	},
	getAllUserGroups: {
		method: 'GET',
		path: '/api/user-groups'
	},
	getAllUsers: {
		method: 'GET',
		path: '/api/users'
	},
	getAnalysisEditHistoryVersionDiff: {
		method: 'GET',
		path: '/api/analysis-profiles/compare-versions/{analysisProfileName}'
	},
	getAnalysisProfile: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}'
	},
	getAnalysisProfileEditVersionHistory: {
		method: 'GET',
		path: '/api/analysis-profiles/history/{analysisProfileName}'
	},
	getAnalysisProfileEditVersionHistoryTimestamps: {
		method: 'GET',
		path: '/api/analysis-profiles/history-timestamps/{analysisProfileName}'
	},
	getAnalysisProfileMetadata: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}/metadata'
	},
	getAnalysisProfileNames: {
		method: 'GET',
		path: '/api/analysis-profiles'
	},
	getAnalysisProfileUsageInfo: {
		method: 'GET',
		path: '/api/analysis-profiles/with-referenced-projects'
	},
	getAnalysisProfileWithProjects: {
		method: 'GET',
		path: '/api/analysis-profiles/{analysisProfileName}/with-referenced-projects'
	},
	getAnalysisResultsByPath: {
		method: 'GET',
		path: '/api/analysis/clangtidy-execution'
	},
	getAnalysisResultsByPath1: {
		method: 'GET',
		path: '/api/analysis/sonarlint'
	},
	getAnalysisStateChangeTiming: {
		method: 'GET',
		path: '/api/debug/performance/state-changes.csv'
	},
	getAnalysisStatus: {
		method: 'GET',
		path: '/api/analysis/clangtidy-execution/status'
	},
	getAnalysisStatus1: {
		method: 'GET',
		path: '/api/analysis/sonarlint/status'
	},
	getAnalysisTools: {
		method: 'GET',
		path: '/api/configuration-tools'
	},
	getAnalysisToolsForProject: {
		method: 'GET',
		path: '/api/configuration-tools/{project}'
	},
	getArchitecturePerformanceStatistics: {
		method: 'GET',
		path: '/api/projects/{project}/architectures/{architecture}/performance',
		acceptType: 'text/plain'
	},
	getAssociatedComparisonIds: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{id}/comparisons/ids'
	},
	getAuthenticatorInformation: {
		method: 'GET',
		path: '/api/authentication'
	},
	getAuthenticatorInformationForUser: {
		method: 'GET',
		path: '/api/authentication/{username}'
	},
	getAvailableServers: {
		method: 'GET',
		path: '/api/auth/servers'
	},
	getAvatarData: {
		method: 'GET',
		path: '/api/avatars/{user}'
	},
	getBackupExportStatus: {
		method: 'GET',
		path: '/api/backups/export/{backupId}/status'
	},
	getBackupImportStatus: {
		method: 'GET',
		path: '/api/backups/import/{backupId}/status'
	},
	getBackupsForImportWizard: {
		method: 'GET',
		path: '/api/import-wizard'
	},
	getBaseline: {
		method: 'GET',
		path: '/api/projects/{project}/baselines/{baseline}'
	},
	getBaselines: {
		method: 'GET',
		path: '/api/baselines'
	},
	getBasicRoles: {
		method: 'GET',
		path: '/api/roles/basic-role-assignments/{instance}'
	},
	getBenchmarkProjects: {
		method: 'GET',
		path: '/api/benchmark-projects'
	},
	getBenchmarkResults: {
		method: 'GET',
		path: '/api/audit/metric-benchmark.csv'
	},
	getBranchForSpecItem: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/{id}/branch'
	},
	getBranchesGetRequest: {
		method: 'GET',
		path: '/api/projects/{project}/branches'
	},
	getBranchesPostRequest: {
		method: 'POST',
		path: '/api/projects/{project}/branches'
	},
	getChangeTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/change-treemap'
	},
	getCodeComponentsTreemapWrapper: {
		method: 'GET',
		path: '/api/projects/{project}/audit/code-components-treemap'
	},
	getCodeOutline: {
		method: 'GET',
		path: '/api/projects/{project}/code-outline/{uniformPath}'
	},
	getCodeReferencesAndTestExecutionMapping: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/references'
	},
	getCodeSearchMatchResultAndTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/audit/code-search/match-list-and-treemap'
	},
	getCommitAlerts: {
		method: 'GET',
		path: '/api/projects/{project}/commit-alerts'
	},
	getCommitsForTask: {
		method: 'GET',
		path: '/api/projects/{project}/tasks/{id}/commits'
	},
	getComparison: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{snapshotId}/comparisons/{comparisonId}'
	},
	getComparisonResultsForContributor: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{snapshotId}/comparisons/{comparisonId}/{project}'
	},
	getCompleteExampleList: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{snapshotId}/comparisons/{comparisonId}/{project}/{contributor}/{diffEntryName}'
	},
	getConcurrencyPerformanceDetails: {
		method: 'GET',
		path: '/api/debug/performance/trigger/concurrency'
	},
	getConfigLanguages: {
		method: 'GET',
		path: '/api/config-languages'
	},
	getConfigLanguagesForProject: {
		method: 'GET',
		path: '/api/config-languages/{project}'
	},
	getConfigurationTemplate: {
		method: 'GET',
		path: '/api/configuration-template'
	},
	getConnectorDescriptors: {
		method: 'GET',
		path: '/api/connector-descriptors'
	},
	getCopyrightLicensesTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/audit/copyright-licenses/treemap'
	},
	getCoverageSources: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps/overview'
	},
	getCurrentSchedulingFilter: {
		method: 'GET',
		path: '/api/scheduler/mode'
	},
	getDashboard: {
		method: 'GET',
		path: '/api/dashboards/{dashboardId}'
	},
	getDashboardTemplateDescriptor: {
		method: 'GET',
		path: '/api/dashboard-templates/{templateId}'
	},
	getDebugContent: {
		method: 'GET',
		path: '/api/projects/{project}/debug/content/{uniformPath}'
	},
	getDeclarationOfAccessibility: {
		method: 'GET',
		path: '/api/accessibility/declaration',
		acceptType: 'text/markdown'
	},
	getDeclarationOfAccessibilityContext: {
		method: 'GET',
		path: '/api/context/accessibility'
	},
	getDefaultPatterns: {
		method: 'GET',
		path: '/api/connectors/default-patterns'
	},
	getDeltaAffectedFiles: {
		method: 'GET',
		path: '/api/projects/{project}/delta/affected-files'
	},
	getDependencyGraph: {
		method: 'GET',
		path: '/api/projects/{project}/audit/dependency-graph-extractor/{uniformPath}'
	},
	getDiffs: {
		method: 'GET',
		path: '/api/compare-elements'
	},
	getDotNetVersions: {
		method: 'GET',
		path: '/api/dot-net-versions'
	},
	getDumpCommitDescriptor: {
		method: 'GET',
		path: '/api/projects/{project}/debug/commit-descriptors',
		acceptType: 'text/plain'
	},
	getDumpCommitTree: {
		method: 'GET',
		path: '/api/projects/{project}/debug/commit-tree',
		acceptType: 'text/plain'
	},
	getDumpCommitTreeNodes: {
		method: 'GET',
		path: '/api/projects/{project}/debug/commit-tree-nodes'
	},
	getEditedServiceProviderConfiguration: {
		method: 'POST',
		path: '/api/auth/saml/sp-configuration',
		contentType: 'application/json'
	},
	getEventAnnouncements: {
		method: 'GET',
		path: '/api/event-announcement/events'
	},
	getExceptionsHierarchyTree: {
		method: 'GET',
		path: '/api/projects/{project}/exceptions-hierarchy/object'
	},
	getExceptionsHierarchyTreeSVG: {
		method: 'GET',
		path: '/api/projects/{project}/exceptions-hierarchy/graph'
	},
	getExternalFileWithPreprocessing: {
		method: 'GET',
		path: '/api/audit/external-x-clones/file/{path}'
	},
	getFileSummary: {
		method: 'GET',
		path: '/api/file-summary'
	},
	getFilesWithIdenticalContent: {
		method: 'GET',
		path: '/api/projects/{project}/audit/files-with-identical-content/{uniformPath}'
	},
	getFilesWithIdenticalNames: {
		method: 'GET',
		path: '/api/projects/{project}/audit/files-with-identical-name/{uniformPath}'
	},
	getFindingChurnCount: {
		method: 'GET',
		path: '/api/projects/{project}/finding-churn/count'
	},
	getFindingDelta: {
		method: 'GET',
		path: '/api/projects/{project}/findings/delta'
	},
	getFindingSiblings: {
		method: 'GET',
		path: '/api/projects/{project}/findings/{id}/siblings'
	},
	getFindingTypeDescriptions: {
		method: 'POST',
		path: '/api/projects/{project}/finding-type-descriptors',
		contentType: 'application/json'
	},
	getFindings: {
		method: 'GET',
		path: '/api/projects/{project}/findings/list'
	},
	getFindingsExport: {
		method: 'GET',
		path: '/api/projects/{project}/findings/list/export/{format}'
	},
	getFindingsSiblings: {
		method: 'GET',
		path: '/api/projects/{project}/findings/previousNextSiblings/{finding}'
	},
	getFindingsSummary: {
		method: 'GET',
		path: '/api/projects/{project}/findings/summary'
	},
	getFindingsTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/findings/treemap'
	},
	getFindingsWithCount: {
		method: 'GET',
		path: '/api/projects/{project}/findings/list/with-count'
	},
	getFirstUIBranchNameGetRequest: {
		method: 'GET',
		path: '/api/projects/{project}/default-branch'
	},
	getFlaggedFindingIds: {
		method: 'GET',
		path: '/api/projects/{project}/findings/flagged/ids'
	},
	getFlaggedFindingInfo: {
		method: 'GET',
		path: '/api/projects/{project}/findings/flagged/{finding}'
	},
	getFlaggedFindings: {
		method: 'GET',
		path: '/api/projects/{project}/findings/flagged'
	},
	getFlaggedFindingsInfos: {
		method: 'POST',
		path: '/api/projects/{project}/findings/flagged/with-ids',
		contentType: 'application/json'
	},
	getGitConnectorIds: {
		method: 'GET',
		path: '/api/projects/{project}/connectors/git/connector-ids'
	},
	getGitHubAppInstallationRepositories: {
		method: 'GET',
		path: '/api/github/repositories'
	},
	getGitHubAppInstallations: {
		method: 'GET',
		path: '/api/github/app-installations'
	},
	getGitHubAppUrls: {
		method: 'GET',
		path: '/api/github/urls'
	},
	getGitTagByName: {
		method: 'GET',
		path: '/api/projects/{project}/git-tags/{tagName}'
	},
	getGlobalAnalysisProgress: {
		method: 'GET',
		path: '/api/analysis-progress'
	},
	getGlobalBranchesGetRequest: {
		method: 'GET',
		path: '/api/branches'
	},
	getGlobalContainerInfo: {
		method: 'GET',
		path: '/api/directories'
	},
	getGlobalCriticalEventLog: {
		method: 'GET',
		path: '/api/logs/critical-events/details/{timestamp}'
	},
	getGlobalCriticalEventLogFrequencies: {
		method: 'GET',
		path: '/api/logs/critical-events/frequencies'
	},
	getGlobalCriticalEventLogs: {
		method: 'GET',
		path: '/api/logs/critical-events'
	},
	getGlobalFirstUIBranchGetRequest: {
		method: 'GET',
		path: '/api/branches/default-branch'
	},
	getGlobalIndicatorsAndGroups: {
		method: 'GET',
		path: '/api/global-indicators-and-groups'
	},
	getGlobalInteractionLog: {
		method: 'GET',
		path: '/api/logs/interaction/details/{timestamp}'
	},
	getGlobalInteractionLogFrequencies: {
		method: 'GET',
		path: '/api/logs/interaction/frequencies'
	},
	getGlobalInteractionLogs: {
		method: 'GET',
		path: '/api/logs/interaction'
	},
	getGlobalJavascriptErrorLog: {
		method: 'GET',
		path: '/api/logs/javascript-errors/details/{timestamp}'
	},
	getGlobalJavascriptErrorLogFrequencies: {
		method: 'GET',
		path: '/api/logs/javascript-errors/frequencies'
	},
	getGlobalJavascriptErrorLogs: {
		method: 'GET',
		path: '/api/logs/javascript-errors'
	},
	getGlobalRecentBranchesGetRequest: {
		method: 'GET',
		path: '/api/branches/recent-branches'
	},
	getGlobalServiceLog: {
		method: 'GET',
		path: '/api/logs/service/details/{timestamp}'
	},
	getGlobalServiceLogFrequencies: {
		method: 'GET',
		path: '/api/logs/service/frequencies'
	},
	getGlobalServiceLogs: {
		method: 'GET',
		path: '/api/logs/service'
	},
	getGlobalWorkerLog: {
		method: 'GET',
		path: '/api/logs/worker/details/{timestamp}'
	},
	getGlobalWorkerLogFrequencies: {
		method: 'GET',
		path: '/api/logs/worker/frequencies'
	},
	getGlobalWorkerLogs: {
		method: 'GET',
		path: '/api/logs/worker'
	},
	getGroupAutoCompletionSuggestions: {
		method: 'GET',
		path: '/api/auth/import/groups/auto-completion-suggestions/{input}'
	},
	getHealthStatus: {
		method: 'GET',
		path: '/api/health-check'
	},
	getHeapDumpFormPage: {
		method: 'GET',
		path: '/api/debug/heap-dump',
		acceptType: 'text/html'
	},
	getImpactedTests: {
		method: 'GET',
		path: '/api/projects/{project}/impacted-tests'
	},
	getImpactedTestsFromAvailableTests: {
		method: 'PUT',
		path: '/api/projects/{project}/impacted-tests',
		contentType: 'application/json'
	},
	getImportantBranches: {
		method: 'GET',
		path: '/api/projects/{project}/important-branches'
	},
	getInstancePermissions: {
		method: 'GET',
		path: '/api/basic-permissions/{instanceId}'
	},
	getIssueAffectedFiles: {
		method: 'GET',
		path: '/api/projects/{project}/issues/{issueId}/affected-files'
	},
	getIssuesByRepositoryCommits: {
		method: 'GET',
		path: '/api/projects/{project}/commit-issues'
	},
	getIssuesDetails: {
		method: 'GET',
		path: '/api/projects/{project}/issues/details'
	},
	getJavaProfilerJvmPackageNames: {
		method: 'GET',
		path: '/api/projects/{project}/jacoco-wizard/packages'
	},
	getJob: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}'
	},
	getJobOptions: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/options'
	},
	getJobQueueSize: {
		method: 'GET',
		path: '/api/execution-status/queue-size'
	},
	getJobs: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs'
	},
	getKubernetesManifest: {
		method: 'GET',
		path: '/api/projects/{project}/kubernetes-manifest/{uniformPath}'
	},
	getLanguageRules: {
		method: 'GET',
		path: '/api/language-rules'
	},
	getLanguageRulesForAnalysisProfile: {
		method: 'GET',
		path: '/api/language-rules/analysis-profile/{analysisProfileName}'
	},
	getLanguageRulesForProject: {
		method: 'GET',
		path: '/api/language-rules/{project}'
	},
	getLastCommitOfType: {
		method: 'GET',
		path: '/api/projects/{project}/repository/last-commit'
	},
	getLateExternalUploadProcessingInfo: {
		method: 'GET',
		path: '/api/projects/{project}/late-external-upload'
	},
	getLicenseInfo: {
		method: 'GET',
		path: '/api/license-info'
	},
	getLicenses: {
		method: 'GET',
		path: '/api/projects/{project}/licenses'
	},
	getLinkRoles: {
		method: 'PUT',
		path: '/api/spec-items/default/{accountName}/schema/link-roles',
		contentType: 'application/json'
	},
	getListOfActiveCommitters: {
		method: 'GET',
		path: '/api/license/active-committer-names'
	},
	getListOfActiveCommittersForProjects: {
		method: 'POST',
		path: '/api/license/active-committer-names',
		contentType: 'application/json'
	},
	getLoginContext: {
		method: 'GET',
		path: '/api/context/login'
	},
	getMergeRequest: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/{idWithRepository}'
	},
	getMergeRequestAffectedFiles: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/affected-files'
	},
	getMergeRequestDelta: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/{idWithRepository}/delta'
	},
	getMergeRequestFindingChurn: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/finding-churn'
	},
	getMergeRequestRepositoryChurn: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests/repository-churn'
	},
	getMetadataForFile: {
		method: 'GET',
		path: '/api/projects/{project}/abap-file-meta-data/{filePath}'
	},
	getMethodHistory: {
		method: 'GET',
		path: '/api/projects/{project}/methods/history/{uniformPath}'
	},
	getMetricBenchmark: {
		method: 'GET',
		path: '/api/audit/metric-benchmark'
	},
	getMetricDirectorySchemaForPath: {
		method: 'GET',
		path: '/api/projects/{project}/metric-schema/{uniformPathType}'
	},
	getMetricDirectorySchemas: {
		method: 'GET',
		path: '/api/metric-schema'
	},
	getMetricSchemaEntries: {
		method: 'GET',
		path: '/api/external-metrics'
	},
	getMetricThresholdConfiguration: {
		method: 'GET',
		path: '/api/metric-threshold-configurations/{metricThresholdConfigurationName}'
	},
	getMinimizedTests: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests'
	},
	getMonitoringPreview: {
		method: 'GET',
		path: '/api/monitoring-info'
	},
	getMultiProjectLatexTable: {
		method: 'GET',
		path: '/api/audit/latex-table'
	},
	getNumberOfActiveUsers: {
		method: 'GET',
		path: '/api/global-warnings/activeUsers'
	},
	getOpenIdEndpoints: {
		method: 'GET',
		path: '/api/auth/openid/issuer-endpoints'
	},
	getPartitions: {
		method: 'GET',
		path: '/api/projects/{project}/external-analysis/status/partitions'
	},
	getPermissionLookup: {
		method: 'GET',
		path: '/api/basic-permissions'
	},
	getPerspectiveContext: {
		method: 'GET',
		path: '/api/context/perspective'
	},
	getPreCommitContent: {
		method: 'GET',
		path: '/api/projects/{project}/content/formatted/pre-commit'
	},
	getProfilerConfiguration: {
		method: 'GET',
		path: '/api/profiler-configurations/{configurationId}'
	},
	getProjectAnalysisProgress: {
		method: 'GET',
		path: '/api/projects/{project}/analysis-progress'
	},
	getProjectConfiguration: {
		method: 'GET',
		path: '/api/projects/{project}/configuration'
	},
	getProjectCriticalEventLog: {
		method: 'GET',
		path: '/api/projects/{project}/logs/critical-events/details/{timestamp}'
	},
	getProjectCriticalEventLogFrequencies: {
		method: 'GET',
		path: '/api/projects/{project}/logs/critical-events/frequencies'
	},
	getProjectCriticalEventLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/critical-events'
	},
	getProjectInteractionLog: {
		method: 'GET',
		path: '/api/projects/{project}/logs/interaction/details/{timestamp}'
	},
	getProjectInteractionLogFrequencies: {
		method: 'GET',
		path: '/api/projects/{project}/logs/interaction/frequencies'
	},
	getProjectInteractionLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/interaction'
	},
	getProjectJavascriptErrorLog: {
		method: 'GET',
		path: '/api/projects/{project}/logs/javascript-errors/details/{timestamp}'
	},
	getProjectJavascriptErrorLogFrequencies: {
		method: 'GET',
		path: '/api/projects/{project}/logs/javascript-errors/frequencies'
	},
	getProjectJavascriptErrorLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/javascript-errors'
	},
	getProjectNotificationRules: {
		method: 'GET',
		path: '/api/notification-rules/project'
	},
	getProjectOptions: {
		method: 'GET',
		path: '/api/projects/{project}/options'
	},
	getProjectServiceLog: {
		method: 'GET',
		path: '/api/projects/{project}/logs/service/details/{timestamp}'
	},
	getProjectServiceLogFrequencies: {
		method: 'GET',
		path: '/api/projects/{project}/logs/service/frequencies'
	},
	getProjectServiceLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/service'
	},
	getProjectUserActivityTrend: {
		method: 'GET',
		path: '/api/unique-project-users/trend'
	},
	getProjectWorkerLog: {
		method: 'GET',
		path: '/api/projects/{project}/logs/worker/details/{timestamp}'
	},
	getProjectWorkerLogFrequencies: {
		method: 'GET',
		path: '/api/projects/{project}/logs/worker/frequencies'
	},
	getProjectWorkerLogs: {
		method: 'GET',
		path: '/api/projects/{project}/logs/worker'
	},
	getReadWriteStatistics: {
		method: 'GET',
		path: '/api/io-performance',
		acceptType: 'text/plain'
	},
	getRecentBranches: {
		method: 'GET',
		path: '/api/projects/{project}/recent-branches'
	},
	getRedundantLiteralTreemap: {
		method: 'GET',
		path: '/api/projects/{project}/redundant-literals/treemap'
	},
	getRedundantLiteralsQuery: {
		method: 'GET',
		path: '/api/projects/{project}/redundant-literals'
	},
	getReport: {
		method: 'GET',
		path: '/api/quality-reports/{id}'
	},
	getReportSlideRawRenderData: {
		method: 'GET',
		path: '/api/quality-reports/{reportId}/slides/{slideId}/raw-render-data'
	},
	getRepositoryChurn: {
		method: 'GET',
		path: '/api/projects/{project}/repository-churn'
	},
	getRepositoryLog: {
		method: 'GET',
		path: '/api/projects/{project}/repository-logs'
	},
	getRepositoryLogWithSplitAggregates: {
		method: 'GET',
		path: '/api/projects/{project}/repository-logs/split'
	},
	getRepositorySummary: {
		method: 'GET',
		path: '/api/projects/{project}/repository-summary'
	},
	getResolvedTasks: {
		method: 'GET',
		path: '/api/projects/{project}/tasks'
	},
	getResourceHistory: {
		method: 'GET',
		path: '/api/projects/{project}/{uniformPath}/resource-history'
	},
	getResourceType: {
		method: 'GET',
		path: '/api/projects/{project}/resource-type'
	},
	getRetrospective: {
		method: 'GET',
		path: '/api/projects/{project}/retrospectives/{id}'
	},
	getRetrospectivesForProject: {
		method: 'GET',
		path: '/api/projects/{project}/retrospectives'
	},
	getReviewBaseline: {
		method: 'GET',
		path: '/api/projects/{project}/baseline'
	},
	getRisks: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/risks'
	},
	getRoleSchema: {
		method: 'GET',
		path: '/api/roles'
	},
	getRunningProfiler: {
		method: 'GET',
		path: '/api/running-profilers/{profilerId}'
	},
	getRunningProfilers: {
		method: 'GET',
		path: '/api/running-profilers'
	},
	getSamlIdentityProviderMetadata: {
		method: 'GET',
		path: '/api/auth/saml/idp-metadata'
	},
	getSchedulerControlPage: {
		method: 'GET',
		path: '/api/scheduler',
		acceptType: 'text/html'
	},
	getSelectedTests: {
		method: 'GET',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests'
	},
	getServerOptionSchema: {
		method: 'GET',
		path: '/api/options/server/schema'
	},
	getServiceLoad: {
		method: 'GET',
		path: '/api/service-load'
	},
	getSingleProjectLatexTable: {
		method: 'GET',
		path: '/api/projects/{project}/audit/latex-table'
	},
	getSingleProjectOption: {
		method: 'GET',
		path: '/api/projects/{project}/options/{optionId}'
	},
	getSingleServerOption: {
		method: 'GET',
		path: '/api/options/server/{optionId}'
	},
	getSlideImage: {
		method: 'GET',
		path: '/api/reports/{reportId}/images/{id}',
		acceptType: 'image/*'
	},
	getSnapshot: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{id}'
	},
	getSnapshotStatus: {
		method: 'GET',
		path: '/api/instance-comparison/snapshots/{id}/status'
	},
	getSpecItemChurn: {
		method: 'GET',
		path: '/api/projects/{project}/delta/affected-spec-items'
	},
	getSpecItemDetails: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/details'
	},
	getSpecItemVerifies: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/{id}/verifies'
	},
	getSpecItemsVerifiedByQueried: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/verified-by-queried-items'
	},
	getSpecItemsVerifyingQueried: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/verify-queried-items'
	},
	getStacktrace: {
		method: 'GET',
		path: '/api/projects/{project}/tests/{test}/stacktrace'
	},
	getStateChangeTimestamps: {
		method: 'GET',
		path: '/api/debug/performance/state/timestamps/{project}'
	},
	getStorePerformanceDetails: {
		method: 'GET',
		path: '/api/debug/performance/store-details.csv'
	},
	getStoredServiceProviderConfiguration: {
		method: 'GET',
		path: '/api/auth/saml/sp-configuration',
		acceptType: 'application/xml'
	},
	getStoredTestLists: {
		method: 'GET',
		path: '/api/projects/{project}/test-lists'
	},
	getStoredTestListsNames: {
		method: 'GET',
		path: '/api/projects/{project}/test-lists/names'
	},
	getSummary1: {
		method: 'GET',
		path: '/api/backups/import/summary'
	},
	getSummary2: {
		method: 'GET',
		path: '/api/projects/{project}/ai/{engine}/content/summary'
	},
	getSystemInformation: {
		method: 'GET',
		path: '/api/system-info'
	},
	getTask: {
		method: 'GET',
		path: '/api/projects/{project}/tasks/{id}'
	},
	getTaskPreviousNext: {
		method: 'GET',
		path: '/api/projects/{project}/tasks/{task}/siblings'
	},
	getTaskSummary: {
		method: 'GET',
		path: '/api/projects/{project}/tasks/summary'
	},
	getTaskTags: {
		method: 'GET',
		path: '/api/projects/{project}/tasks/tags'
	},
	getTaskToFindingMapping: {
		method: 'POST',
		path: '/api/projects/{project}/findings/tasks',
		contentType: 'application/x-www-form-urlencoded'
	},
	getTasksWithCount: {
		method: 'GET',
		path: '/api/projects/{project}/tasks/with-count'
	},
	getTeamscaleShutdownPage: {
		method: 'GET',
		path: '/api/shutdown',
		acceptType: 'text/html'
	},
	getTeamscaleUploadInvocation: {
		method: 'GET',
		path: '/api/projects/{project}/ts-upload-wizard/ts-upload-invocation'
	},
	getTeamscaleUploadWizardInput: {
		method: 'GET',
		path: '/api/projects/{project}/ts-upload-wizard'
	},
	getTechnologyDependencies: {
		method: 'GET',
		path: '/api/projects/{project}/audit/technology/dependencies'
	},
	getTechnologyTreeMap: {
		method: 'GET',
		path: '/api/projects/{project}/audit/technology/treemap'
	},
	getTestCoverage: {
		method: 'GET',
		path: '/api/projects/{project}/test-coverage/{uniformPath}'
	},
	getTestCoverageOverlay: {
		method: 'GET',
		path: '/api/projects/{project}/overlay-tests'
	},
	getTestCoveragePost: {
		method: 'POST',
		path: '/api/projects/{project}/test-coverage/{uniformPath}',
		contentType: 'application/x-www-form-urlencoded'
	},
	getTestGapDataAsCsv: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps.csv',
		acceptType: 'text/csv'
	},
	getTestGapTreeMap: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps/treemap'
	},
	getTgaPercentage: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps/percentage'
	},
	getTgaTable: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps/metrics'
	},
	getTgaTestCoveragePartitions: {
		method: 'GET',
		path: '/api/projects/{project}/test-coverage/line-based/partitions'
	},
	getTgaTrend: {
		method: 'GET',
		path: '/api/projects/{project}/test-gaps/trend'
	},
	getTrackerCustomFields: {
		method: 'PUT',
		path: '/api/connectors/custom-fields',
		contentType: 'application/json'
	},
	getTriggerGraphDot: {
		method: 'GET',
		path: '/api/projects/{project}/debug/triggers/graph-json'
	},
	getTriggerPerformanceDetails: {
		method: 'GET',
		path: '/api/debug/performance/trigger/details.csv'
	},
	getUnusedEventId: {
		method: 'GET',
		path: '/api/event-announcement/unused-id'
	},
	getUsageDataPreview: {
		method: 'POST',
		path: '/api/usage-data/preview',
		contentType: 'application/json'
	},
	getUserAutoCompletionSuggestions: {
		method: 'GET',
		path: '/api/auth/import/users/auto-completion-suggestions/{input}'
	},
	getUserAvatarData: {
		method: 'GET',
		path: '/api/avatars/{user}/image',
		acceptType: 'image/*'
	},
	getUserGroup: {
		method: 'GET',
		path: '/api/user-groups/{userGroupName}'
	},
	getVerifiedBySpecItems: {
		method: 'GET',
		path: '/api/projects/{project}/spec-items/{id}/verified-by'
	},
	getWidgetContext: {
		method: 'GET',
		path: '/api/dashboards/widget-context'
	},
	getWorkerStatus: {
		method: 'GET',
		path: '/api/execution-status/workers'
	},
	hasSufficientRam: {
		method: 'GET',
		path: '/api/global-warnings/sufficientRam'
	},
	hideEventAnnouncement: {
		method: 'POST',
		path: '/api/event-announcement/hide',
		contentType: 'application/json'
	},
	importAbapLintConfigurationFile: {
		method: 'POST',
		path: '/api/import-abaplint-configuration',
		contentType: 'multipart/form-data'
	},
	importAnalysisProfile: {
		method: 'POST',
		path: '/api/analysis-profiles/import',
		contentType: 'multipart/form-data'
	},
	importAndReplaceDashboards: {
		method: 'PUT',
		path: '/api/dashboards/import',
		contentType: 'multipart/form-data'
	},
	importBackup: {
		method: 'POST',
		path: '/api/backups/import',
		contentType: 'multipart/form-data'
	},
	importDashboard: {
		method: 'POST',
		path: '/api/dashboards/import',
		contentType: 'multipart/form-data'
	},
	importDashboardTemplate: {
		method: 'POST',
		path: '/api/dashboard-templates/import',
		contentType: 'multipart/form-data'
	},
	importExternalFindings: {
		method: 'POST',
		path: '/api/external-findings/import',
		contentType: 'multipart/form-data'
	},
	importExternalMetrics: {
		method: 'POST',
		path: '/api/external-metrics/import',
		contentType: 'multipart/form-data'
	},
	importGroup: {
		method: 'POST',
		path: '/api/auth/import/groups/{groupName}'
	},
	importSonarProfile: {
		method: 'POST',
		path: '/api/import-sonar-profile',
		contentType: 'multipart/form-data'
	},
	importSwiftLintConfiguration: {
		method: 'POST',
		path: '/api/import-swiftlint-configuration',
		contentType: 'multipart/form-data'
	},
	importUser: {
		method: 'POST',
		path: '/api/auth/import/users/{userName}'
	},
	isMergeRequestsEnabled: {
		method: 'GET',
		path: '/api/projects/{project}/feature/merge-request'
	},
	isSnapshotBackupSupported: {
		method: 'GET',
		path: '/api/storage-snapshot'
	},
	isSpecItemAnalysisEnabled: {
		method: 'GET',
		path: '/api/projects/{project}/feature/spec-item'
	},
	listAllParseLogEntries: {
		method: 'GET',
		path: '/api/projects/{project}/parse-log/all'
	},
	listMergeRequests: {
		method: 'GET',
		path: '/api/projects/{project}/merge-requests'
	},
	listQualityReports: {
		method: 'GET',
		path: '/api/quality-reports'
	},
	loadFromNamedTestList: {
		method: 'POST',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests/load-from-named-list'
	},
	markDashboardAsFavorite: {
		method: 'POST',
		path: '/api/dashboards/{dashboardId}/favorite'
	},
	performLogin: {
		method: 'POST',
		path: '/api/auth/login',
		contentType: 'application/json'
	},
	performTestQuery: {
		method: 'GET',
		path: '/api/projects/{project}/test-query'
	},
	postBasicRoleForInstance: {
		method: 'POST',
		path: '/api/roles/basic-role-assignments/{instance}',
		contentType: 'application/json'
	},
	postBasicRoleGlobally: {
		method: 'POST',
		path: '/api/roles/basic-role-assignments',
		contentType: 'application/json'
	},
	postGlobalRole: {
		method: 'POST',
		path: '/api/roles/global-roles',
		contentType: 'application/json'
	},
	postGlobalRoleWithAssignments: {
		method: 'POST',
		path: '/api/roles/global-role-assignments',
		contentType: 'application/json'
	},
	postJobTests: {
		method: 'POST',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests',
		contentType: 'application/json'
	},
	postProjectRole: {
		method: 'POST',
		path: '/api/roles/project-roles',
		contentType: 'application/json'
	},
	postResolveUserAliases: {
		method: 'POST',
		path: '/api/users/names/resolution',
		contentType: 'application/x-www-form-urlencoded'
	},
	postRoleChangeGlobally: {
		method: 'POST',
		path: '/api/roles/project-role-assignments',
		contentType: 'application/json'
	},
	postTgaPercentage: {
		method: 'POST',
		path: '/api/projects/{project}/test-gaps/percentage',
		contentType: 'application/x-www-form-urlencoded'
	},
	postUserAvatarData: {
		method: 'POST',
		path: '/api/avatars/{user}/image',
		contentType: 'multipart/form-data'
	},
	previewRepositoryBranches: {
		method: 'POST',
		path: '/api/branches-retriever',
		contentType: 'application/json'
	},
	previewRepositoryCommitMessages: {
		method: 'POST',
		path: '/api/commit-messages-retriever',
		contentType: 'application/json'
	},
	previewRepositoryFiles: {
		method: 'POST',
		path: '/api/files-retriever',
		contentType: 'application/json'
	},
	processBuildWebhookEvent: {
		method: 'POST',
		path: '/api/projects/{project}/build-web-hook'
	},
	processWebhook: {
		method: 'POST',
		path: '/api/gitea/web-hook',
		contentType: 'application/json'
	},
	putAvatarData: {
		method: 'PUT',
		path: '/api/avatars/{user}'
	},
	putGlobalRole: {
		method: 'PUT',
		path: '/api/roles/global-roles/{name}',
		contentType: 'application/json'
	},
	putJobTestsFromImpacted: {
		method: 'PUT',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests-from-impacted'
	},
	putJobTestsFromQuery: {
		method: 'PUT',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests'
	},
	putProjectRole: {
		method: 'PUT',
		path: '/api/roles/project-roles/{name}',
		contentType: 'application/json'
	},
	receiveAnalysisCompletedConfirmation: {
		method: 'POST',
		path: '/api/analysis/clangtidy/completion-hook'
	},
	receiveHeartbeat: {
		method: 'PUT',
		path: '/api/running-profilers/{profilerId}',
		contentType: 'application/json'
	},
	redirectToCorrectHost: {
		method: 'GET',
		path: '/api/instance-comparison/redirect'
	},
	regenerateStoredServiceProviderCertificate: {
		method: 'POST',
		path: '/api/auth/saml/sp-configuration/certificate/generate'
	},
	registerProfiler: {
		method: 'POST',
		path: '/api/running-profilers',
		contentType: 'application/json'
	},
	removeAccessKeys: {
		method: 'DELETE',
		path: '/api/users/{user}/access-key'
	},
	removeQualityReportSlides: {
		method: 'POST',
		path: '/api/quality-reports/{reportId}/remove-slides',
		contentType: 'application/json'
	},
	reportJavascriptError: {
		method: 'POST',
		path: '/api/logs/javascript-errors/report',
		contentType: 'application/json'
	},
	requestAnalysisAndOpenSession: {
		method: 'POST',
		path: '/api/analysis/clangtidy-execution',
		contentType: 'application/json'
	},
	requestAnalysisAndOpenSession1: {
		method: 'POST',
		path: '/api/analysis/sonarlint',
		contentType: 'application/json'
	},
	resolveFinding: {
		method: 'GET',
		path: '/api/projects/{project}/ai/{engine}/findings/{id}/resolution'
	},
	resolveTag: {
		method: 'POST',
		path: '/api/projects/{project}/git-tags/resolve',
		contentType: 'application/json'
	},
	revoteOnAllOpenMergeRequests: {
		method: 'POST',
		path: '/api/projects/{project}/merge-requests/re-vote'
	},
	revoteOnMergeRequest: {
		method: 'POST',
		path: '/api/projects/{project}/merge-requests/re-vote/{idWithRepository}'
	},
	runDatabaseCompaction: {
		method: 'POST',
		path: '/api/database-compaction'
	},
	saveAsNamedTestList: {
		method: 'POST',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/tests/save-as-named-list',
		contentType: 'application/json'
	},
	saveTestListDescriptor: {
		method: 'POST',
		path: '/api/projects/{project}/test-lists',
		contentType: 'application/json'
	},
	setProjectNotificationRules: {
		method: 'PUT',
		path: '/api/notification-rules/project',
		contentType: 'application/json'
	},
	setSchedulingMode: {
		method: 'PUT',
		path: '/api/scheduler/mode',
		contentType: 'application/json'
	},
	setupJob: {
		method: 'POST',
		path: '/api/projects/{project}/minimized-tests/jobs'
	},
	showDefaultAdminWarning: {
		method: 'GET',
		path: '/api/global-warnings/defaultAdmin'
	},
	showDefaultBackupSettingsWarning: {
		method: 'GET',
		path: '/api/global-warnings/defaultBackupSettings'
	},
	showInMemoryWarning: {
		method: 'GET',
		path: '/api/global-warnings/inMemory'
	},
	showShadowModeWarning: {
		method: 'GET',
		path: '/api/global-warnings/shadowMode'
	},
	shutdown: {
		method: 'POST',
		path: '/api/shutdown',
		contentType: 'application/x-www-form-urlencoded'
	},
	startJob: {
		method: 'POST',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}/start'
	},
	stopAndDeleteJob: {
		method: 'DELETE',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}'
	},
	storeEventAnnouncement: {
		method: 'PUT',
		path: '/api/event-announcement/events/{eventId}',
		contentType: 'application/json'
	},
	synchronizeAllGroups: {
		method: 'POST',
		path: '/api/auth/synchronization/groups'
	},
	synchronizeAllUsers: {
		method: 'POST',
		path: '/api/auth/synchronization/users'
	},
	synchronizeSingleGroup: {
		method: 'POST',
		path: '/api/auth/synchronization/groups/{groupName}'
	},
	triggerSnapshotBackup: {
		method: 'POST',
		path: '/api/storage-snapshot',
		contentType: 'multipart/form-data'
	},
	unmarkToleratedAndFalsePositiveFindingsBeforeCommit: {
		method: 'DELETE',
		path: '/api/projects/{project}/findings/unmark-before-commit'
	},
	unregisterProfiler: {
		method: 'DELETE',
		path: '/api/running-profilers/{profilerId}'
	},
	updateAnalysisProfile: {
		method: 'PUT',
		path: '/api/analysis-profiles',
		contentType: 'application/json'
	},
	updateDashboardTemplate: {
		method: 'PUT',
		path: '/api/dashboard-templates/{templateId}',
		contentType: 'application/json'
	},
	updateExternalAccounts: {
		method: 'PUT',
		path: '/api/external-accounts/{externalCredentialsName}',
		contentType: 'application/json'
	},
	updateExternalAnalysisGroup: {
		method: 'PUT',
		path: '/api/external-findings/groups/{groupName}',
		contentType: 'application/json'
	},
	updateExternalFindingDescription: {
		method: 'PUT',
		path: '/api/external-findings/descriptions/{externalFindingDescriptionTypeId}',
		contentType: 'application/json'
	},
	updateExternalMetrics: {
		method: 'PUT',
		path: '/api/external-metrics',
		contentType: 'application/json'
	},
	updateExternalStorageBackend: {
		method: 'PUT',
		path: '/api/external-storage-backends/{externalStorageBackendName}',
		contentType: 'application/json'
	},
	updateJobOptions: {
		method: 'PUT',
		path: '/api/projects/{project}/minimized-tests/jobs/{jobId}'
	},
	updateMetricThresholdConfiguration: {
		method: 'PUT',
		path: '/api/metric-threshold-configurations',
		contentType: 'application/json'
	},
	updatePassword: {
		method: 'PUT',
		path: '/api/authentication/{username}',
		contentType: 'application/json'
	},
	updateProfilerConfiguration: {
		method: 'PUT',
		path: '/api/profiler-configurations/{configurationId}',
		contentType: 'application/json'
	},
	updateQualityReport: {
		method: 'PUT',
		path: '/api/quality-reports/{id}',
		contentType: 'application/json'
	},
	updateQualityReportSlides: {
		method: 'PUT',
		path: '/api/quality-reports/{id}/update-slides',
		contentType: 'application/json'
	},
	updateRetrospective: {
		method: 'PUT',
		path: '/api/projects/{project}/retrospectives/{id}',
		contentType: 'application/json'
	},
	updateRetrospectiveNotes: {
		method: 'PUT',
		path: '/api/projects/{project}/retrospectives/{id}/notes',
		contentType: 'application/json'
	},
	updateTask: {
		method: 'PUT',
		path: '/api/projects/{project}/tasks/{id}',
		contentType: 'application/json'
	},
	updateTaskResolution: {
		method: 'PUT',
		path: '/api/projects/{project}/tasks/{id}/resolution',
		contentType: 'application/json'
	},
	updateTaskStatus: {
		method: 'PUT',
		path: '/api/projects/{project}/tasks/{id}/status',
		contentType: 'application/json'
	},
	updateUserGroup: {
		method: 'PUT',
		path: '/api/user-groups/{oldUserGroupName}',
		contentType: 'application/json'
	},
	uploadArchitecture: {
		method: 'POST',
		path: '/api/projects/{project}/architectures',
		contentType: 'multipart/form-data'
	},
	uploadArchitectureAsJson: {
		method: 'POST',
		path: '/api/projects/{project}/architectures/as-json',
		contentType: 'application/json'
	},
	uploadExternalSnapshot: {
		method: 'POST',
		path: '/api/instance-comparison/snapshots/external',
		contentType: 'multipart/form-data'
	},
	uploadReport: {
		method: 'POST',
		path: '/api/projects/{project}/external-analysis/session/{sessionId}/report',
		contentType: 'multipart/form-data'
	},
	uploadSlideImage: {
		method: 'POST',
		path: '/api/reports/{reportId}/images',
		contentType: 'multipart/form-data'
	},
	validateBranchingConfiguration: {
		method: 'PUT',
		path: '/api/branching-configuration/validation',
		contentType: 'application/json'
	},
	verifyPowershellScriptAnalyzerSetup: {
		method: 'GET',
		path: '/api/verify-powershell-script-analyzer-setup'
	}
} as const;
