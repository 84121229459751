import { ProjectResolver } from 'ts/base/ProjectResolver';
import { PerspectiveViewDescriptorBase } from 'ts/base/view/PerspectiveViewDescriptorBase';
import type { NavigationHash } from 'ts/commons/NavigationHash';
import type { ExtendedPerspectiveContext } from 'ts/data/ExtendedPerspectiveContext';
import { EDashboardPerspectiveView } from 'ts/perspectives/dashboard/EDashboardPerspectiveView';
import { ETeamscalePerspective } from 'typedefs/ETeamscalePerspective';

/** Descriptor for the dashboard perspective. */
export class DashboardPerspectiveDescriptor extends PerspectiveViewDescriptorBase {
	public constructor() {
		super(ETeamscalePerspective.DASHBOARD, EDashboardPerspectiveView);
	}

	public override getProject(perspectiveContext: ExtendedPerspectiveContext, hash: NavigationHash): string | null {
		const projectResolver = new ProjectResolver(perspectiveContext);
		return projectResolver.getProjectForDashboardOrReportsPerspective(hash);
	}
}
