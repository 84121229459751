import type { UseMutateFunction } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { QUERY } from 'api/Query';
import type { JSX } from 'react';
import { useUserPermissionInfo } from 'ts/base/hooks/PermissionInfoHook';
import { usePerspectiveContext } from 'ts/base/hooks/PerspectiveContextHook';
import { useTeamscaleInfo } from 'ts/base/hooks/TeamscaleInfoHook';
import { useTeamscaleServiceClient } from 'ts/base/hooks/TeamscaleServiceClientHook';
import { GlobalWarning } from 'ts/base/perspective/topbar/warnings/GlobalWarning';
import { LONG_STALE_TIME } from 'ts/base/perspective/topbar/warnings/GlobalWarnings';
import { TeamscaleLink } from 'ts/base/routing/TeamscaleLink';
import { Links } from 'ts/commons/links/Links';
import { NavigationUtils } from 'ts/commons/NavigationUtils';
import { ToastNotification } from 'ts/commons/ToastNotification';
import { wrapLicenseIfExists } from 'ts/perspectives/system/SystemLicenseView';
import { EGlobalPermission } from 'typedefs/EGlobalPermission';

const LAST_ACCESSED_DASHBOARD_OPTION = 'license.user.limit';

/** Warns the user when the user limit of the license is exceeded. */
export function useUserLimitWarning(): JSX.Element | null {
	const teamscaleInfo = useTeamscaleInfo();
	const permissionInfo = useUserPermissionInfo();
	const client = useTeamscaleServiceClient();
	const perspectiveContext = usePerspectiveContext();
	const license = wrapLicenseIfExists(teamscaleInfo.licenseInfo.license);
	const { data: numberOfActiveUsers } = QUERY.getNumberOfActiveUsers().useQuery({ staleTime: LONG_STALE_TIME });
	const { mutate: handleAcknowledgeUserLimit } = useMutation({
		mutationFn: () => {
			return client.setCurrentUserOptionAsync(perspectiveContext, LAST_ACCESSED_DASHBOARD_OPTION, {
				numberOfAcknowledgedUsers: numberOfActiveUsers ?? 0,
				licenseEndDate: license?.validTo
			});
		},
		onError(e) {
			ToastNotification.error('We are sorry. Acknowledging the exceeded user limit failed.' + e);
		}
	});

	if (numberOfActiveUsers === undefined || license === null) {
		return null;
	}

	if (
		showWarning(
			perspectiveContext.userInfo.userOptions['license.user.limit'].numberOfAcknowledgedUsers,
			numberOfActiveUsers,
			license.numUsers,
			perspectiveContext.userInfo.userOptions['license.user.limit'].licenseEndDate,
			license.validTo
		) &&
		// Currently, the warning is only shown for admins as we might count too many users
		permissionInfo.hasGlobalPermission(EGlobalPermission.ACCESS_ADMINISTRATIVE_SERVICES)
	) {
		return <UserLimitWarning handleAcknowledgeUserLimit={handleAcknowledgeUserLimit} />;
	}
	return null;
}

/**
 * Returns true in the following cases:<ul> <li>User limit is exceeded and has not been acknowledged for the current
 * license by the user</li> <li> User limit is exceeded and the number of users is 10% higher than the acknowledged
 * value</li></ul>
 */

function showWarning(
	numberOfAcknowledgedUsers: number,
	numberOfUsers: number,
	numberOfLicensedUsers: number,
	acknowledgedLicenseEndDate: string | undefined,
	licenseEndDate: string
) {
	// 10% buffer before we show the warning
	const percentageBufferBeforeWarningShown = 1.1;

	// If a new license has been loaded, we need to reset the user option to show the warning again.
	// Since we don't want to export the license key to the frontend we detect a new license if the license
	// end date or the number of licensed users changed. This is not 100% accurate but good enough since this
	// is just used for showing a warning
	if (licenseEndDate === acknowledgedLicenseEndDate && numberOfAcknowledgedUsers > numberOfLicensedUsers) {
		return numberOfUsers >= numberOfAcknowledgedUsers * percentageBufferBeforeWarningShown;
	} else {
		return numberOfUsers >= numberOfLicensedUsers * percentageBufferBeforeWarningShown;
	}
}

type UserLimitWarningProps = {
	handleAcknowledgeUserLimit: UseMutateFunction;
};

function UserLimitWarning({ handleAcknowledgeUserLimit }: UserLimitWarningProps): JSX.Element {
	const linkToSettings: string = Links.showSystemLicenseInfo();
	return (
		<GlobalWarning
			message={
				<>
					<TeamscaleLink to={linkToSettings}>License</TeamscaleLink> user limit exceeded
					<button
						className="teamscale-link-button"
						onClick={() =>
							handleAcknowledgeUserLimit(undefined, {
								onSuccess() {
									NavigationUtils.updateLocation(linkToSettings);
								}
							})
						}
						title="License user limit exceeded"
					>
						&nbsp;Acknowledge
					</button>
				</>
			}
			severity="warning"
			tooltip="The user limit of the license can only be exceeded temporarily. Please contact CQSE if the user limit is constantly exceeded."
			multiline
		/>
	);
}
